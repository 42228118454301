<mat-card class="container mat-elevation-z5">
  <mat-card-header style="height: 56px">
    <img mat-card-avatar src="{{ videoImageUrl }}" />
    <mat-card-title style="font-weight: bolder">이미지</mat-card-title>
    <mat-card-subtitle style="font-weight: bolder"
      >이미지 파일 크기는 1MB 이하입니다.</mat-card-subtitle
    >
  </mat-card-header>

  <mat-card-content class="debug-redx">
    <mat-horizontal-stepper
      linear
      #stepper
      (selectionChange)="selectionChange($event)"
    >
      <mat-step>
        <ng-template matStepLabel>파일 선택</ng-template>
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="32px"
          class="file-picker debug-redx"
          id="container"
        >
          <input
            style="display: none"
            type="file"
            accept="image/jpg, image/gif, image/png, image/webp"
            #fileInput
            (change)="onFileSelected($event)"
          />
          <button mat-button (click)="fileInput.click()">
            <mat-icon>add_circle_outline</mat-icon>
            <span style="padding-left: 8px">파일 올리기 ...</span>
          </button>

          <div *ngIf="selectedFile">
            <div
              class="debug-redx"
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="0px"
            >
              <mat-icon> photo </mat-icon>
              <div class="mat-body filename">
                {{ selectedFile.name }}&nbsp;&nbsp;({{ selectedFile.size }}
                bytes)
              </div>
            </div>
          </div>
        </div>
        <div>
          <button mat-button (click)="onCancelClick()">취소</button>
          <button mat-button matStepperNext disabled="{{ !selectedFile }}">
            다음
          </button>
        </div>
      </mat-step>
      <!-- //////////////////////////////////// -->

      <mat-step [editable]="false">
        <ng-template matStepLabel>파일 업로드</ng-template>

        <div *ngIf="selectedFile">
          <div
            class="file-upload debug-redx"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="32px"
          >
            <button
              mat-button
              appDebounceClick
              (debounceClick)="onUploadClick()"
              disabled="{{ !isUploadButtonEnabled || postImageUrl !== null }}"
              matTooltip="이미지 업로드"
            >
              <mat-icon>cloud_upload</mat-icon>
              <span class="mat-body filename">
                {{ selectedFile.name }}&nbsp;&nbsp;({{ selectedFile.size }}
                bytes)
              </span>
            </button>

            <mat-progress-spinner
              #uploadProgress
              class="example-margin"
              [color]="color"
              [mode]="mode"
              [value]="progressValue"
              [diameter]="48"
            >
            </mat-progress-spinner>
          </div>
        </div>

        <div>
          <button mat-button (click)="onCancelClick()">취소</button>
          <!-- <button mat-button matStepperPrevious>이전</button> -->
          <button
            mat-button
            matStepperNext
            (click)="onStartSecondsOkClick()"
            disabled="{{ !postImageUrl }}"
          >
            다음
          </button>
        </div>
      </mat-step>
      <!-- //////////////////////////////////// -->
      <mat-step [stepControl]="finishFormGroup" [editable]="false">
        <ng-template matStepLabel>파일 완료</ng-template>

        <form class="debug-redx" [formGroup]="finishFormGroup">
          <div class="debug-redx" fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field class="start-seconds-field" appearance="standard">
              <mat-label>이미지 주소</mat-label>

              <textarea
                [cdkTextareaAutosize]="true"
                [cdkAutosizeMaxRows]="8"
                [cdkAutosizeMinRows]="2"
                formControlName="postImageUrlControl"
                matInput
                #message
                maxlength="5012"
                placeholder="의견을 올려주세요 ..."
                [readonly]="true"
              ></textarea>
            </mat-form-field>

            <span class="placeholder">&nbsp;&nbsp;&nbsp;</span>

            <div *ngIf="postImageUrl">
              <img
                mat-card-avatar
                src="{{ postImageUrl.previewUrl }}"
                (click)="onPreviewImageClick()"
              />
            </div>
          </div>
          <div>
            <button mat-button (click)="onCancelClick()">취소</button>
            <button mat-button (click)="stepper.reset()">초기화</button>
            <button mat-button (click)="onFinishClick()">완료</button>
          </div>
        </form>
      </mat-step>
      <!-- //////////////////////////////////// -->
    </mat-horizontal-stepper>
  </mat-card-content>
</mat-card>
