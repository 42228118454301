import { createAction, props } from '@ngrx/store';
import { JSend } from '../models/jsend';
import { Item } from '../models/youtube-data';

/**
 * @author: john@gomedialy.com
 * @version: 0.13, 09/12/2020
 * @version: 0.17, 10/31/2020
 * @version: 0.21, 12/20/2020
 * @version: 0.22, 01/07/2021
 */

export const hasScrolledToTop = createAction('[Main] Has Scrolled To Top');

export const scrollToTop = createAction('[Main] Scroll To Top');
export const scrollDown = createAction('[Main] Scroll Down');

export const homeRefreshed = createAction('[Main] Home Refreshed');

// home is fully ready.
export const homeLoaded = createAction('[Main] Home Loaded');

export const watchRefreshed = createAction('[Main] Watch Refreshed');

export const browseRefreshed = createAction('[Main] Browse Refreshed');

export const scrollChanged = createAction(
  '[Main] Scroll Changed',
  props<{ path: string; offset: number }>()
);

export const routeChanged = createAction(
  '[Main] Route Changed',
  props<{ path: string; videoId?: string }>()
);

// export const home = createAction('[Main] Home');

// export const watch = createAction(
//   '[Main] Watch',
//   props<{ item: Item; tags: string[] }>()
// );

export const homeRequest = createAction('[Main] Home Request');

export const homeResponse = createAction('[Main] Home Response');

// export const homeResponse = createAction(
//   '[Main] Home Response',
//   props<{ jsend: JSend }>()
// );

export const watchRequest = createAction(
  '[Main] Watch Request',
  props<{ item: Item; tags: string[] }>()
);

export const watchResponse = createAction('[Main] Watch Response');

// export const watchResponse = createAction(
//   '[Main] Watch Response',
//   props<{ jsend: JSend }>()
// );

export const searchRequest = createAction(
  '[Main] Search Request',
  props<{ query: string }>()
);

export const searchResponse = createAction(
  '[Main] Search Response',
  props<{ jsend: JSend }>()
);

export const browseRequest = createAction(
  '[Main] Browse Request',
  props<{ query: string }>()
);

export const browseResponse = createAction(
  '[Main] Browse Response',
  props<{ jsend: JSend }>()
);

/**
 * Ex) /browse -> a 3rd party page -> /browse
 */
export const browseReloadedRequest = createAction(
  '[Main] Browse Reloaded Request',
  props<{ query: string }>()
);

export const browseReloadedResponse = createAction(
  '[Main] Browse Reloaded Response',
  props<{ jsend: JSend }>()
);

// export const loadVideoRequest = createAction(
//   '[Main] Load Video Request',
//   props<{ query: string }>()
// );

// export const loadVideoResponse = createAction(
//   '[Main] Load Video Response',
//   props<{ jsend: JSend }>()
// );
