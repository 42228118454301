import firebase from 'firebase/compat/app';

export const TEXT_MARKDOWN = 'text/markdown; charset=UTF-8';

/**
 * @author: john@gomedialy.com
 * @version: 0.40, 10/28/2020
 * @version: 0.41, 12/25/2020
 */
export interface PostCounts {
  videoId: string;
  postsCount: number;
  subpostsCount: number;
  titlesCount: number;
  subtitlesCount: number;
  // createdAt: Timestamp;
  createdAt: firebase.firestore.Timestamp;
}

export interface Video {
  /**
   * Ex) videoId-12345-1234567
   * For 'video', it does not have any postId
   */
  subvideoId: string;
  videoType: 'video' | 'subtitle' | 'subvideo';
  videoId: string;

  /**
   * main videos do not have any postId
   */
  postId: string | null;
  startSeconds: number;
  endSeconds: number;
  duration: number;
}

export interface Image {
  /**
   * Ex) videoId-12345-1234567
   * For 'video', it does not have any postId
   */
  subvideoId: string;
  videoType: 'video' | 'subtitle' | 'subvideo';
  videoId: string;
  /**
   * Unlike Videos, images always have postId
   */
  postId: string;
  // imageUrl: string;
  previewUrl: string;
  sourceUrl: string;
  checksum: string;
  createdAt: firebase.firestore.Timestamp;
}

export interface Post {
  category: 'post' | 'subtitle';
  tags: string[];
  postType: 'post' | 'subpost' | 'title' | 'subtitle';
  path: string;
  // groupId: string | undefined;
  // postId: string | undefined; // nanoId
  groupId: string;
  postId: string; // nanoId
  status: 'pending' | 'editing' | 'committed' | 'deleted';
  userId: string; // uid
  email: string;
  displayName: string;
  photoUrl: string;
  videoId: string;
  contentType: string;
  content: string; // content
  level: number;
  likes: number;
  dislikes: number;
  controversials: number; // (a + b) * (a/b)
  liked?: boolean;
  disliked?: boolean;

  subvideo0?: Video;
  subvideo1?: Video;
  images?: Image[];

  groupedAt: firebase.firestore.Timestamp;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  editingAt?: firebase.firestore.Timestamp;
  // deletedAt?: Timestamp;
  // pendingAt?: Timestamp;
}

export class EmptyPost {
  public static create(timestamp: firebase.firestore.Timestamp): Post {
    // EmptyPost.create()
    // const timestamp = firebase.firestore.Timestamp.now();
    const post: Post = {
      category: 'post',
      tags: [],
      path: '',
      postType: 'post',
      groupId: '', // videoId-createAt (in millis)
      postId: '', // videoId-createAt (in millis)
      status: 'committed',
      userId: '', // uid
      email: '',
      displayName: '',
      photoUrl: '',
      videoId: '',
      contentType: '',
      content: '', // content
      level: 0,
      likes: 0,
      dislikes: 0,
      controversials: 0,
      groupedAt: timestamp,
      createdAt: timestamp,
      updatedAt: timestamp,
      // groupedAt: firebase.firestore.Timestamp.now(),
      // createdAt: firebase.firestore.Timestamp.now(),
      // updatedAt: firebase.firestore.Timestamp.now(),
    };
    return post;
  }
}

// // const timestamp = firebase.firestore.Timestamp.now();
// export const EMPTY_POST = {
//   category: 'post',
//   tags: [],
//   path: '',
//   postType: 'post',
//   groupId: '', // videoId-createAt (in millis)
//   postId: '', // videoId-createAt (in millis)
//   status: 'committed',
//   userId: '', // uid
//   email: '',
//   displayName: '',
//   photoUrl: '',
//   videoId: '',
//   contentType: '',
//   content: '', // content
//   level: 0,
//   likes: 0,
//   dislikes: 0,
//   controversials: 0,
//   // groupedAt: timestamp,
//   // createdAt: timestamp,
//   // updatedAt: timestamp,
//   // groupedAt: firebase.firestore.Timestamp.now(),
//   // createdAt: firebase.firestore.Timestamp.now(),
//   // updatedAt: firebase.firestore.Timestamp.now(),
// };

/**
 * Timestamp is not serializable on LocalStorageService
 */
export interface PostLocal {
  category: 'post' | 'subtitle';
  tags: string[];
  path: string;
  postType: 'post' | 'subpost' | 'title' | 'subtitle';
  // groupId: string | undefined;
  // postId: string | undefined;
  groupId: string;
  postId: string;
  status: 'pending' | 'editing' | 'committed' | 'deleted';
  userId: string; // uid
  displayName: string;
  email: string;
  photoUrl: string;
  videoId: string;
  contentType: string;
  content: string; // content
  level: number;
  likes: number;
  dislikes: number;
  controversials: number;
  liked?: boolean;
  disliked?: boolean;
  subvideo0?: Video;
  subvideo1?: Video;
  images?: Image[];
  groupedAt: number;
  createdAt: number; // in millis
  updatedAt: number; // in millis
}

export const toPostLocal = (post: Post) => {
  return {
    ...post,
    groupedAt: post.groupedAt.toMillis(),
    createdAt: post.createdAt.toMillis(),
    updatedAt: post.updatedAt.toMillis(),
  };
};

export const toPost = (postLocal: PostLocal) => {
  return {
    ...postLocal,
    groupedAt: firebase.firestore.Timestamp.fromMillis(postLocal.groupedAt),
    createdAt: firebase.firestore.Timestamp.fromMillis(postLocal.createdAt),
    updatedAt: firebase.firestore.Timestamp.fromMillis(postLocal.updatedAt),
  };
};

export const toParent = (path: string) => {
  if (path.endsWith('/posts')) {
    return path;
  }
  const last = path.lastIndexOf('/');
  if (last > 0) {
    return path.substring(0, last);
  }
  return path;
};

export const controversials = (post: Post) => {
  if (post.likes == 0 || post.dislikes == 0) {
    return 0;
  }
  const total = post.likes + post.dislikes;
  if (post.likes >= post.dislikes) {
    // const rate: number = Math.round(post.dislikes / post.likes);
    return +(total * (post.dislikes / post.likes)).toFixed(2);
  } else {
    return +(total * (post.likes / post.dislikes)).toFixed(2);
  }
};
