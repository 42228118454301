import {
  CollectionReference,
  AngularFirestore,
  DocumentChangeAction,
  QueryDocumentSnapshot,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { Observable, of, throwError, EMPTY, partition } from 'rxjs';
import { map, mergeMap, filter, switchMap } from 'rxjs/operators';
import { Post } from '../models/posts';
import { Arrays } from '../utils/guava';
import { PageRequest, PageResponse } from '../services/post.service';
import { Injectable } from '@angular/core';
import { PostQueries } from './post-queries';
import { AuthService } from '../services/auth.service';
// import Timestamp = firestore.Timestamp;
// import QuerySnapshot = firestore.QuerySnapshot;
import { User } from '../models/user';
import { FirestoreService } from '../services/firestore.service';

/**
 * @author: john@gomedialy.com
 * @version: 0.12, 09/25/2020
 * @version: 0.13, 10/28/2020
 */
@Injectable({
  providedIn: 'root',
})
export class PostMineQueries {
  /* fields */

  /**
   * QueryDocumentSnapshot<Post> is not serializable on ngrx.
   * Store it here and use documentId on ngrx, instead.
   */
  private partitionMap = new Map<string, QueryDocumentSnapshot<Post>>();

  constructor(
    private angularFirestore: AngularFirestore,
    private firestoreService: FirestoreService
  ) {}

  listenToMinePosts(
    isSubtitles: boolean,
    pageRequest: PageRequest,
    user: User
  ): Observable<PageResponse | null> {
    return this.forMinePosts(isSubtitles, pageRequest, user)
      .snapshotChanges()
      .pipe(
        switchMap((documentChangeActions: DocumentChangeAction<Post>[]) => {
          return this.createMinePostPageResponse(
            pageRequest.videoId,
            isSubtitles,
            pageRequest,
            documentChangeActions,
            user
          );
        })
      );
  }

  // TODO:
  listUserPosts(
    isSubtitles: boolean,
    pageRequest: PageRequest,
    user: User
  ): Observable<Post> {
    const videoId = pageRequest.videoId;
    const path = `channels/${videoId}/posts`;
    const pageSize = pageRequest.pageSize;
    const orderBy = pageRequest.orderBy;
    const userId = user.userId;
    // const partition = pageRequest.partition;

    return this.angularFirestore
      .collection<Post>(path, (ref) => {
        if (isSubtitles) {
          return ref
            .where('videoId', '==', videoId)
            .where('category', '==', 'subtitle')
            .where('userId', '==', userId)
            .orderBy('groupedAt', orderBy)
            .limit(pageSize);
        }
        return ref
          .where('videoId', '==', videoId)
          .where('userId', '==', userId)
          .orderBy('groupedAt', orderBy)
          .limit(pageSize);
      })
      .get()
      .pipe(
        mergeMap((querySnapshot) =>
          this.firestoreService.listFromQuerySnapshot<Post>(querySnapshot)
        )
      );
  }

  forMinePosts(
    isSubtitles: boolean,
    pageRequest: PageRequest,
    user: User
  ): AngularFirestoreCollection<Post> {
    const videoId = pageRequest.videoId;
    const path = `channels/${videoId}/posts`;
    const pageSize = pageRequest.pageSize;
    const orderBy = pageRequest.orderBy;
    const partition = pageRequest.partition;

    return this.angularFirestore.collection<Post>(path, (ref) => {
      switch (pageRequest.direction) {
        case 'previous':
          if (partition) {
            return this.previousMineQuery(
              ref,
              videoId,
              pageSize,
              isSubtitles,
              orderBy,
              user.userId,
              partition
            );
          } else {
            throw new Error(`previous requires partition.`);
          }
          break;
        case 'next':
          return this.nextMineQuery(
            ref,
            videoId,
            pageSize,
            isSubtitles,
            orderBy,
            user.userId,
            partition
          );
          break;
      }
    });
  }

  /**
   * Mine
   * @param ref
   * @param videoId
   * @param pageSize
   * @param isSubtitles
   * @param partition
   */
  private previousMineQuery(
    ref: CollectionReference,
    videoId: string,
    pageSize: number,
    isSubtitles: boolean,
    orderBy: 'asc' | 'desc',
    partition: string,
    userId: string
  ): firebase.firestore.Query<firebase.firestore.DocumentData> {
    const partitionDoc = this.partitionMap.get(partition);
    if (isSubtitles) {
      return ref
        .where('videoId', '==', videoId)
        .where('category', '==', 'subtitle')
        .where('userId', '==', userId)
        .orderBy('groupedAt', orderBy)
        .endBefore(partitionDoc)
        .limitToLast(pageSize);
    }
    return ref
      .where('videoId', '==', videoId)
      .where('userId', '==', userId)
      .orderBy('groupedAt', orderBy)
      .endBefore(partitionDoc)
      .limitToLast(pageSize);
  }

  private nextMineQuery(
    ref: CollectionReference,
    videoId: string,
    pageSize: number,
    isSubtitles: boolean,
    orderBy: 'asc' | 'desc',
    userId: string,
    partition?: string
  ): firebase.firestore.Query<firebase.firestore.DocumentData> {
    if (isSubtitles) {
      if (partition) {
        const partitionDoc = this.partitionMap.get(partition);
        return ref
          .where('videoId', '==', videoId)
          .where('category', '==', 'subtitle')
          .where('userId', '==', userId)
          .orderBy('groupedAt', orderBy)
          .startAfter(partitionDoc)
          .limit(pageSize);
      } else {
        return ref
          .where('videoId', '==', videoId)
          .where('category', '==', 'subtitle')
          .where('userId', '==', userId)
          .orderBy('groupedAt', orderBy)
          .limit(pageSize);
      }
    }
    if (partition) {
      const partitionDoc = this.partitionMap.get(partition);
      return ref
        .where('videoId', '==', videoId)
        .where('userId', '==', userId)
        .orderBy('groupedAt', orderBy)
        .startAfter(partitionDoc)
        .limit(pageSize);
    } else {
      return ref
        .where('videoId', '==', videoId)
        .where('userId', '==', userId)
        .orderBy('groupedAt', orderBy)
        .limit(pageSize);
    }
  }

  createMinePostPageResponse(
    videoId: string,
    isSubtitles: boolean,
    pageRequest: PageRequest,
    documentChangeActions: DocumentChangeAction<Post>[],
    user: User
  ): Observable<PageResponse | null> {
    // TODO:
    if (Arrays.isEmpty(documentChangeActions)) {
      return of(null);
    }
    const docs = documentChangeActions.map(
      (documentChangeAction) => documentChangeAction.payload.doc
    );
    const start = docs[0];
    // To support a single element of data
    const end = docs.length === 1 ? start : docs[docs.length - 1];
    const posts = docs.map((doc) => doc.data() as Post);

    /**
     * Temporary storage for QueryDocumentSnapshot<Post>
     */
    this.partitionMap.set(start.id, start);
    this.partitionMap.set(end.id, end);
    console.log('partition-map: ', this.partitionMap.size);

    switch (pageRequest.direction) {
      case 'previous':
        const checkPreviousPageRequest: PageRequest = {
          // isFirstPageRequest, hasPreviousPageRequest
          ...pageRequest,
          pageSize: 1,
          partition: start.id,
        };
        return this.forMinePosts(isSubtitles, checkPreviousPageRequest, user)
          .get()
          .pipe(
            PostQueries.createPreviousPageResponse(videoId, start, end, posts)
          );
        break;
      case 'next':
        const checkNextPageRequest: PageRequest = {
          // isLastPageRequest, hasNextPageRequest
          ...pageRequest,
          pageSize: 1,
          partition: end.id,
        };
        return this.forMinePosts(isSubtitles, checkNextPageRequest, user)
          .get()
          .pipe(
            PostQueries.createNextPageResponse(
              videoId,
              start,
              end,
              posts,
              pageRequest.partition
            )
          );
        break;
    }
    return throwError(new Error(`Impossible error`));
  }
}
