import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { switchMap, mergeMap } from 'rxjs/operators';
import { PostEditorComponent } from './post-editor.component';
import { BaseHandler } from '../base.handler';
import {
  imageEditorFeatureKey,
  ImageEditorState,
} from '../reducers/image-editor.reducer';
import {
  editImageRequest,
  editImageResponse,
} from '../actions/image-editor.actions';
import { PostImageUrl } from '../models/images';

/**
 * @author: john@gomedialy.com
 * @version: 0.12, 10/15/2020
 */
export class PostEditorImageHandler extends BaseHandler<PostEditorComponent> {
  /* fields */

  constructor(
    private postEditorComponent: PostEditorComponent,
    private imageEditorStore: Store<{
      [imageEditorFeatureKey]: ImageEditorState;
    }>
  ) {
    super(postEditorComponent);
    this.init();
  }

  private init(): void {
    const imageEditorSubscription = this.imageEditorStore
      .select(imageEditorFeatureKey)
      .pipe(
        mergeMap((state) => {
          switch (state.type) {
            case editImageRequest.type.toString():
              this.parent.imageEditorInput = state;
              break;
            case editImageResponse.type.toString():
              if (state.jsend) {
                // console.log('jsend: ', state);
                switch (state.jsend.status) {
                  case 'success':
                    const postImageUrl = state.jsend.data as PostImageUrl;
                    // console.log('>>> imageUrls: ', imageUrls);

                    const markdownImage = `\n<pre></pre>\n![0](${postImageUrl.previewUrl})\n<pre></pre>`;
                    this.parent.postFormGroup
                      .get('postInputControl')!
                      .setValue(this.parent.content + markdownImage);

                    this.parent.imageEditorInput = null;

                    break;
                  case 'fail':
                    switch (state.jsend.data!.failId) {
                      case 'cancel':
                        this.parent.imageEditorInput = null;
                        break;
                    }
                    this.parent.focusToMessageInput();
                    break;
                }
              }

              break;
          }
          return EMPTY;
        })
      )
      .subscribe();
    this.postEditorComponent.subscriptions.add(imageEditorSubscription);
  }
}
