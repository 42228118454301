import { NgModule } from '@angular/core';
import { DateAgoPipe } from './date-ago.pipe';
import { ShortNumberPipe } from './short-number.pipe';

/**
 * All custom pipes need a module.
 *
 * @author: john@gomedialy.com
 * @version: 0.1, 01/05/2021
 */
@NgModule({
  imports: [],
  declarations: [DateAgoPipe, ShortNumberPipe],
  exports: [DateAgoPipe, ShortNumberPipe],
})
export class PipeModule {
  // tslint:disable-next-line: typedef
  static forRoot() {
    return {
      ngModule: PipeModule,
      providers: [],
    };
  }
}
