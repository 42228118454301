import { Observable, of, from, EMPTY } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { User, ProviderData } from '../models/user';
import { AuthState, authFeatureKey } from '../reducers/auth.reducer';
import { authStateChanged, signupRequest } from '../actions/auth.actions';
import { Store } from '@ngrx/store';
import { BaseService, Context } from '../base.service';
import { mainFeatureKey, MainState } from '../reducers/main.reducer';
import { NGXLogger } from 'ngx-logger';
import { DOCUMENT } from '@angular/common';

/**
 * @author: john@gomedialy.com
 * @version: 0.13, 09/01/2020
 * @version: 0.19, 01/12/2020
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  /* fields */
  youtubeDataScope = 'https://www.googleapis.com/auth/youtube	';
  youtubeDataReadonlyScope = 'https://www.googleapis.com/auth/youtube	';
  private user: User | null = null;
  // TODO: no use?
  // private window: any;

  constructor(
    private mainStore: Store<{ [mainFeatureKey]: MainState }>,
    protected logger: NGXLogger,
    private angularFireAuth: AngularFireAuth,
    private authStore: Store<{ [authFeatureKey]: AuthState }>,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(mainStore, logger);

    this.getRedirectResult()
      .pipe(
        mergeMap((user) => {
          if (user) {
            // this.logger.debug('getRedirectResult() signing-up: ', user);
            this.authStore.dispatch(signupRequest({ user }));
          }
          // this.logger.debug('getRedirectResult() Already signed-up');
          return EMPTY;
        })
      )
      .subscribe(
        (v) => {
          // ignored.
        },
        (error) => {
          this.logger.warn('getRedirectResult()', error);
        }
      );

    this.onAuthStateChanged()
      .pipe(
        mergeMap((user) => {
          if (user) {
            this.user = user;
            this.authStore.dispatch(authStateChanged({ user }));
            // if (!this.user.isAnonymous) {
            //   // this.logger.error(
            //   //   '@@@@@@@@@@@@@@@@@@@@ [USER]',
            //   //   user,
            //   //   redirectResult
            //   // );
            // } else {
            //   // this.logger.info('[USER] anonymous', user);
            // }
            return EMPTY;
          } else {
            this.user = null;
            return this.signInAnonymously();
          }
        })
      )
      .subscribe();

    // this.onAuthStateChanged()
    //   .pipe(
    //     mergeMap((user) => {
    //       return this.getRedirectResult().pipe(map((result) => [user, result]));
    //     }),
    //     // tslint:disable-next-line: variable-name
    //     mergeMap((user_result) => {
    //       const user = user_result[0];
    //       const redirectResult = user_result[1];
    //       this.logger.error('####### result: ', user_result);

    //       if (user) {
    //         this.user = user;
    //         this.authStore.dispatch(authStateChanged({ user }));
    //         if (!this.user.isAnonymous) {
    //           // this.logger.error(
    //           //   '@@@@@@@@@@@@@@@@@@@@ [USER]',
    //           //   user,
    //           //   redirectResult
    //           // );
    //           if (redirectResult) {
    //             this.authStore.dispatch(signupRequest({ user }));
    //           }
    //         } else {
    //           // this.logger.info('[USER] anonymous', user);
    //         }
    //         return EMPTY;
    //       } else {
    //         this.user = null;
    //         // this.authStore.dispatch(authStateChanged({ user: null }));
    //         return this.signInAnonymously();
    //       }
    //     })
    //   )
    //   .subscribe();
  }

  onInit(context: Context): void {}

  onDestroy(context: Context): void {}

  name(): string {
    return 'auth';
  }

  isAnonymousUser(): boolean {
    const user = this.getUser();
    if (user) {
      return user.isAnonymous;
    }
    return true;
  }

  getUser(): User | null {
    return this.user;
  }

  ofUser(): Observable<User | null> {
    return of(this.user);
  }

  private onAuthStateChanged(): Observable<User | null> {
    return new Observable((subscriber) => {
      this.angularFireAuth.onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
          const user: User = this.toUser(firebaseUser);
          subscriber.next(user);
        } else {
          subscriber.next(null);
        }
      });
    });
  }

  private toUser(firebaseUser: firebase.User): User {
    const user: User = {
      userId: firebaseUser.uid,
      displayName: firebaseUser.displayName,
      email: firebaseUser.email,
      emailVerified: firebaseUser.emailVerified,
      photoUrl: firebaseUser.photoURL,
      uid: firebaseUser.uid,
      isAnonymous: firebaseUser.isAnonymous,
      // ProviderData is in array
      // providerData: null,
    };
    const providerData0 = firebaseUser.providerData[0];
    if (providerData0) {
      const providerData: ProviderData = {
        providerId: providerData0.providerId,
        uid: providerData0.uid,
      };
      user.providerData = providerData;
    }
    return user;
  }

  /* Ex) How to use 'new Observable(subscriber ...)
      onRequest<T>(request: https.Request): Observable<T> {
          return new Observable(subscriber => {
              try {
                  const message: PubsubMessage = request.body.message;
                  if ((message) && (message.data)) {
                      const value: T
                          = JSON.parse(Buffer.from(message.data, 'base64').toString());
                      subscriber.next(value);
                      subscriber.complete();
                  }
                  else {
                      //throw new Error('message.data is undefined');
                      throw new Error('invalid pubsub request');
                  }
              } catch (error) {
                  subscriber.error(error);
              }
          });
      }
  */

  signInAnonymously(): Observable<firebase.auth.UserCredential> {
    return from(this.angularFireAuth.signInAnonymously());
  }

  loginWithPopup(): Observable<firebase.auth.UserCredential> {
    return from(
      this.angularFireAuth.signInWithPopup(
        new firebase.auth.GoogleAuthProvider()
      )
    );
  }

  loginWithRedirect(): Observable<void> {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope(this.youtubeDataScope);
    provider.addScope(this.youtubeDataReadonlyScope);
    return from(
      this.angularFireAuth.signInWithRedirect(
        new firebase.auth.GoogleAuthProvider()
      )
    );
  }

  private getRedirectResult(): Observable<User | null> {
    return from(this.angularFireAuth.getRedirectResult()).pipe(
      mergeMap((result) => {
        /**
         * Only if credential and user exist.
         */
        if (result.credential) {
          if (result.user) {
            const firebaseUser: firebase.User = result.user;
            const user = this.toUser(firebaseUser);
            return of(user);
          }
        }
        return of(null);
      })
    );
  }

  logout(): Observable<void> {
    return from(this.angularFireAuth.signOut()).pipe(
      tap(() => {
        /**
         * To remove an online user
         */
        // this.window.location.reload();
      })
    );
  }
}
