/**
 * @author: john@gomedialy.com
 * @version: 0.14, 10/28/2020
 * @version: 0.15, 01/09/2021
 */
export class Strings {
  private constructor() {}

  public static insert(
    source: string,
    position: number,
    target: string
  ): string {
    // var a = 'I want apple';
    // var b = ' an';
    // var position = 6;
    return [source.slice(0, position), target, source.slice(position)].join('');
    // console.log(output);
  }

  public static decodeHtml(document: Document, html: string): string {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }

  public static truncate(text: string, count: number): string {
    if (text.length <= count) {
      return text;
    }
    return text.slice(0, count) + ' ...';
  }

  public static removeAllWhitespaces(text: string): string {
    return text.replace(/\s/g, '');
  }

  public static splitByWhitespace(text: string): string[] {
    const tokens: string[] = text
      .split(/(\s+)/)
      .filter((t) => t.trim().length > 0);
    return tokens;
  }

  public static splitByNumberAndText(numberText: string): string[] {
    // split a number and a text, then filter out empty strings.
    const tokens = numberText
      .split(/([0-9]+)/)
      .filter((t) => t.trim().length > 0);
    return tokens;
  }

  public static hashCode(s: string): number {
    return s.split('').reduce((a, b) => {
      // tslint:disable-next-line: no-bitwise
      a = (a << 5) - a + b.charCodeAt(0);
      // tslint:disable-next-line: no-bitwise
      return a & a;
    }, 0);
  }
}
