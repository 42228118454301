import { Injectable } from '@angular/core';
import { Observable, of, EMPTY, from } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';

/**
 * Memory based local storage.
 *
 * @author: john@gomedialy.com
 * @version: 0.1, 01/16/2021
 */
@Injectable({
  providedIn: 'root',
})
export class LocalDataService {
  /* fields */
  private storage = new Map<string, any>();

  constructor() {}

  has2(collection: string, key?: string): Observable<boolean> {
    if (key) {
      return this.has(collection).pipe(
        mergeMap((exists) => {
          // exists
          if (exists) {
            return this.get<Map<string, any>>(collection).pipe(
              map((dataMap) => {
                if (dataMap) {
                  return dataMap.has(key);
                }
                return false;
              })
            );
          }
          return of(false);
        })
      );
    }
    return this.has(collection);
  }

  get2<T>(collection: string, key: string): Observable<T | null> {
    return this.get<Map<string, T>>(collection).pipe(
      map((dataMap) => {
        if (dataMap) {
          const value = dataMap.get(key);
          if (value) {
            return value;
          }
          return null;
        }
        return null;
      })
    );
  }

  list2<T>(collection: string): Observable<T> {
    return this.get<Map<string, T>>(collection).pipe(
      mergeMap((dataMap) => {
        if (dataMap) {
          return from(Array.from<T>(dataMap.values()));
        }
        return EMPTY;
      })
    );
  }

  set2<T>(collection: string, key: string, value: T): Observable<T> {
    return this.get<Map<string, T>>(collection).pipe(
      mergeMap((dataMap) => {
        if (dataMap) {
          dataMap.set(key, value);
          return this.set(collection, dataMap).pipe(map((v) => value));
        }
        const newDataMap = new Map<string, T>();
        newDataMap.set(key, value);
        return this.set(collection, newDataMap).pipe(map((v) => value));
      })
    );
  }

  delete2<T>(collection: string, key?: string): Observable<boolean> {
    if (key) {
      return this.get<Map<string, T>>(collection).pipe(
        mergeMap((dataMap) => {
          if (dataMap) {
            if (dataMap.delete(key)) {
              return this.set(collection, dataMap).pipe(map((v) => true));
            }
          }
          return this.delete(collection);
        })
      );
    }
    return this.delete(collection);
  }

  has(key: string): Observable<boolean> {
    return of(this.storage.has(key));
  }

  keys(key: string): Observable<string> {
    return from(this.storage.keys());
  }

  get<T>(key: string): Observable<T | null> {
    return of(this.storage.get(key)).pipe(
      mergeMap((value) => {
        if (value) {
          return of(value as T);
        }
        return of(null);
      })
    );
  }

  set<T>(key: string, value: T): Observable<T> {
    return of(this.storage.set(key, value)).pipe(
      mergeMap((v) => {
        return of(value);
      })
    );
  }

  delete(key: string): Observable<boolean> {
    return of(this.storage.delete(key));
  }

  clear(): Observable<void> {
    return of(this.storage.clear());
  }
}
