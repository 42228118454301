import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { Post, EmptyPost } from '../models/posts';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PostService } from '../services/post.service';
import { DateTimeService } from '../services/date-time.service';
import { JSend } from '../models/jsend';
import { EMPTY } from 'rxjs';
import { environment } from '../../environments/environment';
import { BaseComponent } from '../base.component';
import { Store } from '@ngrx/store';
import { postFeatureKey, PostState } from '../reducers/post.reducer';
import { videoFeatureKey, VideoState } from '../reducers/video.reducer';
import {
  ButtonItem,
  ButtonGroupComponent,
  ButtonGroupEvent,
} from '../button-group/button-group.component';
import { authFeatureKey, AuthState } from '../reducers/auth.reducer';
import { AuthService } from '../services/auth.service';
import { ScrollService } from '../services/scroll.service';
import { mergeMap } from 'rxjs/operators';
import {
  editPendingPostContentResponse,
  editPostContentResponse,
  startEditPostRequest,
} from '../actions/post.actions';
import {
  createLikePostRequest,
  createDislikePostRequest,
} from '../actions/post.actions';
import { startSubpostingRequest } from '../actions/post.actions';
import { MarkdownService } from 'ngx-markdown';
import {
  cancelEditPostContentRequest,
  deletePostRequest,
} from '../actions/post.actions';
import firebase from 'firebase/compat/app';
import { DeviceService } from '../services/device.service';
import { loginRequired } from '../actions/auth.actions';
import { MatDialog } from '@angular/material/dialog';
import { PostDeleteComponent } from '../post-delete/post-delete.component';
import { Dates } from '../utils/dates';
import { pauseVideo } from '../actions/video.actions';
import { Icons, YOUTUBE_IMAGE_PATH } from '../utils/icons';

/**
 * @author: john@gomedialy.com
 * @version: 0.47, 12/18/2020
 * @version: 0.55, 01/29/2021
 */
@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
})
export class PostComponent
  extends BaseComponent
  implements OnInit, AfterViewInit {
  /* fields */
  defaultImage = 'https://via.placeholder.com/400x200.png?text=Image';

  @Input()
  index = -1;

  @Input()
  inputPost: Post = EmptyPost.create(firebase.firestore.Timestamp.now()); // = EMPTY_POST;

  @Output()
  events = new EventEmitter<JSend>();

  @ViewChild('userButtonGroup')
  userButtonGroupComponent!: ButtonGroupComponent;

  @ViewChild('ownerButtonGroup')
  ownerButtonGroupComponent!: ButtonGroupComponent;

  userButtonItems: ButtonItem[] = [
    {
      action: 'post_subpost',
      matIcon: 'post_add',
      tooltip: '댓글',
      // style: { color: '#2196f3' },
    },
    // {
    //   action: 'post_subtitle',
    //   matIcon: 'post_add',
    //   tooltip: '동영상+댓글',
    //   style: { color: '#f44336' },
    // },
  ];

  ownerButtonItems: ButtonItem[] = [
    {
      action: 'post_subpost',
      matIcon: 'post_add',
      tooltip: '댓글',
      // style: { color: '#2196f3' },
    },
    // {
    //   action: 'post_subtitle',
    //   matIcon: 'post_add',
    //   tooltip: '동영상+댓글',
    //   style: { color: '#f44336' },
    // },
    // { action: 'post_edit', matIcon: 'edit', tooltip: '수정' },
    // { action: 'post_edit', matIcon: 'published_with_changes', tooltip: '수정' },
    {
      action: 'post_edit',
      // matIcon: 'restore',
      matIcon: 'rule',
      tooltip: '수정',
      // style: { color: '#757575' },
    },
    // { action: 'post_edit', matIcon: 'rule', tooltip: '수정' },
    {
      action: 'post_delete',
      matIcon: 'delete_outline',
      tooltip: '삭제',
      // style: { color: '#757575' },
    },
  ];

  /**
   * TODO: owner vs. user
   */
  isOwner = false;
  postEditorEnabled = false;
  isPending = false;
  likes = '0';
  dislikes = '0';
  commentFormGroup: FormGroup;
  likeIconColor = '#9E9E9E';
  dislikeIconColor = '#9E9E9E';

  isSubtitle = false;
  isSubvideo = false;
  isDeleted = false;
  content = '';

  /**
   * PostEventService manages this.
   */
  // isPlaying: boolean = false;
  // hover & show
  // FIXME: Testing
  isButtonGroupVisible = false;
  userButtonGroupVisibility: 'visible' | 'hidden' = 'hidden';
  ownerButtonGroupVisibility: 'visible' | 'hidden' = 'hidden';
  level = 0;

  /**
   * TODO: more work on this
   */
  componentHeight = '94px';
  // componentHeight = '100px';
  videoImageUrl: string | null = YOUTUBE_IMAGE_PATH;
  subvideoImageUrl: string | null = YOUTUBE_IMAGE_PATH;
  isDesktop = false;
  createdAt = '';
  draftContent = '';
  isEditing = false;
  fadeIn: any;

  constructor(
    private scrollService: ScrollService,
    private authService: AuthService,
    private postService: PostService,
    private formBuilder: FormBuilder,
    private dateTimeService: DateTimeService,
    private authStore: Store<{
      [authFeatureKey]: AuthState;
    }>,
    private postStore: Store<{
      [postFeatureKey]: PostState;
    }>,
    private videoStore: Store<{
      [videoFeatureKey]: VideoState;
    }>,
    private deviceService: DeviceService,
    private dialog: MatDialog
  ) {
    super();

    this.commentFormGroup = this.formBuilder.group({
      commentInputControl: ['', Validators.nullValidator],
    });
    this.isDesktop = this.deviceService.isDesktop();
  }

  private openPostDeleteDialog(): void {
    const dialogRef = this.dialog.open(PostDeleteComponent, {
      width: '500px',
      data: {
        videoId: this.inputPost.videoId,
        content: this.inputPost.content,
      },
    });

    dialogRef.afterClosed().subscribe((isOk) => {
      if (isOk) {
        // console.log(`Dialog result OK: ${isOk}`);
        this.postStore.dispatch(
          deletePostRequest({
            videoId: this.inputPost.videoId,
            postId: this.inputPost.postId,
          })
        );
      } else {
        // console.log(`Dialog result NO: ${isOk}`);
      }
    });
  }

  /**
   * For now, isVisible based optimization is disabled.
   */
  onVisible(visible: boolean): void {
    this.scrollService.onPostComponentVisible(this.index, visible);

    // TODO: Improve!!!
    // const current = this.scrollService.getPageNumber();
    // const myPage = this.scrollService.getPageNumberForIndex(this.index);
    // const difference = Math.abs(current - myPage);
    // if (difference > 1) {
    //   this.isVisible = false;
    //   console.log('page: ', difference);
    // } else {
    //   this.isVisible = true;
    // }
    // if (difference > 1)
  }

  /**
   * On data change, this component is re-built.
   * That is, this is called many times.
   * Be creative and use this to my advantage.
   */
  ngOnInit(): void {
    const post = this.inputPost;
    if (post) {
      // a little bit of tweaking by adding 1
      if (post.level > 0) {
        this.level = post.level + 1;
      }
      switch (post.postType) {
        case 'post':
          break;
        case 'subpost':
          break;
        case 'title':
        case 'subtitle':
          this.isSubtitle = true;
          if (post.subvideo0) {
            this.videoImageUrl = Icons.defaultImageUrl(post.subvideo0.videoId);
          }
          break;
      }

      this.content = post.content;

      if (post.subvideo1) {
        this.isSubvideo = true;
        this.subvideoImageUrl = Icons.defaultImageUrl(post.subvideo1.videoId);
      }

      switch (post.status) {
        case 'pending':
          this.handlePending(post);
          break;
        case 'editing':
          this.handleEditing(post);
          break;
        case 'deleted':
          this.handleDeleted();
          break;
      }

      if (post.liked) {
        this.likeIconColor = 'blue';
      } else {
        this.likeIconColor = '#9E9E9E';
      }
      if (post.disliked) {
        this.dislikeIconColor = 'red';
      } else {
        this.dislikeIconColor = '#9E9E9E';
      }

      const user = this.authService.getUser();
      if (user) {
        this.isOwner = user.userId === post.userId;
      }

      // this.createdAt = post.createdAt.toDate();
      this.createdAt = Dates.toAgo(post.createdAt.toDate());
    }
  }

  private handleDeleted(): void {
    this.isDeleted = true;
    this.content = '> 사용자의 요청으로 삭제되었습니다.';
    // post.subvideo0
    // post
  }

  private handlePending(post: Post): void {
    this.isPending = true;
    const duration = this.dateTimeService.getDurationSince(
      post.createdAt,
      'second'
    );
    if (duration > environment.pendingExpiration) {
      this.postService.postHardDelete(post);
      // const postDeleteSubscription = this.postService
      //   .postHardDelete(post)
      //   .subscribe();
      // this.subscriptions.add(postDeleteSubscription);
    }

    // console.log('>>>>>>>>>>>>>>>>>>>> WTF this is pending: ', post.postId);

    const postStoreSubscription = this.postStore
      .select(postFeatureKey)
      .pipe(
        mergeMap((state) => {
          switch (state.type) {
            case editPendingPostContentResponse.type.toString():
              // state.postId
              // this.inputPost.postId
              // console.log('post pending: ', state);

              if (state.content) {
                this.draftContent = state.content;
              }
              break;
          }
          return EMPTY;
        })
      )
      .subscribe();
    this.subscriptions.add(postStoreSubscription);
  }

  private handleEditing(post: Post): void {
    // TODO: Test
    this.isPending = true;

    const minutesAgo = this.dateTimeService.getDurationSince(
      post.editingAt,
      'minute'
    );
    // console.log(
    //   '>>>>>>>>>>>>>>>>>>>> WTF this is editing: ',
    //   post.postId,
    //   minutesAgo
    // );

    /**
     * Rollback in 10 minutes
     */
    if (minutesAgo > 10) {
      if (post.postId) {
        this.postStore.dispatch(
          cancelEditPostContentRequest({
            videoId: post.videoId,
            postId: post.postId,
          })
        );
      }
    } else {
      const postStoreSubscription = this.postStore
        .select(postFeatureKey)
        .pipe(
          mergeMap((state) => {
            switch (state.type) {
              case editPostContentResponse.type.toString():
                // console.log('post editing: ', state);

                if (state.content) {
                  this.draftContent = state.content;
                }
                break;
            }
            return EMPTY;
          })
        )
        .subscribe();
      this.subscriptions.add(postStoreSubscription);
    }
  }

  /**
   * ! TODO: watch this more carefully
   * The subscriptions are removed ngOnDestory()
   */
  ngAfterViewInit(): void {
    // TODO:
    // console.error(
    //   '>>>> ngAfterViewInit: ',
    //   this.userButtonGroupComponent,
    //   this.ownerButtonGroupComponent
    // );
    const userButtonGroupEventSubscription = this.userButtonGroupComponent.events.subscribe(
      (event: ButtonGroupEvent) => {
        this.onButtonGroupEvent(event);
      }
    );
    this.subscriptions.add(userButtonGroupEventSubscription);
    const ownerButtonGroupEventSubscription = this.ownerButtonGroupComponent.events.subscribe(
      (event: ButtonGroupEvent) => {
        this.onButtonGroupEvent(event);
      }
    );
    this.subscriptions.add(ownerButtonGroupEventSubscription);

    // if (this.userButtonGroupComponent) {
    //   const userButtonGroupEventSubscription = this.userButtonGroupComponent.events.subscribe(
    //     (event: ButtonGroupEvent) => {
    //       this.onButtonGroupEvent(event);
    //     }
    //   );
    //   this.subscriptions.add(userButtonGroupEventSubscription);
    // }
    // if (this.ownerButtonGroupComponent) {
    //   const ownerButtonGroupEventSubscription = this.ownerButtonGroupComponent.events.subscribe(
    //     (event: ButtonGroupEvent) => {
    //       this.onButtonGroupEvent(event);
    //     }
    //   );
    //   this.subscriptions.add(ownerButtonGroupEventSubscription);
    // }
  }

  /**
   * FIXME:
   * For ButtonGroup hover
   */
  mouseOver(): void {
    // console.log('over');
    this.isButtonGroupVisible = true;
    if (this.isOwner) {
      this.ownerButtonGroupVisibility = 'visible';
    } else {
      this.userButtonGroupVisibility = 'visible';
    }
  }

  /**
   * FIXME:
   * For ButtonGroup hover
   */
  mouseOut(): void {
    // console.log('out');
    // FIXME: to false
    this.isButtonGroupVisible = false;
    if (this.isOwner) {
      this.ownerButtonGroupVisibility = 'hidden';
    } else {
      this.userButtonGroupVisibility = 'hidden';
    }
  }

  onLikeClick(): void {
    if (this.authService.isAnonymousUser()) {
      this.authStore.dispatch(loginRequired());
    } else if (this.inputPost) {
      this.postStore.dispatch(
        createLikePostRequest({
          videoId: this.inputPost.videoId,
          postId: this.inputPost.postId ? this.inputPost.postId : '',
        })
      );
    }
  }

  onDislikeClick(): void {
    if (this.authService.isAnonymousUser()) {
      this.authStore.dispatch(loginRequired());
    } else if (this.inputPost) {
      this.postStore.dispatch(
        createDislikePostRequest({
          videoId: this.inputPost.videoId,
          postId: this.inputPost.postId ? this.inputPost.postId : '',
        })
      );
    }
  }

  private onButtonGroupEvent(event: ButtonGroupEvent): void {
    if (this.authService.isAnonymousUser()) {
      this.authStore.dispatch(loginRequired());
    } else if (this.inputPost) {
      switch (event.action) {
        case 'post_subpost':
          this.postStore.dispatch(
            startSubpostingRequest({
              videoId: this.inputPost.videoId,
              parentPost: this.inputPost,
              tags: this.inputPost.tags,
            })
          );
          break;
        case 'post_edit':
          // console.log('post_edit');
          if (this.inputPost.postId) {
            this.videoStore.dispatch(
              pauseVideo({ videoId: this.inputPost.videoId })
            );
            this.postStore.dispatch(
              startEditPostRequest({
                videoId: this.inputPost.videoId,
                postId: this.inputPost.postId,
                content: this.inputPost.content, // Must and initialization
                subvideo0: this.inputPost.subvideo0
                  ? this.inputPost.subvideo0
                  : null,
                subvideo1: this.inputPost.subvideo1
                  ? this.inputPost.subvideo1
                  : null,
              })
            );
          }
          break;
        case 'post_delete':
          // console.log('post_delete');
          this.openPostDeleteDialog();
          break;
      }
    }
  }

  // Only for logging.
  name(): string {
    return 'post';
  }
}
