import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
// import { NGXLogger } from 'ngx-logger';
import { AfterViewInit } from '@angular/core';
import { QueryEventService } from '../query-button/query-event.service';
import { DeviceService } from '../services/device.service';

/**
 * @author: john@gomedialy.com
 * @version: 0.14, 09/06/2020
 * @version: 0.18, 01/15/2021
 */
export interface WordCloudItem {
  formattedTraffic: string;
  text: string;
  style: string;
}

@Component({
  selector: 'app-word-cloud',
  templateUrl: './word-cloud.component.html',
  styleUrls: ['./word-cloud.component.scss'],
})
export class WordCloudComponent implements OnInit, AfterViewInit {
  // @Input()
  // inputData: Array<WordCloudItem> = [];

  @Input()
  // inputs$: Observable<WordCloudItem[]> = EMPTY;
  inputs: WordCloudItem[] = [];
  // inputs: WordCloudItem[] = [];

  @Output()
  events = new EventEmitter<WordCloudItem>();
  isDesktop = false;

  // items: Array<string[]> = [
  //   ['바르셀로나', 'mat-display-4'],
  //   ['곽도원', 'mat-display-3'],
  //   ['비밀의 숲', 'mat-display-3'],
  //   ['고경표', 'mat-display-3'],
  //   ['사회적 거리두기 2단계', 'mat-display-3'],
  //   ['김원웅', 'mat-display-2'],
  //   ['독도', 'mat-display-1'],
  //   ['악인전', 'mat-display-2'],
  //   ['로또924회당첨번호', 'mat-display-2'],
  //   ['박보미', 'mat-display-2'],
  //   ['장예원', 'mat-display-2'],
  // ];
  // items: string[][] = [
  //   ['바르셀로나', 'mat-display-4'],
  //   ['곽도원', 'mat-display-3'],
  //   ['비밀의 숲', 'mat-display-3'],
  //   ['고경표', 'mat-display-3'],
  //   ['사회적 거리두기 2단계', 'mat-display-3'],
  //   ['김원웅', 'mat-display-2'],
  //   ['독도', 'mat-display-1'],
  //   ['악인전', 'mat-display-2'],
  //   ['로또924회당첨번호', 'mat-display-2'],
  //   ['박보미', 'mat-display-2'],
  //   ['장예원', 'mat-display-2'],
  // ];

  constructor(
    private deviceService: DeviceService,
    private queryEventService: QueryEventService
  ) {
    this.isDesktop = this.deviceService.isDesktop();
    this.events = this.queryEventService.events;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}
}
