<div
  [@fadeIn]="fadeIn"
  class="container debug-redx"
  fxLayout="column"
  fxLayoutGap="8px"
>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
    <div>
      <img class="image-rounded" src="{{ photoUrl }}" />
    </div>
    <span class="mat-small">{{ displayName }}</span>
    <span class="mat-small">{{ createdAt | dateAgo }}</span>
  </div>

  <div
    class="word-wrap debug-greenx"
    markdown
    emoji
    [data]="content"
    (ready)="onReady()"
    fxHide="{{ isPending }}"
  ></div>
  <div
    class="debug-greenx"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="8px"
    fxHide="{{ isPending }}"
  >
    <div>
      <button mat-icon-button (click)="onLikeClick()">
        <mat-icon class="icon-font" [style.color]="likeIconColor"
          >thumb_up</mat-icon
        >
      </button>
      <span>{{ likes }}</span>
    </div>
    <div>
      <button mat-icon-button (click)="onDislikeClick()">
        <mat-icon class="icon-font" [style.color]="dislikeIconColor"
          >thumb_down</mat-icon
        >
      </button>
      <span>{{ dislikes }}</span>
    </div>
    <!-- <button
      fxHide="{{ isCommenting }}"
      mat-icon-button
      (click)="onCommentClick()"
    >
      <mat-icon class="icon-font">textsms</mat-icon>
    </button> -->
  </div>
</div>
