import { Component, Input, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { VideoControlService } from './video-control.service';
import { Video } from '../models/posts';
import { Preconditions } from '../utils/guava';
import { Icons } from '../utils/icons';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageComponent, MessageData } from '../message/message.component';

/**
 * @author: john@gomedialy.com
 * @version: 0.15, 10/28/2020
 * @version: 0.17, 12/19/2020
 * @version: 0.23, 01/16/2021
 */
@Component({
  selector: 'app-video-control',
  templateUrl: './video-control.component.html',
  styleUrls: ['./video-control.component.scss'],
})
export class VideoControlComponent implements OnInit {
  /* fields */
  // tslint:disable-next-line: no-input-rename
  @Input('video')
  video!: Video | undefined;

  /**
   * Whether to use either playVideoRequest() or directPlayVideoRequest()
   */
  // tslint:disable-next-line: no-input-rename
  @Input('play')
  playType: 'stack' | 'direct' = 'stack';

  isPlaying = false;

  videoImageUrl: string | null = null; // Videos.localDefaultImageUrl();
  subvideoImageUrl: string | null = null; // Videos.localDefaultImageUrl();
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';
  value = 10;
  tooltip = '';
  isPlayIconVisible = false;

  iconsClass = 'icons';

  centered = false;
  disabled = false;
  unbounded = false;

  radius = 0.5;
  color2 = 'red';

  /**
   * Used as a scrollId
   */
  postId = '';

  constructor(
    private videoControlService: VideoControlService,
    private snackBar: MatSnackBar
  ) {
    this.isPlaying = this.videoControlService.isPlaying;
  }

  private message(videoId: string): void {
    const messageData: MessageData = {
      icon: null,
      imageUrl: Icons.defaultImageUrl(videoId),
      message: '동영상 로딩 중 ...',
    };

    this.snackBar.openFromComponent<MessageData>(MessageComponent, {
      duration: 2000,
      data: messageData,
    });
  }

  ngOnInit(): void {
    if (this.video) {
      if (this.video.postId) {
        this.postId = this.video.postId;
      }

      // const postId = this.video.postId;
      // Preconditions.checkNotNull(postId, 'postId is null.');

      /**
       * For video-editor, it does not produce any postId.
       * In this case, the video has no postId.
       */
      // if (postId === undefined) {
      //   console.log(
      //     'postId is null and it is replaced by videoId: ',
      //     this.video.videoId
      //   );
      //   this.video = { ...this.video, postId: this.video.videoId };
      // }
      this.isPlaying = this.videoControlService.checkVideoPlaying(this.video);
      this.videoImageUrl = Icons.defaultImageUrl(this.video.videoId);
      switch (this.video.videoType) {
        case 'subtitle':
          this.tooltip = '구간동영상';
          this.iconsClass = 'icons-no-bg icon-gray';
          break;
        case 'subvideo':
          this.tooltip = '외부동영상';
          this.iconsClass = 'icons-bg icon-white';
          break;
      }
    }
    this.videoControlService.init(this);
  }

  onClick(): void {
    // console.error(
    //   '--------------------------------- no video onClick: ',
    //   this.video
    // );
    if (this.video) {
      this.message(this.video.videoId);
      this.videoControlService.onPlayClick(this.video, this.playType);
    }
  }
}
