import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { mergeMap, switchMap, delay, take } from 'rxjs/operators';
import { postFeatureKey, PostState } from '../reducers/post.reducer';
import { WatchComponent } from './watch.component';
import {
  topPostPageRequest,
  controversialPostPageRequest,
  risingPostPageRequest,
  newPostPageRequest,
  naturalPostPageRequest,
  minePostPageRequest,
} from '../actions/data.actions';
import { PostsBoardComponent } from '../posts-board/posts-board.component';
import { ScrollService } from '../services/scroll.service';
import { dataFeatureKey, DataState } from '../reducers/data.reducer';
import { PostDataService } from '../services/post-data.service';
import { BaseHandler } from '../base.handler';
import { environment } from '../../environments/environment';
import { postsBoardReady } from '../actions/post.actions';

/**
 * @author: john@gomedialy.com
 * @version: 0.14, 10/30/2020
 * @version: 0.17, 01/26/2021
 */
export class WatchPostBoardHandler extends BaseHandler<WatchComponent> {
  /* fields */
  private pageSize: number = environment.pageSize;

  constructor(
    private watchComponent: WatchComponent,
    private scrollService: ScrollService,
    private postDataService: PostDataService,
    private postStore: Store<{
      [postFeatureKey]: PostState;
    }>,
    private dataStore: Store<{
      [dataFeatureKey]: DataState;
    }>
  ) {
    super(watchComponent);

    /**
     * This runs only once!
     * take(2) is required. The first one is for initial values.
     * The second one is a posts-board ready.
     * take() unsubscribes itself.
     */
    this.postStore
      .select(postFeatureKey)
      .pipe(
        take(2),
        mergeMap((state) => {
          switch (state.type) {
            case postsBoardReady.type.toString():
              this.init(this.parent.postsBoardComponent);
              return of('');
          }
          // skip
          return EMPTY;
        }),
        delay(1000),
        mergeMap((v) => {
          /**
           * To clear previous data from difference channels.
           */
          this.parent.postsBoardComponent.selectAllNew();
          const videoId = this.parent.videoId;
          if (videoId) {
            this.dataStore.dispatch(
              newPostPageRequest({
                videoId,
                category: 'all',
                direction: 'next',
                pageSize: environment.pageSize,
                orderBy: 'desc',
              })
            );
          }

          return EMPTY;
        })
      )
      .subscribe();
  }

  // TODO: onPostsBoardReady()
  private init(postsBoardComponent: PostsBoardComponent): void {
    const postsBoardEventsSubscription = postsBoardComponent.events
      .pipe(
        switchMap((postBoardEvent) => {
          // console.error('>>>>>>>>>>>> post-board-event: ', postBoardEvent);
          const videoId = postBoardEvent.videoId;
          const paginatorEvent = postBoardEvent.paginatorEvent;
          const action = postBoardEvent.action;
          // const lastDataState = this.lastDataStateMap.get(action);
          const pageNumber = this.scrollService.getScrollDownPageNumber();
          const nextPageSize = this.pageSize * pageNumber;

          if (postsBoardComponent) {
            switch (action) {
              case 'sort_all_top':
                switch (paginatorEvent.action) {
                  case 'init':
                    // clear
                    this.postDataService.clear();
                    this.dataStore.dispatch(
                      topPostPageRequest({
                        videoId,
                        category: 'all',
                        direction: 'next',
                        pageSize: this.pageSize,
                        orderBy: 'desc',
                      })
                    );
                    break;
                  case 'next':
                    this.dataStore.dispatch(
                      topPostPageRequest({
                        videoId,
                        category: 'all',
                        direction: paginatorEvent.action,
                        pageSize: nextPageSize,
                        orderBy: 'desc',
                      })
                    );
                    break;
                  case 'previous':
                    break;
                }
                break;
              case 'sort_all_controversial':
                switch (paginatorEvent.action) {
                  case 'init':
                    // clear
                    this.postDataService.clear();
                    this.dataStore.dispatch(
                      controversialPostPageRequest({
                        videoId,
                        category: 'all',
                        direction: 'next',
                        pageSize: this.pageSize,
                        orderBy: 'desc',
                      })
                    );
                    break;
                  case 'next':
                    this.dataStore.dispatch(
                      controversialPostPageRequest({
                        videoId,
                        category: 'all',
                        direction: paginatorEvent.action,
                        pageSize: nextPageSize,
                        orderBy: 'desc',
                      })
                    );
                    break;
                  case 'previous':
                    break;
                }
                break;
              case 'sort_all_rising':
                switch (paginatorEvent.action) {
                  case 'init':
                    // clear
                    this.postDataService.clear();
                    this.dataStore.dispatch(
                      risingPostPageRequest({
                        videoId,
                        category: 'all',
                        direction: 'next',
                        pageSize: this.pageSize,
                        orderBy: 'desc',
                      })
                    );

                    break;
                  case 'next':
                    this.dataStore.dispatch(
                      risingPostPageRequest({
                        videoId,
                        category: 'all',
                        direction: paginatorEvent.action,
                        pageSize: nextPageSize,
                        orderBy: 'desc',
                      })
                    );
                    break;
                  case 'previous':
                    break;
                }
                break;
              case 'sort_all_new':
                switch (paginatorEvent.action) {
                  case 'init':
                    // clear
                    this.postDataService.clear();
                    this.dataStore.dispatch(
                      newPostPageRequest({
                        videoId,
                        category: 'all',
                        direction: 'next',
                        pageSize: this.pageSize,
                        orderBy: 'desc',
                      })
                    );
                    break;
                  case 'next':
                    this.dataStore.dispatch(
                      newPostPageRequest({
                        videoId,
                        category: 'all',
                        direction: paginatorEvent.action,
                        pageSize: nextPageSize,
                        orderBy: 'desc',
                      })
                    );
                    break;
                  case 'previous':
                    break;
                }
                break;
              case 'sort_all_natural':
                switch (paginatorEvent.action) {
                  case 'init':
                    // clear
                    this.postDataService.clear();
                    this.dataStore.dispatch(
                      naturalPostPageRequest({
                        videoId,
                        category: 'all',
                        direction: 'next',
                        pageSize: this.pageSize,
                        orderBy: 'asc',
                      })
                    );
                    break;
                  case 'next':
                    this.dataStore.dispatch(
                      naturalPostPageRequest({
                        videoId,
                        category: 'all',
                        direction: paginatorEvent.action,
                        pageSize: nextPageSize,
                        orderBy: 'asc',
                      })
                    );
                    break;
                  case 'previous':
                    break;
                }
                break;
              case 'sort_all_mine':
                switch (paginatorEvent.action) {
                  case 'init':
                    // clear
                    this.postDataService.clear();
                    this.dataStore.dispatch(
                      minePostPageRequest({
                        videoId,
                        category: 'all',
                        direction: 'next',
                        pageSize: this.pageSize,
                        orderBy: 'desc',
                      })
                    );
                    break;
                  case 'next':
                    this.dataStore.dispatch(
                      minePostPageRequest({
                        videoId,
                        category: 'all',
                        direction: paginatorEvent.action,
                        pageSize: nextPageSize,
                        orderBy: 'desc',
                      })
                    );
                    break;
                  case 'previous':
                    break;
                }
                break;
              case 'sort_subtitle_new':
                switch (paginatorEvent.action) {
                  case 'init':
                    // clear
                    this.postDataService.clear();
                    this.dataStore.dispatch(
                      newPostPageRequest({
                        videoId,
                        category: 'subtitle',
                        direction: 'next',
                        pageSize: this.pageSize,
                        orderBy: 'desc',
                      })
                    );
                    break;
                  case 'next':
                    this.dataStore.dispatch(
                      newPostPageRequest({
                        videoId,
                        category: 'subtitle',
                        direction: paginatorEvent.action,
                        pageSize: nextPageSize,
                        orderBy: 'desc',
                      })
                    );
                    break;
                  case 'previous':
                    break;
                }
                break;
              case 'sort_subtitle_natural':
                switch (paginatorEvent.action) {
                  case 'init':
                    // clear
                    this.postDataService.clear();
                    this.dataStore.dispatch(
                      naturalPostPageRequest({
                        videoId,
                        category: 'subtitle',
                        direction: 'next',
                        pageSize: this.pageSize,
                        orderBy: 'asc',
                      })
                    );
                    break;
                  case 'next':
                    this.dataStore.dispatch(
                      naturalPostPageRequest({
                        videoId,
                        category: 'subtitle',
                        direction: paginatorEvent.action,
                        pageSize: nextPageSize,
                        orderBy: 'asc',
                      })
                    );
                    break;
                  case 'previous':
                    break;
                }
                break;
              case 'sort_subtitle_top':
                switch (paginatorEvent.action) {
                  case 'init':
                    // clear
                    this.postDataService.clear();
                    this.dataStore.dispatch(
                      topPostPageRequest({
                        videoId,
                        category: 'subtitle',
                        direction: 'next',
                        pageSize: this.pageSize,
                        orderBy: 'desc',
                      })
                    );

                    break;
                  case 'next':
                    this.dataStore.dispatch(
                      topPostPageRequest({
                        videoId,
                        category: 'subtitle',
                        direction: paginatorEvent.action,
                        pageSize: nextPageSize,
                        orderBy: 'desc',
                      })
                    );
                    break;
                  case 'previous':
                    break;
                }
                break;
              case 'sort_subtitle_controversial':
                switch (paginatorEvent.action) {
                  case 'init':
                    // clear
                    this.postDataService.clear();
                    this.dataStore.dispatch(
                      controversialPostPageRequest({
                        videoId,
                        category: 'subtitle',
                        direction: 'next',
                        pageSize: this.pageSize,
                        orderBy: 'desc',
                      })
                    );

                    break;
                  case 'next':
                    this.dataStore.dispatch(
                      controversialPostPageRequest({
                        videoId,
                        category: 'subtitle',
                        direction: paginatorEvent.action,
                        pageSize: nextPageSize,
                        orderBy: 'desc',
                      })
                    );
                    break;
                  case 'previous':
                    break;
                }
                break;
              case 'sort_subtitle_rising':
                switch (paginatorEvent.action) {
                  case 'init':
                    // clear
                    this.postDataService.clear();
                    this.dataStore.dispatch(
                      risingPostPageRequest({
                        videoId,
                        category: 'subtitle',
                        direction: 'next',
                        pageSize: this.pageSize,
                        orderBy: 'desc',
                      })
                    );

                    break;
                  case 'next':
                    this.dataStore.dispatch(
                      risingPostPageRequest({
                        videoId,
                        category: 'subtitle',
                        direction: paginatorEvent.action,
                        pageSize: nextPageSize,
                        orderBy: 'desc',
                      })
                    );
                    break;
                  case 'previous':
                    break;
                }
                break;
              case 'sort_subtitle_mine':
                switch (paginatorEvent.action) {
                  case 'init':
                    // clear
                    this.postDataService.clear();
                    this.dataStore.dispatch(
                      minePostPageRequest({
                        videoId,
                        category: 'subtitle',
                        direction: 'next',
                        pageSize: this.pageSize,
                        orderBy: 'desc',
                      })
                    );
                    break;
                  case 'next':
                    this.dataStore.dispatch(
                      minePostPageRequest({
                        videoId,
                        category: 'subtitle',
                        direction: paginatorEvent.action,
                        pageSize: nextPageSize,
                        orderBy: 'desc',
                      })
                    );
                    break;
                  case 'previous':
                    break;
                }
                break;
            }
          }
          return EMPTY;
        })
      )
      .subscribe();
    this.parent.subscriptions.add(postsBoardEventsSubscription);
  }
}
