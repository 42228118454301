import { createAction, props } from '@ngrx/store';
import { JSend } from '../models/jsend';

/**
 * @author: john@gomedialy.com
 * @version: 0.16, 10/07/2020
 * @version: 0.20, 01/08/2021
 */
export const playOrPause = createAction('[Video] Play Or Pause');
export const replay = createAction('[Video] Replay');
export const seekRightTo = createAction('[Video] Seek Right To');
export const seekLeftTo = createAction('[Video] Seek Left To');
export const volumeUp = createAction('[Video] Volume Up');
export const volumeDown = createAction('[Video] Volume Down');

/**
 * No trace of link.
 * For the backward button
 */
export const playPipVideo = createAction(
  '[Video] Play Pip Video',
  props<{
    videoId: string;
    videoType: 'video' | 'subtitle' | 'subvideo';
    postId: string | null;
    startSeconds: number;
    endSeconds: number;
    duration: number;
  }>()
);

// TODO: future work
export const playSubvideoListRequest = createAction(
  '[Video] Play Subvideo List Request',
  props<{ videoId: string }>()
);

// TODO: future work
export const playSubvideoListResponse = createAction(
  '[Video] Play Subvideo List Response',
  props<{ jsend: JSend }>()
);

export const playVideo = createAction(
  '[Video] Play Video',
  props<{
    videoId: string;
    videoType: 'video' | 'subtitle' | 'subvideo';
    postId: string | null;
    startSeconds: number;
    endSeconds: number;
    duration: number;
  }>()
);

/**
 * No trace of link.
 * For the backward button
 */
export const directPlayVideo = createAction(
  '[Video] Direct Play Video',
  props<{
    videoId: string;
    videoType: 'video' | 'subtitle' | 'subvideo';
    postId: string | null;
    startSeconds: number;
    endSeconds: number;
    duration: number;
  }>()
);

// TODO: replace by playOrPause() ?
export const pauseVideo = createAction(
  '[Video] Pause Video',
  props<{ videoId: string }>()
);

// export const playVideoRequest = createAction(
//   '[Video] Play Video Request',
//   props<{
//     videoId: string;
//     videoType: 'video' | 'subtitle' | 'subvideo';
//     postId: string | null;
//     startSeconds: number;
//     endSeconds: number;
//     duration: number;
//   }>()
// );

// export const playVideoResponse = createAction(
//   '[Video] Play Video Response',
//   props<{ jsend: JSend }>()
// );

// /**
//  * No trace of link.
//  * For the backward button
//  */
// export const directPlayVideoRequest = createAction(
//   '[Video] Direct Play Video Request',
//   props<{
//     videoId: string;
//     videoType: 'video' | 'subtitle' | 'subvideo';
//     postId: string | null;
//     startSeconds: number;
//     endSeconds: number;
//     duration: number;
//   }>()
// );

// export const directPlayVideoResponse = createAction(
//   '[Video] Direct Play Video Response',
//   props<{ jsend: JSend }>()
// );

// export const pauseVideoRequest = createAction(
//   '[Video] Pause Video Request',
//   props<{ videoId: string }>()
// );

// export const pauseVideoResponse = createAction(
//   '[Video] Pause Video Response',
//   props<{ jsend: JSend }>()
// );

export const videoUnstarted = createAction(
  '[Video] Unstarted',
  props<{
    videoId: string;
    videoType: 'video' | 'subtitle' | 'subvideo';
    postId: string | null;
    timestamp: number;
    duration: number;
  }>()
);

export const videoBuffering = createAction(
  '[Video] Buffering',
  props<{
    videoId: string;
    videoType: 'video' | 'subtitle' | 'subvideo';
    postId: string | null;
    timestamp: number;
    duration: number;
  }>()
);

export const videoCued = createAction(
  '[Video] Cued',
  props<{
    videoId: string;
    videoType: 'video' | 'subtitle' | 'subvideo';
    postId: string | null;
    timestamp: number;
    duration: number;
  }>()
);

export const videoEnded = createAction(
  '[Video] Ended',
  props<{
    videoId: string;
    videoType: 'video' | 'subtitle' | 'subvideo';
    postId: string | null;
    timestamp: number;
    endSeconds: number;
    duration: number;
  }>()
);

export const videoPaused = createAction(
  '[Video] Paused',
  props<{
    videoId: string;
    videoType: 'video' | 'subtitle' | 'subvideo';
    postId: string | null;
    timestamp: number;
    endSeconds: number;
    duration: number;
  }>()
);

export const videoPlaying = createAction(
  '[Video] Playing',
  props<{
    videoId: string;
    videoType: 'video' | 'subtitle' | 'subvideo';
    postId: string | null;
    timestamp: number;
    endSeconds: number;
    duration: number;
  }>()
);
