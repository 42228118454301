import { Injectable } from '@angular/core';
// @ts-ignore
import { from, of, Observable } from 'rxjs';
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
// @ts-ignore
import * as durationPlugin from 'dayjs/plugin/duration';
// @ts-ignore
import * as localeData from 'dayjs/plugin/localeData';
// @ts-ignore
import * as relativeTime from 'dayjs/plugin/relativeTime';
// @ts-ignore
import * as utc from 'dayjs/plugin/utc';
// // @ts-ignore
// import * as timezone from 'dayjs/plugin/timezone';
// @ts-ignore
import * as locale from 'dayjs/locale/ko';
import firebase from 'firebase/compat/app';
import { Strings } from '../utils/strings';
/**
 * Standard date-format
 */
export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';

/**
 * @author: john@gomedialy.com
 * @version: 0.1, 10/28/2020
 */
@Injectable({
  providedIn: 'root',
})
export class DateTimeService {
  constructor() {}
  /**
   * For 'YYYY-MM-DDTHH:mm:ss[Z]'
   * @param date
   */
  getTimestampFromDateString(date: string): firebase.firestore.Timestamp {
    return firebase.firestore.Timestamp.fromDate(
      dayjs(date, DATE_FORMAT).toDate()
    );
  }

  /**
   * Ex) 11h ago, 9h ago, 1d ago, 3d ago or 11m ago
   * @param timeAgo
   */
  getTimestampFromTimeAgo(timeAgo: string): firebase.firestore.Timestamp {
    // separate 'ago'
    const tokens: string[] = Strings.splitByWhitespace(timeAgo);
    // console.log('(tokens0): ', tokens);
    if (tokens.length !== 2) {
      // just now
      return firebase.firestore.Timestamp.now();
    }
    if (tokens[1] !== 'ago') {
      // just now
      return firebase.firestore.Timestamp.now();
    }
    // split a number and a text, then filter out empty strings.
    const tokens2 = Strings.splitByNumberAndText(tokens[0]);
    console.log('[TIME_AGO]: Paring: ', tokens, tokens2);
    const value = +tokens2[0];
    const unit = tokens2[1];
    switch (unit) {
      case 'h':
        return this.hoursAgoTimestamp(value);
      case 'd':
        return this.daysAgoTimestamp(value);
      case 'm':
        return this.minutesAgoTimestamp(value);
      case 's':
        return this.secondsAgoTimestamp(value);
    }
    // just now
    return firebase.firestore.Timestamp.now();
  }

  getDate(): Date {
    const today: Date = dayjs().add(9, 'hour').toDate();
    return today;
  }

  /**
   * Convert 20200808 to 2020-08-08
   * @param compactDate
   */
  toSimpleDateFormat(compactDate: string): string {
    const date = dayjs(compactDate, 'YYYYMMDD').format('YYYY-MM-DD');
    return date;
  }

  now(): firebase.firestore.Timestamp {
    //return Timestamp.fromMillis(timestamp * 1000);
    return firebase.firestore.Timestamp.now();
  }

  /**
   * Ex) const timestamp: string | number = blockTransactionObject.timestamp;
   * @param timestamp
   */
  // toTimestamp(timestamp: number): Timestamp {
  //   return Timestamp.fromMillis(timestamp * 1000);
  // }

  toTimestamp(date: Date): firebase.firestore.Timestamp {
    return firebase.firestore.Timestamp.fromDate(date);
  }

  secondsAgoTimestamp(seconds: number): firebase.firestore.Timestamp {
    return firebase.firestore.Timestamp.fromMillis(
      this.secondsAgo(seconds).toDate().getTime()
    );
  }

  minutesAgoTimestamp(minutes: number): firebase.firestore.Timestamp {
    return firebase.firestore.Timestamp.fromMillis(
      this.minutesAgo(minutes).toDate().getTime()
    );
  }

  hoursAgoTimestamp(hours: number): firebase.firestore.Timestamp {
    return firebase.firestore.Timestamp.fromMillis(
      this.hoursAgo(hours).toDate().getTime()
    );
  }

  daysAgoTimestamp(days: number): firebase.firestore.Timestamp {
    return firebase.firestore.Timestamp.fromMillis(
      this.daysAgo(days).toDate().getTime()
    );
  }

  secondsAgo(seconds: number): dayjs.Dayjs {
    // return dayjs().subtract(seconds, 'second').add(9, 'hour');
    return dayjs().subtract(seconds, 'second');
  }

  minutesAgo(minutes: number): dayjs.Dayjs {
    // return dayjs().subtract(minutes, 'minute').add(9, 'hour');
    return dayjs().subtract(minutes, 'minute');
  }

  hoursAgo(hours: number): dayjs.Dayjs {
    // return dayjs().subtract(hours, 'hour').add(9, 'hour');
    return dayjs().subtract(hours, 'hour');
  }

  daysAgo(days: number): dayjs.Dayjs {
    // return dayjs().subtract(days, 'day').add(9, 'hour');
    return dayjs().subtract(days, 'day');
  }

  /**
   * Ex)
   * as('seconds')
   * as('minutes')
   * as('hours')
   * toFormat('hh:mm:ss')
   *
   * @param timestamp
   */
  getDurationSince(
    timestamp: firebase.firestore.Timestamp | undefined,
    unit: 'second' | 'minute' | 'hour' | 'day'
  ): number {
    if (timestamp) {
      const diff = dayjs().diff(dayjs(timestamp.toDate()), unit);
      return diff;
      // return dayjs.duration(diff);
      // return Duration.fromMillis(Timestamp.now().toMillis()).minus(timestamp.toMillis());
    }
    // return Duration.fromMillis(0);
    return 0;
  }

  getDuration(value: number, unit: 'second' | 'minute' | 'hour' | 'day'): any {
    switch (unit) {
      case 'second':
        return dayjs.duration({ seconds: value });
      case 'minute':
        return dayjs.duration({ minutes: value });
      case 'hour':
        return dayjs.duration({ hours: value });
      case 'day':
        return dayjs.duration({ days: value });
    }
    return dayjs.duration({ seconds: value });
  }
}
