import { Injectable } from '@angular/core';
import { act, Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { mergeMap } from 'rxjs/operators';
import { EMPTY, from, merge, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { mainFeatureKey, MainState } from '../reducers/main.reducer';
import { routeChanged } from '../actions/main.actions';
import { RouterStateUrl } from '../custom-route-serializer';

/**
 * For simplicity, only youtube format is supported:
 * Ex) https://www.youtube.com/watch?v=ruLB6IMHXx4
 * The core for handing a page-refresh and state management.
 * It keeps path and videoId
 *
 * @author: john@gomedialy.com
 * @version: 0.15, 10/31/2020
 * @version: 0.16, 12/16/2020
 */
@Injectable()
export class RouterEffects {
  /* fields */

  constructor(
    private actions$: Actions,
    private mainStore: Store<{
      [mainFeatureKey]: MainState;
    }>
  ) {
    this.actions$
      .pipe(
        ofType(ROUTER_NAVIGATED),
        mergeMap((action: RouterAction) => {
          /**
           * state.url: /watch?v=Qt_Q9AL2KIE
           * state.queryParams.v: Qt_Q9AL2KIE
           */
          const state = action.payload.routerState as RouterStateUrl;

          /**
           * Use URL to get a path like /watch, /browse ...
           */
          const url = new URL(`http://localhost${state.url}`);
          const path = url.pathname;
          // const videoId = url.searchParams.get('v');
          const videoId = state.queryParams.v;
          // console.error('>>> path: ', path, videoId);

          if (path.startsWith('/watch') || path.startsWith('/videos')) {
            if (videoId) {
              this.mainStore.dispatch(routeChanged({ path, videoId }));
            }
          } else {
            this.mainStore.dispatch(routeChanged({ path }));
          }
          return EMPTY;
        })
      )
      .subscribe();
  }
}

/**
 * Only for navigation$
 */
interface RouterAction {
  type: string;
  payload: Payload;
}

interface Payload {
  event: Event;
  routerState: RouterState;
}

interface Event {
  id: number;
  url: string;
}

interface RouterState {
  url: string;
}
