import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { BaseComponent } from '../base.component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

/**
 * @author: john@gomedialy.com
 * @version: 0.13, 10/08/2020
 * @version: 0.14, 01/15/2021
 */
export interface ButtonItem {
  action: string;
  matIcon?: string;
  svgIcon?: string;
  style?: any;
  tooltip: string;
}
export interface ButtonGroupEvent {
  action: string;
  source?: any;
}
@Component({
  selector: 'app-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss'],
})
export class ButtonGroupComponent
  extends BaseComponent
  implements OnInit, AfterViewInit {
  /* fields */
  @Input()
  buttonItems: ButtonItem[] = [];

  @Output()
  events = new EventEmitter<ButtonGroupEvent>();

  selected: any = null;
  private debounceTime = 500;
  private clicks = new Subject<ButtonGroupEvent>();

  constructor() {
    super();
  }

  ngOnInit(): void {
    // const clickSubscription = this.clicks
    //   .pipe(debounceTime(this.debounceTime))
    //   .subscribe((event: ButtonGroupEvent) => this.events.emit(event));
    // this.subscriptions.add(clickSubscription);
  }

  ngAfterViewInit(): void {
    const clickSubscription = this.clicks
      .pipe(debounceTime(this.debounceTime))
      .subscribe((event: ButtonGroupEvent) => this.events.emit(event));
    this.subscriptions.add(clickSubscription);
  }

  onButtonToggleChange(event: MatButtonToggleChange): void {
    // console.log('clickEvent: ', event);
    this.clicks.next({ action: event.value });

    /**
     * Reset the toggle to work as a normal button.
     */
    setTimeout(() => {
      this.selected = null;
    }, 1);
  }
}
