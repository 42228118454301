import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { mergeMap } from 'rxjs/operators';
import {
  contentDataRequest,
  contentDataResponse,
  wordcloudRequest,
  wordcloudResponse,
} from '../actions/home.actions';
import { of } from 'rxjs';
import { DataService } from '../services/data.service';

/**
 * @author: john@gomedialy.com
 * @version: 0.11, 01/20/2021
 */
@Injectable()
export class HomeEffects {
  /* fields */
  constructor(private actions$: Actions, private dataService: DataService) {}

  wordcloudRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(wordcloudRequest),
      mergeMap((state) => {
        return this.dataService.getWordCloud().pipe(
          mergeMap((wordcloudData) => {
            if (wordcloudData) {
              return of(wordcloudResponse({ wordcloud: wordcloudData }));
            }
            return of(wordcloudResponse({}));
          })
        );
      })
    );
  });

  contentDataRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(contentDataRequest),
      mergeMap((state) => {
        // console.error('**************************** state', state);
        return of(contentDataResponse({}));
      })
    );
  });
}
