import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { NGXLogger } from 'ngx-logger';
import { UserPresence } from '../services/presence.service';

/**
 * @author: john@gomedialy.com
 * @version: 0.12, 12/13/2020
 */
@Component({
  selector: 'app-presence',
  templateUrl: './presence.component.html',
  styleUrls: ['./presence.component.css'],
})
export class PresenceComponent implements OnInit {
  /* fields */
  name = '';
  message = '';
  photoUrl = '';

  constructor(
    private logger: NGXLogger,
    @Inject(MAT_SNACK_BAR_DATA) public userPresence: UserPresence
  ) {
    if (userPresence.isAnonymous) {
      /**
       * For anonymous users, the first 8 characters of their uid is used.
       */
      this.name = this.userPresence.uid.substring(0, 8);
      this.photoUrl = '../assets/images/default-user-photo.png';
    } else {
      if (this.userPresence.name && this.userPresence.photoUrl) {
        this.name = this.userPresence.name;
        this.photoUrl = this.userPresence.photoUrl;
      }
    }

    switch (userPresence.type) {
      case 'joined':
        this.message = ' 님이 들어왔습니다.';
        break;
      case 'left':
        this.message = ' 님이 나갔습니다.';
        break;
    }
  }

  ngOnInit(): void {}
}
