<div
  class="container debug-bluex"
  (ngxVisibility)="onVisible($event)"
  fxLayout="row"
  fxLayoutAlign="start"
  fxLayoutGap="16px"
  fxFlexOffset="{{ level }}"
  matLine
>
  <div>
    <img class="image-rounded" src="{{ inputPost.photoUrl }}" />
  </div>
  <div class="titlex" (mouseover)="mouseOver()" (mouseout)="mouseOut()">
    <div
      class="title debug-greenx"
      style="height: 48px"
      fxLayout="row"
      fxLayoutAlign="start start"
      fxLayoutGap="16px"
    >
      <div fxLayout="column" fxLayoutAlign="start start">
        <span style="font-size: small; font-weight: bolder; line-height: 1.5">{{
          inputPost.displayName
        }}</span>
        <span style="font-size: small; font-weight: bolder; line-height: 1">{{
          createdAt
        }}</span>
      </div>

      <div fxShow="{{ isSubtitle && !isDeleted }}">
        <app-video-control [video]="inputPost.subvideo0"> </app-video-control>
      </div>

      <div fxShow="{{ !isDeleted && isSubvideo }}">
        <app-video-control [video]="inputPost.subvideo1"></app-video-control>
      </div>
      <!-- ////////////////////////////////////////// -->

      <div fxShow="{{ isDesktop && !isDeleted }}">
        <app-button-group
          [style.visibility]="userButtonGroupVisibility"
          fxShow="{{ !isOwner }}"
          #userButtonGroup
          [buttonItems]="userButtonItems"
        ></app-button-group>
        <app-button-group
          [style.visibility]="ownerButtonGroupVisibility"
          fxShow="{{ isOwner }}"
          #ownerButtonGroup
          [buttonItems]="ownerButtonItems"
        ></app-button-group>
      </div>
    </div>
    <div fxShow="{{ draftContent === '' }}">
      <div class="word-wrap debug-redx" markdown ngPreserveWhitespaces emoji>
        {{ content }}
      </div>
    </div>

    <div
      class="debug-greenx"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="32px"
      fxHide="{{ isPending || isDeleted }}"
    >
      <div
        class="debug-redx"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="0px"
      >
        <div fxLayout="row" fxLayoutAlign="start center">
          <button
            mat-icon-button
            appDebounceClick
            (debounceClick)="onLikeClick()"
          >
            <mat-icon style="font-size: 18px" [style.color]="likeIconColor"
              >thumb_up</mat-icon
            >
          </button>
          <span class="mat-small" style="width: 32px; color: 99ee9e">{{
            inputPost.likes
          }}</span>
          <!-- <div style="width: 32px">9999</div> -->
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <button
            mat-icon-button
            appDebounceClick
            (debounceClick)="onDislikeClick()"
          >
            <mat-icon style="font-size: 18px" [style.color]="dislikeIconColor"
              >thumb_down</mat-icon
            >
          </button>
          <div class="mat-small" style="width: 32px; color: 99ee9e">
            {{ inputPost.dislikes }}
          </div>
          <!-- <div style="width: 32px">9999</div> -->
        </div>
      </div>
    </div>

    <div
      class="pending-progress-bar debug-bluex"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxShow="{{ isPending }}"
    >
      <mat-progress-bar
        mode="indeterminate"
        color="warn"
        fxShow="{{ isOwner }}"
      >
      </mat-progress-bar>
      <mat-progress-bar
        mode="indeterminate"
        color="primary"
        fxShow="{{ !isOwner }}"
      >
      </mat-progress-bar>
    </div>
  </div>
</div>
<!-- *ngIf="isPending" -->
<!-- fxShow="{{ isSubvideo }}" -->
<!-- <div *ngIf="isDesktop && !isDeleted"> -->
<!-- <ng-container *ngIf="inputPost.subvideo0 && isSubtitle && !isDeleted">
        <app-video-control [video]="inputPost.subvideo0"> </app-video-control>
      </ng-container>

      <ng-container *ngIf="inputPost.subvideo1 && !isDeleted && isSubvideo">
        <app-video-control [video]="inputPost.subvideo1"></app-video-control>
      </ng-container> -->
