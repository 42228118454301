import firebase from 'firebase/compat/app';

/**
 * @author: john@gomedialy.com
 * @version: 0.12, 09/04/2020
 * @version: 0.13, 10/28/2020
 */

// const idKind = item.id.kind;
// youtube#video, youtube#channel, youtube#playlist
// https://www.youtube.com/watch?v=o4ZWn-v6Dbk&list=PLAzG1h-nuabP0GqqsczHDwNs1ig8SEf-q
// https://www.youtube.com/watch?v=S7T2ZVz_10U&list=PLAzG1h-nuabP0GqqsczHDwNs1ig8SEf-q&index=2
// https://www.youtube.com/channel/UCvF7G_8ED42eCFWaipsq-9g

export const ID_KIND_YOUTUBE_VIDEO = 'youtube#video';
export const ID_KIND_YOUTUBE_CHANNEL = 'youtube#channel';
export const ID_KIND_YOUTUBE_PLAYLIST = 'youtube#playlist';

export interface FirebaseCredential {
  a: any;
  accessToken: string;
  idToken: string;
  providerId: string;
  signInMethod: string;
}

// Double as a firebase entity
export interface Item {
  /**
   * Added to be firestore-friendly
   */
  query: string;
  queryId: string;
  videoId?: string;
  channelId?: string;
  playlistId?: string;
  publishedAt: firebase.firestore.Timestamp;

  kind: string;
  etag: string;
  id: Id;
  snippet: Snippet;
}

export class EmptyItem {
  public static create(timestamp: firebase.firestore.Timestamp): Item {
    const emptyItem: Item = {
      query: '',
      queryId: '',
      publishedAt: timestamp,
      kind: '',
      etag: '',
      id: {
        kind: '',
      },
      snippet: {
        publishedAt: '',
        channelId: '',
        title: '',
        description: '',
        thumbnails: {
          default: {
            url: '',
            width: 0,
            height: 0,
          },
          medium: {
            url: '',
            width: 0,
            height: 0,
          },
          high: {
            url: '',
            width: 0,
            height: 0,
          },
        },
        channelTitle: '',
        liveBroadcastContent: '',
        publishTime: '',
      },
    };
    return emptyItem;
  }
}

// export const EMPTY_ITEM: Item = {
//   query: '',
//   queryId: '',
//   publishedAt: firestore.Timestamp.now(),
//   kind: '',
//   etag: '',
//   id: {
//     kind: '',
//   },
//   snippet: {
//     publishedAt: '',
//     channelId: '',
//     title: '',
//     description: '',
//     thumbnails: {
//       default: {
//         url: '',
//         width: 0,
//         height: 0,
//       },
//       medium: {
//         url: '',
//         width: 0,
//         height: 0,
//       },
//       high: {
//         url: '',
//         width: 0,
//         height: 0,
//       },
//     },
//     channelTitle: '',
//     liveBroadcastContent: '',
//     publishTime: '',
//   },
// };

export interface YoutubeData {
  kind: string;
  etag: string;
  nextPageToken: string;
  regionCode: string;
  pageInfo: PageInfo;
  items: Item[];
}

export interface PageInfo {
  totalResults: number;
  resultsPerPage: number;
}

export interface Id {
  kind: string;
  videoId?: string;
  channelId?: string;
  playlistId?: string;
}

export interface Snippet {
  publishedAt: string;
  channelId: string;
  title: string;
  description: string;
  thumbnails: Thumbnails;
  channelTitle: string;
  liveBroadcastContent: string;
  publishTime: string;
}

export interface Thumbnails {
  default: Image;
  medium: Image;
  high: Image;
}

export interface Image {
  url: string;
  width: number;
  height: number;
}
