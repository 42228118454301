import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { BaseService, Context } from '../base.service';
import { mainFeatureKey, MainState } from '../reducers/main.reducer';
import { NGXLogger } from 'ngx-logger';
import { WordCloudItem } from '../word-cloud/word-cloud.component';
import { DeviceDetectorService } from 'ngx-device-detector';

/**
 * @author: john@gomedialy.com
 * @version: 0.13, 11/07/2020
 * @version: 0.16, 01/23/2021
 * @version: 0.17, 03/16/2021
 */
export interface ImageUrl {
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class MetaService extends BaseService {
  /* fields */
  private description =
    '한국인의 대대손손 한백년 맺힌 영어의 한(恨)을 푸는 커뮤니티 서비스';
  private isDesktop = false;

  constructor(
    mainStore: Store<{
      [mainFeatureKey]: MainState;
    }>,
    protected logger: NGXLogger,
    private deviceService: DeviceDetectorService,
    private title: Title,
    private meta: Meta
  ) {
    super(mainStore, logger);
    this.isDesktop = this.deviceService.isDesktop();
  }

  updateTitle(title: string): void {
    this.title.setTitle(title);
  }

  updateOgUrl(url: string): void {
    this.meta.updateTag({ name: 'og:url', content: url });
  }

  updateDescription(desc: string): void {
    this.meta.updateTag({ name: 'description', content: desc });
  }

  buildBrowse(query: string): void {
    this.title.setTitle(`Browse | ${query}`);

    // this.meta.updateTag({
    //   property: 'og:title',
    //   content: 'GoMedialy.com',
    // });
    // this.meta.updateTag({
    //   property: 'og:description',
    //   content: this.description,
    // });
    // this.meta.updateTag({
    //   property: 'og:url',
    //   content: 'https://gomedialy-dev.web.app/',
    // });

    // this.meta.updateTag({
    //   property: 'og:image',
    //   content: environment.redirectsBaseUrl + '/images/home/',
    // });
    // this.meta.updateTag({
    //   property: 'og:image:height',
    //   content: '630',
    // });
    // this.meta.updateTag({
    //   property: 'og:image:width',
    //   content: '1200',
    // });

    // this.meta.updateTag({
    //   property: 'twitter:image',
    //   content: environment.redirectsBaseUrl + '/images/home/',
    // });
    // this.meta.updateTag({
    //   name: 'twitter:card',
    //   content: 'summary_large_image',
    // });
    // this.meta.updateTag({
    //   name: 'twitter:title',
    //   content: 'GoMedialy.com',
    // });
    // this.meta.updateTag({
    //   name: 'twitter:description',
    //   content: this.description,
    // });
  }

  buildHome(topWordcloudItem: WordCloudItem): void {
    if (this.isDesktop) {
      this.title.setTitle(
        `Haanglish | #1: ${topWordcloudItem.text} (${topWordcloudItem.formattedTraffic})`
      );
    } else {
      this.title.setTitle(`Haanglish`);
    }
    // this.meta.updateTag({
    //   property: 'og:title',
    //   content: 'GoMedialy.com',
    // });
    // this.meta.updateTag({
    //   property: 'og:description',
    //   content: this.description,
    // });
    // this.meta.updateTag({
    //   property: 'og:url',
    //   content: 'https://gomedialy-dev.web.app/',
    // });

    // this.meta.updateTag({
    //   property: 'og:image',
    //   content: environment.redirectsBaseUrl + '/images/home/',
    // });
    // this.meta.updateTag({
    //   property: 'og:image:height',
    //   content: '630',
    // });
    // this.meta.updateTag({
    //   property: 'og:image:width',
    //   content: '1200',
    // });

    // this.meta.updateTag({
    //   property: 'twitter:image',
    //   content: environment.redirectsBaseUrl + '/images/home/',
    // });
    // this.meta.updateTag({
    //   name: 'twitter:card',
    //   content: 'summary_large_image',
    // });
    // this.meta.updateTag({
    //   name: 'twitter:title',
    //   content: 'GoMedialy.com',
    // });
    // this.meta.updateTag({
    //   name: 'twitter:description',
    //   content: this.description,
    // });
  }

  buildWatch(videoId: string, wordcloudItem?: WordCloudItem): void {
    // console.error('>>>>>>> buildWatch: ', videoId);
    if (wordcloudItem) {
      this.title.setTitle(
        `Watch | ${wordcloudItem.text} (${wordcloudItem.formattedTraffic})`
      );
    } else {
      this.title.setTitle(`Watch | ${videoId}`);
    }

    // if (query) {
    //   this.title.setTitle(`Watch | ${query}`);
    // } else {
    //   this.title.setTitle(`Watch | ${videoId}`);
    // }

    // this.meta.updateTag({
    //   property: 'og:title',
    //   content: 'GoMedialy.com | Watch',
    // });
    // this.meta.updateTag({
    //   property: 'og:description',
    //   content: this.description,
    // });
    // this.meta.updateTag({
    //   property: 'og:url',
    //   content: 'https://gomedialy-dev.web.app/videos/' + videoId,
    // });

    // this.meta.updateTag({
    //   property: 'og:image',
    //   content: environment.redirectsBaseUrl + '/images/' + videoId,
    // });
    // this.meta.updateTag({
    //   property: 'og:image:height',
    //   content: '630',
    // });
    // this.meta.updateTag({
    //   property: 'og:image:width',
    //   content: '1200',
    // });

    // this.meta.updateTag({
    //   property: 'twitter:image',
    //   content: environment.redirectsBaseUrl + '/images/' + videoId + '/',
    // });
    // this.meta.updateTag({
    //   name: 'twitter:card',
    //   content: 'summary_large_image',
    // });
    // this.meta.updateTag({
    //   name: 'twitter:title',
    //   content: 'GoMedialy.com | Watch',
    // });
    // this.meta.updateTag({
    //   name: 'twitter:description',
    //   content: this.description,
    // });
  }

  onInit(context: Context): void {
    // throw new Error('Method not implemented.');
  }

  onDestroy(context: Context): void {
    // throw new Error('Method not implemented.');
  }

  name(): string {
    return 'title-meta';
  }
}
