import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { concatMap, switchMap } from 'rxjs/operators';
import { JSend } from '../models/jsend';
import {
  searchResponse,
  searchRequest,
  browseRequest,
  browseResponse,
  browseReloadedResponse,
  browseReloadedRequest,
  homeRequest,
  homeResponse,
  watchRequest,
  watchResponse,
} from '../actions/main.actions';
import { Router } from '@angular/router';
import { from, of } from 'rxjs';

/**
 * @author: john@gomedialy.com
 * @version: 0.13, 09/15/2020
 * @version: 0.26, 01/20/2021
 */
@Injectable()
export class MainEffects {
  constructor(private actions$: Actions, private router: Router) {}

  homeRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(homeRequest),
      concatMap(() => {
        return from(this.router.navigateByUrl('')).pipe(
          concatMap(() => {
            return of(homeResponse());
          })
        );
      })
    );
  });

  watchRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(watchRequest),
      concatMap((state) => {
        if (state.item.videoId) {
          /**
           * Ex) this.router.navigate(['/heroes', { id: heroId }]);
           */
          return from(
            this.router.navigateByUrl(`/watch?v=${state.item.videoId}`)
          ).pipe(
            concatMap(() => {
              return of(watchResponse());
            })
          );
        }
        return from(this.router.navigate(['watch'])).pipe(
          concatMap(() => {
            return of(watchResponse());
          })
        );
      })
    );
  });

  /**
   * TODO: which one is better between searchResponse and watchResponse
   */
  searchRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(searchRequest),
      concatMap((state) => {
        const videoId = state.query;
        return from(this.router.navigateByUrl(`/watch?v=${videoId}`)).pipe(
          concatMap(() => {
            // TODO: Test
            // return of(watchResponse());
            return of(searchResponse({ jsend: JSend.success() }));
          })
        );
      })
    );
  });

  browseRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(browseRequest),
      concatMap((state) => {
        const query = state.query;
        return from(this.router.navigateByUrl(`/browse?q=${query}`)).pipe(
          concatMap(() => {
            return of(browseResponse({ jsend: JSend.success() }));
          })
        );
      })
    );
  });

  browseReloadedRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(browseReloadedRequest),
      concatMap((state) => {
        return of(browseReloadedResponse({ jsend: JSend.success() }));
      })
    );
  });
}
