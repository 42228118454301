import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
} from '@angular/core';
import { Item, Snippet } from '../models/youtube-data';
import { Strings } from '../utils/strings';
import { DateTimeService } from '../services/date-time.service';
import { BaseComponent } from '../base.component';
import { DOCUMENT } from '@angular/common';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeIn } from 'ng-animate';

/**
 * @author: john@gomedialy.com
 * @version: 0.12, 09/06/2020
 * @version: 0.14, 10/28/2020
 * @version: 0.15, 01/08/2021
 */
@Component({
  selector: 'app-video-info',
  templateUrl: './video-info.component.html',
  styleUrls: ['./video-info.component.scss'],
  animations: [trigger('fadeIn', [transition('* => *', useAnimation(fadeIn))])],
})
export class VideoInfoComponent extends BaseComponent implements OnInit {
  /* fields */
  fadeIn: any;
  title = 'title';
  subtitle = 'subtitle';
  description0 = '';
  description1 = '';

  @Input()
  item!: Item;

  @Output()
  events = new EventEmitter<Item>();

  constructor(
    private dateTimeService: DateTimeService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.item) {
      const snippet: Snippet = this.item.snippet;

      this.title = snippet.channelTitle;

      const timestamp = this.dateTimeService.getTimestampFromDateString(
        snippet.publishedAt
      );
      this.subtitle = timestamp.toDate().toDateString();
      this.description0 = Strings.decodeHtml(this.document, snippet.title);
      this.description1 = Strings.decodeHtml(
        this.document,
        snippet.description
      );
    }
  }

  name(): string {
    return 'video-info';
  }
}
