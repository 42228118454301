import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseService, Context } from '../base.service';
import { mainFeatureKey, MainState } from '../reducers/main.reducer';
import { NGXLogger } from 'ngx-logger';
import { EMPTY, Observable, of, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  playOrPause,
  replay,
  seekLeftTo,
  seekRightTo,
  volumeUp,
  volumeDown,
  videoBuffering,
  videoCued,
  videoEnded,
  videoPaused,
  videoUnstarted,
  directPlayVideo,
  playVideo,
  pauseVideo,
} from '../actions/video.actions';
import { videoFeatureKey, VideoState } from '../reducers/video.reducer';
import { VideoPlayerComponent } from './video-player.component';
import { WatchComponent } from '../watch/watch.component';

/**
 * @author: john@gomedialy.com
 * @version: 0.19, 12/26/2020
 * @version: 0.23, 01/25/2021
 */
export interface VideoSnapshot {
  videoId: string;
  videoType: 'video' | 'subtitle' | 'subvideo';
  timestamp: number;
  endSeconds: number;
  duration: number;
  postId: string | null; // a video and posts go together.
}

@Injectable({
  providedIn: 'root',
})
export class VideoPlayerService extends BaseService {
  /* fields */
  private playing = false;
  private ended = false;
  private videoSnapshot: VideoSnapshot | null = null;
  private players: Map<
    'video-player' | 'pip-player',
    VideoPlayerComponent
  > = new Map<'video-player' | 'pip-player', VideoPlayerComponent>();
  private seekTime = 0;
  private selectedPlayerId: 'video-player' | 'pip-player' = 'video-player';
  private initialized = false;

  constructor(
    private mainStore: Store<{
      [mainFeatureKey]: MainState;
    }>,
    private videoStore: Store<{
      [videoFeatureKey]: VideoState;
    }>,
    protected logger: NGXLogger
  ) {
    // super(mainStore);
    super(mainStore, logger);
    // TODO: Test
    // const tag = document.createElement('script');
    // tag.src = 'https://www.youtube.com/iframe_api';
    // document.body.appendChild(tag);

    /**
     * !We don't need any unsubscrition here.
     */
    this.videoStore
      .select(videoFeatureKey)
      .pipe(
        switchMap((state) => {
          const player = this.players.get(this.selectedPlayerId);
          switch (state.type) {
            case playOrPause.type.toString():
              if (player) {
                if (this.playing) {
                  player.pauseVideo();
                } else {
                  player.playVideo();
                }
              }
              break;
            case replay.type.toString():
              this.seekTime = 0;
              if (player) {
                player.seekTo(0, true);
              }

              break;
            case seekLeftTo.type.toString():
              if (player) {
                const value0 = (2 / player.getDuration()) * 100;
                this.seekTime = this.seekTime - value0;
                const leftSeekTo = player.getCurrentTime() - 2;
                player.seekTo(leftSeekTo, true);
              }
              break;
            case seekRightTo.type.toString():
              if (player) {
                const value = (5 / player.getDuration()) * 100;
                this.seekTime = this.seekTime + value;
                const rightSeekTo = player.getCurrentTime() + 5;
                player.seekTo(rightSeekTo, true);
              }

              break;
            case volumeUp.type.toString():
              break;
            case volumeDown.type.toString():
              break;
            case playVideo.type.toString():
              if (state.videoId) {
                if (player) {
                  player.loadVideo(
                    state.videoId,
                    state.videoType,
                    state.startSeconds,
                    state.endSeconds,
                    state.duration,
                    false,
                    state.postId
                  );
                }
              }
              break;
            case directPlayVideo.type.toString():
              if (state.videoId) {
                if (player) {
                  player.loadVideo(
                    state.videoId,
                    state.videoType,
                    state.startSeconds,
                    state.endSeconds,
                    state.duration,
                    true,
                    state.postId
                  );
                }
              }
              break;
            case pauseVideo.type.toString():
              if (player) {
                if (this.playing) {
                  player.pauseVideo();
                }
              }
              break;
          }

          return EMPTY;
        })
      )
      .subscribe();
  }

  /**
   * From watch.component
   */
  onVideoPlayerVisible(watchComponent: WatchComponent, visible: boolean): void {
    watchComponent.isPipVisible = !visible;
    this.selectedPlayerId = visible ? 'video-player' : 'pip-player';

    if (this.selectedPlayerId === 'pip-player') {
      if (this.playing) {
        const videoPlayer = this.players.get('video-player');
        if (videoPlayer) {
          /**
           * MUST pause the video.
           */
          videoPlayer.pauseVideo();
        }
      }
    }
  }

  getPlayer(): 'video-player' | 'pip-player' {
    return this.selectedPlayerId;
  }

  onPlayerInit(player: VideoPlayerComponent): void {
    const id = player.getId();
    this.players.set(id, player);
    // this.selectedPlayerId = id;
  }

  /**
   * From video-player.component
   */
  onPlayerDestory(player: VideoPlayerComponent): void {
    switch (player.getId()) {
      case 'video-player':
        this.selectedPlayerId = 'video-player';
        break;
      case 'pip-player':
        this.selectedPlayerId = 'video-player';
        this.players.delete('pip-player');
        break;
    }
  }

  /**
   * This is not called continuously.
   * Only on changes, it is called
   */
  onStateChange(
    videoId: string,
    videoType: 'video' | 'subtitle' | 'subvideo',
    endSeconds: number,
    postId: string | null,
    event: YT.OnStateChangeEvent
  ): void {
    // this.videoPlayerService.onStateChange(this, event);
    const player = event.target;
    const duration = +player.getDuration().toFixed(0);
    const timestamp = +player.getCurrentTime().toFixed(0);
    // const this.videoPlayerService.getVideoSnapshot();
    const snapshot: VideoSnapshot = {
      videoId,
      videoType,
      postId,
      timestamp,
      endSeconds,
      duration,
    };
    switch (event.data) {
      case YT.PlayerState.UNSTARTED:
        this.videoStore.dispatch(videoUnstarted(snapshot));
        break;
      case YT.PlayerState.ENDED:
        this.onPlaying(false);
        this.onEnded(true);
        this.videoStore.dispatch(videoEnded(snapshot));
        break;
      case YT.PlayerState.PLAYING:
        this.onPlaying(true);
        this.onEnded(false);
        break;
      case YT.PlayerState.PAUSED:
        this.onPlaying(false);
        this.onEnded(false);
        this.videoStore.dispatch(videoPaused(snapshot));
        break;
      case YT.PlayerState.BUFFERING:
        this.videoStore.dispatch(videoBuffering(snapshot));
        break;
      case YT.PlayerState.CUED:
        this.videoStore.dispatch(videoCued(snapshot));
        event.target.playVideo();
        break;
    }
  }

  /**
   * in milis
   */
  getSeekTime(): number {
    return this.seekTime;
  }

  /**
   * TODO: onEnded()
   * From video-play.component
   */
  onEnded(ended: boolean): void {
    this.ended = ended;
  }

  isEnded(): boolean {
    return this.ended;
  }

  /**
   * TODO: onPlaying()
   * From video-player.component
   */
  onPlaying(playing: boolean): void {
    this.playing = playing;
  }

  isPlaying(videoId?: string): boolean {
    if (videoId) {
      const currentVideoId = this.getVideoId();
      if (currentVideoId) {
        return videoId === currentVideoId;
      }
      return false;
    }
    return this.playing;
  }

  getVideoId(): string | null {
    const videoSnapshot = this.getVideoSnapshot();
    if (videoSnapshot) {
      return videoSnapshot.videoId;
    }
    return null;
  }

  getVideoSnapshot(): VideoSnapshot | null {
    return this.videoSnapshot;
  }

  /**
   * From video-player.component
   */
  setVideoSnapshot(videoSnapshot: VideoSnapshot | null): void {
    this.videoSnapshot = videoSnapshot;
  }

  /**
   * From app.component
   */
  getCurrentTime(): number {
    const player = this.players.get(this.selectedPlayerId);
    if (player) {
      return +player.videoPlayer.getCurrentTime().toFixed(0);
    }
    return 0;
  }

  onInit(context: Context): void {
    /**
     * To remove multiple calls at the same time.
     */
    if (!this.initialized) {
      this.initialized = true;
      // this.onInit2(context);
    }
  }

  onDestroy(context: Context): void {
    this.initialized = false;
  }

  name(): string {
    return 'video-player-service';
  }
}
