import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

/**
 * Replacement for MatPaginatorIntl
 *
 * @author: john@gomedialy.com
 * @version: 0.1, 09/13/2020
 */
@Injectable()
export class PostsPaginatorIntl extends MatPaginatorIntl {
  constructor() {
    super();
    this.getAndInitTranslations();
  }

  getAndInitTranslations() {
    this.itemsPerPageLabel = '페이지';
    this.nextPageLabel = '다음';
    this.previousPageLabel = '이전';
    this.firstPageLabel = '처음';
    this.lastPageLabel = '마지막';
    this.changes.next();
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 / ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} / ${length}`;
  };
}
