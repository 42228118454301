import {
  AfterViewInit,
  EventEmitter,
  HostListener,
  Inject,
  Injectable,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { PostDataService } from './post-data.service';
import { Arrays } from '../utils/guava';
import { Post } from '../models/posts';
import { Store } from '@ngrx/store';
import { dataFeatureKey, DataState } from '../reducers/data.reducer';
import { merge, EMPTY, of } from 'rxjs';
import { BaseService, Context } from '../base.service';
import { mainFeatureKey, MainState } from '../reducers/main.reducer';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';
import { NGXLogger } from 'ngx-logger';

/**
 * @author: john@gomedialy.com
 * @version: 0.14, 09/26/2020
 * @version: 0.18, 10/30/2020
 */
export interface ScrollEvent {
  direction: 'up' | 'down';
  pageNumber: number;
}

@Injectable({
  providedIn: 'root',
})
export class ScrollService extends BaseService {
  /* fields */

  /**
   * TODO: events are not being used for now. Later for optimization
   */
  events = new EventEmitter<ScrollEvent>();
  private pageNumber = 1;
  private pageSize = environment.pageSize;
  private lastPageNumber = 1;
  private heightMap = new Map<number, number>();

  constructor(
    mainStore: Store<{
      [mainFeatureKey]: MainState;
    }>,
    protected logger: NGXLogger,
    // private postDataSource: PostDataSource,
    // private dataStore: Store<{
    //   [dataFeatureKey]: DataState;
    // }>,
    private pageScrollService: PageScrollService,
    @Inject(DOCUMENT) private document: any
  ) {
    // super(mainStore);
    super(mainStore, logger);
  }

  scrollTo(target: string, scrollOffset?: number): void {
    if (!scrollOffset) {
      scrollOffset = 100;
    }

    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: `#${target}`,
      scrollOffset,
      duration: 250,
    });
  }

  onInit(context: Context): void {
    this.heightMap.clear();
  }

  onDestroy(context: Context): void {}

  /**
   * From PostComponent
   * @param index
   * @param visible
   */
  onPostComponentVisible(index: number, visible: boolean): void {
    if (visible) {
      this.pageNumber = +(index / this.pageSize).toFixed(0) + 1;

      let direction: 'up' | 'down' = 'down';
      if (this.lastPageNumber > this.pageNumber) {
        // up
        direction = 'up';
      } else {
        // down
        direction = 'down';
      }
      /**
       * Only on page turn, scroll-events are fired.
       */
      if (index % this.pageSize === 0) {
        const scrollEvent: ScrollEvent = {
          direction,
          pageNumber: this.pageNumber,
        };
        /**
         * TODO: Not in use for now.
         */
        this.events.emit(scrollEvent);
        // console.log('scroll-event: ', scrollEvent);
        this.lastPageNumber = this.pageNumber;
      }
    }
  }

  getHeight(index: number): string | undefined {
    const height = this.heightMap.get(index);
    if (height) {
      return `${height}px`;
    }
    // return '94px'; // default for now.
    return undefined;
  }

  /**
   * TODO:
   * @param index
   * @param height
   */
  onAfterViewInit(index: number, height: number): void {
    if (!this.heightMap.has(index)) {
      this.heightMap.set(index, height);
    }
    // const height = this.elementRef.nativeElement.offsetHeight;
    // const width = this.elementRef.nativeElement.offsetWidth;
    // // this.componentHeight = height;

    // console.error('>>>>>>>>>>>>>>> height, width: ', height, width);
  }

  getPageNumberForIndex(index: number): number {
    return +(index / this.pageSize).toFixed(0) + 1;
  }

  /**
   * TODO: In use for now.
   */
  getPageNumber(): number {
    return this.pageNumber;
  }

  /**
   * For data-loading
   * TODO: in use for now.
   */
  getScrollDownPageNumber(): number {
    return this.pageNumber + 1;
  }

  onScrollDown() {
    // console.info('[SCROLL_DOWN]: ', this.pageNumber);
  }

  onScrollUp() {
    // console.info('[SCROLL_UP]: ', this.pageNumber);
  }

  name(): string {
    return 'scroll';
  }

  // constructor() {
  //   this.appendItems(0, this.sum);
  // }

  // array = [];
  // sum = 100;
  // throttle = 300;
  // scrollDistance = 1;
  // scrollUpDistance = 2;
  // direction = '';
  // modalOpen = false;

  // addItems(startIndex: number, endIndex: number, _method) {
  //   for (let i = 0; i < this.sum; ++i) {
  //     this.array[_method]([i, ' ', this.generateWord()].join(''));
  //   }
  // }

  // appendItems(startIndex, endIndex) {
  //   this.addItems(startIndex, endIndex, 'push');
  // }

  // prependItems(startIndex, endIndex) {
  //   this.addItems(startIndex, endIndex, 'unshift');
  // }

  // onScrollDown(ev) {
  //   console.log('scrolled down!!', ev);

  //   // add another 20 items
  //   const start = this.sum;
  //   this.sum += 20;
  //   this.appendItems(start, this.sum);

  //   this.direction = 'down';
  // }

  // onUp(ev) {
  //   console.log('scrolled up!', ev);
  //   const start = this.sum;
  //   this.sum += 20;
  //   this.prependItems(start, this.sum);

  //   this.direction = 'up';
  // }
}
