import { Injectable } from '@angular/core';
import { mergeMap, switchMap, take, finalize } from 'rxjs/operators';
import { from, of, Observable, EMPTY, throwError, Subscription } from 'rxjs';
import { FirestoreService } from './firestore.service';
import { Post, PostCounts } from '../models/posts';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { AuthService } from './auth.service';
import { BaseService, Context } from '../base.service';
import { Store } from '@ngrx/store';
import { mainFeatureKey, MainState } from '../reducers/main.reducer';
import { CounterService } from './counter.service';
import { NGXLogger } from 'ngx-logger';

/**
 * @author: john@gomedialy.com
 * @version: 0.11, 09/26/2020
 * @version: 0.13, 10/28/2020
 */
export interface PostCountsListener {
  onPostCountsChange(postCounts: PostCounts): void;
}
@Injectable({
  providedIn: 'root',
})
export class PostCountsService extends BaseService {
  /* fields */
  private angularFirestore: AngularFirestore;
  private firestore: firebase.firestore.Firestore;
  private postCounts: PostCounts = {
    videoId: '',
    postsCount: 0,
    subpostsCount: 0,
    titlesCount: 0,
    subtitlesCount: 0,
    createdAt: firebase.firestore.Timestamp.now(),
  };

  constructor(
    mainStore: Store<{
      [mainFeatureKey]: MainState;
    }>,
    protected logger: NGXLogger,
    private authService: AuthService,
    private firestoreService: FirestoreService,
    private counterService: CounterService
  ) {
    // super(mainStore);
    super(mainStore, logger);
    this.angularFirestore = this.firestoreService.getAngularFirestore();
    this.firestore = this.firestoreService.firestore;
  }

  onInit(context: Context): void {}

  onDestroy(context: Context): void {}

  name(): string {
    return 'post-counts';
  }

  setPostCountsListener(
    videoId: string,
    postCountsListener: PostCountsListener
  ): void {
    this.initializePostCountsDoc(videoId);

    const docPath = `channels/${videoId}/post_counts/${videoId}`;
    const postCountsSubscription = this.counterService
      .listenToDocChanges<PostCounts>(docPath)
      .pipe(
        switchMap((postCounts) => {
          if (postCounts) {
            this.postCounts = postCounts;
            // console.log('>>> updated: ', this.postCounts);
            postCountsListener.onPostCountsChange(postCounts);
          }
          return EMPTY;
        })
      )
      .subscribe();
    this.subscriptions.push(postCountsSubscription);
  }

  private initializePostCountsDoc(videoId: string): void {
    const docPath = `channels/${videoId}/post_counts/${videoId}`;
    const postCounts: PostCounts = {
      videoId,
      postsCount: 0,
      subpostsCount: 0,
      titlesCount: 0,
      subtitlesCount: 0,
      createdAt: firebase.firestore.Timestamp.now(),
    };
    this.counterService.initializeCountDoc(docPath, postCounts);
  }

  /**
   * From post.effects
   * @param videoId
   * @param postId
   * @param liked
   * @param path
   */
  updatePostCounts(
    videoId: string,
    countType: 'posts' | 'subposts' | 'titles' | 'subtitles',
    value: number
  ): Observable<void> {
    const docPath = `channels/${videoId}/post_counts/${videoId}`;
    switch (countType) {
      case 'posts':
        return this.counterService.updateCount(docPath, 'postsCount', value);
      case 'subposts':
        return this.counterService.updateCount(docPath, 'subpostsCount', value);
      case 'titles':
        return this.counterService.updateCount(docPath, 'titlesCount', value);
      case 'subtitles':
        return this.counterService.updateCount(
          docPath,
          'subtitlesCount',
          value
        );
    }
  }

  // private listenToPostsInfo(
  //   videoId: string
  // ): Observable<PostCounts | undefined> {
  //   const path = `channels/${videoId}/posts-info/${videoId}`;
  //   return this.angularFirestore.doc<PostCounts>(path).valueChanges();
  // }
}
