import { Pipe, PipeTransform } from '@angular/core';

/**
 * Ex) {{dog.views | shortNumber}}
 *
 * @author: john@gomedialy.com
 * @version: 0.1, 10/25/2020
 * @version: 0.11, 11/18/2020
 * @version: 0.12, 01/05/2021
 */
@Pipe({
  name: 'shortNumber',
})
export class ShortNumberPipe implements PipeTransform {
  transform(value: number, args?: any): any {
    if (isNaN(value)) {
      return null;
    } // will only work value is a number
    if (value === null) {
      return null;
    }
    if (value === 0) {
      // return null;
      return 0;
    }
    let abs = Math.abs(value);
    const rounder = Math.pow(10, 1);
    const isNegative = value < 0; // will also work for Negetive numbers
    let key = '';

    const powers = [
      { key: 'Q', value: Math.pow(10, 15) },
      { key: 'T', value: Math.pow(10, 12) },
      { key: 'B', value: Math.pow(10, 9) },
      { key: 'M', value: Math.pow(10, 6) },
      { key: 'K', value: 1000 },
    ];

    for (const power of powers) {
      let reduced = abs / power.value;
      reduced = Math.round(reduced * rounder) / rounder;
      if (reduced >= 1) {
        abs = reduced;
        key = power.key;
        break;
      }
    }

    // for (let i = 0; i < powers.length; i++) {
    //   let reduced = abs / powers[i].value;
    //   reduced = Math.round(reduced * rounder) / rounder;
    //   if (reduced >= 1) {
    //     abs = reduced;
    //     key = powers[i].key;
    //     break;
    //   }
    // }
    return (isNegative ? '-' : '') + abs + key;
  }
}
