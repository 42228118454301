import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, tap, throttleTime } from 'rxjs/operators';

/**
 * Ex) <button appDebounceClick
 * (debounceClick)="log()" [debounceTime]="700">Debounce Click</button>
 *
 * @author: john@gomedialy.com
 * @version: 0.1, 09/22/2020
 * @version: 0.11, 12/09/2020
 */
@Directive({
  selector: '[appDebounceClick]',
})
export class DebounceClickDirective implements OnInit, OnDestroy {
  /* fields */
  @Input()
  debounceTime = 250;

  @Output()
  debounceClick = new EventEmitter();

  private clicks = new Subject();
  private subscription: Subscription | null = null;

  constructor() {}

  ngOnInit(): void {
    this.subscription = this.clicks
      .pipe(
        debounceTime(this.debounceTime)
        // tap((e) => {
        //   console.log('clicked: ', e);
        // })
      )
      .subscribe((e) => this.debounceClick.emit(e));
  }

  // /**
  //  * @param e
  //  */
  // emitThrottledClick(e: any) {
  //   console.log('throttled-click: ', e);
  //   this.throttledClick.emit(e);
  // }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event'])
  // tslint:disable-next-line: no-any
  clickEvent(event: any): void {
    // console.log('clickEvent: ', event);
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }
}
