import { Injectable, EventEmitter } from '@angular/core';
import { WordCloudItem } from '../word-cloud/word-cloud.component';

/**
 * @author: john@gomedialy.com
 * @version: 0.1, 11/17/2020
 */
@Injectable({
  providedIn: 'root',
})
export class QueryEventService {
  /* fields */
  events = new EventEmitter<WordCloudItem>();

  constructor() {}

  onWordClick(wordCloudItem: WordCloudItem): void {
    this.events.emit(wordCloudItem);
  }
}
