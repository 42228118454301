import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import {
  signupRequest,
  signupResponse,
  loginRequest,
  loginResponse,
  logoutRequest,
  logoutResponse,
} from '../actions/auth.actions';
import { of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { JSend } from '../models/jsend';
import { AuthService } from '../services/auth.service';
import { FirestoreService } from '../services/firestore.service';
import { User } from '../models/user';
import firebase from 'firebase/compat/app';
// import { NGXLogger } from 'ngx-logger';

/**
 * @author: john@gomedialy.com
 * @version: 0.14, 09/03/2020
 * @version: 0.16, 11/16/2020
 */
@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private firestoreService: FirestoreService // private logger: NGXLogger
  ) {}

  signupRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(signupRequest),
      mergeMap((authState) => {
        const user: User = authState.user;

        /**
         * For now, userId is uid since we do everything serverless.
         */
        const userId = user.uid;
        return this.firestoreService.findOnlyOne<User>('users', userId).pipe(
          mergeMap((currentUser) => {
            const timestamp = firebase.firestore.Timestamp.now();
            if (currentUser) {
              /**
               * updatedAt is changed.
               */
              const updateUser: User = {
                ...currentUser,
                updatedAt: timestamp,
              };
              return this.firestoreService.setAndReturn(
                'users',
                userId,
                updateUser,
                updateUser
              );
            }
            const createUser: User = {
              ...user,
              createdAt: timestamp,
              updatedAt: timestamp,
            };
            return this.firestoreService.setAndReturn(
              'users',
              userId,
              createUser,
              createUser
            );
          }),
          mergeMap(() => {
            // TODO: Test
            // return of(signupResponse({ jsend: JSend.success(user) }));
            return of(signupResponse({ jsend: JSend.success() }));
          })
        );
      })
    );
  });

  loginRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginRequest),
      mergeMap(() => {
        return this.authService.loginWithRedirect();
      }),
      map(() => {
        console.log('$$$$$ login effects works!!!: ', loginRequest);
        return loginResponse({ jsend: JSend.success() });
      })
    );
  });

  logoutRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(logoutRequest),
      mergeMap(() => {
        return this.authService.logout();
      }),
      map(() => {
        // console.log('$$$$$ logout effects works!!!: ', logoutRequest);
        return logoutResponse({ jsend: JSend.success() });
      })
    );
  });
}
