import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JSend } from '../models/jsend';
import { tap, mergeMap, map } from 'rxjs/operators';
import { Objects } from '../utils/objects';

/**
 * @author: john@gomedialy.com
 * @version: 0.1, 01/09/2021
 */
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private httpClient: HttpClient) {}

  getVideoImageUrl(videoId: string): Observable<string | null> {
    return this.get<JSend>(
      `${environment.functionsBaseUrl}/image?v=${videoId}`
    ).pipe(
      map((jsend) => {
        switch (jsend.status) {
          case 'success':
            return jsend.data ? jsend.data.url : null;
            break;
          default:
            return null;
        }
      })
    );
  }

  get<T>(url: string): Observable<T> {
    return this.httpClient.get<T>(url);
  }
}
