import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import {
  mergeMap,
  debounceTime,
  delay,
  concatMap,
  filter,
  map,
} from 'rxjs/operators';
import { Item } from '../models/youtube-data';
import { DataService } from '../services/data.service';
import { ContentData } from '../models/data';
import {
  WordCloudComponent,
  WordCloudItem,
} from '../word-cloud/word-cloud.component';
import {
  ContentCardEventService,
  ContentCardEvent,
} from '../content-card/content-card-event.service';
import { select, Store } from '@ngrx/store';
import { MainState, mainFeatureKey } from '../reducers/main.reducer';
import {
  watchRequest,
  scrollChanged,
  homeLoaded,
} from '../actions/main.actions';
import { BaseComponent } from '../base.component';
import { homeRefreshed, routeChanged } from '../actions/main.actions';
import { ScrollService } from '../services/scroll.service';
import { Strings } from '../utils/strings';
import { NgxSpinnerService } from 'ngx-spinner';
import { ViewportScroller } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { homeFeatureKey, HomeState } from '../reducers/home.reducer';
import { LocalDataService } from '../services/local-data.service';
import {
  wordcloudResponse,
  contentDataResponse,
} from '../actions/home.actions';
import { MetaService } from '../services/meta.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { zoomIn, zoomOut } from 'ng-animate';
import { authFeatureKey, AuthState } from '../reducers/auth.reducer';
import { authStateChanged, loginRequired } from '../actions/auth.actions';
import { NGXLogger } from 'ngx-logger';

/**
 * @author: john@gomedialy.com
 * @version: 0.20, 09/12/2020
 * @version: 0.53, 02/03/2021
 */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('zoomIn', [
      transition(
        'void => *',
        useAnimation(zoomIn, { params: { timing: 0.25, delay: 0 } })
      ),
    ]),
    trigger('zoomOut', [
      transition(
        '* => void',
        useAnimation(zoomOut, { params: { timing: 0.25, delay: 0 } })
      ),
    ]),
  ],
})
export class HomeComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {
  /* fields */
  @ViewChild('wordCloud')
  wordCloudComponent?: WordCloudComponent;
  wordCloudItems: WordCloudItem[] = [];

  /**
   * For menu-card-group input-data
   */
  contentDataList: ContentData[] = [];
  scrollToTopEnabled = false;

  private isDesktop = true;
  zoomIn: any;
  zoomOut: any;

  constructor(
    private logger: NGXLogger,
    private authStore: Store<{
      [authFeatureKey]: AuthState;
    }>,
    private dataService: DataService,
    private contentCardEventService: ContentCardEventService,
    private mainStore: Store<{
      [mainFeatureKey]: MainState;
    }>,
    private homeStore: Store<{
      [homeFeatureKey]: HomeState;
    }>,
    private scrollService: ScrollService,
    private viewportScroller: ViewportScroller,
    private localDataService: LocalDataService,
    private deviceService: DeviceDetectorService,
    private spinner: NgxSpinnerService,
    private metaService: MetaService
  ) {
    super();
    this.isDesktop = this.deviceService.isDesktop();
    /**
     * Things work well this way.
     */
    this.restoreLastPosition();

    const authStoreSubscription = this.authStore
      .pipe(select(authFeatureKey))
      .pipe(
        mergeMap((authState) => {
          switch (authState.type) {
            case authStateChanged.type.toString():
              const user = authState.user;
              if (user) {
                this.onUserReady();
              }
              break;
          }
          return EMPTY;
        })
      )
      .subscribe();
    this.subscriptions.add(authStoreSubscription);

    // spinner strats here.
    this.spinner.show();
  }

  private onUserReady(): void {
    /** spinner starts on init */
    // this.spinner.show();

    const wordcloudSubscription = this.dataService
      .listenToWordCloud()
      .subscribe((wordcloudData) => {
        this.homeStore.dispatch(
          wordcloudResponse({ wordcloud: wordcloudData })
        );
      });
    this.subscriptions.add(wordcloudSubscription);
    const contentDataSubscription = this.dataService
      .listenToContentDataCollection()
      .subscribe((clientData) => {
        this.homeStore.dispatch(contentDataResponse({ content: clientData }));
      });
    this.subscriptions.add(contentDataSubscription);

    const homeStoreSubscription = this.homeStore
      .select(homeFeatureKey)
      .pipe(
        mergeMap((state) => {
          switch (state.type) {
            case wordcloudResponse.type.toString():
              if (state.wordcloud) {
                if (this.isDesktop) {
                  this.wordCloudItems = state.wordcloud.desktop;
                } else {
                  this.wordCloudItems = state.wordcloud.mobile;
                }
                this.metaService.buildHome(this.wordCloudItems[0]);
              }
              break;
            case contentDataResponse.type.toString():
              if (state.content) {
                this.contentDataList = state.content.data;
              }

              /**
               * Hide spinner when the content is ready.
               */
              this.spinner.hide();
              break;
          }

          return EMPTY;
        })
      )
      .subscribe();
    this.subscriptions.add(homeStoreSubscription);

    const mainStoreSubscription = this.mainStore
      .select(mainFeatureKey)
      .pipe(
        mergeMap((state) => {
          // console.error('mainState: ', state);

          switch (state.type) {
            case routeChanged.type.toString():
              if (state.path === '/') {
                /**
                 * For home, responses work like as a reload.
                 * Reload the current data without flickering.
                 */
                this.homeStore.dispatch(wordcloudResponse({}));
                this.homeStore.dispatch(contentDataResponse({}));
              }
              break;
            case scrollChanged.type.toString():
              // console.log('scroll-changed: ', state);
              const offset = state.offset;
              return of(offset);
            case homeRefreshed.type.toString():
              break;
            case homeLoaded.type.toString():
              // console.error('>>>>>>>>>>>>>>>>>>>>> homeLoaded: ', state);
              // if (!this.isRefreshed) {
              //   // TODO: fast caching
              //   // console.error(
              //   //   '### homeLoaded... with refresh: ',
              //   //   this.isRefreshed
              //   // );
              // }
              break;
          }
          return EMPTY;
        }),
        debounceTime(250),
        mergeMap((offset) => {
          /**
           * Use debounceTime() to show FAB gracefully.
           */
          setTimeout(() => {
            this.scrollToTopEnabled = offset !== 0;
          }, 500);
          return EMPTY;
        })
      )
      .subscribe();
    this.subscriptions.add(mainStoreSubscription);

    const contentCardEventSubscription = this.contentCardEventService.events.subscribe(
      (event: ContentCardEvent) => {
        // console.log('>>>> menu-card-event: ', event);
        const item: Item = event.source;
        switch (event.action) {
          case 'play': // play_video
            // console.error(
            //   '#################### called many.... times: ',
            //   event
            // );
            const tags = event.tags;
            this.mainStore.dispatch(watchRequest({ item, tags }));
            break;
          case 'like': // like_video
            break;
          case 'share': // share_video
            // console.log('################# share .....');
            // this.openDialog();
            break;
        }
      }
    );
    this.subscriptions.add(contentCardEventSubscription);
  }

  private restoreLastPosition(): void {
    /**
     * Compare to the scrollId, this is way fast and user-friendly.
     */
    const scrollToLastHomePositionSubscription = this.scrollToLastPosition(0)
      .pipe(
        concatMap(() => {
          return this.mainStore.select(mainFeatureKey).pipe(
            filter((state) => {
              if (state.type === scrollChanged.type.toString()) {
                if (state.path === '/') {
                  return true;
                }
              }
              return false;
            }),
            debounceTime(250),
            mergeMap((state) => {
              const offset = state.offset;
              return this.localDataService.set2('home', 'lastOffset', offset);
            })
          );
        })
      )
      .subscribe();
    this.subscriptions.add(scrollToLastHomePositionSubscription);
  }

  private scrollToLastPosition(delayInMis: number): Observable<number | null> {
    return this.localDataService.get2<number>('home', 'lastOffset').pipe(
      delay(delayInMis),
      map((lastOffset) => {
        if (lastOffset) {
          /**
           * 70px is the margin-top
           */
          // this.viewportScroller.scrollToPosition([0, lastOffset - 70]);
          this.viewportScroller.scrollToPosition([0, lastOffset]);
        }
        return lastOffset;
      })
    );
  }

  ngOnInit(): void {
    /** spinner starts on init */
    // this.spinner.show();
  }

  ngAfterViewInit(): void {
    if (this.wordCloudComponent) {
      const wordCloudSubscription = this.wordCloudComponent.events.subscribe(
        (event: WordCloudItem) => {
          // console.log(event);
          const hashCode = this.createHtmlElementId(event.text);
          // const hashCode = Objects.hashString(event.text);
          this.scrollService.scrollTo(hashCode);
        }
      );
      this.subscriptions.add(wordCloudSubscription);
    }
    // this.restoreLastPosition();

    // TODO: Test
    // this.spinner.hide();

    // this.postStore.dispatch(deletePendingPostsRequest());

    // // TODO: Test
    // this.postService
    //   .listLocalPendingPosts()
    //   .pipe(toArray())
    //   .subscribe((posts) => {
    //     const postIds = posts.map((post) => post.postId);
    //     console.error('pending-posts: ', postIds);
    //   });
  }

  /**
   * To address words with spaces in the middle.
   * Use a hash value for ids for scroll positions
   * @param text do something?
   */
  createHtmlElementId(text: string): string {
    return Strings.hashCode(text).toString();
    // return Objects.hashString(text);
  }

  onScrollToTop(): void {
    // console.error('>>>>>>>>>> onScrollToTop() worked!');
    this.scrollService.scrollTo('home-top');
  }

  protected name(): string {
    return 'home';
  }
}
