import { Injectable } from '@angular/core';
import { FirestoreService } from './firestore.service';
import firebase from 'firebase/compat/app';
import { DateTimeService } from './date-time.service';
import { TrendingSearch, TopSearch } from '../models/trends';
import {
  throwIfEmpty,
  catchError,
  concatMap,
  toArray,
  mergeMap,
} from 'rxjs/operators';
import { Observable, of, EMPTY, from } from 'rxjs';
import { WordWeight } from '../models/words';
import { QuerySuggestions } from '../models/suggestions';
import { AngularFirestore } from '@angular/fire/compat/firestore';

/**
 * @author: john@gomedialy.com
 * @version: 0.16, 09/05/2020
 * @version: 0.18, 11/20/2020
 */
@Injectable({
  providedIn: 'root',
})
export class TrendingSearchesService {
  /* fields */
  private angularFirestore: AngularFirestore;
  private firestore: firebase.firestore.Firestore;

  constructor(
    private firestoreService: FirestoreService,
    private dateTimeService: DateTimeService
  ) {
    this.angularFirestore = this.firestoreService.getAngularFirestore();
    this.firestore = this.angularFirestore.firestore;
  }

  listenToTrendingSearchesByDuration(
    start: firebase.firestore.Timestamp,
    end: firebase.firestore.Timestamp
  ): Observable<TrendingSearch[]> {
    return this.angularFirestore
      .collection<TrendingSearch>('trending_searches', (ref) => {
        return ref
          .where('date', '>=', start)
          .where('date', '<=', end)
          .orderBy('date', 'desc')
          .limit(100);
      })
      .valueChanges();
    // .pipe(
    //   mergeMap(trendingSearches => from(trendingSearches))
    // );

    // const querySnapshot: Observable<QuerySnapshot> = this.firestoreService.query(
    //   'trending_searches',
    //   'date',
    //   '>=',
    //   from,
    //   'date',
    //   '<=',
    //   to
    // );
    // return querySnapshot;
  }

  // TODO: something wrong with this!!!
  listQuerySuggestionsByQueries(
    queries: string[]
  ): Observable<QuerySuggestions[]> {
    return from(queries).pipe(
      concatMap((query) => {
        return this.findQuerySuggestionsByQuery(query).pipe(
          concatMap((querySuggestions) => {
            if (querySuggestions) {
              return of(querySuggestions);
            }
            // return of(EMPTY_QUERY_SUGGESTIONS);
            return EMPTY;
          })
        );
      }),
      toArray()
    );
  }

  findQuerySuggestionsByQuery(
    query: string
  ): Observable<QuerySuggestions | null> {
    return from(
      this.angularFirestore
        .collection<QuerySuggestions>('query_suggestions', (ref) => {
          return ref.where('query', '==', query);
        })
        .get()
    ).pipe(
      mergeMap((querySnapshot) => {
        return this.firestoreService.findOnlyOneFromQuerySnapshot<QuerySuggestions>(
          querySnapshot
        );
      })
    );

    // return this.firestoreService
    //   .queryList<QuerySuggestions>('query_suggestions', 'query', '==', query)
    //   .pipe(
    //     throwIfEmpty(),
    //     catchError((err: Error) => {
    //       if (err.name === 'EmptyError') {
    //         return of(null);
    //       }
    //       throw err;
    //     })
    //   );
  }

  findLatestTrendingSearchByQuery(
    query: string
  ): Observable<TrendingSearch | null> {
    // return this.angularFirestore.collection<Post>(path, (ref) => {
    //   switch (pageRequest.direction) {
    //     case 'previous':
    //       if (partition) {
    //         return this.previousControversialsQuery(
    //           ref,
    //           videoId,
    //           pageSize,
    //           isSubtitles,
    //           partition
    //         );
    //       } else {
    //         throw new Error(`previous requires partition.`);
    //       }
    //       break;
    //     case 'next':
    //       return this.nextControversialsQuery(
    //         ref,
    //         videoId,
    //         pageSize,
    //         isSubtitles,
    //         partition
    //       );
    //       break;
    //   }
    // });

    return this.angularFirestore
      .collection<TrendingSearch>('trending_searches', (ref) => {
        return ref
          .where('title.query', '==', query)
          .orderBy('timestamp', 'desc')
          .limit(1);
      })
      .get()
      .pipe(
        mergeMap((querySnapshot) => {
          return this.firestoreService.findOnlyOneFromQuerySnapshot<TrendingSearch>(
            querySnapshot
          );
        })
        // throwIfEmpty(),
        // catchError((err: Error) => {
        //   if (err.name === 'EmptyError') {
        //     return of(null);
        //   }
        //   throw err;
        // })
      );

    // const querySnapshot: Observable<firebase.firestore.QuerySnapshot> = this.firestoreService.query(
    //   'trending_searches',
    //   'title.query',
    //   '==',
    //   query
    // );
    // return this.firestoreService
    //   .queryLatestItemsAsc<TrendingSearch>(querySnapshot, 'timestamp', 1)
    //   .pipe(
    //     throwIfEmpty(),
    //     catchError((err: Error) => {
    //       if (err.name === 'EmptyError') {
    //         return of(null);
    //       }
    //       throw err;
    //     })
    //   );
  }

  // findLatestTrendingSearchByQuery2(
  //   query: string
  // ): Observable<TrendingSearch | null> {
  //   const querySnapshot: Observable<firebase.firestore.QuerySnapshot> = this.firestoreService.query(
  //     'trending_searches',
  //     'title.query',
  //     '==',
  //     query
  //   );
  //   return this.firestoreService
  //     .queryLatestItemsAsc<TrendingSearch>(querySnapshot, 'timestamp', 1)
  //     .pipe(
  //       throwIfEmpty(),
  //       catchError((err: Error) => {
  //         if (err.name === 'EmptyError') {
  //           return of(null);
  //         }
  //         throw err;
  //       })
  //     );
  // }

  listTrendingSearchesByDuration(
    from: firebase.firestore.Timestamp,
    to: firebase.firestore.Timestamp
  ): Observable<TrendingSearch> {
    const querySnapshot: Observable<firebase.firestore.QuerySnapshot> = this.listRawTrendingSearchesByDuration(
      from,
      to
    );
    return this.firestoreService
      .queryLatestItemsAsc<TrendingSearch>(querySnapshot, 'date', 100)
      .pipe(
        concatMap((trendingSearch) => {
          return of(trendingSearch);
        })
      );
  }

  // getRelativeWordWeightsByDuration(
  //   from: Timestamp,
  //   to: Timestamp
  // ): Observable<Array<WordWeight>> {
  //   return this.listRawWordWeightByDuration(from, to).pipe(
  //     toArray(),
  //     mergeMap((rawWordWeights) => {
  //       const length = rawWordWeights.length;
  //       const rawWeightTotal = rawWordWeights.reduce((acc, value) => {
  //         return acc + value.weight;
  //       }, 0);

  //       const wordWeights = rawWordWeights.map((wordWeight) => {
  //         wordWeight.weight = wordWeight.weight / rawWeightTotal;
  //         return wordWeight;
  //       });
  //       this.sortDscByWeight(wordWeights);
  //       const topWeight: number = wordWeights[0].weight;

  //       const relativeWordWeights = wordWeights.map((wordWeight) => {
  //         wordWeight.weight = this.toRelativeWeight(
  //           topWeight,
  //           wordWeight.weight
  //         );
  //         return wordWeight;
  //       });
  //       return of(relativeWordWeights);
  //     })
  //   );
  // }

  private listRawTrendingSearchesByDuration(
    start: firebase.firestore.Timestamp,
    end: firebase.firestore.Timestamp
  ): Observable<firebase.firestore.QuerySnapshot> {
    return from(
      this.firestore
        .collection('trending_searches')
        .where('date', '>=', start)
        .where('date', '<=', end)
        .get()
    );

    // return this.angularFirestore.collection('trending_searches', (ref) => {
    //   return ref.where('date', '>=', from).where('date', '<=', to);
    // });

    // const querySnapshot: Observable<firebase.firestore.QuerySnapshot> = this.firestoreService.query(
    //   'trending_searches',
    //   'date',
    //   '>=',
    //   from,
    //   'date',
    //   '<=',
    //   to
    // );
    // return querySnapshot;
  }
}
