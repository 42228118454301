<button
  mat-button
  appDebounceClick
  (debounceClick)="onWordClick(item)"
  style="border-radius: 0px; padding: 4px"
  [@bounceIn]="bounceIn"
  [@flash]="state"
>
  <div class="debug-redx" fxLayout="column" fxLayoutAlign="space-around center">
    <div class="{{ item.style }}" [ngStyle]="style">
      {{ text }}
    </div>
    <div class="caption" style="font-weight: bolder">
      {{ formattedTraffic
      }}<span *ngIf="queryCount">{{ queryCount | shortNumber }}</span>
    </div>
  </div>
</button>
<!-- [@bounceIn]="bounceIn" -->
