import { Injectable } from '@angular/core';
import { YoutubeData, Item } from '../models/youtube-data';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { from, of, Observable, EMPTY } from 'rxjs';
import { FirestoreService, TxContext } from './firestore.service';
import { Post } from '../models/posts';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { AuthService } from './auth.service';
import { controversials } from '../models/posts';
import { User, UserPostPreference } from '../models/user';
import { delay } from 'rxjs/operators';
import { BaseService, Context } from '../base.service';
import { Store } from '@ngrx/store';
import { mainFeatureKey, MainState } from '../reducers/main.reducer';
import { CounterService } from './counter.service';
import { NGXLogger } from 'ngx-logger';
import { Firestores } from '../utils/firestores';
import { authFeatureKey, AuthState } from '../reducers/auth.reducer';
import { authStateChanged } from '../actions/auth.actions';

/**
 * @author: john@gomedialy.com
 * @version: 0.12, 09/26/2020
 * @version: 0.13, 10/28/2020
 * @version: 0.14, 01/24/2021
 */
@Injectable({
  providedIn: 'root',
})
export class PostPreferencesService extends BaseService {
  /* fields */
  private angularFirestore: AngularFirestore;
  private firestore: firebase.firestore.Firestore;
  private userPreferencesMap = new Map<string, string>();
  private currentUser: User | null = null;
  private currentVideoId: string | null = null;

  constructor(
    mainStore: Store<{
      [mainFeatureKey]: MainState;
    }>,
    private authStore: Store<{
      [authFeatureKey]: AuthState;
    }>,
    protected logger: NGXLogger,
    private authService: AuthService,
    private firestoreService: FirestoreService,
    private counterService: CounterService
  ) {
    super(mainStore, logger);
    this.angularFirestore = this.firestoreService.getAngularFirestore();
    this.firestore = this.firestoreService.firestore;
  }

  onInit(context: Context): void {}

  onDestroy(context: Context): void {
    this.currentVideoId = null;
    this.currentUser = null;
  }

  /**
   * Build userPreferencesMap, which determines the color of likes and dislikes.
   */
  setUserPostPreferencesListener(videoId: string, user: User): void {
    if (
      videoId === this.currentVideoId &&
      user.userId === this.currentUser?.userId
    ) {
      this.logger.debug(
        'setUserPostPreferencesListener() duplicate & ignored:',
        videoId,
        user.userId
      );
      return;
    }

    this.currentVideoId = videoId;
    this.currentUser = user;

    const postPreferencesSubscription = this.listenToUserPostPreferences(
      user.userId,
      videoId
    )
      .pipe(
        switchMap((userPostPreferences) => {
          // Convert an array into a map for fast search.
          this.userPreferencesMap = new Map(
            userPostPreferences.map((i) => {
              switch (i.preference) {
                case 'like':
                case 'dislike':
                  return [i.postId, i.preference];
                default:
                  return [i.postId, 'none'];
              }
            })
          );

          return EMPTY;
        })
      )
      .subscribe();
    this.subscriptions.push(postPreferencesSubscription);
  }

  updatePost(post: Post): Post {
    // data is read-only.
    const postId = post.postId;
    if (postId) {
      const preference = this.userPreferencesMap.get(postId);
      switch (preference) {
        case 'like':
          // make a copy
          return { ...post, liked: true, disliked: false };
        case 'dislike':
          // make a copy
          return { ...post, liked: false, disliked: true };
        case 'none':
          // make a copy
          return { ...post, liked: false, disliked: false };
      }
    }
    return post;
  }

  private updateControversials(postDocPath: string): void {
    of('')
      .pipe(
        delay(3000),
        mergeMap((v) => {
          return this.angularFirestore
            .doc(postDocPath)
            .get({ source: 'cache' })
            .pipe(
              mergeMap((documentSnapshot) => {
                if (documentSnapshot.exists) {
                  const post = documentSnapshot.data() as Post;
                  const value = controversials(post);
                  post.controversials = value;
                  // console.log('controversials: ', value);
                  return from(
                    this.angularFirestore
                      .doc(postDocPath)
                      .update({ controversials: value })
                  );
                }
                // console.warn('missed cache.');
                return EMPTY;
              })
            );
        })
      )
      .subscribe();
  }

  /**
   * @param videoId
   * @param postId
   * @param liked
   * @param path
   */
  preferPost(
    videoId: string,
    postId: string,
    liked: boolean,
    path: string
  ): Observable<void> {
    const user = this.authService.getUser();
    if (user) {
      const userPreferencesDocPath = `users/${user.userId}/user-post-preferences/${postId}`;

      return this.counterService.runUpdateTxCount<UserPostPreference>(
        userPreferencesDocPath,
        (txContext) => {
          const transaction = txContext.transaction;
          const userPostPreference = txContext.doc;

          const userPreferencesDocRef = this.firestore.doc(
            userPreferencesDocPath
          );
          if (userPostPreference) {
            switch (userPostPreference.preference) {
              case 'like':
                userPostPreference.preference = null;
                userPostPreference.updatedAt = firebase.firestore.Timestamp.now();

                transaction.update(userPreferencesDocRef, userPostPreference);
                return this.counterService
                  .updateCount(path, 'likes', -1)
                  .pipe(tap((v) => this.updateControversials(path)))
                  .toPromise();
              case 'dislike':
                userPostPreference.preference = null;
                userPostPreference.updatedAt = firebase.firestore.Timestamp.now();
                transaction.update(userPreferencesDocRef, userPostPreference);
                return this.counterService
                  .updateCount(path, 'dislikes', -1)
                  .pipe(tap((v) => this.updateControversials(path)))
                  .toPromise();
              case null:
                userPostPreference.preference = liked ? 'like' : 'dislike';
                userPostPreference.updatedAt = firebase.firestore.Timestamp.now();
                transaction.update(userPreferencesDocRef, userPostPreference);
                if (liked) {
                  return this.counterService
                    .updateCount(path, 'likes', +1)
                    .pipe(tap((v) => this.updateControversials(path)))
                    .toPromise();
                }
                return this.counterService
                  .updateCount(path, 'dislikes', +1)
                  .pipe(tap((v) => this.updateControversials(path)))
                  .toPromise();
            } // switch
          } else {
            // create new
            const timestamp = firebase.firestore.Timestamp.now();
            const userPostPreference: UserPostPreference = {
              userId: user.userId,
              videoId,
              postId,
              preference: liked ? 'like' : 'dislike',
              createdAt: timestamp,
              updatedAt: timestamp,
            };
            transaction.set(userPreferencesDocRef, userPostPreference, {
              merge: false,
            });
            if (liked) {
              return this.counterService
                .updateCount(path, 'likes', +1)
                .pipe(tap((v) => this.updateControversials(path)))
                .toPromise();
            }
            return this.counterService
              .updateCount(path, 'dislikes', +1)
              .pipe(tap((v) => this.updateControversials(path)))
              .toPromise();
          }
        }
      );
    }
    return EMPTY;
  }

  name(): string {
    return 'post-preferences';
  }

  private getUserPostPreferences(
    userId: string,
    videoId: string
  ): Observable<UserPostPreference[]> {
    const path = `users/${userId}/user-post-preferences`;
    return this.angularFirestore
      .collection<UserPostPreference>(path, (ref) => {
        return ref
          .where('userId', '==', userId)
          .where('videoId', '==', videoId)
          .limit(100);
      })
      .get()
      .pipe(
        mergeMap((querySnapshot) =>
          Firestores.querySnapshotAsListOf<UserPostPreference>(querySnapshot)
        )
      );
  }

  private listenToUserPostPreferences(
    userId: string,
    videoId: string
  ): Observable<UserPostPreference[]> {
    const path = `users/${userId}/user-post-preferences`;
    return this.angularFirestore
      .collection<UserPostPreference>(path, (ref) => {
        return ref
          .where('userId', '==', userId)
          .where('videoId', '==', videoId)
          .limit(100);
      })
      .valueChanges();
  }
}
