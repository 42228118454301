<div
  [@fadeIn]="fadeIn"
  id="{{ linkId }}"
  class="container debug-redx"
  fxLayout="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="0px"
  style="border-bottom: 1px solid #eeeeee"
>
  <div
    class="mat-elevation-z4 debug-redx"
    style="width: 96%; padding: 16px 0px"
  >
    <img
      class="image-crop-lazyLoad debug-bluex"
      src="{{ imageUrl }}"
      loading="lazy"
      appDebounceClick
      (debounceClick)="onCardImageClick()"
    />
    <!-- <button mat-flat-button class="debug-redx image-crop-lazyLoad"></button> -->
  </div>

  <div class="debug-bluex" style="padding: 8px 8px 0px">
    <div
      appDebounceClick
      (debounceClick)="onCardImageClick()"
      matRipple
      class="debug-redx"
      style="background-color: #e0e0e0; padding: 8px; width: 330px"
    >
      <div
        class="debug-bluex"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="8px"
      >
        <mat-icon>people</mat-icon>
        <span style="color: gray; font-weight: bolder" *ngIf="viewCount"
          >실시간 뷰: {{ viewCount | shortNumber }}</span
        >
      </div>
      <div class="mat-h4" style="margin: 0px; font-weight: bolder">
        {{ title.length > 25 ? (title | slice: 0:25) + " ..." : title }}
      </div>

      <div style="color: #424242; font-weight: bolder" class="mat-body-2">
        {{
          description.length > 100
            ? (description | slice: 0:100) + "..."
            : description
        }}
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <img src="{{ item.favicon }}" />
        <div style="color: #9e9e9e; font-weight: bolder">{{ item.domain }}</div>
      </div>
    </div>
  </div>

  <div fxFlex></div>
</div>
