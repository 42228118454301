import { Injectable } from '@angular/core';
import { YoutubeData, Item } from '../models/youtube-data';
import { concatMap, mergeMap, toArray } from 'rxjs/operators';
import { from, of, Observable } from 'rxjs';
// import { FirestoreService } from './firestore.service';
// import { DateTimeService } from './date-time.service';
import firebase from 'firebase/compat/app';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Firestores } from '../utils/firestores';

/**
 * @author: john@gomedialy.com
 * @version: 0.12, 09/02/2020
 * @version: 0.14, 10/30/2020
 * @version: 0.16, 01/04/2021
 */
@Injectable({
  providedIn: 'root',
})
export class YouTubeDataService {
  /* fields */
  // private angularFirestore: AngularFirestore;

  constructor(private angularFirestore: AngularFirestore) {}

  findItemByVideoId(videoId: string): Observable<Item | null> {
    return this.angularFirestore
      .collection<Item>('youtube_searches', (ref) => {
        return ref.where('id.videoId', '==', videoId).limit(1);
      })
      .get()
      .pipe(
        concatMap((querySnapshot) =>
          Firestores.querySnapshotAsOneAndOnlyOf<Item>(querySnapshot)
        )
      );
  }

  // in desc order
  listItemsByQuery(
    query: string,
    since: firebase.firestore.Timestamp,
    limit: number
  ): Observable<Item[]> {
    return this.angularFirestore
      .collection<Item>('youtube_searches', (ref) => {
        return ref
          .where('query', '==', query)
          .where('publishedAt', '>=', since)
          .orderBy('publishedAt', 'desc')
          .limit(limit);
      })
      .get()
      .pipe(
        concatMap((querySnapshot) =>
          // this.firestoreService.listFromQuerySnapshot<Item>(querySnapshot)
          Firestores.querySnapshotAsListOf<Item>(querySnapshot)
        )
        // toArray()
      );
  }

  // in desc order
  listItemsArrayByQueries(
    queries: string[],
    since: firebase.firestore.Timestamp,
    limit: number
  ): Observable<Array<Item[]>> {
    return from(queries).pipe(
      concatMap((query) => {
        return this.listItemsByQuery(query, since, limit);
      }),
      toArray()
    );
  }

  // in desc order
  listItemsByQuery2(
    query: string,
    since: firebase.firestore.Timestamp,
    limit: number
  ): Observable<Item[]> {
    return this.angularFirestore
      .collection<Item>('youtube_searches', (ref) => {
        return ref
          .where('query', '==', query)
          .where('publishedAt', '>=', since)
          .orderBy('publishedAt', 'desc')
          .limit(limit);
      })
      .get()
      .pipe(
        concatMap((querySnapshot) =>
          // this.firestoreService.listFromQuerySnapshot<Item>(querySnapshot)
          Firestores.querySnapshotAsListOf<Item>(querySnapshot)
        )
      );
  }

  // listItemsByQuery(
  //   query: string,
  //   since: Timestamp,
  //   limit: number
  // ): Observable<Item> {
  //   const querySnapshot: Observable<QuerySnapshot> = this.getQuerySnapshotByQuery(
  //     query,
  //     since
  //   );
  //   return this.firestoreService
  //     .queryLatestItemsAsc<Item>(querySnapshot, 'publishedAt', limit)
  //     .pipe(
  //       concatMap((item) => {
  //         // TODO:
  //         return of(item);
  //       })
  //     );
  // }

  // private getQuerySnapshotByQuery(
  //   query: string,
  //   since: Timestamp
  // ): Observable<QuerySnapshot> {
  //   const querySnapshot: Observable<QuerySnapshot> = this.firestoreService.query(
  //     'youtube_searches',
  //     'query',
  //     '==',
  //     query,
  //     'publishedAt',
  //     '>=',
  //     since
  //   );
  //   return querySnapshot;
  // }
}
