import {
  AngularFirestore,
  QueryDocumentSnapshot,
} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { map, mergeMap, filter } from 'rxjs/operators';
import { Post } from '../models/posts';

/**
 * @author: john@gomedialy.com
 * @version: 0.17, 09/23/2020
 * @version: 0.18, 10/28/2020
 */
export class PostQueries {
  public static createPreviousPageResponse = (
    videoId: string,
    start: QueryDocumentSnapshot<Post>,
    end: QueryDocumentSnapshot<Post>,
    posts: Post[]
  ) =>
    map(
      (
        querySnapshot: firebase.firestore.QuerySnapshot<
          firebase.firestore.DocumentData
        >
      ) => {
        if (querySnapshot.size == 0) {
          return {
            videoId,
            posts,
            start: start.id,
            end: end.id,
            hasPrevious: false,
            hasNext: true,
            isFirst: true,
            isLast: false,
          };
        }
        return {
          videoId,
          posts,
          start: start.id,
          end: end.id,
          hasPrevious: true,
          hasNext: true,
          isFirst: false,
          isLast: false,
        };
      }
    );

  public static createNextPageResponse = (
    videoId: string,
    start: QueryDocumentSnapshot<Post>,
    end: QueryDocumentSnapshot<Post>,
    posts: Post[],
    partition?: string
  ) =>
    map(
      (
        querySnapshot: firebase.firestore.QuerySnapshot<
          firebase.firestore.DocumentData
        >
      ) => {
        if (partition) {
          if (querySnapshot.size === 0) {
            return {
              videoId,
              posts,
              start: start.id,
              end: end.id,
              hasPrevious: true,
              hasNext: false,
              isFirst: false,
              isLast: true,
            };
          }
          return {
            videoId,
            posts,
            start: start.id,
            end: end.id,
            hasPrevious: true,
            hasNext: true,
            isFirst: false,
            isLast: false,
          };
        } else {
          /**
           * No partition
           * For the first call of next without a partition.
           */
          if (querySnapshot.size == 0) {
            return {
              videoId,
              posts,
              start: start.id,
              end: end.id,
              hasPrevious: false,
              hasNext: false,
              isFirst: true,
              isLast: true,
            };
          }
          return {
            videoId,
            posts,
            start: start.id,
            end: end.id,
            hasPrevious: false,
            hasNext: true,
            isFirst: true,
            isLast: false,
          };
        }
      }
    );
}
