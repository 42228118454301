import { BrowserModule } from '@angular/platform-browser';
import { Inject, NgModule, SecurityContext, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire/compat';
import {
  AngularFireFunctionsModule,
  ORIGIN,
  REGION,
} from '@angular/fire/compat/functions';
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/compat/analytics';
import { environment } from '../environments/environment';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './effects/auth.effects';
import { MainEffects } from './effects/main.effects';
import { PostEffects } from './effects/post.effects';
import { DataEffects } from './effects/data.effects';
import { RouterEffects } from './effects/router.effects';
import { HomeEffects } from './effects/home.effects';
import {
  authReducer,
  authFeatureKey,
  AuthState,
} from './reducers/auth.reducer';
import {
  mainReducer,
  mainFeatureKey,
  MainState,
} from './reducers/main.reducer';
import {
  postReducer,
  postFeatureKey,
  PostState,
} from './reducers/post.reducer';
import {
  dataReducer,
  dataFeatureKey,
  DataState,
} from './reducers/data.reducer';
import {
  stackReducer,
  stackFeatureKey,
  StackState,
} from './reducers/stack.reducer';
import {
  videoEditorReducer,
  videoEditorFeatureKey,
} from './reducers/video-editor.reducer';
import {
  imageEditorReducer,
  imageEditorFeatureKey,
} from './reducers/image-editor.reducer';
import { HomeComponent } from './home/home.component';
import { Home2Component } from './home2/home2.component';
import { AppMaterialModule } from './app-material.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { WordCloudComponent } from './word-cloud/word-cloud.component';
import { ContentCardGroupComponent } from './content-card-group/content-card-group.component';
import { ContentCardComponent } from './content-card/content-card.component';
import { WatchComponent } from './watch/watch.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { PostsBoardComponent } from './posts-board/posts-board.component';
import { MarkdownModule, MarkedOptions, MarkedRenderer } from 'ngx-markdown';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { PostComponent } from './post/post.component';
import { PostEditorComponent } from './post-editor/post-editor.component';
import { SubtitleComponent } from './subtitle/subtitle.component';
import { VideoInfoComponent } from './video-info/video-info.component';
import {
  videoReducer,
  videoFeatureKey,
  VideoState,
} from './reducers/video.reducer';
import { ButtonGroupComponent } from './button-group/button-group.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { PostsPaginatorIntl } from './posts-board/posts-paginator-i18n';
import { PostsPaginatorDirective } from './posts-board/posts-paginator';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxVisibilityModule } from 'ngx-visibility';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { VideoEditorComponent } from './video-editor/video-editor.component';
import { VideoControlComponent } from './video-control/video-control.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { ImageEditorComponent } from './image-editor/image-editor.component';
import {
  AngularFireStorageModule,
  AngularFireStorageReference,
  AngularFireUploadTask,
} from '@angular/fire/compat/storage';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { ShareModule } from 'ngx-sharebuttons';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import { VideoEditorState } from './reducers/video-editor.reducer';
import { ImageEditorState } from './reducers/image-editor.reducer';
import {
  AngularFirePerformanceModule,
  PerformanceMonitoringService,
} from '@angular/fire/compat/performance';
import * as fromRouter from '@ngrx/router-store';
import { RouterStateUrl } from './custom-route-serializer';
import { PostDeleteComponent } from './post-delete/post-delete.component';
import { FooterComponent } from './footer/footer.component';
import { PresenceComponent } from './presence/presence.component';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { QueryButtonComponent } from './query-button/query-button.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PostPreviewComponent } from './post-preview/post-preview.component';
import { DeviceService } from './services/device.service';
import { BrowseComponent } from './browse/browse.component';
import { SearchCardComponent } from './search-card/search-card.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { GlobalErrorHandler } from './global-error-handler';
import { VideoButtonComponent } from './video-button/video-button.component';
import { PipPlayerComponent } from './pip-player/pip-player.component';
import { PipeModule } from './pipes/pipe.module';
import { Objects } from './utils/objects';
import { MessageComponent } from './message/message.component';
import {
  homeFeatureKey,
  homeReducer,
  HomeState,
} from './reducers/home.reducer';
import { VideoBarComponent } from './video-bar/video-bar.component';

/**
 * @author: john@gomedialy.com
 * @version: 0.62, 02/01/2021
 */
export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();
  renderer.image = (url: string) => {
    switch (window.name) {
      case 'mobile':
        // return `<img class="markdown-image" src="../assets/images/icons8-image-64.png" alt="" onclick="onImageClick('${url}')">`;
        return `<img class="markdown-image" src="../assets/images/twotone_photo_camera_black_48dpx.png" alt="" onclick="onImageClick('${url}')">`;
      default:
        return `<img class="markdown-image" src=${url} alt="" onclick="onImageClick('${url}')">`;
    }
  };

  return {
    renderer,
    gfm: true,
    breaks: false,
    pedantic: false,
    smartLists: true,
    smartypants: false,
  };
}

export interface AppState {
  home: HomeState;
  auth: AuthState;
  main: MainState;
  post: PostState;
  data: DataState;
  video: VideoState;
  stack: StackState;
  videoEditor: VideoEditorState;
  imageEditor: ImageEditorState;
  router: fromRouter.RouterReducerState<RouterStateUrl>;
}

export const appReducers: ActionReducerMap<AppState> = {
  [homeFeatureKey]: homeReducer,
  [authFeatureKey]: authReducer,
  [mainFeatureKey]: mainReducer,
  [postFeatureKey]: postReducer,
  [videoFeatureKey]: videoReducer,
  [dataFeatureKey]: dataReducer,
  [stackFeatureKey]: stackReducer,
  [videoEditorFeatureKey]: videoEditorReducer,
  [imageEditorFeatureKey]: imageEditorReducer,
  router: fromRouter.routerReducer,
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    Home2Component,
    WordCloudComponent,
    ContentCardGroupComponent,
    ContentCardComponent,
    WatchComponent,
    VideoPlayerComponent,
    PostsBoardComponent,
    PostComponent,
    PostEditorComponent,
    SubtitleComponent,
    VideoInfoComponent,
    ButtonGroupComponent,
    PaginatorComponent,
    PostsPaginatorDirective,
    DebounceClickDirective,
    VideoEditorComponent,
    VideoControlComponent,
    ImageEditorComponent,
    PostDeleteComponent,
    FooterComponent,
    PresenceComponent,
    QueryButtonComponent,
    PostPreviewComponent,
    BrowseComponent,
    SearchCardComponent,
    VideoButtonComponent,
    PipPlayerComponent,
    MessageComponent,
    VideoBarComponent,
    // DateAgoPipe,
  ],
  imports: [
    LoggerModule.forRoot(environment.logging),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    // ScrollingModule,
    // ExperimentalScrollingModule,
    // AngularPageVisibilityModule,
    InfiniteScrollModule,
    // LazyLoadImageModule,
    HttpClientModule,
    AppRoutingModule,
    AppMaterialModule,
    AngularFireModule.initializeApp(
      Objects.fromBase64Json(environment.firebase)
    ),
    // Just like that, you're offline enabled!
    AngularFirestoreModule.enablePersistence(),
    AngularFireStorageModule,
    AngularFireAnalyticsModule,
    // AngularFireFunctionsModule,
    StoreModule.forRoot(appReducers),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    EffectsModule.forRoot([
      AuthEffects,
      MainEffects,
      PostEffects,
      DataEffects,
      HomeEffects,
      RouterEffects,
    ]),
    MarkdownModule.forRoot({
      // turn off the sanitize for now.
      // Later, we need to turn it on.
      sanitize: SecurityContext.NONE,
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptionsFactory,
      },
    }),
    NgxVisibilityModule,
    PickerModule,
    GalleryModule,
    LightboxModule,
    ShareModule,
    NgxPageScrollCoreModule,
    AngularFirePerformanceModule,
    // TrendModule,
    FontAwesomeModule,
    NgxSpinnerModule,
    PipeModule.forRoot(),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: GlobalErrorHandler,
    //   multi: true,
    // },

    ScreenTrackingService,
    UserTrackingService,
    PerformanceMonitoringService,
    { provide: MatPaginatorIntl, useClass: PostsPaginatorIntl },
    { provide: REGION, useValue: 'asia-northeast3' },
    // {
    //   provide: ORIGIN,
    //   useValue: 'https://asia-northeast1-gomedialy-dev.cloudfunctions.net',
    // },
    // { provide: ORIGIN, useValue: 'http://localhost:5000' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private deviceService: DeviceService,
    @Inject(DOCUMENT) private document: Document
  ) {
    const isDesktop = this.deviceService.isDesktop();
    const window = this.document.defaultView;
    if (window) {
      if (isDesktop) {
        window.name = '';
      } else {
        window.name = 'mobile';
      }
    }
  }
}
