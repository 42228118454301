import { Component, Inject, OnInit } from '@angular/core';
import { faAt } from '@fortawesome/free-solid-svg-icons';
// import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import {
  faFacebook,
  faFacebookSquare,
  faTwitterSquare,
  faFacebookF,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { BUILD } from 'build';
import { DeviceDetectorService } from 'ngx-device-detector';

/**
 * @author: john@plutusds.com
 * @version: 0.13, 11/18/2020
 * @version: 0.18, 01/31/2021
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  /* fields */
  // tslint:disable-next-line: no-any
  private window: any;
  // faFacebook = faFacebookSquare;
  // faTwitter = faTwitterSquare;
  faFacebook = faFacebook;
  faTwitter = faTwitter;
  faAt = faAt;
  version = BUILD;
  isMobile = false;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView;
  }

  ngOnInit(): void {}

  onFacebookClick(): void {
    /**
     * Must start a new window.
     */
    this.window.open(`https://www.facebook.com/gomedialy`, '_blank');
  }

  onTwitterClick(): void {
    /**
     * Must start a new window.
     */
    this.window.open(`https://twitter.com/gomedialy`, '_blank');
  }
}
