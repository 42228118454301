import firebase from 'firebase/compat/app';
import { Observable, of, from, throwError } from 'rxjs';
import { mergeMap, map, toArray } from 'rxjs/operators';

/**
 * @author: john@gomedialy.com
 * @version: 0.11, 12/19/2020
 */
export class Firestores {
  private constructor() {}

  public static querySnapshotAsOneAndOnlyOf<T>(
    querySnapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  ): Observable<T | null> {
    return of(querySnapshot).pipe(
      mergeMap((querySnapshot0) => {
        /**
         * without this, it seems to just return EMPTY, which is NEVER and do not continue the flow.
         */
        switch (querySnapshot0.size) {
          case 0:
            // not found
            return of(null);
          case 1:
            // one and only
            return of(querySnapshot0.docs[0].data() as T);
        }
        return throwError(new Error(`Multiple items: ${querySnapshot0.size}`));
      })
    );
  }

  public static querySnapshotAsListOf<T>(
    querySnapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  ): Observable<T[]> {
    return of(querySnapshot).pipe(
      mergeMap((querySnapshot0) => {
        /**
         * without this, it seems to just return EMPTY, which is NEVER and do not continue the flow.
         */
        if (querySnapshot0.empty) {
          return of([]);
        }
        return from(querySnapshot0.docs).pipe(
          map((document) => {
            return document.data() as T;
          }),
          toArray()
        );
      })
    );
  }

  /**
   * To object
   * @param value
   */
  public static documentSnapShotAs<T>(
    value: firebase.firestore.DocumentSnapshot
  ): Observable<T | null> {
    // admin.firestore.DocumentSnapshot
    return of(value).pipe(
      mergeMap((documentSnapshot) => {
        if (documentSnapshot.exists) {
          return of(documentSnapshot.data() as T);
        }
        return of(null);
      })
    );
  }
}
