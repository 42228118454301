<div class="container" id="{{ postId }}">
  <!-- image must be a container to keep material component features -->
  <img
    class="container image-rounded"
    src="{{ videoImageUrl }}"
    appDebounceClick
    [debounceTime]="50"
    (debounceClick)="onClick()"
  />

  <mat-spinner
    class="spinner"
    *ngIf="isPlaying"
    [diameter]="40"
    [mode]="mode"
    [color]="color"
    [value]="value"
    appDebounceClick
    [debounceTime]="50"
    (debounceClick)="onClick()"
  >
  </mat-spinner>

  <mat-icon
    class="{{ iconsClass }}"
    *ngIf="!isPlaying"
    appDebounceClick
    [debounceTime]="50"
    (debounceClick)="onClick()"
    matTooltip="{{ tooltip }}"
    >play_arrow</mat-icon
  >
  <mat-icon
    class="{{ iconsClass }}"
    *ngIf="isPlaying"
    appDebounceClick
    [debounceTime]="50"
    (debounceClick)="onClick()"
    matTooltip="{{ tooltip }}"
    >pause</mat-icon
  >
</div>
