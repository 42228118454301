import { Injectable, Output, EventEmitter } from '@angular/core';
// import { PostNode } from '../models/posts';
import { JSend } from '../models/jsend';
import { Item } from '../models/youtube-data';
// import { AngularFireDatabase } from '@angular/fire/compat/database';
import { from } from 'rxjs';

/**
 * @author: john@gomedialy.com
 * @version: 0.11, 09/12/2020
 * @version: 0.12, 12/15/2020
 */
export interface ContentCardEvent {
  action: 'play' | 'like' | 'share';
  source: Item;
  tags: string[];
}

@Injectable({
  providedIn: 'root',
})
export class ContentCardEventService {
  /* fields */
  events = new EventEmitter<ContentCardEvent>();

  constructor() {
    // console.error('### WTF: db: ', this.db);
    // from(this.db.object('views/test').update({ count: 1 })).subscribe(
    //   (v) => console.log('result: ', v),
    //   (error) => console.error(error)
    // );
  }

  onCardImageClick(tags: string[], item: Item): void {
    this.events.emit({ action: 'play', source: item, tags });
  }

  onLikeClick(item: Item): void {
    this.events.emit({ action: 'like', source: item, tags: [] });
  }

  onShareClick(item: Item): void {
    this.events.emit({ action: 'share', source: item, tags: [] });
  }
}
