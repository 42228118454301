import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { EMPTY, of, from, BehaviorSubject, Observable } from 'rxjs';
import {
  map,
  mergeMap,
  concatMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import {
  controversialPostPageRequest,
  controversialPostPageRequestProcessing,
  minePostPageRequest,
  minePostPageRequestProcessing,
  naturalPostPageRequest,
  naturalPostPageRequestProcessing,
  newPostPageRequest,
  newPostPageRequestProcessing,
  postPageRequest,
  postPageRequestProcessing,
  postPageResponse,
  risingPostPageRequest,
  risingPostPageRequestProcessing,
  topPostPageRequest,
  topPostPageRequestProcessing,
} from '../actions/data.actions';
import { PostDataService } from '../services/post-data.service';
import { PostSortType, PageRequest } from '../services/post.service';
import { ScrollService } from '../services/scroll.service';

/**
 * @author: john@gomedialy.com
 * @version: 0.16, 09/23/2020
 * @version: 0.17, 10/28/2020
 * @version: 0.19, 01/24/2021
 */
@Injectable()
export class DataEffects {
  /* fields */

  constructor(
    private actions$: Actions,
    private postDataService: PostDataService,
    private scrollService: ScrollService,
    private router: Router,
    private authService: AuthService
  ) {}

  topPostPageRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(topPostPageRequest),
      switchMap((state) => {
        const pageRequest: PageRequest = {
          ...state,
        };
        switch (state.category) {
          case 'all':
            this.postDataService.load(PostSortType.ALL_TOP, pageRequest);
            break;
          case 'subtitle':
            this.postDataService.load(PostSortType.SUBTITLES_TOP, pageRequest);
            break;
        }
        return of(topPostPageRequestProcessing());
      })
    );
  });

  controversialPostPageRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(controversialPostPageRequest),
      switchMap((state) => {
        const pageRequest: PageRequest = {
          ...state,
        };
        switch (state.category) {
          case 'all':
            this.postDataService.load(
              PostSortType.ALL_CONTROVERSIAL,
              pageRequest
            );
            break;
          case 'subtitle':
            this.postDataService.load(
              PostSortType.SUBTITLES_CONTROVERSIAL,
              pageRequest
            );
            break;
        }
        return of(controversialPostPageRequestProcessing());
      })
    );
  });

  risingPostPageRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(risingPostPageRequest),
      switchMap((state) => {
        const pageRequest: PageRequest = {
          ...state,
        };
        switch (state.category) {
          case 'all':
            this.postDataService.load(PostSortType.ALL_RISING, pageRequest);
            break;
          case 'subtitle':
            this.postDataService.load(
              PostSortType.SUBTITLES_RISING,
              pageRequest
            );
            break;
        }
        return of(risingPostPageRequestProcessing());
      })
    );
  });

  newPostPageRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(newPostPageRequest),
      switchMap((state) => {
        const pageRequest: PageRequest = {
          ...state,
        };
        switch (state.category) {
          case 'all':
            this.postDataService.load(PostSortType.ALL_NEW, pageRequest);
            break;
          case 'subtitle':
            this.postDataService.load(PostSortType.SUBTITLES_NEW, pageRequest);
            break;
        }
        return of(newPostPageRequestProcessing());
      })
    );
  });

  naturalPostPageRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(naturalPostPageRequest),
      switchMap((state) => {
        const pageRequest: PageRequest = {
          ...state,
        };
        switch (state.category) {
          case 'all':
            this.postDataService.load(PostSortType.ALL_NATURAL, pageRequest);
            break;
          case 'subtitle':
            this.postDataService.load(
              PostSortType.SUBTITLES_NATURAL,
              pageRequest
            );
            break;
        }
        return of(naturalPostPageRequestProcessing());
      })
    );
  });

  /**
   * TODO:
   */
  minePostPageRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(minePostPageRequest),
      switchMap((state) => {
        const pageRequest: PageRequest = {
          ...state,
        };
        switch (state.category) {
          case 'all':
            this.postDataService.load(PostSortType.ALL_MINE, pageRequest);
            break;
          case 'subtitle':
            this.postDataService.load(PostSortType.SUBTITLES_MINE, pageRequest);
            break;
        }
        return of(minePostPageRequestProcessing());
      })
    );
  });

  /**
   * TODO: Do I need this?
   */
  postPageRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(postPageRequest),
      switchMap((state) => {
        const pageRequest: PageRequest = {
          ...state,
        };
        // console.error('###################### postPageRequest...');
        // this.postDataService.load(PostSortType.ALL_NATURAL, pageRequest);
        return of(postPageRequestProcessing());
        // return of(postPageResponse())
      })
    );
  });
}
