<div
  class="container"
  fxLayout="column"
  fxLayoutGap="0px"
  style="padding-top: 8px"
>
  <div style="padding-left: 8px">
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-icon style="font-size: 32px; font-weight: bolder"
        >trending_up</mat-icon
      >
      <div
        class="mat-display-1"
        style="margin: 0px 0px 0px 16px; font-weight: bolder; min-width: 200px"
      >
        {{ traffic
        }}<span *ngIf="queryCount">{{ queryCount | shortNumber }}</span>
      </div>
      <div fxFlex="2"></div>
      <!-- <ngx-trend
        class="debug-bluex"
        [smooth]="true"
        [radius]="20"
        [strokeWidth]="2"
        [width]="64"
        [height]="32"
        [autoDraw]="true"
        [autoDrawDuration]="1000"
        [padding]="4"
        autoDrawEasing="ease-in"
        [data]="[0, 10, 5, 22, 3.6, 11]"
      ></ngx-trend> -->
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-icon color="accent">star_rate</mat-icon>
      <div class="mat-h2" style="margin: 0px 0px 0px 8px; font-weight: bolder">
        {{ title }}
      </div>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <!-- <ng-container *ngFor="let item of subtitle">
      <button
        mat-button
        appDebounceClick
        (debounceClick)="onSubtitleClick(item)"
        color="accent"
      >
        <span style="white-space: normal; padding: 0px"> #{{ item }} </span>
      </button>
    </ng-container> -->

    <mat-chip-list
      class="debug-redx"
      fxLayout="row"
      fxLayoutAlign="space-around center"
      style="padding: 8px"
    >
      <mat-chip
        *ngFor="let item of subqueries"
        id="{{ item.query }}"
        appDebounceClick
        (debounceClick)="onSubqueryClick([item.query, $event])"
        style="font-weight: bolder"
        >{{ item.query }}
        <span
          *ngIf="item.count > 0"
          id="{{ getScrollId(item.query) }}"
          style="
            color: #9e9e9e;
            padding-left: 4px;
            font-size: smaller;
            font-weight: bolder;
          "
          >+{{ item.count | shortNumber }}</span
        ></mat-chip
      >
    </mat-chip-list>
  </div>

  <div
    class="debug-bluex"
    fxLayout="row wrap"
    fxLayoutAlign="start center"
    fxLayoutGap="0px"
    fxLayout.gt-md="row wrap"
    fxLayoutAlign.gt-md="start center"
    fxLayoutGap.gt-md="0px"
    fxLayout.gt-xs="row wrap"
    fxLayoutAlign.gt-xs="space-around center"
    fxLayoutGap.gt-xs="0px"
    fxLayout.xs="column"
    fxLayoutAlign.xs="space-around center"
  >
    <ng-container *ngFor="let item of input.items">
      <app-content-card
        class="debug-redx"
        [inputData]="item"
        [tags]="input.suggestions"
      ></app-content-card>
    </ng-container>
  </div>
</div>

<!-- *ngIf="item.count > 0" -->
