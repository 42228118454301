import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  Inject,
  NgZone,
} from '@angular/core';
import { VideoPlayerService, VideoSnapshot } from './video-player.service';
import { Item } from '../models/youtube-data';
import { EMPTY, timer, Subscription, of } from 'rxjs';
import { YouTubePlayer } from '@angular/youtube-player';
import { mergeMap, tap } from 'rxjs/operators';
import { BaseComponent } from '../base.component';
import { Store } from '@ngrx/store';
import { videoPlaying } from '../actions/video.actions';
import { videoFeatureKey, VideoState } from '../reducers/video.reducer';
import { delay } from 'rxjs/operators';
import { stackFeatureKey, StackState } from '../reducers/stack.reducer';
import { Video } from '../models/posts';
import {
  videoEditorFeatureKey,
  VideoEditorState,
} from '../reducers/video-editor.reducer';
import { ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DeviceService } from '../services/device.service';
import { NGXLogger } from 'ngx-logger';
import { pushVideo } from '../actions/stack.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';

/**
 * Ex) https://www.youtube.com/watch?v=uk1lzkH-e4U
 * @author: john@gomedialy.com
 * @version: 0.44, 01/25/2021
 */

/**
 * According to Google documents
 */
let apiLoaded = false;

/**
 * There are two video-players: VideoPlayer and PipVideoPlayer.
 * Each of them has a different size and can not play at the same time.
 */
@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {
  /* fields */

  @Input()
  id: 'video-player' | 'pip-player' = 'video-player';

  /**
   * item is only for a pip-player
   */
  @Input()
  item!: Item;

  // tslint:disable-next-line: no-input-rename
  @Input('startSeconds')
  startSeconds!: number;

  // tslint:disable-next-line: no-input-rename
  @Input('endSeconds')
  endSeconds!: number;

  // tslint:disable-next-line: no-input-rename
  @Input('width')
  playerWidth!: number;

  // tslint:disable-next-line: no-input-rename
  @Input('playerVars')
  playerVars!: any;

  // tslint:disable-next-line: no-input-rename
  @Input('height')
  playerHeight!: number;

  @ViewChild('videoPlayer')
  videoPlayer!: YouTubePlayer;

  @Input()
  videoId!: string;

  subtitle!: string;
  timestamp = 0;
  duration = 0;
  videoType: 'video' | 'subvideo' | 'subtitle' = 'video';
  postId: string | null = null;

  playTime = 0;

  /**
   * Either video-player or video-editor
   */
  // styleClass: 'video-player' | 'video-editor' | 'mobile-player' =
  //   'video-player';
  styleClass: 'video-player' | 'video-editor' | 'mobile-player' =
    'mobile-player';

  // playerVars = {
  //   autoplay: 0,
  //   controls: 1,
  //   modestbranding: 1,
  //   cc_load_policy: 1,
  //   playsinline: 1,
  //   showinfo: 0,
  // };

  videoPlayerVars = {
    autoplay: 0,
    controls: 1,
    modestbranding: 0,
    cc_load_policy: 1,
    playsinline: 0, // 0: off
    showinfo: 0,
    rel: 0,
    // origin: window.location.href,
    // origin: 'http://localhost:4200',
  };

  /**
   * From video-player.service
   */
  isPipVisible = true;

  // tslint:disable-next-line: no-any
  private window: any;
  // private currentStackIndex = -1;
  private isDesktop = false;

  constructor(
    private logger: NGXLogger,
    public videoStore: Store<{
      [videoFeatureKey]: VideoState;
    }>,
    public videoEditorStore: Store<{
      [videoEditorFeatureKey]: VideoEditorState;
    }>,
    public stackStore: Store<{
      [stackFeatureKey]: StackState;
    }>,
    public videoPlayerService: VideoPlayerService,
    public elementRef: ElementRef,
    public deviceService: DeviceService,
    private ngZone: NgZone,
    @Inject(DOCUMENT) public document: Document,
    private snackbar: MatSnackBar
  ) {
    super();
    this.window = this.document.defaultView;
    this.isDesktop = this.deviceService.isDesktop();

    if (!this.isDesktop) {
      // if (!this.deviceService.isDesktop()) {
      this.styleClass = 'mobile-player';
    }

    /**
     * ! This is called many times ?
     * Get the current snapshot.
     * For PipPlayer to make a transition from VideoPlayer to PipPlayer
     */
    const videoSnapshot = this.videoPlayerService.getVideoSnapshot();
    if (videoSnapshot) {
      this.videoId = videoSnapshot.videoId;
      this.videoType = videoSnapshot.videoType;
      this.startSeconds = videoSnapshot.timestamp;
      this.endSeconds = videoSnapshot.endSeconds;
      this.duration = videoSnapshot.duration;
      this.postId = videoSnapshot.postId;
    }

    /**
     * ! Not sure of where to use this?
     * Javascript connector
     */
    // tslint:disable-next-line: no-string-literal
    this.window['videoPlayerComponentReference'] = {
      component: this,
      zone: this.ngZone,
      loadAngularFunction: () => this.onYouTubeIframeAPIReady(),
    };

    // const stackStoreSubscription = this.stackStore
    //   .select(stackFeatureKey)
    //   .pipe(
    //     mergeMap((state) => {
    //       this.currentStackIndex = state.index;
    //       // state.
    //       // switch (state.type) {
    //       //   case pushVideo.type.toString():
    //       //     console.error('loadVideo with a stack: ', state);
    //       //     break;
    //       // }
    //       return EMPTY;
    //     })
    //   )
    //   .subscribe();
    // this.subscriptions.add(stackStoreSubscription);
  }

  onYouTubeIframeAPIReady(): void {
    // this.logger.debug('onYouTubeIframeAPIReady()');
  }

  playVideo(): void {
    this.videoPlayer.playVideo();
  }

  pauseVideo(): void {
    this.videoPlayer.pauseVideo();
  }

  getCurrentTime(): number {
    return this.videoPlayer.getCurrentTime();
  }

  getDuration(): number {
    return this.videoPlayer.getDuration();
  }

  seekTo(seconds: number, allowSeekAhead: boolean): void {
    this.videoPlayer.seekTo(seconds, allowSeekAhead);
  }

  ngOnInit(): void {
    if (!apiLoaded) {
      // This code loads the IFrame Player API code asynchronously, according to the instructions at
      // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
      const tag = this.document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      this.document.body.appendChild(tag);
      apiLoaded = true;
    }

    /**
     * For pipPlayer, pipPlayerVars is being used.
     */
    if (this.playerVars === null) {
      this.playerVars = this.videoPlayerVars;
    }

    /**
     * onPlayerInit() must be done here after 'id' like either 'video-player' or 'pip-player' has been set.
     */
    this.videoPlayerService.onPlayerInit(this);
  }

  ngAfterViewInit(): void {
    // do nothing
  }

  /**
   * Everything must be done in ngAfterViewInit() when video-player is available.
   */
  private ngAfterViewInitOnPlayerReady(): void {
    if (this.videoPlayer) {
      const player = this.videoPlayer;

      /**
       * The time of the timer determines how smooth the progress can be.
       */
      const timerSubscription = timer(0, 100)
        .pipe(
          mergeMap(() => {
            // console.log('timer: ', this.playTime);

            if (player.getDuration()) {
              this.duration = +player.getDuration().toFixed(0);
            }

            if (player.getCurrentTime()) {
              this.timestamp = +player.getCurrentTime().toFixed(0);

              // TODO: videoProgressValue
              this.playTime = +((this.timestamp / this.duration) * 100).toFixed(
                0
              );

              const snapshot: VideoSnapshot = {
                videoId: this.videoId,
                videoType: this.videoType,
                timestamp: this.timestamp,
                endSeconds: this.endSeconds,
                duration: this.duration,
                postId: this.postId,
              };
              switch (player.getPlayerState()) {
                case YT.PlayerState.PLAYING:
                  this.videoStore.dispatch(videoPlaying(snapshot));
                  this.videoPlayerService.setVideoSnapshot(snapshot);
                  // this.playTime = (this.timestamp / this.duration) * 100;
                  break;
                case YT.PlayerState.PAUSED:
                  break;
              }
            }
            return EMPTY;
          })
        )
        .subscribe();
      this.subscriptions.add(timerSubscription);
    }
  }

  /**
   * startSeconds works really well.
   */
  loadVideo(
    videoId: string,
    videoType: 'video' | 'subtitle' | 'subvideo',
    startSeconds: number,
    endSeconds: number,
    duration: number,
    isDirect: boolean,
    postId: string | null
  ): void {
    // this.logger.error(
    //   '###################### loadVideo: ',
    //   // this.videoPlayer,
    //   videoId,
    //   isDirect,
    //   this.videoPlayer
    // );

    if (this.videoPlayer) {
      // this.logger.debug(
      //   'loadVideo: ',
      //   this.videoPlayer.getPlayerState(),
      //   videoId,
      //   startSeconds,
      //   duration,
      //   isDirect
      // );
    } else {
      /**
       * when video-player is not ready, just stop.
       */
      return;
    }

    // this.videoId = videoId;
    this.videoType = videoType;
    this.postId = postId;

    // if (startSeconds === -1 && duration === -1) {
    //   console.error(
    //     '@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ this is being used anyway!!!!!'
    //   );
    //   // TODO: Test
    //   this.videoId = videoId;

    //   // For resume
    //   if (this.videoPlayer) {
    //     this.videoPlayer.playVideo();
    //   }
    //   return;
    // }

    if (this.isDesktop) {
      switch (videoType) {
        case 'video':
          // nothing
          // console.error(
          //   '######################### video: ',
          //   videoId,
          //   videoType,
          //   startSeconds,
          //   endSeconds
          // );
          // this.videoId = 'a';
          // this.logger.debug(
          //   'loadVideo()',
          //   videoId,
          //   videoType,
          //   startSeconds,
          //   endSeconds
          // );
          this.videoPlayer.endSeconds = endSeconds;
          this.videoPlayer.seekTo(startSeconds, true);
          this.videoPlayer.playVideo();
          break;
        case 'subtitle':
        case 'subvideo':
          // console.error(
          //   '######################### subtitle or subvideo: ',
          //   videoId,
          //   videoType,
          //   startSeconds,
          //   endSeconds,
          //   this.getId()
          // );
          // this.logger.debug(
          //   'loadVideo() video-player:',
          //   videoId,
          //   videoType,
          //   startSeconds,
          //   endSeconds
          // );
          switch (this.getId()) {
            case 'video-player':
              this.videoPlayer.endSeconds = endSeconds;
              this.videoPlayer.seekTo(startSeconds, true);
              this.videoPlayer.playVideo();
              break;
            default:
              // console.error('>>> the truth is .....');
              /**
               * With the same videoId, you can not re-play it.
               * You have to have a different videoId each time.
               * Using this fake videoId, we can re-play the same video.
               */
              this.videoId = 'a';
              break;
          }
          break;
      }
    } else {
      /**
       * This is good enough for the mobile.
       */
      this.videoId = 'a';
    }

    setTimeout(() => {
      this.loadVideoImpl(
        videoId,
        videoType,
        startSeconds,
        endSeconds,
        duration,
        isDirect,
        postId
      );
    }, 0);
  }

  private loadVideoImpl(
    videoId: string,
    videoType: 'video' | 'subtitle' | 'subvideo',
    startSeconds: number,
    endSeconds: number,
    duration: number,
    isDirect: boolean,
    postId: string | null
  ): void {
    // this.logger.error(
    //   'loadVideo()',
    //   videoId,
    //   videoType,
    //   startSeconds,
    //   endSeconds
    // );

    // TODO: Test
    // this.openSnackBar(
    //   `${videoId} ${videoType} ${startSeconds} ${endSeconds}`,
    //   'ok'
    // );

    this.videoId = videoId;
    if (this.videoPlayer) {
      // start-over
      this.videoPlayer.startSeconds = startSeconds;
      this.videoPlayer.endSeconds = endSeconds;
      this.endSeconds = endSeconds;
      const video: Video = {
        videoType,
        subvideoId: `${videoId}-${startSeconds}-${duration}`,
        videoId,
        postId,
        startSeconds,
        endSeconds,
        duration,
      };
      if (!isDirect) {
        this.stackStore.dispatch(
          pushVideo({
            video,
          })
        );
      }
    }
  }

  onReady(event: YT.PlayerEvent): void {
    /**
     * Ths order of the lines of code determines key-events after reloading.
     */
    event.target.playVideo();
    this.ngAfterViewInitOnPlayerReady();
  }

  // TODO: useless (?)
  onApiChange(event: any): void {
    // console.error('------------------------ onApiChange: ', event);
  }

  /**
   * This is not called continuously.
   * Only on changes, it is called
   */
  onStateChange(event: YT.OnStateChangeEvent): void {
    this.videoPlayerService.onStateChange(
      this.videoId,
      this.videoType,
      this.endSeconds,
      this.postId,
      event
    );
  }

  getId(): 'video-player' | 'pip-player' {
    return this.id;
  }

  // For logging
  name(): string {
    return this.id;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.videoPlayerService.onPlayerDestory(this);
  }

  /**
   * Mobile debugger
   */
  private openSnackBar(message: string, action: string): void {
    this.snackbar.open(message, action, {
      duration: 1000,
    });
  }
}
