import { Action, createReducer, on, props } from '@ngrx/store';
import * as VideoActions from '../actions/video.actions';
import { JSend } from '../models/jsend';

/**
 * @author: john@gomedialy.com
 * @version: 0.16, 10/07/2020
 * @version: 0.20, 01/08/2021
 */
export const videoFeatureKey = 'video';

/**
 * startSeconds === timestamp.
 * startSeconds is only for videoPlayRequest()
 */
export interface VideoState {
  type: string | null;
  videoId: string | null;
  videoType: 'video' | 'subtitle' | 'subvideo';
  postId: string | null;
  startSeconds: number;
  endSeconds: number;
  timestamp: number;
  duration: number;
  jsend: JSend | null;
}

export const initialState: VideoState = {
  type: null,
  videoId: null,
  videoType: 'video',
  postId: null,
  startSeconds: 0,
  endSeconds: 0,
  timestamp: 0,
  duration: 0,
  jsend: null,
};

export const videoReducer = createReducer(
  initialState,

  on(VideoActions.playPipVideo, (state, action) => {
    // keep the current data but the type
    return { ...state, ...action };
  }),

  on(VideoActions.playOrPause, (state, action) => {
    // keep the current data but the type
    return { ...state, ...action };
  }),
  on(VideoActions.replay, (state, action) => {
    return { ...state, ...action };
  }),
  on(VideoActions.seekLeftTo, (state, action) => {
    return { ...state, ...action };
  }),
  on(VideoActions.seekRightTo, (state, action) => {
    return { ...state, ...action };
  }),
  on(VideoActions.volumeUp, (state, action) => {
    return { ...state, ...action };
  }),
  on(VideoActions.volumeDown, (state, action) => {
    return { ...state, ...action };
  }),

  // play
  on(VideoActions.playVideo, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),

  // direct-play
  on(VideoActions.directPlayVideo, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),

  // pause
  on(VideoActions.pauseVideo, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),

  // // play
  // on(VideoActions.playVideoRequest, (state, action) => {
  //   return { ...state, ...action, jsend: null };
  // }),
  // on(VideoActions.playVideoResponse, (state, action) => {
  //   return { ...state, ...action, jsend: action.jsend };
  // }),

  // // direct-play
  // on(VideoActions.directPlayVideoRequest, (state, action) => {
  //   return { ...state, ...action, jsend: null };
  // }),
  // on(VideoActions.directPlayVideoResponse, (state, action) => {
  //   return { ...state, ...action, jsend: action.jsend };
  // }),

  // // pause
  // on(VideoActions.pauseVideoRequest, (state, action) => {
  //   return { ...state, ...action, jsend: null };
  // }),

  // on(VideoActions.pauseVideoResponse, (state, action) => {
  //   return { ...state, ...action, jsend: action.jsend };
  // }),

  // state
  on(VideoActions.videoBuffering, (state, action) => {
    // return { ...state, ...action, jsend: null };

    /**
     * To keep the current postId
     */
    return {
      ...state,
      type: action.type,
      videoType: action.videoType,
      timestamp: action.timestamp,
      duration: action.duration,
      jsend: null,
    };
  }),
  on(VideoActions.videoCued, (state, action) => {
    // return { ...state, ...action, jsend: null };
    /**
     * To keep the current postId
     */
    return {
      ...state,
      type: action.type,
      videoType: action.videoType,
      timestamp: action.timestamp,
      duration: action.duration,
      jsend: null,
    };
  }),
  on(VideoActions.videoEnded, (state, action) => {
    // return { ...state, ...action, jsend: null };
    /**
     * To keep the current postId
     */
    return {
      ...state,
      type: action.type,
      videoType: action.videoType,
      timestamp: action.timestamp,
      duration: action.duration,
      jsend: null,
    };
  }),
  on(VideoActions.videoPaused, (state, action) => {
    // return { ...state, ...action, jsend: null };
    /**
     * To keep the current postId
     */
    return {
      ...state,
      type: action.type,
      videoType: action.videoType,
      timestamp: action.timestamp,
      duration: action.duration,
      jsend: null,
    };
  }),
  on(VideoActions.videoUnstarted, (state, action) => {
    // return { ...state, ...action, jsend: null };
    /**
     * To keep the current postId
     */
    return {
      ...state,
      type: action.type,
      videoType: action.videoType,
      timestamp: action.timestamp,
      duration: action.duration,
      jsend: null,
    };
  }),
  on(VideoActions.videoPlaying, (state, action) => {
    // return { ...state, ...action, jsend: null };

    // videoId: string;
    // videoType: 'video' | 'subtitle' | 'subvideo';
    // postId: string | null;
    // timestamp: number;
    // duration: number;

    /**
     * To keep the current postId
     */
    return {
      ...state,
      type: action.type,
      videoType: action.videoType,
      timestamp: action.timestamp,
      duration: action.duration,
      jsend: null,
    };
  })
);
