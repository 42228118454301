import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { mergeMap } from 'rxjs/operators';
import {
  editPendingPostContentResponse,
  startEditPostResponse,
  startPostingResponse,
} from '../actions/post.actions';
import { postFeatureKey, PostState } from '../reducers/post.reducer';
import { BaseComponent } from '../base.component';
import { AuthService } from '../services/auth.service';
import { authFeatureKey, AuthState } from '../reducers/auth.reducer';
import { authStateChanged } from '../actions/auth.actions';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounce, bounceIn, fadeIn } from 'ng-animate';
import { EMPTY } from 'rxjs';
import {
  startSubpostingResponse,
  editPostContentResponse,
} from '../actions/post.actions';

/**
 * @author: john@gomedialy.com
 * @version: 0.12, 01/30/2021
 */
@Component({
  selector: 'app-post-preview',
  templateUrl: './post-preview.component.html',
  styleUrls: ['./post-preview.component.css'],
  // animations: [trigger('fadeIn', [transition('* => *', useAnimation(fadeIn))])],
})
export class PostPreviewComponent extends BaseComponent implements OnInit {
  /* fields */
  // fadeIn: any;
  photoUrl = '';
  title = 'title';
  subtitle = 'subtitle';
  draftContent = '';

  constructor(
    private authService: AuthService,
    private authStore: Store<{
      [authFeatureKey]: AuthState;
    }>,
    private postStore: Store<{
      [postFeatureKey]: PostState;
    }>
  ) {
    super();
  }

  ngOnInit(): void {
    this.authStore
      .select(authFeatureKey)
      .pipe(
        mergeMap((state) => {
          switch (state.type) {
            case authStateChanged.type.toString():
              const user = state.user;
              if (user) {
                if (user.displayName) {
                  this.title = '미리보기';
                  this.subtitle = user.displayName;
                }
                if (user.photoUrl) {
                  this.photoUrl = user.photoUrl;
                }
              }
              break;
          }
          return EMPTY;
        })
      )
      .subscribe();

    // const user = this.authService.getUser();
    // this.router.navigate(['/home']);

    // this.draftContent = '# Apple Steve Jobs ...';
    const postStoreSubscription = this.postStore
      .select(postFeatureKey)
      .pipe(
        mergeMap((state) => {
          // console.log('postState: ', state);

          switch (state.type) {
            case startPostingResponse.type.toString():
              this.title = '미리보기 | 댓글 게시';
              break;
            case startSubpostingResponse.type.toString():
              this.title = '미리보기 | 댓글 추가';
              break;
            case startEditPostResponse.type.toString():
              this.title = '미리보기 | 댓글 수정';
              if (state.content) {
                this.draftContent = state.content;
              }
              break;
            case editPostContentResponse.type.toString():
              // this.title = '미리보기 | 댓글 수정';
              if (state.content) {
                this.draftContent = state.content;
              }
              break;
            case editPendingPostContentResponse.type.toString():
              // state.postId
              // this.inputPost.postId
              // console.log('post pending: ', state);

              if (state.content) {
                this.draftContent = state.content;
              }
              break;
          }
          return EMPTY;
        })
      )
      .subscribe();
    this.subscriptions.add(postStoreSubscription);
  }

  name(): string {
    return 'post-preview';
  }
}
