import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  QueryDocumentSnapshot,
  DocumentChangeAction,
  DocumentReference,
  DocumentData,
  DocumentSnapshot,
  QuerySnapshot,
  AngularFirestoreDocument,
  DocumentSnapshotDoesNotExist,
  DocumentChange,
} from '@angular/fire/compat/firestore';
import { EMPTY, Observable, of, range, from, Subject } from 'rxjs';
import { map, tap, toArray, mergeMap, finalize } from 'rxjs/operators';
import { Preconditions } from '../utils/guava';
import firebase from 'firebase/compat/app';

/**
 * @author: john@gomedialy.com
 * @version: 0.18, 10/28/2020
 * @version: 0.19, 01/18/2021
 */
export interface TxContext<T> {
  transaction: firebase.firestore.Transaction;
  doc?: T;
}
@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  /* fields */
  firestore: firebase.firestore.Firestore;

  constructor(private angularFirestore: AngularFirestore) {
    this.firestore = this.angularFirestore.firestore;
    // // this.firestore.enablePersistence
    // this.angularFirestore.persistenceEnabled$.subscribe(
    //   (persistenceEnabled) => {
    //     console.log('[FIRESTORE] persistenceEnabled: ', persistenceEnabled);
    //   },
    //   (err) => {
    //     console.warn(err);
    //   }
    // );
  }

  // /**
  //  * ! WTF
  //  */
  // delete(docPath: string): Observable<string> {
  //   // console.error('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ REALLY: ', docPath);
  //   const document: DocumentReference = this.firestore.doc(docPath);
  //   // document.delete().then((v) => {
  //   //   console.error('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ REALLY2: ', v);
  //   // });

  //   from(document.delete())
  //     // .pipe(
  //     //   mergeMap((v) => {
  //     //     console.error('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ REALLY: ', v);
  //     //     return of(docPath);
  //     //   })
  //     // )
  //     .subscribe(
  //       (v) => {
  //         console.error('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ REALLY3: ', v);
  //       },
  //       (error) => {}
  //     );
  //   return of('');
  // }

  /* Ex) How to use 'new Observable(subscriber ...)
      onRequest<T>(request: https.Request): Observable<T> {
          return new Observable(subscriber => {
              try {
                  const message: PubsubMessage = request.body.message;
                  if ((message) && (message.data)) {
                      const value: T
                          = JSON.parse(Buffer.from(message.data, 'base64').toString());
                      subscriber.next(value);
                      subscriber.complete();
                  }
                  else {
                      //throw new Error('message.data is undefined');
                      throw new Error('invalid pubsub request');
                  }
              } catch (error) {
                  subscriber.error(error);
              }
          });
      }
  */

  runTransaction<T>(
    docPath: string,
    updateFunction: (txContext: TxContext<T>) => Promise<void>
  ): Observable<void> {
    return from(
      this.firestore.runTransaction((transaction) => {
        const docRef = this.firestore.doc(docPath);
        return transaction.get(docRef).then((docSnapshot) => {
          if (docSnapshot.exists) {
            const data = docSnapshot.data() as T;
            const txContext: TxContext<T> = {
              transaction,
              doc: data,
            };
            return updateFunction(txContext);
          }
          const txContext: TxContext<T> = {
            transaction,
          };
          return updateFunction(txContext);
        });
      })
    ); // from
  }

  /**
   * batchStart() ... batchSet() ... batchCommit()
   */
  batchStart(): Observable<firebase.firestore.WriteBatch> {
    // this.angularFirestore.firestore.bat
    return of(this.firestore.batch());
  }

  batchSet(
    batch: firebase.firestore.WriteBatch,
    collection: string,
    doc: string,
    data: DocumentData
  ): Observable<firebase.firestore.WriteBatch> {
    const document: DocumentReference = this.firestore.doc(
      collection + '/' + doc
    );
    return of(batch.set(document, data, { merge: true }));
  }

  batchSetBlocking(
    batch: firebase.firestore.WriteBatch,
    collection: string,
    doc: string,
    data: DocumentData
  ): firebase.firestore.WriteBatch {
    const document: DocumentReference = this.firestore.doc(
      collection + '/' + doc
    );
    return batch.set(document, data, { merge: true });
  }

  batchCommit(batch: firebase.firestore.WriteBatch): Observable<void> {
    return from(batch.commit());
  }

  batchCommitAndReturnValue(
    batch: firebase.firestore.WriteBatch,
    value: any
  ): Observable<any> {
    return this.batchCommit(batch).pipe(map(() => value));
  }

  listFromDocumentChangeActions<T>(
    documentChangeActions: DocumentChangeAction<T>[]
  ): Observable<T> {
    return from(documentChangeActions).pipe(
      map((documentChangeAction) => {
        // documentChangeAction.type
        return documentChangeAction.payload.doc.data() as T;
      })
    );
  }

  listFromQuerySnapshot<T>(
    querySnapshot: QuerySnapshot<DocumentData>
  ): Observable<T> {
    return of(querySnapshot).pipe(
      mergeMap((querySnapshot) => {
        if (querySnapshot.empty) {
          return EMPTY;
        }
        return from(querySnapshot.docs).pipe(
          map((document) => {
            return document.data() as T;
          })
        );
      })
    );
  }

  // To get ref.path
  listDocumentChangeFromQuerySnapshot(
    querySnapshot: QuerySnapshot<DocumentData>
  ): Observable<DocumentChange<DocumentData>> {
    return of(querySnapshot).pipe(
      mergeMap((querySnapshot) => {
        if (querySnapshot.empty) {
          return EMPTY;
        }
        return from(querySnapshot.docChanges()).pipe(
          map((documentChange: DocumentChange<DocumentData>) => {
            return documentChange;
          })
        );
      })
    );
  }

  findOnlyQueryDocumentSnapshotFromQuerySnapshot(
    querySnapshot: QuerySnapshot<DocumentData>
  ): Observable<QueryDocumentSnapshot<DocumentData> | null> {
    return of(querySnapshot).pipe(
      map((querySnapshot) => {
        if (querySnapshot.empty) {
          return null;
        }
        const queryDocumentSnapshots: QueryDocumentSnapshot<DocumentData>[] =
          querySnapshot.docs;
        Preconditions.checkArgument(
          queryDocumentSnapshots.length == 1,
          'Duplicats exist.'
        );
        const queryDocumentSnapshot: QueryDocumentSnapshot<DocumentData> =
          queryDocumentSnapshots[0];
        return queryDocumentSnapshot;
      })
    );
  }

  // findOnlyDocumentReferenceFromQuerySnapshot(
  //   querySnapshot: QuerySnapshot<DocumentData>
  // ): Observable<firestore.DocumentReference<firestore.DocumentData> | null> {
  //   return this.findOnlyQueryDocumentSnapshotFromQuerySnapshot(
  //     querySnapshot
  //   ).pipe(
  //     map((queryDocumentSnapshot) => {
  //       if (queryDocumentSnapshot) {
  //         return queryDocumentSnapshot.ref;
  //       }
  //       return null;
  //     })
  //   );
  // }

  findOnlyOneFromQuerySnapshot<T>(
    querySnapshot: QuerySnapshot<DocumentData>
  ): Observable<T | null> {
    return this.findOnlyQueryDocumentSnapshotFromQuerySnapshot(
      querySnapshot
    ).pipe(
      map((queryDocumentSnapshot) => {
        if (queryDocumentSnapshot) {
          return queryDocumentSnapshot.data() as T;
        }
        return null;
      })
    );
  }

  // findOnlyOneFromQuerySnapshot2<T>(
  //   querySnapshot: QuerySnapshot<DocumentData>
  // ): Observable<T | null> {
  //   return of(querySnapshot).pipe(
  //     map((querySnapshot) => {
  //       if (querySnapshot.empty) {
  //         return null;
  //       }
  //       const queryDocumentSnapshots: QueryDocumentSnapshot<DocumentData>[] =
  //         querySnapshot.docs;
  //       Preconditions.checkArgument(
  //         queryDocumentSnapshots.length == 1,
  //         'Duplicats exist.'
  //       );
  //       return queryDocumentSnapshots[0].data() as T;
  //     })
  //   );
  // }

  add<T>(collection: string, data: T): Observable<string> {
    return from(this.angularFirestore.collection<T>(collection).add(data)).pipe(
      mergeMap((documentReference) => {
        // documentReference.id
        return from(
          this.angularFirestore
            .collection<T>(collection)
            .doc(documentReference.id)
            .set(data)
        ).pipe(map((v) => documentReference.id));
      })
    );
  }

  getAngularFirestore(): AngularFirestore {
    return this.angularFirestore;
  }

  // getFirestore(): Firestore {
  //   return this.firestore;
  // }

  // // angularQuery(), fQuery
  // query(
  //   collection: string,
  //   key: string,
  //   operation: '==' | '<' | '>' | '<=' | '>=',
  //   value: any,
  //   key1?: string,
  //   operation1?: '==' | '<' | '>' | '<=' | '>=',
  //   value1?: any
  // ): Observable<QuerySnapshot<DocumentData>> {
  //   if (key1 && operation1 && value1) {
  //     return this.angularFirestore
  //       .collection(collection, (ref) => {
  //         return ref
  //           .where(key, operation, value)
  //           .where(key1, operation1, value1);
  //       })
  //       .get();
  //   } else {
  //     return this.angularFirestore
  //       .collection(collection, (ref) => {
  //         return ref.where(key, operation, value);
  //       })
  //       .get();
  //   }
  // }

  queryx(
    collection: string,
    key: string,
    operation: '==' | '<' | '>' | '<=' | '>=',
    value: any,
    key1?: string,
    operation1?: '==' | '<' | '>' | '<=' | '>=',
    value1?: any
  ): Observable<QuerySnapshot<DocumentData>> {
    let query: firebase.firestore.Query<firebase.firestore.DocumentData>;
    if (key1 && operation1 && value1) {
      query = this.firestore
        .collection(collection)
        .where(key, operation, value)
        .where(key1, operation1, value1);
    } else {
      query = this.firestore
        .collection(collection)
        .where(key, operation, value);
    }
    return from(query.get());
  }

  queryLatestItemsAsc<T>(
    queryObservable: Observable<QuerySnapshot<DocumentData>>,
    orderBy: string,
    limit: number
  ): Observable<T> {
    return this.queryOrderBy<T>(queryObservable, orderBy, 'desc', limit);
  }

  queryLatestItemsDesc<T>(
    queryObservable: Observable<QuerySnapshot<DocumentData>>,
    orderBy: string,
    limit: number
  ): Observable<T> {
    return this.queryOrderBy<T>(queryObservable, orderBy, 'asc', limit);
  }

  /**
   * orderBy(), limit() and limitToLast() are confusing.
   * Just use queryLatestItemsAsc() and queryLatestItemsDesc()
   *
   * @param queryObservable TODO:
   * @param orderBy TODO:
   * @param direction TODO:
   * @param limit TODO:
   */
  private queryOrderBy<T>(
    queryObservable: Observable<QuerySnapshot<DocumentData>>,
    orderBy: string,
    direction: 'asc' | 'desc',
    limit: number
  ): Observable<T> {
    return queryObservable.pipe(
      mergeMap((querySnapshot) => {
        /**
         * direction: asc, desc
         */
        switch (direction) {
          case 'asc':
            return from(
              querySnapshot.query
                .orderBy(orderBy, direction)
                .limitToLast(limit)
                .get()
            ).pipe(
              mergeMap((documentData) => {
                return from(documentData.docs).pipe(
                  map((doc) => {
                    return doc.data() as T;
                  })
                );
              })
            );
          case 'desc':
            return from(
              querySnapshot.query.orderBy(orderBy, direction).limit(limit).get()
            ).pipe(
              mergeMap((documentData) => {
                return from(documentData.docs).pipe(
                  map((doc) => {
                    return doc.data() as T;
                  })
                );
              })
            );
        }
        return EMPTY;
      })
    );
  }

  // queryList<T>(
  //   collection: string,
  //   key: string,
  //   operation: '==' | '<' | '>' | '<=' | '>=',
  //   value: any,
  //   key1?: string,
  //   operation1?: '==' | '<' | '>' | '<=' | '>=',
  //   value1?: any
  // ): Observable<T> {
  //   return this.query(
  //     collection,
  //     key,
  //     operation,
  //     value,
  //     key1,
  //     operation1,
  //     value1
  //   ).pipe(
  //     mergeMap((querySnapshot) => {
  //       return this.listFromQuerySnapshot<T>(querySnapshot);
  //     })
  //   );
  // }

  // setAndReturn
  set(collection: string, doc: string, data: DocumentData): Observable<void> {
    const document: DocumentReference = this.firestore.doc(
      collection + '/' + doc
    );
    return from(document.set(data, { merge: true }));
  }

  // setAndReturn(collection: string, doc: string, data: FirebaseFirestore.DocumentData, returnValue: any): Observable<any> {
  //     return this.set(collection, doc, data)
  //         .pipe(
  //             map(writeResult => {
  //                 return returnValue;
  //             })
  //         )
  // }

  setAndReturn<T>(
    collection: string,
    doc: string,
    data: DocumentData,
    returnValue: T
  ): Observable<T> {
    return this.set(collection, doc, data).pipe(
      map((writeResult) => {
        return returnValue;
      })
    );
  }

  findOnlyDocumentSnapshot(
    collection: string,
    doc: string
  ): Observable<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null> {
    return from(this.firestore.collection(collection).doc(doc).get()).pipe(
      map((documentSnapshot) => {
        if (documentSnapshot.exists) {
          return documentSnapshot;
        }
        return null;
      })
    );

    // return this.angularFirestore
    //   .collection(collection)
    //   .doc(doc)
    //   .get()
    //   .pipe(
    //     map((documentSnapshot) => {
    //       if (documentSnapshot.exists) {
    //         return documentSnapshot;
    //       }
    //       return null;
    //     })
    //   );
  }

  findOnlyOne<T>(collection: string, doc: string): Observable<T | null> {
    return this.findOnlyDocumentSnapshot(collection, doc).pipe(
      map((documentSnapshot) => {
        if (documentSnapshot) {
          return documentSnapshot.data() as T;
        }
        return null;
      })
    );
  }

  // get(collection: string, doc: string): Observable<firestore.DocumentSnapshot> {
  //   const document: DocumentReference = this.firestore.doc(
  //     collection + '/' + doc
  //   );
  //   return from(document.get());
  // }

  // getObject<T>(collection: string, doc: string): Observable<T | undefined> {
  //   return this.get(collection, doc).pipe(
  //     mergeMap((documentSnapshot) => {
  //       if (!documentSnapshot.exists) {
  //         return of(undefined);
  //       }
  //       return this.fromDocumentSnapshot<T>(documentSnapshot);
  //     })
  //   );
  // }

  // getOptional<T>(collection: string, doc: string): Observable<Optional<T>> {
  //   return this.get(collection, doc).pipe(
  //     mergeMap((documentSnapshot) => {
  //       if (!documentSnapshot.exists) {
  //         return of(Optional.empty<T>());
  //       }
  //       return this.fromDocumentSnapshotOptional<T>(documentSnapshot);
  //     })
  //   );
  // }

  // find(collection: string, doc: string): Observable<FirebaseFirestore.DocumentData|undefined> {
  //     return this.get(collection, doc)
  //         .pipe(
  //             mergeMap(documentSnapshot => {
  //                 if (documentSnapshot.exists) {
  //                     return of(documentSnapshot.data());
  //                 }
  //                 return of(undefined);
  //             })
  //         )
  // }

  // /**
  //  * To object
  //  */
  // private fromDocumentSnapshot<T>(
  //   value: firestore.DocumentSnapshot
  // ): Observable<T | undefined> {
  //   return of(value).pipe(
  //     mergeMap((documentSnapshot) => {
  //       if (documentSnapshot.exists) {
  //         return of(documentSnapshot.data() as T);
  //       }
  //       return of(undefined);
  //     })
  //   );
  // }

  // private fromDocumentSnapshotOptional<T>(
  //   value: firestore.DocumentSnapshot
  // ): Observable<Optional<T>> {
  //   return of(value).pipe(
  //     mergeMap((documentSnapshot) => {
  //       if (documentSnapshot.exists) {
  //         return of(Optional.of(documentSnapshot.data() as T));
  //       }
  //       return of(Optional.empty<T>());
  //     })
  //   );
  // }

  // /**
  //  * For a simple and small scale
  //  */
  // getCollectionCount(collection: string): Observable<number> {
  //
  //   return fromPromise(this.firestore.collection(collection).get())
  //     .pipe(
  //       mergeMap(querySnapshot => {
  //         return of(querySnapshot.size);
  //       })
  //     );
  //
  //   // return of(firestore.doc(collection + '/' + doc))
  //   //     .pipe(
  //   //         mergeMap(querySnapshot => {
  //   //
  //   //             querySnapshot
  //   //
  //   //             const documents: Array<FirebaseFirestore.QueryDocumentSnapshot<FirebaseFirestore.DocumentData>>
  //   //                 = querySnapshot..docs;
  //   //
  //   //             let count = 0;
  //   //             for (const doc of documents) {
  //   //                 count += doc.get('count');
  //   //             }
  //   //             //return count;
  //   //             return of(count);
  //   //         }),
  //   //     );
  // }

  // count(): Observable<number> {
  //
  //     db.collection("cities").get().then(function(querySnapshot) {
  //         console.log(querySnapshot.size);
  //     });
  //
  // }

  // update(collection: string, doc: string, data: FirebaseFirestore.UpdateData): Observable<FirebaseFirestore.WriteResult> {
  //     const document: FirebaseFirestore.DocumentReference
  //         = firestore.doc(collection + '/' + doc);
  //     return fromPromise(document.update(data, {merge: true}));
  // }
}
