<mat-toolbar class="debug-redx app-toolbar">
  <div
    class="app-toolbar-container mat-elevation-z4 debug-bluex"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="0px"
  >
    <div>
      <button
        [@bounceIn]="bounceIn"
        mat-flat-button
        class="gomedialy-logo debug-bluex"
        appDebounceClick
        (debounceClick)="onLogoClick()"
        *ngIf="isHome"
      >
        Haanglish
      </button>
      <button
        [@bounceIn]="bounceIn"
        mat-flat-button
        class="gomedialy-logo debug-greenx"
        appDebounceClick
        (debounceClick)="onArrowLeftClick()"
        *ngIf="!isHome"
      >
        <mat-icon style="font-weight: bolder">arrow_back_ios</mat-icon>
      </button>
    </div>

    <div
      *ngIf="isBrowse"
      classx="mat-headline"
      style="
        font-size: min(5vw, 24px);
        font-weight: bolder;
        margin: 0px;
        overflow: hidden;
        white-space: nowrap;
      "
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-icon style="margin-left: 8px; font-weight: bolder">
        arrow_right
      </mat-icon>
      {{ query }}
    </div>
    <div *ngIf="isBrowse" fxFlex></div>

    <div *ngIf="isHome && isDesktop && false">
      <form class="debug-redx" [formGroup]="searchFormGroup">
        <button mat-button style="padding: 1rem" disabled="{{ true }}">
          <img class="image-rounded" src="{{ searchImageUrl }}" />
          <img
            #youtubeVideoImage
            style="display: none"
            src="{{ testSearchImageUrl }}"
            (load)="onYoutubeImageLoad($event)"
          />
        </button>
        <mat-form-field class="search-form-field">
          <mat-label></mat-label>
          <input
            #searchInput
            matInput
            placeholder="https://www.youtube.com/watch?v=abcdefghijk"
            formControlName="searchControl"
          />
          <button
            mat-button
            *ngIf="searchValue"
            matSuffix
            mat-icon-button
            (click)="searchValue = ''"
          >
            <mat-icon>close</mat-icon>
          </button>

          <!-- <mat-icon matSuffix svgIcon="youtube"></mat-icon> -->
        </mat-form-field>
        <button
          mat-button
          style="padding: 1rem"
          appDebounceClick
          (debounceClick)="onSearchClick()"
          disabled="{{ !searchButtonEnabled }}"
        >
          <mat-icon style="font-weight: bolder">play_circle_outline</mat-icon>
        </button>
      </form>
    </div>

    <div
      class="debug-bluex"
      fxLayout="row"
      fxLayoutGap="32px"
      fxLayoutGap.lt-md="0px"
      fxHide="{{ isHome || isBrowse }}"
    >
      <div class="debug-redx" fxLayout="row" fxLayoutAlign="start center">
        <!-- Video image -->
        <img class="image-rounded" src="{{ videoImageUrl }}" fxHide.lt-sm />
        <div class="title2" style="color: #9e9e9e" fxHide.lt-sm>
          {{ timestampMiliseconds | date: "H:mm:ss":"UTC" }} /
          <!-- {{ durationMiliseconds | date: "H:mm:ss":"UTC" }} -->
          {{ endMiliseconds | date: "H:mm:ss":"UTC" }}
        </div>

        <!-- Home -->
        <button
          mat-button
          style="padding: 1rem"
          appDebounceClick
          (debounceClick)="onHomeClick()"
          disabled="{{ !homeButtonEnabled }}"
          matTooltip="원본 재생"
        >
          <mat-icon matBadge="{{ linksLength }}" matBadgeColor="accent">
            home
          </mat-icon>
        </button>
        <!-- Backward -->
        <button
          mat-button
          style="padding: 1rem"
          appDebounceClick
          (debounceClick)="onBackwardClick()"
          disabled="{{ !previousButtonEnabled }}"
          matTooltip="이전 재생"
        >
          <mat-icon>arrow_back</mat-icon>
        </button>
        <!-- Play & Pause -->
        <button
          mat-button
          style="padding: 1rem"
          appDebounceClick
          (debounceClick)="onPlayClick()"
          matTooltip="재생"
          fxHide="{{ isPlaying() }}"
        >
          <mat-icon>play_arrow</mat-icon>
        </button>

        <button
          mat-button
          style="padding: 1rem"
          appDebounceClick
          (debounceClick)="onPlayClick()"
          matTooltip="정지"
          fxShow="{{ isPlaying() }}"
        >
          <mat-icon>pause</mat-icon>
        </button>

        <!-- Replay -->
        <button
          mat-button
          style="padding: 1rem"
          appDebounceClick
          (debounceClick)="onReplayClick()"
          matTooltip="다시 시작"
          disabled="{{ !reloadButtonEnabled }}"
          fxHide.lt-sm
          fxHide="{{ isSearch }}"
        >
          <mat-icon>replay</mat-icon>
        </button>
      </div>

      <div *ngIf="isDesktop">
        <button
          class="debug-redx"
          mat-button
          style="padding: 1rem"
          appDebounceClick
          (debounceClick)="onStartPostingClick()"
          matTooltip="댓글"
          disabled="{{ isPostingButtonDisabled }}"
        >
          <mat-icon>post_add</mat-icon>
        </button>
      </div>

      <div *ngIf="isDesktop">
        <button
          class="debug-redx"
          mat-button
          style="padding: 1rem"
          appDebounceClick
          (debounceClick)="onScrollToTop()"
          matTooltip="위로 이동"
          disabled="{{ !scrollToTopButtonEnabled }}"
        >
          <mat-icon style="font-weight: bolder">keyboard_arrow_up</mat-icon>
          <!-- <mat-icon style="font-weight: bolder">vertical_align_top</mat-icon> -->
        </button>
        <button
          class="debug-redx"
          mat-button
          style="padding: 1rem"
          appDebounceClick
          (debounceClick)="onScrollDown()"
          matTooltip="아래로 이동"
        >
          <mat-icon style="font-weight: bolder">keyboard_arrow_down</mat-icon>
          <!-- <mat-icon style="font-weight: bolder">vertical_align_bottom</mat-icon> -->
        </button>
      </div>
    </div>
    <div *ngIf="!userState" fxFlex="10"></div>
    <div
      [@bounceIn]="bounceIn"
      *ngIf="userState === 'anonymous'"
      fxLayout="row"
      fxLayoutAlign="end center"
      fxFlex="10"
    >
      <!-- <button
        matTooltip="구글 로그인"
        mat-button
        style="padding: 1rem"
        appDebounceClick
        (debounceClick)="onLoginClick()"
        style="padding: 1rem"
      >
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
          <img class="image-rounded" src="../assets/images/google-red-48.png" />
        </div>
      </button> -->
    </div>
    <div
      [@bounceIn]="bounceIn"
      *ngIf="userState === 'user'"
      fxLayout="row"
      fxLayoutAlign="end center"
      fxFlex="10"
    >
      <button
        mat-button
        style="padding: 1rem"
        matTooltip="로그아웃"
        appDebounceClick
        (debounceClick)="onLogoutClick()"
      >
        <img class="image-rounded" src="{{ photoUrl }}" />
      </button>
    </div>
  </div>
</mat-toolbar>
<div class="container">
  <router-outlet></router-outlet>
</div>
<div *ngIf="isFooterReady">
  <app-footer></app-footer>
</div>
<!-- matTooltip="로그아웃: {{ displayName }}" -->
