<div
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="16px"
  class="blue-snackbar"
>
  <ng-container *ngIf="icon">
    <mat-icon style="font-weight: bolder">{{ icon }}</mat-icon>
  </ng-container>
  <ng-container *ngIf="imageUrl">
    <img class="image-rounded" src="{{ imageUrl }}" />
  </ng-container>
  <div style="color: whitesmoke; font-weight: bolder">{{ message }}</div>
  <div fxFlex></div>
  <mat-icon>clear</mat-icon>
</div>
