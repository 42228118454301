<div
  [@fadeIn]="fadeIn"
  id="{{ id }}"
  class="container debug-redx"
  fxLayout="column"
  fxLayoutGap="8px"
  style="border-bottom: 1px solid #eeeeee"
>
  <div fxLayout="row" fxLayoutAlign="start center">
    <div class="debug-redx">
      <img
        width="32"
        height="32"
        style="vertical-align: middle"
        src="assets/images/youtube-48-bw.png"
      />
    </div>
    <div fxLayout="column">
      <div
        style="
          margin: 0px;
          font-size: small;
          font-weight: bolder;
          line-height: 1.5;
        "
      >
        {{ title }}
      </div>
      <div
        style="
          color: gray;
          font-size: small;
          font-weight: bolder;
          line-height: 1;
        "
      >
        {{ subtitle | dateAgo }}
      </div>
    </div>
  </div>
  <div matRipple>
    <img
      class="image-crop-lazyLoad debug-redx"
      src="{{ imageUrl }}"
      loading="lazy"
      appDebounceClick
      (debounceClick)="onCardImageClick()"
      matBadge="4"
      matBadgeOverlap="false"
    />
  </div>
  <div style="padding: 0px 0px">
    <p class="mat-body-2" style="margin: 0px; font-weight: bolder">
      {{ content }}
    </p>
  </div>

  <div
    class="debug-redx"
    fxLayout="row"
    fxLayout="start center"
    fxLayoutGap="16px"
  >
    <div
      class="debug-redx"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="8px"
      fxFlex="55"
    >
      <mat-icon>people</mat-icon>
      <span style="color: gray; font-weight: bolder"
        >실시간: {{ channelUserCount | shortNumber }} 명 | 뷰:
        {{ viewCount | shortNumber }}</span
      >
    </div>
    <div>
      <button
        mat-icon-button
        matTooltip="Facebook"
        shareButton="facebook"
        [url]="shareWatchUrl"
        [description]="shareDescription"
        [tags]="shareTags"
      >
        <fa-icon class="debug-redx" [icon]="faFacebook" size="lg"></fa-icon>
      </button>
      <button
        mat-icon-button
        matTooltip="Twitter"
        shareButton="twitter"
        [url]="shareWatchUrl"
        [description]="shareDescription"
        [tags]="shareTags"
      >
        <fa-icon class="debug-redx" [icon]="faTwitter" size="lg"></fa-icon>
      </button>
      <button
        mat-icon-button
        matTooltip="Kakao"
        appDebounceClick
        (debounceClick)="onKakaoClick()"
      >
        <fa-icon [icon]="faKakao" size="lg"></fa-icon>
      </button>
    </div>
  </div>
</div>

<!-- [tags]="'GoMedialy'" -->
