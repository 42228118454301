/**
 * @author: john@gomedialy.com
 * @version: 0.1, 08/12/2020
 */
export class JSend {
  status: 'success' | 'error' | 'fail';
  message?: string;
  data?: any;

  constructor(
    status: 'success' | 'fail' | 'error',
    data?: any,
    message?: string
  ) {
    this.status = status;
    this.data = data;
    this.message = message;
  }

  static success(data?: any): JSend {
    return new JSend('success', data, undefined);
  }

  static error(message?: any): JSend {
    return new JSend('error', undefined, message);
  }

  static fail(data?: any): JSend {
    return new JSend('fail', data, undefined);
  }
}
