<div id="home-top"></div>
<div
  class="container debug-redx"
  fxLayout="column"
  fxLayoutAlign="space-around center"
  fxLayoutGap="32px"
>
  <div
    fxShow.gt-xs="true"
    fxHide.xs
    class="debug-greenx mat-display-3"
    style="font-weight: bolder"
  >
    한글리쉬 | 恨-glish | 한(恨)의영어
  </div>
  <div
    fxHide
    fxShow.xs
    class="debug-greenx mat-display-3"
    style="font-weight: bolder"
  >
    한글리쉬
  </div>
  <div
    fxHide
    fxShow.xs
    class="debug-greenx mat-display-3"
    style="font-weight: bolder"
  >
    恨-glish
  </div>
  <div
    fxHide
    fxShow.xs
    class="debug-greenx mat-display-3"
    style="font-weight: bolder"
  >
    한(恨)의영어
  </div>
  <div
    class="debug-greenx mat-display-1"
    style="font-weight: bolder; color: #cc3355"
  >
    한글리쉬 APP 출시!
  </div>
  <div
    class="debug-bluex"
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutGap="0px"
  >
    <img
      class="logo-image"
      src="assets/images/google-play-button.png"
      fxFlex="12%"
      fxFlex.sm="20%"
      fxFlex.xs="35%"
      (click)="openAppMarket()"
    />
    <div style="width: 10%"></div>
    <img
      class="logo-image"
      fxFlex="12%"
      fxFlex.sm="20%"
      fxFlex.xs="35%"
      src="assets/images/apple-store-button.png"
      (click)="openAppStore()"
    />
  </div>

  <div
    class="debug-bluex"
    fxLayout="row wrap"
    fxLayoutAlign="space-around center"
    fxLayoutGap="16px"
    fxLayout.gt-md="row wrap"
    fxLayoutAlign.gt-md="space-around center"
    fxLayoutGap.gt-md="0px"
    fxLayout.gt-xs="row wrap"
    fxLayoutAlign.gt-xs="space-around center"
    fxLayoutGap.gt-xs="0px"
    fxLayout.xs="column"
    fxLayoutAlign.xs="space-around center"
  >
    <div
      class="debug-bluex"
      fxFlex="25%"
      fxFlex.xs="100%"
      fxFlex.sm="50%"
      *ngFor="let num of [0, 1, 2, 3, 4, 5, 6, 7]"
    >
      <img
        class="snapshot-center"
        src="assets/images/screenshot{{ num }}.png"
      />
    </div>
  </div>
  <div style="height: 32px"></div>
  <div
    class="debug-bluex"
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutGap="0px"
  >
    <img
      class="logo-image"
      src="assets/images/google-play-button.png"
      fxFlex="12%"
      fxFlex.sm="20%"
      fxFlex.xs="35%"
      (click)="openAppMarket()"
    />
    <div style="width: 10%"></div>
    <img
      class="logo-image"
      fxFlex="12%"
      fxFlex.sm="20%"
      fxFlex.xs="35%"
      src="assets/images/apple-store-button.png"
      (click)="openAppStore()"
    />
  </div>
</div>

<!-- <a
href="http://play.google.com/store/apps/details?id=com.gomedialy.haanglish&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
><img
  class="logo-image"
  fxFlex="22%"
  fxFlex.sm="20%"
  fxFlex.xs="35%"
  alt="다운로드하기 Google Play"
  src="https://play.google.com/intl/en_us/badges/static/images/badges/ko_badge_web_generic.png"
/></a> -->
