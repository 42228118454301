<div class="container" fxLayout="column" fxLayoutGap="16px">
  <div
    class="debug-redx"
    style="padding-left: 8px"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
  >
    <mat-button-toggle-group
      #sortOptionsGroup
      [(value)]="sortOptionSelectedValue"
      (change)="onSortOptionChange($event)"
    >
      <mat-button-toggle value="new" style="font-weight: bolder"
        >최신</mat-button-toggle
      >
      <mat-button-toggle value="natural" style="font-weight: bolder"
        >시간</mat-button-toggle
      >
      <mat-button-toggle value="top" style="font-weight: bolder"
        >인기</mat-button-toggle
      >
      <mat-button-toggle value="controversial" style="font-weight: bolder"
        >논쟁</mat-button-toggle
      >
      <mat-button-toggle value="rising" style="font-weight: bolder"
        >상승</mat-button-toggle
      >
      <mat-button-toggle value="mine" style="font-weight: bolder" fxHide.lt-sm
        >내것</mat-button-toggle
      >
    </mat-button-toggle-group>
    <mat-button-toggle-group
      #selectionOptionsGroup
      [(value)]="selectionOptionSelectedValue"
      (change)="onSelectionOptionChange($event)"
      fxHide.lt-sm
    >
      <mat-button-toggle value="all" style="font-weight: bolder"
        >전체</mat-button-toggle
      >
      <mat-button-toggle value="subtitle" style="font-weight: bolder"
        >구간</mat-button-toggle
      >
    </mat-button-toggle-group>
    <div fxFlex></div>
  </div>

  <mat-divider></mat-divider>
  <div>
    <div
      class="mat-h3"
      style="color: gray; padding-left: 16px; font-weight: bolder"
    >
      동시접속자: {{ channelUserCount | shortNumber }} | 전체 의견:
      {{ postCount | shortNumber }}
    </div>
  </div>
  <mat-list
    class="debug-redx"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollUpDistance]="7"
    [infiniteScrollThrottle]="1000"
    (scrolled)="onScrollDown()"
    (scrolledUp)="onScrollUp()"
    [scrollWindow]="true"
    [immediateCheck]="false"
    [alwaysCallback]="false"
  >
    <mat-list-item
      class="debug-redx"
      *ngFor="let post of dataService.data; index as i"
    >
      <app-post
        class="debug-redx"
        matLine
        [inputPost]="post"
        [index]="i"
      ></app-post>
    </mat-list-item>
  </mat-list>
  <div *ngIf="isLoading">
    <mat-spinner class="loading" [diameter]="32"> </mat-spinner>
    <!-- <mat-progress-bar
      class="loading"
      fxShow="{{ isLoading }}"
      mode="indeterminate"
    ></mat-progress-bar> -->
  </div>
  <!-- <mat-progress-bar class="loading" mode="buffer"></mat-progress-bar> -->

  <div class="placeholder"></div>
  <mat-divider></mat-divider>
  <!-- <app-paginator #bottomPaginator></app-paginator> -->
</div>

<!-- value="{{ initialSortOptionSelectedValue }}" -->
<!-- value="{{ initialSelectionOptionSelectedValue }}" -->
