<div class="container">
  <!-- image must be a container to keep material component features -->
  <img
    class="container image-rounded"
    src="{{ videoImageUrl }}"
    appDebounceClick
    [debounceTime]="250"
    (debounceClick)="onClick()"
  />

  <!-- <mat-spinner
    class="spinner"
    *ngIf="isPlaying"
    [diameter]="40"
    [mode]="mode"
    [color]="color"
    [value]="value"
    appDebounceClick
    [debounceTime]="250"
    (debounceClick)="onClick()"
  >
  </mat-spinner> -->

  <mat-icon
    class="{{ iconsClass }}"
    *ngIf="!isPlaying"
    appDebounceClick
    [debounceTime]="250"
    (debounceClick)="onClick()"
    matTooltip="{{ tooltip }}"
    >clear</mat-icon
  >
  <!-- <mat-icon
    class="{{ iconsClass }}"
    *ngIf="isPlaying"
    appDebounceClick
    [debounceTime]="250"
    (debounceClick)="onClick()"
    matTooltip="{{ tooltip }}"
    >pause</mat-icon
  > -->
</div>
