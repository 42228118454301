import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { mergeMap, switchMap, take, finalize } from 'rxjs/operators';
import { from, Observable, EMPTY } from 'rxjs';
import { FirestoreService, TxContext } from './firestore.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { Counter } from './firestore-counter';
import { BaseService, Context } from '../base.service';
import { Store } from '@ngrx/store';
import { mainFeatureKey, MainState } from '../reducers/main.reducer';
import { NGXLogger } from 'ngx-logger';

/**
 * @author: john@gomedialy.com
 * @version: 0.1, 09/26/2020
 * @version: 0.11, 10/28/2020
 */
@Injectable({
  providedIn: 'root',
})
export class CounterService extends BaseService {
  /* fields */
  private angularFirestore: AngularFirestore;
  private firestore: firebase.firestore.Firestore;

  constructor(
    mainStore: Store<{
      [mainFeatureKey]: MainState;
    }>,
    protected logger: NGXLogger,
    private firestoreService: FirestoreService
  ) {
    super(mainStore, logger);
    // super(mainStore);
    this.angularFirestore = this.firestoreService.getAngularFirestore();
    this.firestore = this.firestoreService.firestore;
  }

  onInit(context: Context): void {}

  onDestroy(context: Context): void {}

  name(): string {
    return 'counter';
  }

  initializeCountDoc(docPath: string, initData: any): void {
    /**
     * This completes.
     * No need of unsubscription
     */
    this.angularFirestore
      .doc(docPath)
      .get()
      .pipe(
        switchMap((documentSnapshot) => {
          if (documentSnapshot.exists) {
            return EMPTY;
          }
          return this.angularFirestore
            .doc(docPath)
            .set(initData, { merge: false });
        })
      )
      .subscribe();
  }

  /**
   * @param docPath
   * @param field
   * @param value
   */
  runUpdateTxCount<T>(
    docPath: string,
    updateFunction: (txContext: TxContext<T>) => Promise<void>
  ): Observable<void> {
    return this.firestoreService.runTransaction<T>(docPath, updateFunction);
  }

  updateCount(docPath: string, field: string, value: number): Observable<void> {
    const docRef = this.firestore.doc(docPath);
    return from(new Counter(docRef, field).incrementBy(value));
  }

  listenToDocChanges<T>(docPath: string): Observable<T | undefined> {
    return this.angularFirestore.doc<T>(docPath).valueChanges();
  }
}
