import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  Input,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent } from '../base.component';
import { videoFeatureKey, VideoState } from '../reducers/video.reducer';
import { VideoPlayerComponent } from '../video-player/video-player.component';
import { VideoPlayerService } from '../video-player/video-player.service';
import { directPlayVideo } from '../actions/video.actions';

/**
 * @author: john@gomedialy.com
 * @version: 0.19, 01/29/2021
 */
@Component({
  selector: 'app-pip-player',
  templateUrl: './pip-player.component.html',
  styleUrls: ['./pip-player.component.scss'],
  // animations: [trigger('fadeIn', [transition('* => *', useAnimation(fadeIn))])],
})
export class PipPlayerComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {
  /* fields */
  // fadeIn: any;

  @ViewChild('pipPlayer')
  pipPlayer!: VideoPlayerComponent;

  // tslint:disable-next-line: no-input-rename
  @Input('videoState')
  videoState!: VideoState | null;

  playerWidth = 375;
  playerHeight = 240;
  isEnded = false;

  // Updated one
  pipPlayerVars = {
    autoplay: 0,
    controls: 0,
    modestbranding: 0,
    cc_load_policy: 0,
    playsinline: 0, // 0: off
    showinfo: 0,
    rel: 0,
  };

  videoId = '';

  constructor(
    public videoStore: Store<{
      [videoFeatureKey]: VideoState;
    }>,
    // public videoEditorStore: Store<{
    //   [videoEditorFeatureKey]: VideoEditorState;
    // }>,
    // public stackStore: Store<{
    //   [stackFeatureKey]: StackState;
    // }>,
    public videoPlayerService: VideoPlayerService // public elementRef: ElementRef, // public deviceService: DeviceService,
  ) // @Inject(DOCUMENT) public document: Document
  {
    super();

    /**
     * Even if the video ends, the pip-player must remain.
     */
    const videoId0 = this.videoPlayerService.getVideoId();
    this.videoId = videoId0 ? videoId0 : '';
    this.isEnded = this.videoPlayerService.isEnded();
    // if (!this.isEnded) {
    //   const videoId0 = this.videoPlayerService.getVideoId();
    //   this.videoId = videoId0 ? videoId0 : '';
    // }

    // const videoSnapshot = this.videoPlayerService.getVideoSnapshot();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  getVideoId(): string | null {
    return this.videoId;
  }

  ngOnDestroy(): void {
    // MUST for BaseComponent
    super.ngOnDestroy();

    /**
     * To sync from pip-player back to video-player.
     * When a video is ended on pip-player, the video is ended on video-player as well.
     */
    const isEnded = this.videoPlayerService.isEnded();
    if (!isEnded) {
      const videoSnapshot = this.videoPlayerService.getVideoSnapshot();
      if (videoSnapshot) {
        this.videoStore.dispatch(
          directPlayVideo({
            videoId: videoSnapshot.videoId,
            videoType: videoSnapshot.videoType,
            postId: videoSnapshot.postId,
            startSeconds: videoSnapshot.timestamp,
            endSeconds: videoSnapshot.endSeconds,
            duration: videoSnapshot.duration,
          })
        );
      }
    }
  }

  /**
   * Override this to set the name.
   */
  name(): string {
    return 'pip-player';
  }
}
