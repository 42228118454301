import { Injectable } from '@angular/core';
import { mergeMap, take, concatMap, retryWhen, map, tap } from 'rxjs/operators';
import { from, Observable, EMPTY, forkJoin } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { BaseService, Context } from '../base.service';
import { Store } from '@ngrx/store';
import { mainFeatureKey, MainState } from '../reducers/main.reducer';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { delay } from 'rxjs/operators';
import { Gallery, ImageItem } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import * as SparkMD5 from 'spark-md5';
import { ImageUrls, PostImage, PostImageUrl } from '../models/images';
import { AuthService } from './auth.service';
import { nanoid } from 'nanoid';
import { environment } from '../../environments/environment';
import { NGXLogger } from 'ngx-logger';
import { Firestores } from '../utils/firestores';

/**
 * postImageUrl = previewUrl + sourceUrl
 *
 * @author: john@gomedialy.com
 * @version: 0.18, 11/01/2020
 * @version: 0.20, 01/09/2020
 */
export interface ProgressListener {
  onProgress(bytesTransferred: number, totalBytes: number): void;
  onRetry(): void;
  onComplete(postImageUrl: PostImageUrl): void;
}
@Injectable({
  providedIn: 'root',
})
export class ImagesService extends BaseService {
  /* fields */
  private previewImagePrefix = environment.previewImagePrefix;
  private sourceImagePrefix = environment.sourceImagePrefix;
  private delay = 2000; // 2 seconds

  constructor(
    mainStore: Store<{
      [mainFeatureKey]: MainState;
    }>,
    protected logger: NGXLogger,
    private firestore: AngularFirestore,
    private fireStorage: AngularFireStorage,
    private gallery: Gallery,
    private lightbox: Lightbox,
    private authService: AuthService
  ) {
    // super(mainStore);
    super(mainStore, logger);
  }

  upload(
    videoId: string,
    postId: string,
    file: File,
    fileHash: string,
    listener: ProgressListener
  ): Observable<PostImageUrl> {
    const imageId = nanoid();
    const filename0 = file.name as string;
    // const filename = imageId;
    const index = filename0.lastIndexOf('.');
    const extension = filename0.substr(index);
    /**
     * Ex) filename: {imageId}.png
     */
    // const filename = `${imageId}${extension}`;

    const storageFiles = this.createResizeImageStorageFiles(
      videoId,
      postId,
      imageId,
      extension
    );

    const task = this.fireStorage.upload(storageFiles[0], file);
    return task.snapshotChanges().pipe(
      concatMap((upload) => {
        if (upload) {
          switch (upload.state) {
            case 'running':
              // console.log('running: ', upload.bytesTransferred, upload.totalBytes);
              listener.onProgress(upload.bytesTransferred, upload.totalBytes);
              break;
            case 'success':
              return this.getImageUrls(storageFiles).pipe(
                mergeMap((imageUrls) => {
                  const imageDoc = `channels/${videoId}/images/${imageId}`;
                  const user = this.authService.getUser();
                  if (user) {
                    const postImage: PostImage = {
                      imageId,
                      videoId,
                      postId,
                      userId: user.userId,
                      previewUrl: imageUrls.previewUrl,
                      sourceUrl: imageUrls.sourceUrl,
                      fileHash,
                      createdAt: firebase.firestore.Timestamp.now(),
                    };
                    return from(
                      this.firestore.doc(imageDoc).set(postImage)
                    ).pipe(
                      map(() => {
                        const postImageUrl = this.createPostImageUrl(postImage);
                        listener.onComplete(postImageUrl);
                        return postImageUrl;
                      })
                    );
                  }
                  return EMPTY;
                })
              );
          }
        }
        return EMPTY;
      }),
      delay(this.delay),
      retryWhen((errors) =>
        errors.pipe(
          delay(this.delay),
          take(10),
          tap(() => listener.onRetry())
        )
      )
    );
  }

  createPostImageUrl(postImage: PostImage): PostImageUrl {
    // console.log('############## createPostImageUrl');
    const sourceURL = new URL(postImage.sourceUrl);
    const stoken = sourceURL.searchParams.get('token');

    const previewURL = new URL(postImage.previewUrl);
    previewURL.searchParams.set('videoId', postImage.videoId);
    previewURL.searchParams.set('postId', postImage.postId);
    previewURL.searchParams.set('imageId', postImage.imageId);
    if (stoken) {
      previewURL.searchParams.set('stoken', stoken);
    }

    // if (this.deviceService.isDesktop()) {
    //   previewURL.searchParams.set('device', 'mobile');
    // }
    return { previewUrl: previewURL.toString() };
  }

  getSourceUrl(postImageUrl: string): string {
    const postImageURL = new URL(
      postImageUrl.replace(this.previewImagePrefix, this.sourceImagePrefix)
    );
    const stoken = postImageURL.searchParams.get('stoken');
    if (stoken) {
      postImageURL.searchParams.set('token', stoken);
    }
    postImageURL.searchParams.delete('stoken');
    return postImageURL.toString();
  }

  showImages(postImageUrl: string): void {
    const postImageURL = new URL(postImageUrl);
    const postId = postImageURL.searchParams.get('postId');
    if (postId) {
      const galleryRef = this.gallery.ref(postId);
      const sourceImageUrl = this.getSourceUrl(postImageUrl);
      const imageItems = [
        new ImageItem({
          thumb: postImageUrl,
          src: sourceImageUrl,
        }),
      ];

      galleryRef.load(imageItems);

      this.lightbox.open(0, postId, {
        panelClass: 'fullscreenx',
      });
    }
  }

  showPostImages(videoId: string, postId: string, imageId: string): void {
    this.listPostImages(videoId, postId)
      .pipe(
        mergeMap((postImages) => {
          const index = postImages.findIndex(
            (postImage) => postImage.imageId === imageId
          );
          const imageItems = postImages.map(
            (postImage) =>
              new ImageItem({
                thumb: postImage.previewUrl,
                src: postImage.sourceUrl,
              })
          );
          const galleryRef = this.gallery.ref(postId);
          galleryRef.load(imageItems);

          this.lightbox.open(index, postId, {
            panelClass: 'fullscreenx',
          });

          return EMPTY;
        })
      )
      .subscribe();
  }

  onInit(context: Context): void {}

  onDestroy(context: Context): void {}

  findUserPostImage(
    videoId: string,
    userId: string,
    fileHash: string
  ): Observable<PostImage | null> {
    return this.firestore
      .collection<PostImage>(`channels/${videoId}/images`, (ref) => {
        return ref
          .where('userId', '==', userId)
          .where('fileHash', '==', fileHash);
      })
      .get()
      .pipe(
        mergeMap((querySnapshot) => {
          return Firestores.querySnapshotAsOneAndOnlyOf<PostImage>(
            querySnapshot
          );
          // return this.firestoreService.findOnlyOneFromQuerySnapshot<PostImage>(
          //   querySnapshot
          // );
        })
      );
  }

  listPostImages(videoId: string, postId: string): Observable<PostImage[]> {
    return this.firestore
      .collection<PostImage>(`channels/${videoId}/images`, (ref) => {
        return ref.where('postId', '==', postId).orderBy('createdAt', 'asc');
      })
      .get()
      .pipe(
        mergeMap((querySnapshot) => {
          return Firestores.querySnapshotAsListOf<PostImage>(querySnapshot);
          // console.log('>>>>>>>>>>>>>>> querySnapshot: ', querySnapshot.size);
          // return this.firestoreService.listFromQuerySnapshot<PostImage>(
          //   querySnapshot
          // );
        })
        // toArray()
      );
  }

  listImageUrls(videoId: string, postId: string): Observable<ImageUrls> {
    const dirPath = `channels/${videoId}/${postId}`;

    // TODO: Test
    this.fireStorage
      .ref(dirPath)
      .listAll()
      .pipe(
        mergeMap((listResult) => {
          const downloadUrls = listResult.items.map((item) =>
            item.getDownloadURL()
          );
          // downloadUrls.sort((a, b) => a.compare)
          // return of(downloadUrls);
          console.log('url: ', downloadUrls);

          return EMPTY;
        })
      )
      .subscribe();
    return EMPTY;
  }

  private getImageUrls(storageFiles: string[]): Observable<ImageUrls> {
    const filePath200Url = this.fireStorage
      .ref(storageFiles[1])
      .getDownloadURL();
    const filePath1000Url = this.fireStorage
      .ref(storageFiles[2])
      .getDownloadURL();

    return forkJoin([filePath200Url, filePath1000Url]).pipe(
      map((urls) => {
        const imageUrls: ImageUrls = {
          previewUrl: urls[0],
          sourceUrl: urls[1],
        };
        // return of(urls);
        return imageUrls;
      })
    );
  }

  getFileHash(file: File): Observable<string> {
    return from(this.computeChecksumMd5(file));
  }

  private computeChecksumMd5(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const chunkSize = 2097152; // Read in chunks of 2MB
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();

      let cursor = 0; // current cursor in file

      // tslint:disable-next-line: only-arrow-functions
      fileReader.onerror = () => {
        reject('MD5 computation failed - error reading the file');
      };

      // fileReader.onerror = function (): void {
      //   reject('MD5 computation failed - error reading the file');
      // };

      // read chunk starting at `cursor` into memory
      // tslint:disable-next-line: variable-name
      function processChunk(chunk_start: number): void {
        // tslint:disable-next-line: variable-name
        const chunk_end = Math.min(file.size, chunk_start + chunkSize);
        fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
      }

      // when it's available in memory, process it
      // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead
      // of `any` for `e` variable, otherwise stick with `any`
      // See https://github.com/Microsoft/TypeScript/issues/25510
      // tslint:disable-next-line: only-arrow-functions

      fileReader.onload = (e: any) => {
        spark.append(e.target.result); // Accumulate chunk to md5 computation
        cursor += chunkSize; // Move past this chunk

        if (cursor < file.size) {
          // Enqueue next chunk to be accumulated
          processChunk(cursor);
        } else {
          // Computation ended, last chunk has been processed. Return as Promise value.
          // This returns the base64 encoded md5 hash, which is what
          // Rails ActiveStorage or cloud services expect
          resolve(btoa(spark.end(true)));

          // If you prefer the hexdigest form (looking like
          // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
          // resolve(spark.end());
        }
      };

      // fileReader.onload = function (e: any): void {
      //   spark.append(e.target.result); // Accumulate chunk to md5 computation
      //   cursor += chunkSize; // Move past this chunk

      //   if (cursor < file.size) {
      //     // Enqueue next chunk to be accumulated
      //     processChunk(cursor);
      //   } else {
      //     // Computation ended, last chunk has been processed. Return as Promise value.
      //     // This returns the base64 encoded md5 hash, which is what
      //     // Rails ActiveStorage or cloud services expect
      //     resolve(btoa(spark.end(true)));

      //     // If you prefer the hexdigest form (looking like
      //     // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
      //     // resolve(spark.end());
      //   }
      // };

      processChunk(0);
    });
  }

  private createResizeImageStorageFiles(
    videoId: string,
    postId: string,
    imageId: string,
    extension: string
  ): string[] {
    const storageFile0 = `channels/${videoId}/${postId}/${imageId}${extension}`;
    const storageFile1 = `channels/${videoId}/${postId}/${imageId}_200x200${extension}`;
    const storageFile2 = `channels/${videoId}/${postId}/${imageId}_1200x630${extension}`;

    return [storageFile0, storageFile1, storageFile2];
  }

  name(): string {
    return 'images';
  }
}
