<div
  class="container debug-redx"
  fxLayout="row"
  fxLayoutAlign="space-around center"
>
  <div
    class="content debug-redx"
    fxLayout.gt-xs="row"
    fxLayoutGap.gt-xs="64px"
    fxLayoutAlign.gt-xs="start center"
    fxLayout.lt-sm="column"
    fxLayoutGap.lt-sm="16px"
    fxLayoutAlign.lt-sm="space-around start"
  >
    <div
      class="debug-redx"
      fxLayout="column"
      fxLayoutAlign="space-around start"
      fxLayoutGap="8px"
    >
      <div class="gomedialy-logo">GoMedialy</div>
      <div style="font-size: small; font-weight: bold; color: white">
        고미디어리 | 한글리쉬
      </div>
      <div style="font-size: small; font-weight: bold; color: white">
        문의: info@gomedialy.com
      </div>
      <!-- <div style="font-size: small; font-weight: bold; color: white">
        사업자 등록번호: 000-00-0000
      </div> -->
      <div style="font-size: small; font-weight: bold; color: gray">
        {{ version }}
      </div>
    </div>
    <div fxFlex.gt-xs></div>

    <div
      class="debug-redx"
      fxLayout="column"
      fxLayoutAlign="start center"
      fxLayoutGap="8px"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="space-around center"
        fxLayoutGap="32px"
      >
        <button
          mat-icon-button
          appDebounceClick
          (debounceClick)="onFacebookClick()"
        >
          <!-- <mat-icon svgIcon="facebook"></mat-icon> -->
          <fa-icon style="color: white" [icon]="faFacebook" size="3x"></fa-icon>
        </button>
        <button
          mat-icon-button
          appDebounceClick
          (debounceClick)="onTwitterClick()"
        >
          <!-- <mat-icon svgIcon="twitter"></mat-icon> -->
          <fa-icon style="color: white" [icon]="faTwitter" size="3x"></fa-icon>
        </button>
      </div>
    </div>

    <div fxFlex.gt-xs></div>
    <div
      class="debug-redx"
      fxLayout="column"
      fxLayoutAlign="space-around start"
      fxLayoutGap="8px"
    >
      <div style="font-size: medium; font-weight: bolder; color: white">
        정책 및 이용 안내
      </div>
      <div style="font-size: small; font-weight: bold; color: white">
        이용 약관
      </div>
      <div style="font-size: small; font-weight: bold; color: white">
        개인정보 처리방침
      </div>
    </div>
  </div>
</div>
