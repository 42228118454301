import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { PaginatorEventService } from './paginator-event.service';

/**
 * @author: john@gomedialy.com
 * @version: 0.11, 09/18/2020
 */
@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit, AfterViewInit {
  /* fields */
  @ViewChild('previous')
  previousButton?: MatButton;

  @ViewChild('next')
  nextButton?: MatButton;

  constructor(private paginatorEventService: PaginatorEventService) {
    this.paginatorEventService.initialize(this);
  }

  onPreviousClick(): void {
    // console.log('previous clicked.');
    this.paginatorEventService.emit({ action: 'previous' });
  }

  onNextClick(): void {
    // console.log('next clicked.');
    this.paginatorEventService.emit({ action: 'next' });
  }

  ngAfterViewInit(): void {
    // console.log('previous: ', this.previousButton);
    // console.log('next: ', this.nextButton);
  }

  ngOnInit(): void {}
}
