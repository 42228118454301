import { AfterViewInit, OnDestroy, OnInit, Directive } from '@angular/core';
import { Subscription } from 'rxjs';

/**
 * @author: john@gomedialy.com
 * @version: 0.15, 10/01/2020
 * @version: 0.16, 12/25/2020
 */
@Directive()
export class BaseComponent implements OnDestroy {
  /* fields */
  subscriptions = new Subscription();

  getVideoId(): string | null {
    return null;
  }

  /**
   * Override this to set the name.
   */
  protected name(): string {
    return 'Base';
  }

  ngOnDestroy(): void {
    // console.info(`${this.name()}: ngOnDestroy() Unsubscribing ...`);
    this.subscriptions.unsubscribe();
  }
}
