import {
  CollectionReference,
  AngularFirestore,
  DocumentChangeAction,
  QueryDocumentSnapshot,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { Observable, of, throwError, EMPTY, partition, from } from 'rxjs';
import {
  map,
  mergeMap,
  filter,
  switchMap,
  distinct,
  take,
  count,
  reduce,
} from 'rxjs/operators';
import { Post } from '../models/posts';
import { Arrays } from '../utils/guava';
import { PageRequest, PageResponse } from '../services/post.service';
import { Injectable } from '@angular/core';
import { PostQueries } from './post-queries';
import { AuthService } from '../services/auth.service';
// import Timestamp = firebase.firestore.Timestamp;
// import QuerySnapshot = firebase.firestore.QuerySnapshot;
// import { User } from '../models/user';
// import { FirestoreService } from '../services/firestore.service';
// import { toArray } from 'rxjs/operators';
import { UserPostQueries } from './user-post-queries';

/**
 * @author: john@gomedialy.com
 * @version: 0.1, 09/24/2020
 * @version: 0.11, 10/28/2020
 */
@Injectable({
  providedIn: 'root',
})
export class PostGroupQueries {
  /* fields */

  /**
   * QueryDocumentSnapshot<Post> is not serializable on ngrx.
   * Store it here and use documentId on ngrx, instead.
   */
  private partitionMap = new Map<string, QueryDocumentSnapshot<Post>>();

  constructor(
    private angularFirestore: AngularFirestore,
    private userPostQueries: UserPostQueries
  ) {}

  listenToTop10GroupPosts(
    pageRequest: PageRequest,
    groupIds: string[]
  ): Observable<PageResponse | null> {
    return this.forTop10GroupPosts(pageRequest, groupIds)
      .snapshotChanges()
      .pipe(
        switchMap((documentChangeActions: DocumentChangeAction<Post>[]) => {
          // console.log('>>>> change: ', documentChangeActions);
          // let isSubtitles = false;
          // switch (pageRequest.category) {
          //   case 'all':
          //     break;
          //   case 'subtitle':
          //     isSubtitles = true;
          //     break;
          //   case 'mine':
          //     break;
          // }

          return this.createPageResponse(
            pageRequest.videoId,
            pageRequest,
            documentChangeActions,
            groupIds
          );
        })
      );
  }

  forTop10GroupPosts(
    pageRequest: PageRequest,
    groupIds: string[]
  ): AngularFirestoreCollection<Post> {
    const videoId = pageRequest.videoId;
    let userId = '';
    if (pageRequest.userId) {
      userId = pageRequest.userId;
    }
    let isSubtitles = false;
    switch (pageRequest.category) {
      case 'all':
        break;
      case 'subtitle':
        isSubtitles = true;
        break;
      case 'mine':
        break;
    }
    const path = `channels/${videoId}/posts`;
    const pageSize = pageRequest.pageSize;
    const orderBy = pageRequest.orderBy;
    const partition = pageRequest.partition;

    return this.angularFirestore.collection<Post>(path, (ref: any) => {
      switch (pageRequest.direction) {
        case 'previous':
          if (partition) {
            return this.previousPostsQuery(
              ref,
              // videoId,
              pageSize,
              isSubtitles,
              orderBy,
              // userId,
              partition,
              groupIds
            );
          } else {
            throw new Error(`previous requires partition.`);
          }
          break;
        case 'next':
          return this.nextPostsQuery(
            ref,
            // videoId,
            pageSize,
            isSubtitles,
            orderBy,
            // userId,
            groupIds,
            partition
          );
          break;
      }
    });
  }

  /**
   * @param ref
   * @param videoId
   * @param pageSize
   * @param isSubtitles
   * @param partition
   */
  private previousPostsQuery(
    ref: CollectionReference,
    // videoId: string,
    pageSize: number,
    isSubtitles: boolean,
    orderBy: 'asc' | 'desc',
    partition: string,
    // userId: string,
    groupIds: string[]
  ): firebase.firestore.Query<firebase.firestore.DocumentData> {
    const partitionDoc = this.partitionMap.get(partition);
    if (isSubtitles) {
      return (
        ref
          // .where('videoId', '==', videoId)
          .where('category', '==', 'subtitle')
          // .where('userId', '==', userId)
          .where('groupId', 'in', groupIds)
          .orderBy('groupedAt', orderBy)
          .endBefore(partitionDoc)
          .limitToLast(pageSize)
      );
    }
    return (
      ref
        // .where('videoId', '==', videoId)
        // .where('userId', '==', userId)
        .where('groupId', 'in', groupIds)
        .orderBy('groupedAt', orderBy)
        .endBefore(partitionDoc)
        .limitToLast(pageSize)
    );
  }

  private nextPostsQuery(
    ref: CollectionReference,
    // videoId: string,
    pageSize: number,
    isSubtitles: boolean,
    orderBy: 'asc' | 'desc',
    // userId: string,
    groupIds: string[],
    partition?: string
  ): firebase.firestore.Query<firebase.firestore.DocumentData> {
    if (isSubtitles) {
      if (partition) {
        const partitionDoc = this.partitionMap.get(partition);
        return (
          ref
            // .where('videoId', '==', videoId)
            .where('category', '==', 'subtitle')
            .where('groupId', 'in', groupIds)
            // .where('userId', '==', userId)
            .orderBy('groupedAt', orderBy)
            .startAfter(partitionDoc)
            .limit(pageSize)
        );
      } else {
        return (
          ref
            // .where('videoId', '==', videoId)
            .where('category', '==', 'subtitle')
            .where('groupId', 'in', groupIds)
            // .where('userId', '==', userId)
            .orderBy('groupedAt', orderBy)
            .limit(pageSize)
        );
      }
    }
    if (partition) {
      const partitionDoc = this.partitionMap.get(partition);
      return (
        ref
          // .where('videoId', '==', videoId)
          // .where('userId', '==', userId)
          .where('groupId', 'in', groupIds)
          .orderBy('groupedAt', orderBy)
          .startAfter(partitionDoc)
          .limit(pageSize)
      );
    } else {
      return (
        ref
          // .where('videoId', '==', videoId)
          .where('groupId', 'in', groupIds)
          // .where('userId', '==', userId)
          .orderBy('groupedAt', orderBy)
          .limit(pageSize)
      );
    }
  }

  createPageResponse(
    videoId: string,
    pageRequest: PageRequest,
    documentChangeActions: DocumentChangeAction<Post>[],
    groupIds: string[]
  ): Observable<PageResponse | null> {
    // TODO:
    if (Arrays.isEmpty(documentChangeActions)) {
      return of(null);
    }
    const docs = documentChangeActions.map(
      (documentChangeAction) => documentChangeAction.payload.doc
    );
    const start = docs[0];
    // To support a single element of data
    const end = docs.length === 1 ? start : docs[docs.length - 1];
    const posts = docs.map((doc) => doc.data() as Post);

    /**
     * Temporary storage for QueryDocumentSnapshot<Post>
     */
    this.partitionMap.set(start.id, start);
    this.partitionMap.set(end.id, end);
    // console.log('partition-map: ', this.partitionMap.size);

    switch (pageRequest.direction) {
      case 'previous':
        const checkPreviousPageRequest: PageRequest = {
          // isFirstPageRequest, hasPreviousPageRequest
          ...pageRequest,
          pageSize: 1,
          partition: start.id,
        };
        return this.forTop10GroupPosts(checkPreviousPageRequest, groupIds)
          .get()
          .pipe(
            PostQueries.createPreviousPageResponse(videoId, start, end, posts)
          );
        break;
      case 'next':
        const checkNextPageRequest: PageRequest = {
          // isLastPageRequest, hasNextPageRequest
          ...pageRequest,
          pageSize: 1,
          partition: end.id,
        };
        return this.forTop10GroupPosts(checkNextPageRequest, groupIds)
          .get()
          .pipe(
            PostQueries.createNextPageResponse(
              videoId,
              start,
              end,
              posts,
              pageRequest.partition
            )
          );
        break;
    }
    return throwError(new Error(`Impossible error`));
  }
}
