import { createAction, props } from '@ngrx/store';
import { User } from '../models/user';
import { JSend } from '../models/jsend';

/**
 * [Auth] login-request, logout-request, login-response, logout-response
 * [Auth] State-Changedlogged-in, state-logged-out
 *
 * @author: john@gomedialy.com
 * @version: 0.13, 08/15/2020
 * @version: 0.14, 12/13/2020
 */

/**
 * Called frequently.
 * We could do a signup with this. However, loginRedirectResult is better.
 */
export const authStateChanged = createAction(
  '[Auth] State Changed',
  props<{ user: User | null }>()
);

export const loginRequired = createAction('[Auth] Login Required');

/**
 * Called only on login. This is better for a signup handling.
 */
export const signupRequest = createAction(
  '[Auth] Signup Request',
  props<{ user: User }>()
);

export const signupResponse = createAction(
  '[Auth] Signup Response',
  props<{ jsend: JSend }>()
);

export const loginRequest = createAction('[Auth] Login Request');
export const loginResponse = createAction(
  '[Auth] Login Response',
  props<{ jsend: JSend }>()
);

export const logoutRequest = createAction('[Auth] Logout Request');
export const logoutResponse = createAction(
  '[Auth] Logout Response',
  props<{ jsend: JSend }>()
);
