import { Injectable, Output, EventEmitter } from '@angular/core';
import { PaginatorComponent } from './paginator.component';

/**
 * @author: john@gomedialy.com
 * @version: 0.13, 09/20/2020
 */
export interface PaginatorEvent {
  action: 'init' | 'previous' | 'next';
}

@Injectable({
  providedIn: 'root',
})
export class PaginatorEventService {
  /* fields */
  events = new EventEmitter<PaginatorEvent>();
  private paginatorComponents: PaginatorComponent[] = [];
  hasPrevious = false;
  hasNext = false;

  constructor() {}

  initialize(paginatorComponent: PaginatorComponent): void {
    this.paginatorComponents.push(paginatorComponent);
  }

  setPreviousEnabled(enabled: boolean): void {
    this.paginatorComponents.forEach((paginatorComponent) => {
      if (paginatorComponent.previousButton) {
        paginatorComponent.previousButton.disabled = !enabled;
      }
    });
    this.hasPrevious = enabled;
  }

  setNextEnabled(enabled: boolean): void {
    this.paginatorComponents.forEach((paginatorComponent) => {
      if (paginatorComponent.nextButton) {
        paginatorComponent.nextButton.disabled = !enabled;
      }
    });
    this.hasNext = enabled;
  }

  emit(paginatorEvent: PaginatorEvent): void {
    this.events.emit(paginatorEvent);
  }
}
