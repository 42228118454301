import { createAction, props } from '@ngrx/store';
import { JSend } from '../models/jsend';
import { Video } from '../models/posts';

/**
 * @author: john@gomedialy.com
 * @version: 0.13, 10/08/2020
 * @version: 0.14, 01/07/2021
 */
export const pushVideo = createAction(
  '[Stack] Push Video',
  props<{
    video: Video;
  }>()
);

export const popVideo = createAction('[Stack] Pop Video');

export const clearVideos = createAction('[Stack] Clear Videos');

export const closeVideos = createAction('[Stack] Close Videos');

// export const pushVideoRequest = createAction(
//   '[Stack] Push Video Request',
//   props<{
//     video: Video;
//   }>()
// );

// export const pushVideoResponse = createAction(
//   '[Stack] Push Video Response',
//   props<{ jsend: JSend }>()
// );

// export const popVideoRequest = createAction(
//   '[Stack] Pop Video Request'
//   // props<{
//   //   video: Subvideo;
//   // }>()
// );

// export const popVideoResponse = createAction(
//   '[Stack] Pop Video Response',
//   props<{ jsend: JSend }>()
// );

// // To home
// export const clearVideosRequest = createAction(
//   '[Stack] Clear Videos Request'
//   // props<{
//   //   video: Subvideo;
//   // }>()
// );

// export const clearVideosResponse = createAction(
//   '[Stack] Clear Videos Response',
//   props<{ jsend: JSend }>()
// );

// export const closeVideosRequest = createAction(
//   '[Stack] Close Videos Request'
//   // props<{
//   //   video: Subvideo;
//   // }>()
// );

// export const closeVideosResponse = createAction(
//   '[Stack] Close Videos Response',
//   props<{ jsend: JSend }>()
// );
