import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BaseComponent } from '../base.component';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import {
  imageEditorFeatureKey,
  ImageEditorState,
} from '../reducers/image-editor.reducer';
import { JSend } from '../models/jsend';
import { ImagesService, ProgressListener } from '../services/images.service';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import {
  editImageResponse,
  editImageRequest,
} from '../actions/image-editor.actions';
import { mergeMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { PostImageUrl } from '../models/images';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeIn } from 'ng-animate';

/**
 * Ex) https://www.youtube.com/watch?v=fZS-7tcJ_X4
 * @author: john@gomedialy.com
 * @version: 0.14, 10/15/2020
 * @version: 0.17, 01/30/2021
 */
@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss'],
  // animations: [
  //   trigger('fadeIn', [transition('* <=> *', useAnimation(fadeIn))]),
  // ],
})
export class ImageEditorComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, ProgressListener {
  /* fields */
  // fadeIn: any;
  private fileSizeLimit = 1024 * 1000;

  // can not be null.
  @Input('input')
  inputImageEditorState!: ImageEditorState;
  videoId!: string;
  postId!: string;

  // inputPost!: Post;
  finishFormGroup!: FormGroup;

  isEditable = false;
  selectionIndex = 0;
  videoUrlOkButtonEnabled = false;
  startSecondsOkButtonEnabled = true;
  endSecondsOkButtonEnabled = false;

  videoImageUrl: string | null = 'assets/images/photo_black_48dp.png';
  value = '';

  postImageUrl: PostImageUrl | null = null;
  selectedFile: File | null = null;

  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'determinate';
  progressValue = 0;
  isUploadButtonEnabled = false;

  constructor(
    private imageEditorStore: Store<{
      [imageEditorFeatureKey]: ImageEditorState;
    }>,
    private formBuilder: FormBuilder,
    private imagesService: ImagesService,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit(): void {
    switch (this.inputImageEditorState.type) {
      case editImageRequest.type.toString():
        if (
          this.inputImageEditorState.videoId &&
          this.inputImageEditorState.postId
        ) {
          this.videoId = this.inputImageEditorState.videoId;
          this.postId = this.inputImageEditorState.postId;
        }
        break;
    }

    // Step 3
    this.finishFormGroup = this.formBuilder.group({
      postImageUrlControl: [{ value: '', disabled: false }],
    });
  }

  /**
   * All the data are directly linked to ui changes.
   * Therefore, things should be done in ngAfterViewInit(), not ngOnInit();
   */
  ngAfterViewInit(): void {}

  onPreviewImageClick(): void {
    if (this.postImageUrl) {
      this.imagesService.showImages(this.postImageUrl.previewUrl);
    }
  }

  /**
   * ProgressListener
   * @param bytesTransferred
   * @param totalBytes
   */
  onProgress(bytesTransferred: number, totalBytes: number): void {
    /**
     * The first half is done here.
     */
    // this.progressValue = (bytesTransferred / totalBytes) * 100;
    this.progressValue = (bytesTransferred / totalBytes) * 50;
  }

  onRetry(): void {
    /**
     * The second half is done here with each retry.
     */
    this.progressValue = this.progressValue + 5;
  }

  /**
   * ProgressListener
   * @param postImage
   */
  onComplete(postImage: PostImageUrl): void {
    // the rest 10 % done here.
    this.progressValue = 100;
  }

  onUploadClick(): void {
    if (this.selectedFile && this.videoId && this.postId) {
      const file = this.selectedFile;
      this.imagesService
        .getFileHash(this.selectedFile)
        .pipe(
          // TODO: more checks with PostImages
          mergeMap((fileHash) => {
            this.isUploadButtonEnabled = false;
            return this.imagesService.upload(
              this.videoId,
              this.postId,
              file,
              fileHash,
              this
            );
          })
        )
        .subscribe(
          (postImageUrl) => {
            this.postImageUrl = postImageUrl;
          },
          (error) => console.error(error)
        );
    }
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    const user = this.authService.getUser();
    if (user) {
      this.imagesService
        .getFileHash(file)
        .pipe(
          mergeMap((fileHash) => {
            this.isUploadButtonEnabled = true;
            /**
             * To see if users upload duplicate files.
             */
            return this.imagesService
              .findUserPostImage(this.videoId, user.userId, fileHash)
              .pipe(
                mergeMap((postImage) => {
                  // console.log('postImage: ', postImage);

                  if (postImage) {
                    this.postImageUrl = this.imagesService.createPostImageUrl(
                      postImage
                    );
                  } else {
                    this.postImageUrl = null;
                  }
                  return EMPTY;
                })
              );
          })
        )
        .subscribe();
    }

    if (file.size < this.fileSizeLimit) {
      this.selectedFile = file;
    }
  }

  selectionChange(event: StepperSelectionEvent): void {
    this.selectionIndex = event.selectedIndex;

    switch (this.selectionIndex) {
      // switch (event.selectedIndex) {
      case 0:
        break;
      case 2:
        this.setFinishFormValues();
        break;
    }
  }

  private setFinishFormValues(): void {
    if (this.postImageUrl) {
      this.finishFormGroup.controls['postImageUrlControl'].setValue(
        this.postImageUrl.previewUrl
      );
    }
  }

  onStartSecondsOkClick(): void {}

  onEndSecondsOkClick(): void {}

  onCancelClick(): void {
    this.imageEditorStore.dispatch(
      editImageResponse({ jsend: JSend.fail({ failId: 'cancel' }) })
    );
  }

  onFinishClick(): void {
    // console.log('onFinishClick(): ', this.postImageUrl);
    // TODO: Test
    // this.imagesService.listImageUrls(this.inputPost.videoId, this.inputPost.postId);

    this.imageEditorStore.dispatch(
      editImageResponse({
        jsend: JSend.success(this.postImageUrl),
      })
    );
  }

  name(): string {
    return 'image-editor';
  }
}
