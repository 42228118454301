import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Store } from '@ngrx/store';
import { Observable, EMPTY, Subject, from } from 'rxjs';
import { BaseService, Context } from '../base.service';
import { mainFeatureKey, MainState } from '../reducers/main.reducer';
import firebase from 'firebase/compat/app';
import { AuthService } from './auth.service';
import { NGXLogger } from 'ngx-logger';
import { concatMap } from 'rxjs/operators';
import { HttpService } from './http.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';

/**
 * @author: john@gomedialy.com
 * @version: 0.15, 11/24/2020
 */
export interface UserPresence {
  uid: string;
  type: 'joined' | 'left';
  isAnonymous: boolean;
  updatedAt: number;
  name?: string;
  photoUrl?: string;
}

export interface ChannelInfo {
  channelId: string;
}
@Injectable({
  providedIn: 'root',
})
export class PresenceService extends BaseService {
  /* fields */
  constructor(
    mainStore: Store<{
      [mainFeatureKey]: MainState;
    }>,
    protected logger: NGXLogger,
    private authService: AuthService,
    private db: AngularFireDatabase,
    private httpService: HttpService,
    private afirestore: AngularFirestore
  ) {
    // super(mainStore);
    super(mainStore, logger);
    // console.log('>>>>>>>>>>>>>>> functions: ', functions);
  }

  /**
   * Ex) videos/{videoId}/viewCount
   * @param valuePath
   */
  valueChanges<T>(valuePath: string): Observable<T | null> {
    // return from(
    //   new Promise<T | null>((resolve, reject) => {
    //     firebase
    //       .database()
    //       .ref(valuePath)
    //       .on(
    //         'value',
    //         (snapshot) => {
    //           resolve(snapshot.val());
    //         },
    //         // tslint:disable-next-line: no-any
    //         (error: any) => {
    //           reject(error);
    //         }
    //       );
    //   })
    // );

    return this.db.object<T>(valuePath).valueChanges();
  }

  joinChannel(channelId: string): void {
    const user = this.authService.getUser();
    if (user) {
      const uid = user.uid;
      from(
        this.afirestore
          .doc<ChannelInfo>(`user2channel/${uid}`)
          .set({ channelId }, { merge: true })
      ).subscribe();
    }
  }

  leaveChannel(channelId: string): void {
    const user = this.authService.getUser();
    if (user) {
      const uid = user.uid;
      from(
        this.afirestore.doc<ChannelInfo>(`user2channel/${uid}`).delete()
      ).subscribe();
    }
  }

  connect(): void {
    // Fetch the current user's ID from Firebase Authentication.
    // const uid = firebase.auth().currentUser.uid;
    const user = this.authService.getUser();
    if (user) {
      const uid = user.uid;
      // Create a reference to this user's specific status node.
      // This is where we will store data about being online/offline.
      const userStatusDatabaseRef = firebase.database().ref('/status/' + uid);
      // We'll create two constants which we will write to
      // the Realtime database when this device is offline
      // or online.
      const isOfflineForDatabase = {
        state: 'offline',
        updatedAt: firebase.database.ServerValue.TIMESTAMP,
      };
      const isOnlineForDatabase = {
        state: 'online',
        updatedAt: firebase.database.ServerValue.TIMESTAMP,
      };
      // ...
      const userStatusFirestoreRef = firebase.firestore().doc('/status/' + uid);
      // Firestore uses a different server timestamp value, so we'll
      // create two more constants for Firestore state.
      const isOfflineForFirestore = {
        state: 'offline',
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
      const isOnlineForFirestore = {
        state: 'online',
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
      firebase
        .database()
        .ref('.info/connected')
        .on('value', (snapshot) => {
          if (snapshot.val() === false) {
            // Instead of simply returning, we'll also set Firestore's state
            // to 'offline'. This ensures that our Firestore cache is aware
            // of the switch to 'offline.'
            userStatusFirestoreRef.set(isOfflineForFirestore);
            return;
          }
          userStatusDatabaseRef
            .onDisconnect()
            .set(isOfflineForDatabase)
            .then(() => {
              userStatusDatabaseRef.set(isOnlineForDatabase);
              // We'll also add Firestore set here for when we come online.
              userStatusFirestoreRef.set(isOnlineForFirestore);
            });
        });
    }
  }

  // connectOld(): void {
  //   // Fetch the current user's ID from Firebase Authentication.
  //   // const uid = firebase.auth().currentUser.uid;
  //   const user = this.authService.getUser();
  //   if (user) {
  //     const uid = user.uid;
  //     // Create a reference to this user's specific status node.
  //     // This is where we will store data about being online/offline.
  //     const userStatusDatabaseRef = firebase.database().ref('/status/' + uid);

  //     // We'll create two constants which we will write to
  //     // the Realtime database when this device is offline
  //     // or online.
  //     const isOfflineForDatabase = {
  //       state: 'offline',
  //       updatedAt: firebase.database.ServerValue.TIMESTAMP,
  //     };

  //     const isOnlineForDatabase = {
  //       state: 'online',
  //       updatedAt: firebase.database.ServerValue.TIMESTAMP,
  //     };

  //     // Create a reference to the special '.info/connected' path in
  //     // Realtime Database. This path returns `true` when connected
  //     // and `false` when disconnected.
  //     firebase
  //       .database()
  //       .ref('.info/connected')
  //       .on('value', (snapshot) => {
  //         // If we're not currently connected, don't do anything.
  //         if (snapshot.val() === false) {
  //           return;
  //         }

  //         // If we are currently connected, then use the 'onDisconnect()'
  //         // method to add a set which will only trigger once this
  //         // client has disconnected by closing the app,
  //         // losing internet, or any other means.
  //         userStatusDatabaseRef
  //           .onDisconnect()
  //           .set(isOfflineForDatabase)
  //           .then(() => {
  //             // The promise returned from .onDisconnect().set() will
  //             // resolve as soon as the server acknowledges the onDisconnect()
  //             // request, NOT once we've actually disconnected:
  //             // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

  //             // We can now safely set ourselves as 'online' knowing that the
  //             // server will mark us as offline once we lose connection.
  //             userStatusDatabaseRef.set(isOnlineForDatabase);
  //           });
  //       });
  //   }
  // }

  onInit(context: Context): void {}

  onDestroy(context: Context): void {}

  name(): string {
    return 'presence';
  }
}
