import { Action, createReducer, on, props } from '@ngrx/store';
import * as AuthActions from '../actions/auth.actions';
import { User } from '../models/user';
import { JSend } from '../models/jsend';

/**
 * @author: john@gomedialy.com
 * @version: 0.17, 09/21/2020
 * @version: 0.19, 12/13/2020
 */
export const authFeatureKey = 'auth';

export interface AuthState {
  type: string | null;
  user: User | null;
  jsend: JSend | null;
}

export const initialState: AuthState = {
  type: null,
  user: null,
  jsend: null,
};

export const authReducer = createReducer(
  initialState,
  /**
   * disabled signup-request payload not to have any side-effects
   */
  on(AuthActions.signupRequest, (state, action) => {
    // return { ...state, ...action, jsend: null };
    return { ...state, jsend: null };
  }),
  /**
   * disabled signup-request payload not to have any side-effects
   */
  on(AuthActions.signupResponse, (state, action) => {
    // return { ...state, ...action, jsend: action.jsend };
    return { ...state, jsend: action.jsend };
  }),
  on(AuthActions.loginRequest, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),
  on(AuthActions.loginResponse, (state, action) => {
    return { ...state, ...action, jsend: action.jsend };
  }),
  on(AuthActions.logoutRequest, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),
  on(AuthActions.logoutResponse, (state, action) => {
    return { ...state, ...action, jsend: action.jsend, user: null };
  }),
  on(AuthActions.authStateChanged, (state, action) => {
    return { ...state, ...action };
  }),
  on(AuthActions.loginRequired, (state, action) => {
    return { ...state, ...action };
  })
);
