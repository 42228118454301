<mat-button-toggle-group
  id="button-group"
  #group="matButtonToggleGroup"
  [(ngModel)]="selected"
  (change)="onButtonToggleChange($event)"
>
  <!-- No ngFor since it hurts UI, Everything must be done this way -->
  <mat-button-toggle
    *ngIf="buttonItems[0]"
    value="{{ buttonItems[0].action }}"
    matTooltip="{{ buttonItems[0].tooltip }}"
  >
    <div *ngIf="buttonItems[0].matIcon">
      <mat-icon class="icon-fontx" [ngStyle]="buttonItems[0].style">{{
        buttonItems[0].matIcon
      }}</mat-icon>
    </div>
    <div *ngIf="buttonItems[0].svgIcon">
      <mat-icon
        class="icon-fontx"
        svgIcon="{{ buttonItems[0].svgIcon }}"
      ></mat-icon>
    </div>
  </mat-button-toggle>
  <mat-button-toggle
    *ngIf="buttonItems[1]"
    value="{{ buttonItems[1].action }}"
    matTooltip="{{ buttonItems[1].tooltip }}"
  >
    <!-- <mat-icon class="icon-font" svgIcon="{{ buttonItems[1].matIcon }}">{{
      buttonItems[1].matIcon
    }}</mat-icon> -->
    <div *ngIf="buttonItems[1].matIcon">
      <mat-icon class="icon-fontx" [ngStyle]="buttonItems[1].style">{{
        buttonItems[1].matIcon
      }}</mat-icon>
    </div>
    <div *ngIf="buttonItems[1].svgIcon">
      <mat-icon
        class="icon-fontx"
        svgIcon="{{ buttonItems[1].svgIcon }}"
      ></mat-icon>
    </div>
  </mat-button-toggle>
  <mat-button-toggle
    *ngIf="buttonItems[2]"
    value="{{ buttonItems[2].action }}"
    matTooltip="{{ buttonItems[2].tooltip }}"
  >
    <!-- <mat-icon class="icon-font" svgIcon="{{ buttonItems[2].matIcon }}">{{
      buttonItems[2].matIcon
    }}</mat-icon> -->

    <div *ngIf="buttonItems[2].matIcon">
      <mat-icon class="icon-fontx" [ngStyle]="buttonItems[2].style">{{
        buttonItems[2].matIcon
      }}</mat-icon>
    </div>
    <div *ngIf="buttonItems[2].svgIcon">
      <mat-icon
        class="icon-fontx"
        svgIcon="{{ buttonItems[2].svgIcon }}"
      ></mat-icon>
    </div>
  </mat-button-toggle>
  <mat-button-toggle
    *ngIf="buttonItems[3]"
    value="{{ buttonItems[3].action }}"
    matTooltip="{{ buttonItems[3].tooltip }}"
  >
    <!-- <mat-icon class="icon-font" svgIcon="{{ buttonItems[3].matIcon }}">{{
      buttonItems[3].matIcon
    }}</mat-icon> -->
    <div *ngIf="buttonItems[3].matIcon">
      <mat-icon class="icon-fontx" [ngStyle]="buttonItems[3].style">{{
        buttonItems[3].matIcon
      }}</mat-icon>
    </div>
    <div *ngIf="buttonItems[3].svgIcon">
      <mat-icon
        class="icon-fontx"
        svgIcon="{{ buttonItems[3].svgIcon }}"
      ></mat-icon>
    </div>
  </mat-button-toggle>
</mat-button-toggle-group>
