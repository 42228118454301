/**
 * @author: john@gomedialy.com
 * @version: 0.13, 09/17/2020
 * @version: 0.15, 01/13/2021
 */
export class Paths {
  private constructor() {}

  // post.path = `channels/${post.videoId}/posts/${post.postId}`;
  public static root2(videoId: string, postId: string): string {
    return `channels/${videoId}/posts/${postId}`;
  }

  public static root(path: string): string {
    /**
     * Ex) channels/2eY6M9ujPjU/posts/ysZMN4xZ9Rjl5nPKVjGML/posts/QITfjcnZ8v7dWXTRg6oC3
     * count of 'posts' is 3
     */
    if (Paths.level(path) === 0) {
      // root itself
      return path;
    }
    const firstIndex = path.indexOf('/posts/');
    const secondIndex = path.indexOf('/posts/', firstIndex + 7); // 7: '/posts/'
    return path.substring(0, secondIndex);
  }

  public static parent(path: string): string | null {
    /**
     * Ex) channels/2eY6M9ujPjU/posts/ysZMN4xZ9Rjl5nPKVjGML/posts/QITfjcnZ8v7dWXTRg6oC3
     * count of 'posts' is 3
     */
    if (Paths.level(path) === 0) {
      return null;
    }

    const lastIndex = path.lastIndexOf('/posts/');
    return path.substring(0, lastIndex);
  }

  public static child(parentPath: string, postId: string): string {
    if (Paths.isPath(parentPath)) {
      return `${parentPath}/posts/${postId}`;
    }
    throw new Error(`Invalid parent-path: ${parentPath}`);
  }

  // post.path = `channels/${post.videoId}/posts/${post.postId}`;
  public static videoId(path: string): string {
    if (path.startsWith('/') || path.endsWith('/')) {
      throw new Error(`Invalid path: ${path}`);
    }
    const tokens = path.split('/');
    const length = tokens.length;
    if (length >= 4) {
      // second one
      return tokens[1];
    }
    throw new Error(`Invalid path: ${path}`);
  }

  public static postId(path: string): string {
    if (path.startsWith('/') || path.endsWith('/')) {
      throw new Error(`Invalid path: ${path}`);
    }
    const tokens = path.split('/');
    const length = tokens.length;
    if (length >= 4) {
      // last one
      return tokens[length - 1];
    }
    throw new Error(`Invalid path: ${path}`);
  }

  public static level(path: string): number {
    if (path.startsWith('/') || path.endsWith('/')) {
      throw new Error(`Invalid path: ${path}`);
    }
    const length = path.split('/').length;
    if (length >= 4) {
      /**
       * Ex) channels/2eY6M9ujPjU/posts/ysZMN4xZ9Rjl5nPKVjGML/posts/QITfjcnZ8v7dWXTRg6oC3
       * count of 'posts' is 3
       */
      const count = (path.match(/posts/g) || []).length;
      if (count > 0) {
        /**
         * ! what the fuck was I thinking?
         */
        // return count - 2;
        return count - 1;
      }
      return 0;
    }
    throw new Error(`Invalid path: ${path}`);
  }

  public static isPath(path: string): boolean {
    return Paths.level(path) >= 0;
  }
}
