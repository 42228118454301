import { Action, createReducer, on, props } from '@ngrx/store';
import * as VideoEditorActions from '../actions/video-editor.actions';
import { JSend } from '../models/jsend';

/**
 * @author: john@gomedialy.com
 * @version: 0.15, 10/31/2020
 */
// export const videoEditorFeatureKey = 'video-editor';
export const videoEditorFeatureKey = 'videoEditor';

export interface VideoEditorState {
  type: string | null;
  videoId: string | null;
  postId: string | null;
  // subvideoId: string | null;
  // startSeconds: number;
  // duration: number;
  tags: string[];
  jsend: JSend | null;
}

export const initialState: VideoEditorState = {
  type: null,
  videoId: null,
  postId: null,
  // subvideoId: null,
  // startSeconds: 0,
  // duration: 0,
  tags: [],
  jsend: null,
};

export const videoEditorReducer = createReducer(
  initialState,
  // Subtitle
  // on(VideoEditorActions.editSubtitleRequest, (state, action) => {
  //   return { ...state, ...action, jsend: null };
  // }),
  // on(VideoEditorActions.editSubtitleResponse, (state, action) => {
  //   return { ...state, ...action, jsend: action.jsend };
  // }),
  // Subvideo
  on(VideoEditorActions.editSubvideoRequest, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),
  on(VideoEditorActions.editSubvideoResponse, (state, action) => {
    return { ...state, ...action, jsend: action.jsend };
  }),

  on(VideoEditorActions.clearVideoEditor, (state, action) => {
    return { ...initialState };
  })
);
