import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Objects } from '../utils/objects';
import { HttpService } from './http.service';
import { mergeMap } from 'rxjs/operators';
import { Strings } from '../utils/strings';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NGXLogger } from 'ngx-logger';
// import { MatSnackBar } from '@angular/material/snack-bar';
import { Item } from '../models/youtube-data';

/**
 * @author: john@gomedialy.com
 * @version: 0.13, 01/24/2021
 * @version: 0.14, 03/16/2021
 */

/**
 * For kakao.min.js
 */
declare var Kakao: any;

@Injectable({
  providedIn: 'root',
})
export class KakaoService {
  constructor(
    private logger: NGXLogger,
    private httpService: HttpService,
    // private snackBar: MatSnackBar,
    @Inject(DOCUMENT) private document: Document
  ) {
    const kakaoConfig = Objects.fromBase64Json<any>(environment.kakao);
    Kakao.init(kakaoConfig.javascriptKey);
  }

  // // TODO:
  // private log(text: string): void {
  //   this.snackBar.open(text, 'ok', { duration: 3000 });
  // }

  sendLink(item: Item): void {
    const videoId = item.videoId;
    if (item.videoId) {
      this.httpService
        .getVideoImageUrl(item.videoId)
        .pipe(
          mergeMap((imageUrl) => {
            if (imageUrl) {
              this.logger.debug('[KAKAO] ', imageUrl);

              const title = `[#Haanglish 인기검색어:'#${
                item.query
              }'] - ${Strings.decodeHtml(this.document, item.snippet.title)}`;
              const description = Strings.decodeHtml(
                this.document,
                item.snippet.description
              );

              const watchUrl = `${environment.gomedialyBaseUrl}/watch?v=${videoId}`;
              Kakao.Link.sendDefault({
                objectType: 'feed',
                content: {
                  title,
                  description,
                  imageUrl,
                  link: {
                    webUrl: watchUrl,
                    mobileWebUrl: watchUrl,
                  },
                },
              });
            }
            return EMPTY;
          })
        )
        .subscribe(
          (v) => {
            // console.error(v);
          },
          (error) => this.logger.warn(error)
        );
    }
  }
}
