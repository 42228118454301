import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { HomeComponent } from './home/home.component';
import { WatchComponent } from './watch/watch.component';
import { CustomSerializer } from './custom-route-serializer';
// import { MainComponent } from './main/main.component';
import { BrowseComponent } from './browse/browse.component';
import { Home2Component } from './home2/home2.component';

const routes: Routes = [
  { path: '', component: Home2Component },
  // { path: '', component: MainComponent },
  // { path: 'home', component: HomeComponent },
  { path: 'videos/:id', component: WatchComponent },
  { path: 'watch', component: WatchComponent },
  { path: 'browse', component: BrowseComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];
// { path: '**', component: HomeComponent },
// { path: '**', redirectTo: '/', pathMatch: 'full' },
// { path: '', redirectTo: '/home', pathMatch: 'full' },

/**
 * @author: john@gomedialy.com
 * @version: 0.14, 09/05/2020
 * @version: 0.22, 12/13/2020
 */
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Restore the last scroll position
      scrollPositionRestoration: 'top',
      // scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 0],
      initialNavigation: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
