import { Store } from '@ngrx/store';
import { Subscription, EMPTY } from 'rxjs';
import { mainFeatureKey, MainState } from './reducers/main.reducer';
import { mergeMap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import {
  browseRefreshed,
  homeRefreshed,
  watchRefreshed,
} from './actions/main.actions';
import { routeChanged } from './actions/main.actions';

/**
 * @author: john@gomedialy.com
 * @version: 0.12, 09/27/2020
 * @version: 0.19, 01/12/2021
 */
export interface Context {
  // TODO: more
  path?: string;
  videoId: string;
  tags: string[];
}

// @Directive()
export abstract class BaseService {
  /* fields */
  protected subscriptions: Subscription[] = [];

  private EMPTY_CONTEXT: Context = {
    path: '',
    videoId: '',
    tags: [],
  };

  private context: Context = this.EMPTY_CONTEXT;

  constructor(
    mainStore: Store<{
      [mainFeatureKey]: MainState;
    }>,
    protected logger: NGXLogger
  ) {
    mainStore
      .select(mainFeatureKey)
      .pipe(
        mergeMap((state) => {
          // console.log('############### refresh...: ', state.type);

          switch (state.type) {
            case homeRefreshed.type.toString():
            case watchRefreshed.type.toString():
            case browseRefreshed.type.toString():
              this.onInitImpl(state);
              break;
            case routeChanged.type.toString():
              switch (state.path) {
                case '/':
                case '/home':
                  this.onDestroyImpl();
                  break;
                default:
                  this.onInitImpl(state);
                  break;
              }
              break;
            // case watchResponse.type.toString():
            //   break;
            // case homeRequest.type.toString():
            //   break;
          }

          return EMPTY;
        })
      )
      .subscribe();
  }

  private onInitImpl(state: MainState): void {
    /**
     * For a normal flow.
     */
    if (state.item) {
      if (state.item.videoId) {
        if (state.path) {
          this.context = {
            path: state.path,
            videoId: state.item.videoId,
            tags: state.tags,
          };
        } else {
          this.context = {
            videoId: state.item.videoId,
            tags: state.tags,
          };
        }
        // console.error('init: ', this.context);
        this.onInit(this.context);
      }
    } else if (state.path && state.videoId) {
      /**
       * For a refresh or a direct access to pages.
       */
      this.context = {
        path: state.path,
        videoId: state.videoId,
        tags: state.tags,
      };
      this.onInit(this.context);
    }
  }

  private onDestroyImpl(): void {
    this.onDestroy(this.context);
    this.context = this.EMPTY_CONTEXT;
    // this.logger.debug(`onDestroy() Unsubscribing`, this.name());
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    // clear
    this.subscriptions = [];
  }

  abstract onInit(context: Context): void;

  abstract onDestroy(context: Context): void;

  /**
   * Override this to set the name.
   */
  abstract name(): string;
}
