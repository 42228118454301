import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  HostListener,
  Inject,
  ElementRef,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { authFeatureKey, AuthState } from './reducers/auth.reducer';
import { filter, mergeMap, map, delay, catchError } from 'rxjs/operators';
import {
  authStateChanged,
  loginRequest,
  logoutRequest,
} from './actions/auth.actions';
import { of, EMPTY, Subject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { MainState, mainFeatureKey } from './reducers/main.reducer';
import { postFeatureKey, PostState } from './reducers/post.reducer';
import {
  watchResponse,
  routeChanged,
  scrollChanged,
  searchResponse,
  browseReloadedResponse,
  watchRefreshed,
  browseRefreshed,
  scrollDown,
  searchRequest,
} from './actions/main.actions';
import { EmptyItem, Item } from './models/youtube-data';
import { BaseComponent } from './base.component';
import {
  startPostingRequest,
  cancelPostingResponse,
  startEditPostResponse,
  startPostingResponse,
  startSubpostingResponse,
  commitPostUpdateResponse,
} from './actions/post.actions';
import { videoFeatureKey, VideoState } from './reducers/video.reducer';
import {
  directPlayVideo,
  pauseVideo,
  playOrPause,
  playVideo,
  replay,
  seekRightTo,
  videoEnded,
  videoPaused,
  videoPlaying,
  volumeDown,
  volumeUp,
} from './actions/video.actions';
import { stackFeatureKey, StackState } from './reducers/stack.reducer';
import { Video } from './models/posts';
import { PostDataService } from './services/post-data.service';
import { DOCUMENT } from '@angular/common';
import { ScrollService } from './services/scroll.service';
import { ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import firebase from 'firebase/compat/app';
import { PresenceService } from './services/presence.service';
import { DeviceService } from './services/device.service';
import {
  browseResponse,
  homeRefreshed,
  scrollToTop,
} from './actions/main.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { loginRequired } from './actions/auth.actions';
import { seekLeftTo } from './actions/video.actions';
import { VideoPlayerService } from './video-player/video-player.service';
import { pushVideo, clearVideos, popVideo } from './actions/stack.actions';
import {
  Icons,
  YOUTUBE_IMAGE_PATH,
  DEFAULT_USER_PHOTO_PATH,
} from './utils/icons';
import { YouTubeDataService } from './services/youtube-data.service';
import { deletePendingPostsResponse } from './actions/post.actions';
import { NGXLogger } from 'ngx-logger';
import {
  commitPostResponse,
  commitSubpostResponse,
} from './actions/post.actions';
import {
  cancelSubpostingResponse,
  cancelEditPostContentResponse,
} from './actions/post.actions';
import { trigger, transition, useAnimation } from '@angular/animations';
import {
  zoomIn,
  zoomOut,
  fadeIn,
  fadeOut,
  rubberBand,
  pulse,
  bounceIn,
} from 'ng-animate';

/**
 * @author: john@gomedialy.com
 * @version: 0.59, 02/03/2021
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('bounceIn', [
      transition(
        'void => *',
        useAnimation(bounceIn, { params: { timing: 1, delay: 0 } })
      ),
    ]),
    trigger('pulse', [
      transition(
        'void => *',
        useAnimation(pulse, { params: { timing: 0.5, delay: 0 } })
      ),
    ]),
    trigger('rubberBand', [
      transition(
        'void => *',
        useAnimation(rubberBand, { params: { timing: 1, delay: 0 } })
      ),
    ]),
    trigger('zoomIn', [
      transition(
        'void => *',
        useAnimation(zoomIn, { params: { timing: 0.25, delay: 0 } })
      ),
    ]),
    trigger('zoomOut', [
      transition(
        '* => void',
        useAnimation(zoomOut, { params: { timing: 0.25, delay: 0 } })
      ),
    ]),
    trigger('fadeIn', [
      transition(
        'void => *',
        useAnimation(fadeIn, { params: { timing: 1, delay: 0 } })
      ),
    ]),
    trigger('fadeOut', [
      transition(
        '* => void',
        useAnimation(fadeOut, { params: { timing: 1, delay: 0 } })
      ),
    ]),
  ],
})
export class AppComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {
  /* fields */
  title = 'GoMedialy';
  displayName = '';
  photoUrl = DEFAULT_USER_PHOTO_PATH;
  query = '';

  private isLoggedIn = false;
  isHome = false;
  isBrowse = false;
  isDesktop = false;

  // item
  item: Item | undefined = undefined;
  private tags: string[] = [];
  previousButtonEnabled = false;
  nextButtonEnabled = false;
  reloadButtonEnabled = false;
  homeButtonEnabled = false;
  playlistButtonEnabled = false;
  scrollToTopButtonEnabled = false;
  private index = 0;
  subvideoId!: string;
  private links: Video[] = [];
  /**
   * stack index
   */
  linksLength = 0;

  // video
  timestampMiliseconds = 0;
  endMiliseconds = 0;
  durationMiliseconds = 0;
  // current video
  private video!: Video;
  videoImageUrl: string = YOUTUBE_IMAGE_PATH;
  private path = '/';
  navigation: 'home-top' | 'watch-top' | 'home' | 'watch' = 'home-top';

  @ViewChild('searchInput')
  searchInput?: ElementRef;

  @ViewChild('youtubeVideoImage')
  youtubeVideoImage?: ElementRef;

  searchImageUrl = YOUTUBE_IMAGE_PATH;
  testSearchImageUrl = YOUTUBE_IMAGE_PATH;
  searchValue = '';
  searchButtonEnabled = false;
  searchFormGroup!: FormGroup;
  isSearch = false;
  isPostingButtonDisabled = false;

  private youtubeVideoId: string | null = null;
  private window: any;

  /**
   * For login buttons
   */
  userState: 'user' | 'anonymous' | null = null;

  /**
   * For hasScrolledToTop()
   */
  private offset0Subject = new Subject<number>();

  /**
   * Footer should be only ready after all the other ui is done.
   */
  isFooterReady = false;
  bounceIn: any;
  pulse: any;
  rubberBand: any;
  zoomIn: any;
  zoomOut: any;
  fadeIn: any;
  fadeOut: any;

  private openSnackBar(message: string, action: string): void {
    this.snackbar.open(message, action, {
      duration: 2000,
    });
  }

  /**
   * Provide info to main-store
   */
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(): void {
    // do some stuff here when the window is scrolled
    const verticalOffset =
      this.window.pageYOffset ||
      this.document.documentElement.scrollTop ||
      this.document.body.scrollTop ||
      0;
    this.updateNavigation(+verticalOffset.toFixed(0));
  }

  // event: { target: HTMLInputElement; })
  @HostListener('window:keydown', ['$event'])
  // tslint:disable-next-line: typedef
  keyEvent(event: KeyboardEvent) {
    if (event.target instanceof HTMLInputElement) {
      switch (event.code) {
        case 'Enter':
          if (this.searchButtonEnabled) {
            const searchControl = event.target as HTMLInputElement;
            // console.error('search-control: ', event.code, searchControl.value);
            this.onSearchClick();
          }
          break;
      }
    }
  }

  private updateNavigation(offset: number): void {
    switch (this.path) {
      case '/':
      case '/home':
        if (offset === 0) {
          this.navigation = 'home-top';
          // this.isFooterLazyReady = false;
        } else {
          this.navigation = 'home';
        }
        break;
      case '/watch':
      case '/videos':
        if (offset === 0) {
          this.navigation = 'watch-top';
          this.scrollToTopButtonEnabled = false;
        } else {
          this.navigation = 'watch';
          this.scrollToTopButtonEnabled = true;
        }
        break;
    }

    if (offset === 0) {
      this.offset0Subject.next(0);
    }
    this.mainStore.dispatch(scrollChanged({ path: this.path, offset }));
  }

  constructor(
    private authStore: Store<{
      [authFeatureKey]: AuthState;
    }>,
    private mainStore: Store<{
      [mainFeatureKey]: MainState;
    }>,
    private videoStore: Store<{
      [videoFeatureKey]: VideoState;
    }>,
    private stackStore: Store<{
      [stackFeatureKey]: StackState;
    }>,
    private postStore: Store<{
      [postFeatureKey]: PostState;
    }>,
    private logger: NGXLogger,
    private postDataSource: PostDataService,
    private router: Router,
    private deviceService: DeviceService,
    private scrollService: ScrollService,
    private formBuilder: FormBuilder,
    private presence: PresenceService,
    @Inject(DOCUMENT) private document: Document,
    private videoPlayerService: VideoPlayerService,
    private youTubeDataService: YouTubeDataService,
    private snackbar: MatSnackBar
  ) {
    super();

    this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe((event) => {
        if (event.id === 1 && event.url === event.urlAfterRedirects) {
          if (event.url.startsWith('/watch')) {
            this.mainStore.dispatch(watchRefreshed());
          } else if (event.url.startsWith('/browse')) {
            this.mainStore.dispatch(browseRefreshed());
          } else {
            this.mainStore.dispatch(homeRefreshed());
          }
        }
      });

    this.window = this.document.defaultView;

    const authStoreSubscription = authStore
      .pipe(select(authFeatureKey))
      .pipe(
        map((authState) => {
          switch (authState.type) {
            case authStateChanged.type.toString():
              const user = authState.user;
              if (user) {
                if (user.displayName) {
                  this.displayName = user.displayName;
                }
                if (user.photoUrl) {
                  this.photoUrl = user.photoUrl;
                }

                /**
                 * anonymous-users are not considered as logged-in users.
                 * however, their presences are counted.
                 */
                if (user.isAnonymous) {
                  this.isLoggedIn = false;
                  this.userState = 'anonymous';
                } else {
                  this.isLoggedIn = true;
                  this.userState = 'user';
                }
                this.onUserReady();
                this.presence.connect();
                return true;
              }
              break;
            case loginRequired.type.toString():
              this.openSnackBar('구글 로그인이 필요합니다.', '확인');
              break;
          }
          this.isLoggedIn = false;
          return false;
        })
      )
      .subscribe();
    this.subscriptions.add(authStoreSubscription);

    /**
     * item is available only on /watch
     */
    const mainStoreSubscription = this.mainStore
      .select(mainFeatureKey)
      .pipe(
        mergeMap((state) => {
          switch (state.type) {
            case scrollChanged.type.toString():
              // console.error('>>> scrollChanged: ', state);
              switch (state.path) {
                case '/':
                case '/home':
                  // keep focus to the youtubeUrlInput
                  this.setFocusToYoutubeUrlInput();
                  break;
                case '/watch':
                case '/videos':
                  break;
                case '/browse':
                  break;
              }
              break;
            case routeChanged.type.toString():
              if (state.path) {
                this.path = state.path;
              }
              switch (state.path) {
                case '/':
                case '/home':
                  this.isHome = true;
                  this.isBrowse = false;
                  this.isSearch = false;
                  this.setFocusToYoutubeUrlInput();
                  break;
                case '/watch':
                case '/videos':
                  this.isHome = false;
                  this.isBrowse = false;
                  break;
                case '/browse':
                  this.isBrowse = true;
                  this.isHome = false;
                  break;
                default:
                  this.isHome = true;
                  this.isBrowse = false;
                  break;
              }
              break;
            case browseResponse.type.toString():
              if (state.query) {
                this.query = state.query;
              }
              break;
            case browseReloadedResponse.type.toString():
              if (state.query) {
                this.query = state.query;
              }
              break;
            case watchRefreshed.type.toString():
              const videoId = state.videoId ? state.videoId : '';
              return this.youTubeDataService.findItemByVideoId(videoId).pipe(
                mergeMap((item) => {
                  // console.error('>>>>>>>>>>>> item from YouTubeData: ', item);
                  if (item === null) {
                    this.item = EmptyItem.create(
                      firebase.firestore.Timestamp.now()
                    );
                    this.item.videoId = videoId;
                    this.tags = state.tags;
                    // if (state.item) {
                    //   // this.channelTitle = state.item.snippet.channelTitle;
                    // }
                  } else {
                    // TODO:
                    this.item = item;
                    this.tags = state.tags;
                  }
                  return EMPTY;
                }),
                catchError(() => {
                  // console.log('############# error reload: ', error);
                  return EMPTY;
                })
              );

              break;
            case watchResponse.type.toString():
              if (state.item) {
                this.item = state.item;
                this.tags = state.tags;
                if (this.item.videoId) {
                  this.videoImageUrl = Icons.defaultImageUrl(this.item.videoId);
                }
                return of(true);
              }
              break;
            case searchResponse.type.toString():
              this.isSearch = true;
              if (state.query) {
                this.item = EmptyItem.create(
                  firebase.firestore.Timestamp.now()
                );
                this.item.videoId = state.query;
                this.tags = state.tags;
                if (this.item.videoId) {
                  this.videoImageUrl = Icons.defaultImageUrl(this.item.videoId);
                }
                return of(true);
              }
              break;
          }
          return of(false);
        })
      )
      .subscribe();
    this.subscriptions.add(mainStoreSubscription);
  }

  private onUserReady(): void {
    const postStoreSubscription = this.postStore
      .select(postFeatureKey)
      .pipe(
        mergeMap((state) => {
          switch (state.type) {
            case commitPostResponse.type.toString():
            case commitSubpostResponse.type.toString():
            case commitPostUpdateResponse.type.toString():
            case deletePendingPostsResponse.type.toString():
            case cancelPostingResponse.type.toString():
            case cancelSubpostingResponse.type.toString():
            case cancelEditPostContentResponse.type.toString():
              this.isPostingButtonDisabled = false;
              break;
            case startPostingResponse.type.toString():
            case startSubpostingResponse.type.toString():
            case startEditPostResponse.type.toString():
              this.isPostingButtonDisabled = true;
              break;
          }
          return EMPTY;
        })
      )
      .subscribe();
    this.subscriptions.add(postStoreSubscription);

    const stackStoreSubscription = this.stackStore
      .select(stackFeatureKey)
      .pipe(
        mergeMap((state) => {
          switch (state.type) {
            // case pushVideoResponse.type.toString():
            // case clearVideosResponse.type.toString():
            case pushVideo.type.toString():
            case clearVideos.type.toString():
              this.handleStackState(state);
              break;
            case popVideo.type.toString():
              // case popVideoResponse.type.toString():
              this.handleStackState(state);
              const link = this.links[this.index];
              // console.log('backward: @@@@@@@@@@@@@: ', link);
              this.videoStore.dispatch(directPlayVideo(link));
              break;
          }
          return EMPTY;
        })
      )
      .subscribe();
    this.subscriptions.add(stackStoreSubscription);

    let lastType: string | null = null;
    const videoStoreSubscription = this.videoStore
      .select(videoFeatureKey)
      .pipe(
        /**
         * Only when the state.type changes.
         * Ex) playing -> Paused -> Ended
         */
        filter((state: VideoState) => {
          // allow video playing
          if (state.type === videoPlaying.type.toString()) {
            return true;
          }
          if (state.type !== lastType) {
            lastType = state.type;
            return true;
          }
          return false;
        }),
        mergeMap((state) => {
          // console.log('>>> video state: ', state);
          switch (state.type) {
            case seekLeftTo.type.toString():
              break;
            case seekRightTo.type.toString():
              break;
            case volumeUp.type.toString():
              // this.openSnackBar('+5', 'OK');
              break;
            case volumeDown.type.toString():
              // this.openSnackBar('-5', 'OK');
              break;
            case videoPlaying.type.toString():
              if (state.postId) {
                this.timestampMiliseconds = state.timestamp * 1000;
                this.durationMiliseconds = state.duration * 1000;
                const post = this.postDataSource.findPost(state.postId);

                if (post) {
                  switch (state.videoType) {
                    case 'subtitle':
                      if (post.subvideo0) {
                        this.endMiliseconds = post.subvideo0.endSeconds * 1000;
                        if (this.timestampMiliseconds > this.endMiliseconds) {
                          this.endMiliseconds = this.durationMiliseconds;
                        }
                      }
                      break;
                    case 'subvideo':
                      if (post.subvideo1) {
                        this.endMiliseconds = post.subvideo1.endSeconds * 1000;
                        if (this.timestampMiliseconds > this.endMiliseconds) {
                          this.endMiliseconds = this.durationMiliseconds;
                        }
                      }
                      break;
                  }
                } else {
                  /**
                   * To handle directPlayVideo()
                   * postId is present but it is not on the firestore
                   * Ex) videoId === postId while editing
                   */
                  this.timestampMiliseconds = state.timestamp * 1000;
                  this.endMiliseconds = state.endSeconds * 1000;
                }
              } else {
                /**
                 * To handle directPlayVideo()
                 *
                 */
                this.timestampMiliseconds = state.timestamp * 1000;
                this.endMiliseconds = state.duration * 1000;
              }
              break;
            case videoPaused.type.toString():
              // this.isPlaying = false;
              break;
            case videoEnded.type.toString():
              // this.isPlaying = false;
              this.timestampMiliseconds = 0;
              this.endMiliseconds = 0;
              break;
          }
          return EMPTY;
        })
      )
      .subscribe();
    this.subscriptions.add(videoStoreSubscription);
  }

  isPlaying(): boolean {
    return this.videoPlayerService.isPlaying();
  }

  // TODO: stacks
  private handleStackState(state: StackState): void {
    // console.error('########### handleStackState: ', state);

    this.links = state.stack;
    const length = this.links.length;
    this.linksLength = length;
    this.index = length - 1;
    const lastLink = this.links[this.index];
    this.video = lastLink;
    this.subvideoId = lastLink.subvideoId;
    // console.error(
    //   '>>>>>>>>>>>>>>>>>>>>>>>>>>>>. handleStackState: ',
    //   state.video
    // );

    // this.videoImageUrl = `https://i.ytimg.com/vi/${state.videoId}/default.jpg`;
    // this.videoImageUrl = `https://i.ytimg.com/vi/${this.video.videoId}/default.jpg`;
    this.videoImageUrl = Icons.defaultImageUrl(this.video.videoId);

    // home
    if (length === 1) {
      this.homeButtonEnabled = false;
      this.previousButtonEnabled = false;
      this.reloadButtonEnabled = true;
    } else {
      this.homeButtonEnabled = true;
      this.previousButtonEnabled = true;
      this.playlistButtonEnabled = true;
      this.reloadButtonEnabled = true;
    }
  }

  private setFocusToYoutubeUrlInput(): void {
    of('')
      .pipe(delay(100))
      .subscribe(() => {
        if (this.searchInput) {
          // tslint:disable-next-line: no-non-null-assertion
          this.searchInput!.nativeElement.focus();
        }
      });
  }

  ngOnInit(): void {
    this.isDesktop = this.deviceService.isDesktop();

    // this.isVideoUrlReadOnly = true;
    this.searchFormGroup = this.formBuilder.group({
      searchControl: [
        { value: '', disabled: false },
        [Validators.required, this.searchValidation],
      ],
    });
    // this.onVideoUrlOkClick();
  }

  ngAfterViewInit(): void {
    this.setFocusToYoutubeUrlInput();
    /**
     * Footer is visible when all the rendering is done.
     */
    of('')
      .pipe(delay(1000))
      .subscribe(() => {
        this.isFooterReady = true;
      });
  }

  onYoutubeUrlClearClick(): void {
    this.searchButtonEnabled = false;
  }

  onBackwardClick(): void {
    // this.stackStore.dispatch(popVideoRequest());
    this.stackStore.dispatch(popVideo());
  }

  onPlayClick(): void {
    if (this.index === 0) {
      this.videoStore.dispatch(playOrPause());
    } else {
      // console.log('Play');
      if (this.videoPlayerService.isPlaying()) {
        // if (this.isPlaying) {
        this.videoStore.dispatch(pauseVideo({ videoId: this.video.videoId }));
      } else {
        this.videoStore.dispatch(
          playVideo({
            ...this.video,
            postId: this.video.postId,
            startSeconds: -1,
            endSeconds: 0,
            duration: -1,
          })
        );
      }
    }
    // this.isPlaying = !this.isPlaying;
  }

  onReplayClick(): void {
    if (this.index === 0) {
      this.videoStore.dispatch(replay());
    } else {
      const link = this.links[this.index];
      this.videoStore.dispatch(directPlayVideo(link));
    }
  }

  onRewindClick(): void {
    this.videoStore.dispatch(seekLeftTo());
  }

  onForwardClick(): void {
    this.videoStore.dispatch(seekRightTo());
  }

  onHomeClick(): void {
    this.stackStore.dispatch(clearVideos());
    this.videoStore.dispatch(directPlayVideo(this.links[0]));
  }

  onPlaylistClick(): void {}

  onInfoClick(): void {
    // console.log('info');
  }

  onLogoClick(): void {
    this.scrollService.scrollTo('home-top');
  }

  onArrowLeftClick(): void {
    this.router.navigateByUrl('/home');
  }

  onStartPostingClick(): void {
    if (!this.isLoggedIn) {
      this.authStore.dispatch(loginRequired());
    } else if (this.item && this.item.videoId) {
      this.videoStore.dispatch(pauseVideo({ videoId: this.item.videoId }));
      this.postStore.dispatch(
        startPostingRequest({ videoId: this.item.videoId, tags: this.tags })
      );
    }
  }

  onLoginClick(): void {
    this.authStore.dispatch(loginRequest());
  }

  onLogoutClick(): void {
    this.authStore.dispatch(logoutRequest());
  }

  onSearchClick(): void {
    if (this.youtubeVideoId) {
      this.mainStore.dispatch(searchRequest({ query: this.youtubeVideoId }));
    }
    // this.searchValue = '';
    this.clearSearchControlValue();
    this.youtubeVideoId = null;
    this.testSearchImageUrl = YOUTUBE_IMAGE_PATH;
  }

  onScrollToTop(): void {
    // console.log('onScrollToTop');
    this.mainStore.dispatch(scrollToTop());
  }

  onScrollDown(): void {
    // console.log('onScrollDown');
    this.mainStore.dispatch(scrollDown());
  }

  private clearSearchControlValue(): void {
    // tslint:disable-next-line: no-string-literal
    this.searchFormGroup.controls['searchControl'].setValue('');
  }

  /**
   * searchValidation(), onYoutubeImageLoad() and getYoutubeVideoId() all work together
   * to check if a videoId exists.
   */
  private searchValidation = (control: AbstractControl) => {
    const url = control.value;
    if (this.getYouTubeVideoId(url)) {
      // null means ok
      this.searchButtonEnabled = true;
      return null;
    }
    // not ok
    this.searchButtonEnabled = false;
    return { error: 'WTF' };
  };

  onYoutubeImageLoad(event: any): void {
    const src = event.target.src as string;
    if (src === this.testSearchImageUrl) {
      // console.error('############### onYouTubeImageLoad(): ', event);

      const width = event.target.width;
      if (width > 120 || width === 320) {
        // this.searchImageUrl = this.testSearchImageUrl;
        // this.searchImageUrl = `https://i.ytimg.com/vi/${this.youtubeVideoId}/default.jpg`;
        if (this.youtubeVideoId) {
          this.searchImageUrl = Icons.defaultImageUrl(this.youtubeVideoId);
        }
        this.searchButtonEnabled = true;
        // console.error(
        //   '############### onYouTubeImageLoad2(): ',
        //   this.searchImageUrl
        // );
        return;
      }
    }
    this.searchImageUrl = YOUTUBE_IMAGE_PATH;
    // this.searchValue = '';
    this.youtubeVideoId = null;
    this.searchButtonEnabled = false;
    this.clearSearchControlValue();
  }

  /**
   * Ex) https://www.youtube.com/watch?v=rf4FlJvkkrA
   */
  private getYouTubeVideoId(url: string): string | null {
    try {
      const videoId = new URL(url.trim()).searchParams.get('v');
      if (videoId && videoId.length > 8) {
        // mqdefault.jpg;
        // this.searchImageUrl = `https://i.ytimg.com/vi/${videoId}/default.jpg`;
        this.testSearchImageUrl = `https://i.ytimg.com/vi/${videoId}/mqdefault.jpg`;
        // this.searchImageUrl = `https://i.ytimg.com/vi/${videoId}/mqdefault.jpg`;
        // this.searchImageUrl = `https://isss.ytimg.com/vi/${videoId}/default.jpg`;

        // this.httpService.get(this.searchImageUrl).subscribe(
        //   (v) => {
        //     console.log('image test: ', v);
        //   },
        //   (err) => {
        //     console.error('image test: ', err);
        //   }
        // );

        this.youtubeVideoId = videoId;
        return videoId;
      }
    } catch (error) {
      // ignore
    }
    // this.videoImageUrl = '../assets/images/icons8-film-reel-64.png';
    return null;
  }

  // For logging
  name(): string {
    return 'app';
  }
}
