<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
  <img class="image-rounded debug-redx" src="{{ videoImageUrl }}" />
  <div>
    <h2 class="debug-bluex" mat-dialog-title style="font-weight: bolder">
      댓글 삭제
    </h2>
    <div style="color: gray; font-weight: bolder">
      한번 삭제된 댓글은 복구할 수 없습니다.
    </div>
  </div>
</div>
<div style="height: 32px"></div>

<mat-dialog-content class="mat-typography">
  <!-- <div style="color: #9e9e9e">&#10077;</div> -->
  <div
    class="word-wrap debug-redx"
    style="padding: 4px 0px"
    markdown
    ngPreserveWhitespaces
    emoji
    [data]="content"
  ></div>
  <!-- <div style="color: #9e9e9e">&#10078;</div> -->
</mat-dialog-content>
<div style="height: 16px"></div>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close cdkFocusInitial>취소</button>
  <button mat-button [mat-dialog-close]="true">확인</button>
</mat-dialog-actions>
