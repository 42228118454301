<div
  [@zoomIn]="zoomIn"
  class="container"
  fxLayout="column"
  fxLayoutAlign="center center"
>
  <div style="color: white; font-size: xx-large; font-weight: bolder">
    {{ message }}
  </div>
</div>
