import { createReducer, on, props } from '@ngrx/store';
import * as HomeActions from '../actions/home.actions';
import { ClientData, ContentData } from '../models/data';
import { WordCloudData } from '../services/data.service';

/**
 * @author: john@gomedialy.com
 * @version: 0.11, 01/16/2021
 */
export const homeFeatureKey = 'home';

export interface HomeState {
  type: string | null;
  wordcloud: WordCloudData | null;
  content: ClientData<ContentData[]> | null;
  // lastOffset: number;
}

export const initialState: HomeState = {
  type: null,
  wordcloud: null,
  content: null,
  // lastOffset: 0,
};

export const homeReducer = createReducer(
  initialState,
  on(HomeActions.wordcloudRequest, (state, action) => {
    return { ...state, ...action };
  }),
  on(HomeActions.wordcloudResponse, (state, action) => {
    if (action.wordcloud) {
      return { ...state, ...action };
    }
    return { ...state, type: action.type };
  }),
  on(HomeActions.contentDataRequest, (state, action) => {
    return { ...state, ...action };
  }),
  on(HomeActions.contentDataResponse, (state, action) => {
    if (action.content) {
      return { ...state, ...action };
    }
    return { ...state, type: action.type };
  })
);
