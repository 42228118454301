<div id="browse-top"></div>
<div
  class="container debug-bluex"
  fxLayout="row wrap"
  fxLayoutAlign="start center"
  fxLayoutGap="0px"
  fxLayout.gt-md="row wrap"
  fxLayoutAlign.gt-md="start center"
  fxLayoutGap.gt-md="0px"
  fxLayout.gt-xs="row wrap"
  fxLayoutAlign.gt-xs="space-around center"
  fxLayoutGap.gt-xs="0px"
  fxLayout.xs="column"
  fxLayoutAlign.xs="space-around center"
>
  <ng-container *ngFor="let item of items$ | async">
    <app-search-card [item]="item"></app-search-card>
  </ng-container>
</div>
<div
  *ngIf="scrollToTopEnabled"
  ngClass.lt-lg="fab-up-mobile"
  ngClass.lg="fab-up-mobile"
  ngClass.gt-lg="fab-up-desktop"
  [@zoomIn]="zoomIn"
  [@zoomOut]="zoomOut"
>
  <button mat-fab style="background-color: #616161" (click)="onScrollToTop()">
    <mat-icon style="font-weight: bolder">arrow_upward</mat-icon>
  </button>
</div>
<ngx-spinner
  type="ball-beat"
  size="medium"
  bdColor="rgba(0, 0, 0, 0)"
  color="#2196F3"
></ngx-spinner>
