import {
  CollectionReference,
  AngularFirestore,
  DocumentChangeAction,
  QueryDocumentSnapshot,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { Observable, of, throwError, EMPTY, partition } from 'rxjs';
import { map, mergeMap, filter } from 'rxjs/operators';
import { Post } from '../models/posts';
import { Arrays } from '../utils/guava';
import { PageRequest, PageResponse } from '../services/post.service';
import { Injectable } from '@angular/core';
import { PostQueries } from './post-queries';
// import Timestamp = firestore.Timestamp;
// import QuerySnapshot = firestore.QuerySnapshot;

/**
 * @author: john@gomedialy.com
 * @version: 0.12, 09/25/2020
 * @version: 0.13, 10/28/2020
 */
@Injectable({
  providedIn: 'root',
})
export class PostRisingQueries {
  /* fields */

  /**
   * QueryDocumentSnapshot<Post> is not serializable on ngrx.
   * Store it here and use documentId on ngrx, instead.
   */
  private partitionMap = new Map<string, QueryDocumentSnapshot<Post>>();

  constructor(private angularFirestore: AngularFirestore) {}

  listenToRisingPosts(
    isSubtitles: boolean,
    pageRequest: PageRequest
  ): Observable<PageResponse | null> {
    const videoId = pageRequest.videoId;
    return this.forRisingPosts(isSubtitles, pageRequest)
      .snapshotChanges()
      .pipe(
        mergeMap((documentChangeActions: DocumentChangeAction<Post>[]) => {
          return this.createRisingPostPageResponse(
            videoId,
            isSubtitles,
            pageRequest,
            documentChangeActions
          );
        })
      );
  }

  forRisingPosts(
    // videoId: string,
    isSubtitles: boolean,
    pageRequest: PageRequest
  ): AngularFirestoreCollection<Post> {
    const videoId = pageRequest.videoId;
    const path = `channels/${videoId}/posts`;
    const pageSize = pageRequest.pageSize;
    const partition = pageRequest.partition;

    return this.angularFirestore.collection<Post>(path, (ref) => {
      switch (pageRequest.direction) {
        case 'previous':
          if (partition) {
            return this.previousRisingsQuery(
              ref,
              videoId,
              pageSize,
              isSubtitles,
              partition
            );
          } else {
            throw new Error(`previous requires partition.`);
          }
          break;
        case 'next':
          return this.nextRisingsQuery(
            ref,
            videoId,
            pageSize,
            isSubtitles,
            partition
          );
          break;
      }
    });
  }

  /**
   * Risings
   * @param ref
   * @param videoId
   * @param pageSize
   * @param isSubtitles
   * @param partition
   */
  private previousRisingsQuery(
    ref: CollectionReference,
    videoId: string,
    pageSize: number,
    isSubtitles: boolean,
    partition: string
  ): firebase.firestore.Query<firebase.firestore.DocumentData> {
    const partitionDoc = this.partitionMap.get(partition);
    if (isSubtitles) {
      return ref
        .where('videoId', '==', videoId)
        .where('category', '==', 'subtitle')
        .orderBy('likes', 'desc')
        .orderBy('groupedAt', 'desc')
        .endBefore(partitionDoc)
        .limitToLast(pageSize);
    }
    return ref
      .where('videoId', '==', videoId)
      .orderBy('likes', 'desc')
      .orderBy('groupedAt', 'desc')
      .endBefore(partitionDoc)
      .limitToLast(pageSize);
  }

  private nextRisingsQuery(
    ref: CollectionReference,
    videoId: string,
    pageSize: number,
    isSubtitles: boolean,
    partition?: string
  ): firebase.firestore.Query<firebase.firestore.DocumentData> {
    if (isSubtitles) {
      if (partition) {
        const partitionDoc = this.partitionMap.get(partition);
        return ref
          .where('videoId', '==', videoId)
          .where('category', '==', 'subtitle')
          .orderBy('likes', 'desc')
          .orderBy('groupedAt', 'desc')
          .startAfter(partitionDoc)
          .limit(pageSize);
      } else {
        return ref
          .where('videoId', '==', videoId)
          .where('category', '==', 'subtitle')
          .orderBy('likes', 'desc')
          .orderBy('groupedAt', 'desc')
          .limit(pageSize);
      }
    }
    if (partition) {
      const partitionDoc = this.partitionMap.get(partition);
      return ref
        .where('videoId', '==', videoId)
        .orderBy('likes', 'desc')
        .orderBy('groupedAt', 'desc')
        .startAfter(partitionDoc)
        .limit(pageSize);
    } else {
      return ref
        .where('videoId', '==', videoId)
        .orderBy('likes', 'desc')
        .orderBy('groupedAt', 'desc')
        .limit(pageSize);
    }
  }

  createRisingPostPageResponse(
    videoId: string,
    isSubtitles: boolean,
    pageRequest: PageRequest,
    documentChangeActions: DocumentChangeAction<Post>[]
  ): Observable<PageResponse | null> {
    if (Arrays.isEmpty(documentChangeActions)) {
      return of(null);
    }
    const docs = documentChangeActions.map(
      (documentChangeAction) => documentChangeAction.payload.doc
    );
    const start = docs[0];
    const end = docs[docs.length - 1];
    const posts = docs.map((doc) => doc.data() as Post);
    /**
     * Temporary storage for QueryDocumentSnapshot<Post>
     */
    this.partitionMap.set(start.id, start);
    this.partitionMap.set(end.id, end);
    // console.log('partition-map: ', this.partitionMap.size);

    switch (pageRequest.direction) {
      case 'previous':
        const checkPreviousPageRequest: PageRequest = {
          ...pageRequest,
          pageSize: 1,
          partition: start.id,
        };
        return this.forRisingPosts(isSubtitles, checkPreviousPageRequest)
          .get()
          .pipe(
            PostQueries.createPreviousPageResponse(videoId, start, end, posts)
          );
        break;
      case 'next':
        const checkNextPageRequest: PageRequest = {
          ...pageRequest,
          pageSize: 1,
          partition: end.id,
        };
        return this.forRisingPosts(isSubtitles, checkNextPageRequest)
          .get()
          .pipe(
            PostQueries.createNextPageResponse(
              videoId,
              start,
              end,
              posts,
              pageRequest.partition
            )
          );
        break;
    }
    return throwError(new Error(`Impossible error`));
  }
}
