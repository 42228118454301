import { Action, createReducer, on, props } from '@ngrx/store';
import * as ImageEditorActions from '../actions/image-editor.actions';
import { JSend } from '../models/jsend';

/**
 * @author: john@gomedialy.com
 * @version: 0.12, 10/31/2020
 */
// export const imageEditorFeatureKey = 'image-editor';
export const imageEditorFeatureKey = 'imageEditor';

export interface ImageEditorState {
  type: string | null;
  videoId: string | null;
  postId: string | null;
  // subvideoId: string | null;
  // startSeconds: number;
  // duration: number;
  tags: string[];
  jsend: JSend | null;
}

export const initialState: ImageEditorState = {
  type: null,
  videoId: null,
  postId: null,
  // subvideoId: null,
  // startSeconds: 0,
  // duration: 0,
  tags: [],
  jsend: null,
};

export const imageEditorReducer = createReducer(
  initialState,
  on(ImageEditorActions.editImageRequest, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),
  on(ImageEditorActions.editImageResponse, (state, action) => {
    return { ...state, ...action, jsend: action.jsend };
  }),
  on(ImageEditorActions.clearImageEditor, (state, action) => {
    return { ...initialState };
  })
);
