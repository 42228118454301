import { Injectable } from '@angular/core';
import { TrendingSearchesService } from './trending-searches.service';
import { LocalStorageService } from './local-storage.service';
import { Observable, of, EMPTY } from 'rxjs';
import { DateTimeService } from './date-time.service';
import firebase from 'firebase/compat/app';
import { concatMap, toArray, map, mergeMap } from 'rxjs/operators';
import { TopSearch } from '../models/trends';
import { WordWeight } from '../models/words';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { WordCloudItem } from '../word-cloud/word-cloud.component';
import { FirestoreService } from './firestore.service';
import { ClientData, ContentData } from '../models/data';
import { DeviceService } from './device.service';
import { LocalDataService } from './local-data.service';
import { NGXLogger } from 'ngx-logger';
import { Firestores } from '../utils/firestores';

/**
 * All the data services + CachService => DataEffects
 *
 * @author: john@gomedialy.com
 * @version: 0.13, 09/06/2020
 * @version: 0.22, 01/20/2021
 */
export interface WordCloudData {
  desktop: WordCloudItem[];
  mobile: WordCloudItem[];
  createdAt: firebase.firestore.Timestamp;
}
@Injectable({
  providedIn: 'root',
})
// TODO: ClientDataService to match the functions
export class DataService {
  /* fields */
  private angularFirestore: AngularFirestore;
  private isDesktop = false;

  constructor(
    private logger: NGXLogger,
    private firestoreService: FirestoreService,
    private trendingSearchesService: TrendingSearchesService,
    private dateTimeService: DateTimeService,
    private localStorage: LocalStorageService,
    private localDataService: LocalDataService,
    private deviceService: DeviceService
  ) {
    this.angularFirestore = this.firestoreService.getAngularFirestore();
    this.isDesktop = this.deviceService.isDesktop();
  }

  getWordCloud(): Observable<WordCloudData | null> {
    return this.angularFirestore
      .collection<WordCloudData>('wordclouds', (ref) => {
        return ref.orderBy('createdAt', 'desc').limit(1); // an array of a single item
      })
      .get()
      .pipe(
        mergeMap((querySnapshot) =>
          Firestores.querySnapshotAsOneAndOnlyOf<WordCloudData>(querySnapshot)
        ),
        mergeMap((wordcloudData) => {
          if (wordcloudData) {
            return this.localDataService
              .get2<firebase.firestore.Timestamp>('wordcloudData', 'createdAt')
              .pipe(
                mergeMap((createdAt) => {
                  if (createdAt) {
                    if (createdAt.isEqual(wordcloudData.createdAt)) {
                      return of(wordcloudData);
                    }
                  }
                  this.logger.debug('[DATA] wordcloudData changed');
                  return this.localDataService
                    .set2('wordcloudData', 'createdAt', wordcloudData.createdAt)
                    .pipe(map(() => wordcloudData));
                })
              );
          }
          return of(null);
        })
      );
  }

  listenToWordCloud(): Observable<WordCloudData> {
    return this.angularFirestore
      .collection<WordCloudData>('wordclouds', (ref) => {
        return ref.orderBy('createdAt', 'desc').limit(1); // an array of a single item
      })
      .valueChanges()
      .pipe(
        mergeMap((list) => {
          if (list.length === 1) {
            return of(list[0]);
          }
          return EMPTY;
        }),
        mergeMap((wordcloudData) => {
          // this.logger.info(
          //   '>>>>>>>>>>>>>>>>>>> wordcloudData: ',
          //   wordcloudData
          // );

          return this.localDataService
            .get2<firebase.firestore.Timestamp>('wordcloudData', 'createdAt')
            .pipe(
              mergeMap((createdAt) => {
                if (createdAt) {
                  if (createdAt.isEqual(wordcloudData.createdAt)) {
                    return EMPTY;
                  }
                }
                this.logger.debug('[DATA] wordcloudData changed');
                return this.localDataService
                  .set2('wordcloudData', 'createdAt', wordcloudData.createdAt)
                  .pipe(map(() => wordcloudData));
              })
            );
        })
      );
  }

  listenToContentDataCollection(): Observable<ClientData<ContentData[]>> {
    return this.angularFirestore
      .collection<ClientData<ContentData[]>>('content_data', (ref) => {
        return ref.orderBy('createdAt', 'desc').limit(1);
      })
      .valueChanges()
      .pipe(
        mergeMap((list) => {
          if (list.length === 1) {
            return of(list[0]);
          }
          return EMPTY;
        }),
        mergeMap((contentData) => {
          return of(contentData);
        }),
        mergeMap((clientData) => {
          return this.localDataService
            .get2<firebase.firestore.Timestamp>('contentData', 'createdAt')
            .pipe(
              mergeMap((createdAt) => {
                if (createdAt) {
                  if (createdAt.isEqual(clientData.createdAt)) {
                    return EMPTY;
                  }
                }
                this.logger.debug('[DATA] contentData changed');
                return this.localDataService
                  .set2('contentData', 'createdAt', clientData.createdAt)
                  .pipe(map(() => clientData));
              })
            );
        })
      );
  }

  /**
   * Sort by traffic
   */
  listenToSortedTopSearchesByDuration(
    start: firebase.firestore.Timestamp,
    end: firebase.firestore.Timestamp
  ): Observable<TopSearch[]> {
    // TopSearch
    return this.listenToTopSearchesByDuration(start, end).pipe(
      map((topSearches) => {
        return topSearches.map((topSearch) => {
          const formattedTraffic = topSearch.formattedTraffic;
          const weight: number = +formattedTraffic.replace('K+', '000');
          // console.log(
          //   'top-search: ',
          //   query,
          //   weight,
          //   topSearch.date.toDate().toDateString()
          // );
          const searchWeight = {
            search: topSearch,
            weight,
          };
          return searchWeight;
        });
      }),
      concatMap((rawSearchWeights) => {
        const rawSearchWeightTotal = rawSearchWeights.reduce((acc, value) => {
          return acc + value.weight;
        }, 0);

        const searchWeights = rawSearchWeights.map((searchWeight) => {
          searchWeight.weight = searchWeight.weight / rawSearchWeightTotal;
          return searchWeight;
        });
        // this.sortDscByWeight2(searchWeights);
        searchWeights.sort((a, b) => {
          return b.weight - a.weight; // dsc
        });

        const topSearches = searchWeights.map(
          (searchWeight) => searchWeight.search
        );
        return of(topSearches);
      })
    );
  }

  /**
   * For card-group
   */
  private listenToTopSearchesByDuration(
    start: firebase.firestore.Timestamp,
    end: firebase.firestore.Timestamp
  ): Observable<TopSearch[]> {
    return this.trendingSearchesService
      .listenToTrendingSearchesByDuration(start, end)
      .pipe(
        map((trendingSearches) => {
          return trendingSearches.map((trendingSearch) => {
            const relatedQueries: string[] = trendingSearch.relatedQueries.map(
              (queryLink) => {
                return queryLink.query;
              }
            );
            const topSearch: TopSearch = {
              date: trendingSearch.date,
              timestamp: trendingSearch.timestamp,
              query: trendingSearch.title.query,
              formattedTraffic: trendingSearch.formattedTraffic,
              relatedQueries,
            };
            return topSearch;
          });
        })
      );
  }

  // TODO: listenToWordWeights()
  listWordWeights(): Observable<WordCloudItem[]> {
    /**
     * TODO: Test for now.
     */
    // console.error('================= WTF: ', this.cache);
    return this.localStorage.has('word-cloud').pipe(
      concatMap(() => {
        if (false) {
          // console.error('@@@@@@@@@@@@@@@@@@@@ fromCache: ');
          return this.localStorage.get<WordWeight[]>('word-cloud');
        }
        const start = this.dateTimeService.daysAgoTimestamp(2);
        const end = this.dateTimeService.now();
        // console.error('@@@@@@@@@@@@@@@@@@@@ build new: ');
        return this.getRelativeWordWeightsByDuration(start, end).pipe(
          concatMap((wordWeights) => {
            return this.localStorage.set<WordWeight[]>(
              'word-cloud',
              wordWeights
            );
          })
        );
      }),
      map((wordWeights) => {
        // console.error('>>>>>>>>>>>>>>> wtf: ', wordWeights);
        if (wordWeights) {
          // this.buildWordCloud(wordWeights as WordWeight[]);
          return this.buildWordCloud(wordWeights);
        }
        // return of(wordWeights);
        return [];
      })
    );
  }

  private buildWordCloud(wordWeights: WordWeight[]): WordCloudItem[] {
    // console.error('>>>>>>>>>>>> isDesktop: ', this.isDesktop);
    if (this.isDesktop) {
      return wordWeights.map((wordWeight) => {
        const weight = wordWeight.weight;
        switch (weight) {
          case 1:
            return {
              formattedTraffic: wordWeight.formattedTraffic,
              text: wordWeight.word,
              style: 'mat-display-1',
            };
          // break;
          case 2:
            return {
              formattedTraffic: wordWeight.formattedTraffic,
              text: wordWeight.word,
              style: 'mat-display-2',
            };
          // break;
          case 3:
            return {
              formattedTraffic: wordWeight.formattedTraffic,
              text: wordWeight.word,
              style: 'mat-display-3',
            };
          // break;
          case 4:
            return {
              formattedTraffic: wordWeight.formattedTraffic,
              text: wordWeight.word,
              style: 'mat-display-4',
            };
          // break;
        }
        // return {text: '', style: ''};
        throw new Error(`word-weight is not between 1 and 4: ${weight}`);
      });
    }
    return wordWeights.map((wordWeight) => {
      const weight = wordWeight.weight;
      switch (weight) {
        case 1:
          return {
            formattedTraffic: wordWeight.formattedTraffic,
            text: wordWeight.word,
            // style: 'mat-display-1',
            style: 'mat-h2',
          };
        // break;
        case 2:
          return {
            formattedTraffic: wordWeight.formattedTraffic,
            text: wordWeight.word,
            style: 'mat-display-1',
          };
        // break;
        case 3:
          return {
            formattedTraffic: wordWeight.formattedTraffic,
            text: wordWeight.word,
            style: 'mat-display-2',
          };
        // break;
        case 4:
          return {
            formattedTraffic: wordWeight.formattedTraffic,
            text: wordWeight.word,
            style: 'mat-display-3',
          };
        // break;
      }
      // return {text: '', style: ''};
      throw new Error(`word-weight is not between 1 and 4: ${weight}`);
    });
  }

  /**
   * Everything starts from here.
   */
  listTopSearchesByDuration(
    start: firebase.firestore.Timestamp,
    end: firebase.firestore.Timestamp
  ): Observable<TopSearch> {
    return this.trendingSearchesService
      .listTrendingSearchesByDuration(start, end)
      .pipe(
        map((trendingSearch) => {
          // console.error('WTF: ', trendingSearch.title.query);
          // trendingSearch.title.query;
          const relatedQueries: string[] = trendingSearch.relatedQueries.map(
            (queryLink) => {
              return queryLink.query;
            }
          );
          const topSearch: TopSearch = {
            date: trendingSearch.date,
            timestamp: trendingSearch.timestamp,
            query: trendingSearch.title.query,
            formattedTraffic: trendingSearch.formattedTraffic,
            relatedQueries,
          };
          return topSearch;
        })
      );
  }

  // TODO: getCurrentTopSearchesByDuration
  getSortedTopSearchesByDuration(
    start: firebase.firestore.Timestamp,
    end: firebase.firestore.Timestamp
  ): Observable<Array<TopSearch>> {
    // TopSearch
    return this.listTopSearchesByDuration(start, end).pipe(
      map((topSearch) => {
        const formattedTraffic = topSearch.formattedTraffic;
        const weight: number = +formattedTraffic.replace('K+', '000');
        // console.log(
        //   'top-search: ',
        //   query,
        //   weight,
        //   topSearch.date.toDate().toDateString()
        // );
        const searchWeight = {
          search: topSearch,
          weight,
        };
        return searchWeight;
      }),
      toArray(),
      concatMap((rawSearchWeights) => {
        const rawSearchWeightTotal = rawSearchWeights.reduce((acc, value) => {
          return acc + value.weight;
        }, 0);

        const searchWeights = rawSearchWeights.map((searchWeight) => {
          searchWeight.weight = searchWeight.weight / rawSearchWeightTotal;
          return searchWeight;
        });
        // this.sortDscByWeight2(searchWeights);
        searchWeights.sort((a, b) => {
          return b.weight - a.weight; // dsc
        });

        const topSearches = searchWeights.map(
          (searchWeight) => searchWeight.search
        );
        return of(topSearches);

        // const topWeight: number = wordWeights[0].weight;

        // const relativeWordWeights = wordWeights.map((wordWeight) => {
        //   wordWeight.weight = this.toRelativeWeight(
        //     topWeight,
        //     wordWeight.weight
        //   );
        //   return wordWeight;
        // });
        // return of(relativeWordWeights);
      })
    );
  }

  getRelativeWordWeightsByDuration(
    start: firebase.firestore.Timestamp,
    end: firebase.firestore.Timestamp
  ): Observable<Array<WordWeight>> {
    return this.listTopSearchesByDuration(start, end).pipe(
      map((topSearch) => {
        const query = topSearch.query;
        const formattedTraffic = topSearch.formattedTraffic;
        const weight: number = +formattedTraffic.replace('K+', '000');
        // console.log(
        //   'top-search: ',
        //   query,
        //   weight,
        //   topSearch.date.toDate().toDateString()
        // );
        const wordWeight: WordWeight = {
          formattedTraffic,
          word: query,
          weight,
        };
        return wordWeight;
      }),
      toArray(),
      concatMap((rawWordWeights) => {
        const rawWeightTotal = rawWordWeights.reduce((acc, value) => {
          return acc + value.weight;
        }, 0);

        const wordWeights = rawWordWeights.map((wordWeight) => {
          wordWeight.weight = wordWeight.weight / rawWeightTotal;
          return wordWeight;
        });
        this.sortDescByWeight(wordWeights);
        const topWeight: number = wordWeights[0].weight;

        const relativeWordWeights = wordWeights.map((wordWeight) => {
          wordWeight.weight = this.toRelativeWeight(
            topWeight,
            wordWeight.weight
          );
          return wordWeight;
        });
        return of(relativeWordWeights);
      })
    );
  }

  private toRelativeWeight(top: number, weight: number): number {
    const unit = top / 4;
    if (weight > 0 && weight <= unit) {
      return 1;
    } else if (weight > unit && weight <= unit * 2) {
      return 2;
    } else if (weight > unit * 2 && weight <= unit * 3) {
      return 3;
    }
    return 4;
  }

  private sortDescByWeight(wordWeights: WordWeight[]): WordWeight[] {
    wordWeights.sort((a, b) => {
      return b.weight - a.weight; // desc
    });
    return wordWeights;
  }
}
