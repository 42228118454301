/**
 * @author: john@gomedialy.com
 * @version: 0.1, 01/05/2021
 */
export class Dates {
  private constructor() {}

  /**
   * Sometimes, dateAgoPipe just disappear when rebuilt.
   * Use directly instead here.
   * Even from DateAgoPipe
   */
  public static toAgo(value: string | Date): string {
    if (value === null) {
      return '';
    }

    // this.logger.debug('dateAgo: ', value);

    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) {
        // less than 30 seconds ago will show as 'Just now'
        // return 'Just now';
        return '방금';
      }

      // const intervals: any = {
      //   year: 31536000,
      //   month: 2592000,
      //   week: 604800,
      //   day: 86400,
      //   hour: 3600,
      //   minute: 60,
      //   second: 1,
      // };
      // const intervals: any = {
      //   year: 31536000,
      //   month: 2592000,
      //   w: 604800, // 주
      //   d: 86400, // 일
      //   h: 3600, // 시간
      //   m: 60, // 분
      //   s: 1, // 초
      // };
      const intervals: any = {
        year: 31536000,
        month: 2592000,
        주: 604800, // 주
        일: 86400, // 일
        시간: 3600, // 시간
        분: 60, // 분
        초: 1, // 초
      };

      let counter;
      // for (const interval of intervals) {
      //   counter = Math.floor(seconds / interval);
      //   if (counter > 0) {
      //     if (counter === 1) {
      //       return counter + ' ' + interval + ' 전'; // singular (1 day ago)
      //     } else {
      //       return counter + ' ' + interval + ' 전'; // plural (2 days ago)
      //     }
      //   }
      // }

      // tslint:disable-next-line: forin
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);

        if (counter > 0) {
          if (counter === 1) {
            return counter + ' ' + i + ' 전'; // singular (1 day ago)
          } else {
            return counter + ' ' + i + ' 전'; // plural (2 days ago)
          }
        }
      }
    }
    return '';
  }
}
