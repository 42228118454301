import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import {
  GoogleSearchService,
  SearchItem,
} from '../services/google-search.service';
import { ViewportScroller } from '@angular/common';
import {
  map,
  mergeMap,
  delay,
  filter,
  debounceTime,
  concatMap,
} from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { BaseComponent } from '../base.component';
import { Store } from '@ngrx/store';
import { mainFeatureKey, MainState } from '../reducers/main.reducer';
import {
  // I am not sure what to do about this.
  browseReloadedRequest,
  scrollChanged,
} from '../actions/main.actions';
import { ScrollService } from '../services/scroll.service';
import { LocalStorageService } from '../services/local-storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';
import { MetaService } from '../services/meta.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { zoomIn, zoomOut } from 'ng-animate';

/**
 * Ex) https://www.google.com/webhp?igu=1&q=kakaotv
 * @author: john@gomedialy.com
 * @version: 0.20, 01/28/2021
 */
@Component({
  selector: 'app-browse',
  templateUrl: './browse.component.html',
  styleUrls: ['./browse.component.scss'],
  animations: [
    trigger('zoomIn', [
      transition(
        'void => *',
        useAnimation(zoomIn, { params: { timing: 0.25, delay: 0 } })
      ),
    ]),
    trigger('zoomOut', [
      transition(
        '* => void',
        useAnimation(zoomOut, { params: { timing: 0.25, delay: 0 } })
      ),
    ]),
  ],
})
export class BrowseComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {
  /* fields */
  query = '';
  // items: SearchItem[] = [];
  items$: Observable<SearchItem[]> = EMPTY;
  description = '';
  scrollToTopEnabled = false;
  zoomIn: any;
  zoomOut: any;

  constructor(
    private mainStore: Store<{
      [mainFeatureKey]: MainState;
    }>,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private googleSearchesService: GoogleSearchService,
    private viewportScroller: ViewportScroller,
    private scrollService: ScrollService,
    private spinner: NgxSpinnerService,
    private metaService: MetaService
  ) {
    super();

    const mainStoreSubscription = this.mainStore
      .select(mainFeatureKey)
      .pipe(
        debounceTime(250),
        mergeMap((state) => {
          switch (state.type) {
            case scrollChanged.type.toString():
              // console.log('scroll-changed: ', state);
              const offset = state.offset;
              setTimeout(() => {
                this.scrollToTopEnabled = offset !== 0;
              }, 500);
              break;
          }
          return EMPTY;
        })
      )
      .subscribe();
    this.subscriptions.add(mainStoreSubscription);
  }

  /**
   * To support both the desktop and the mobile.
   * On the mobile, the refresh does not seem to work. In that case, the second try works.
   * On the desktop, the first try and the second try work in a row and provide a smooth scrolling.
   */
  private scrollToLastBrowsePosition(): void {
    // this.openSnackBar('On first try to scroll....', 'ok');
    const scrollToLastBrowsePositionSubscription = this.scrollToLastBrowsePositionImpl(
      2000
    )
      .pipe(
        concatMap(() => {
          return this.mainStore.select(mainFeatureKey).pipe(
            filter((state) => {
              if (state.type === scrollChanged.type.toString()) {
                if (state.path === '/browse') {
                  return true;
                }
              }
              return false;
            }),
            debounceTime(250),
            mergeMap((state) => {
              const offset = state.offset;
              // console.log('updated: ', offset);

              return this.localStorageService
                .get2<number>('browse', 'openAt')
                .pipe(
                  concatMap((openAt) => {
                    if (openAt) {
                      // this.openSnackBar('On second try to scroll....', 'ok');

                      return this.scrollToLastBrowsePositionImpl(0).pipe(
                        concatMap(() => {
                          return this.localStorageService.delete2(
                            'browse',
                            'openAt'
                          );
                        })
                      );
                    }

                    return this.localStorageService.set2(
                      'browse',
                      'lastOffset',
                      offset
                    );
                  })
                );
            })
          );
        })
      )
      .subscribe();
    this.subscriptions.add(scrollToLastBrowsePositionSubscription);
  }

  private scrollToLastBrowsePositionImpl(
    delayInMis: number
  ): Observable<number | null> {
    return this.localStorageService.get2<number>('browse', 'lastOffset').pipe(
      delay(delayInMis),
      map((lastOffset) => {
        if (lastOffset) {
          /**
           * 70px is the margin-top
           */
          this.viewportScroller.scrollToPosition([0, lastOffset]);
        }
        return lastOffset;
      })
    );
  }

  // // TODO: remove ?
  // private openSnackBar(message: string, action: string) {
  //   this.snackbar.open(message, action, {
  //     duration: 2000,
  //   });
  // }

  ngAfterViewInit(): void {
    this.scrollToLastBrowsePosition();
    // this.restoreLastPosition();
  }

  ngOnInit(): void {
    this.spinner.show();

    const mainStoreSubscription = this.mainStore
      .select(mainFeatureKey)
      .pipe(
        delay(0), // not to have a ExpressionChangedAfterItHasBeenCheckedError
        mergeMap((state) => {
          /**
           * When /browse is reloaded, state.query is null.
           * This sends query to the app-bar
           */
          if (!state.query) {
            const query = this.route.snapshot.queryParamMap.get('q');
            if (query) {
              this.metaService.buildBrowse(query);
              this.mainStore.dispatch(browseReloadedRequest({ query }));
            }
          }
          return EMPTY;
        })
      )
      .subscribe();
    this.subscriptions.add(mainStoreSubscription);

    const queryValue = this.route.snapshot.queryParamMap.get('q');
    if (queryValue) {
      this.metaService.buildBrowse(queryValue);
      this.items$ = this.googleSearchesService
        .listenToSearchesData(queryValue)
        .pipe(
          tap(() => {
            // if (searchItems.length === 0) {
            //   this.openSnackBar('데이터가 없습니다.', '확인');
            // }
            this.spinner.hide();
          })
        );
    }
  }

  onScrollToTop(): void {
    this.scrollService.scrollTo('browse-top');
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    /**
     * Clear any scrolling-related data here on destroy()
     */
    this.localStorageService.delete2('browse', 'openAt').subscribe();
  }

  protected name(): string {
    return 'browse';
  }
}
