import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
} from '@angular/core';
import { Item, Snippet } from '../models/youtube-data';
import { Strings } from '../utils/strings';
import { DateTimeService } from '../services/date-time.service';
import { Router } from '@angular/router';
import { JSend } from '../models/jsend';
import { ContentCardEventService } from './content-card-event.service';
import { DOCUMENT } from '@angular/common';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebook,
  faFacebookSquare,
  faFacebookF,
  faTwitter,
  faFacebookMessenger,
} from '@fortawesome/free-brands-svg-icons';
import { from, Observable, EMPTY, of } from 'rxjs';
import { RealtimeCounterService } from '../services/realtime-counter.service';
import { map, catchError } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { environment } from '../../environments/environment';
import { Objects } from '../utils/objects';
import { KakaoService } from '../services/kakao.service';
import { BaseComponent } from '../base.component';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeIn } from 'ng-animate';
import { MatSnackBar } from '@angular/material/snack-bar';

/**
 * @author: john@gomedialy.com
 * @version: 0.14, 09/12/2020
 * @version: 0.33, 01/29/2021
 * @version: 0.34, 03/16.2021
 */
@Component({
  selector: 'app-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss'],
  animations: [
    trigger('fadeIn', [transition('void => *', useAnimation(fadeIn))]),
  ],
})
export class ContentCardComponent extends BaseComponent implements OnInit {
  /* fields */
  id = '';
  title = '';
  subtitle = '';
  imageUrl = 'https://via.placeholder.com/400x200.png?text=Loading';
  content = 'content';
  likeIconColor = 'gray';

  defaultImage = 'https://via.placeholder.com/400x200.png?text=Loading';

  // tslint:disable-next-line: no-input-rename
  @Input('imageUrl')
  titleImageUrl = '';

  // tslint:disable-next-line: no-input-rename
  @Input('inputData')
  inputData?: Item;

  // tslint:disable-next-line: no-input-rename
  @Input('tags')
  tags: string[] = [];
  // 'GoMedialy, Rambo, Stewie';

  @Output()
  events = new EventEmitter<Item>();

  // faCoffee = faCoffee;
  // // faFacebookF = faFacebookF;
  faFacebook = faFacebookF;
  faTwitter = faTwitter;
  // faKakao = faFacebookMessenger;
  faKakao = faComment;

  // channelUserCount$: Observable<number> = of(0);
  // viewCount$: Observable<number> = of(0);
  channelUserCount = 0;
  viewCount = 0;

  shareWatchUrl = '';
  shareDescription = '';

  // leave this empty for now.
  shareTags = '';
  fadeIn: any;

  constructor(
    private contentCardEventService: ContentCardEventService,
    private dateTimeService: DateTimeService,
    private snackbar: MatSnackBar,
    private realtimeCounterService: RealtimeCounterService,
    @Inject(DOCUMENT) private document: Document,
    private logger: NGXLogger,
    private kakao: KakaoService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.inputData) {
      const snippet: Snippet = this.inputData.snippet;
      this.title = snippet.channelTitle;

      const timestamp = this.dateTimeService.getTimestampFromDateString(
        snippet.publishedAt
      );
      this.subtitle = timestamp.toDate().toDateString();
      this.imageUrl = snippet.thumbnails.high.url;

      // smart scroll-id
      if (this.inputData.id.videoId) {
        this.id = this.inputData.id.videoId;
      }

      this.content = Strings.decodeHtml(this.document, snippet.title);

      const videoId = this.inputData.videoId;
      if (videoId) {
        // this.channelUserCount$ = this.realtimeCounterService.getCount(
        //   `channels/${videoId}/users`
        // );
        // this.viewCount$ = this.realtimeCounterService.getCount(
        //   `channels/${videoId}/views`
        // );

        const userCountSubscription = this.realtimeCounterService
          .getCount(`channels/${videoId}/users`)
          .subscribe((counts) => {
            this.channelUserCount = counts;
          });
        this.subscriptions.add(userCountSubscription);

        const viewCountSubscription = this.realtimeCounterService
          .getCount(`channels/${videoId}/views`)
          .subscribe((counts) => {
            this.viewCount = counts;
          });
        this.subscriptions.add(viewCountSubscription);

        this.shareWatchUrl = `${environment.gomedialyBaseUrl}/watch?v=${videoId}`;
        const query = this.inputData.query;
        this.shareDescription = `[#Haanglish 인기검색어:#${query}] ${this.content}`;
      }
    }
  }

  onCardImageClick(): void {
    if (this.inputData) {
      this.contentCardEventService.onCardImageClick(this.tags, this.inputData);
    }
  }

  onKakaoClick(): void {
    if (this.inputData && this.inputData.videoId) {
      this.openSnackBar('카카오 연결 중입니다.', '확인');
      this.kakao.sendLink(this.inputData);
    }
  }

  onLikeClick(): void {
    if (this.inputData) {
      this.contentCardEventService.onLikeClick(this.inputData);
    }
  }

  onShareClick(): void {
    if (this.inputData) {
      this.contentCardEventService.onShareClick(this.inputData);
    }
  }

  private openSnackBar(message: string, action: string): void {
    this.snackbar.open(message, action, {
      duration: 4000,
    });
  }
}
