<!-- 640 360 [width]="640" [height]="400"-->
<div
  class="container debug-red"
  [ngClass]="styleClass"
  fxLayout="column"
  fxLayoutAlign="space-around center"
>
  <mat-progress-bar
    *ngIf="videoId"
    mode="determinate"
    color="primary"
    value="{{ playTime }}"
  ></mat-progress-bar>

  <div *ngIf="videoId">
    <youtube-player
      #videoPlayer
      (apiChange)="onApiChange($event)"
      (ready)="onReady($event)"
      (stateChange)="onStateChange($event)"
      [id]="'video-player'"
      [videoId]="videoId"
      [startSeconds]="startSeconds"
      [endSeconds]="endSeconds"
      [width]="playerWidth"
      [height]="playerHeight"
      [playerVars]="playerVars"
    >
    </youtube-player>
  </div>

  <!-- <youtube-player
    #videoPlayer
    frameborder="0"
    allowfullscreen=""
    fxShow="true"
    videoId="{{ videoId }}"
    [width]="1440"
    [height]="872"
    [startSeconds]="0"
    [endSeconds]="1000000"
    [showBeforeIframeApiLoads]="true"
    [playerVars]="{
      autoplay: 0,
      controls: 1,
      modestbranding: 1,
      disablekb: 1,
      rel: 0,
      showinfo: 0,
      fs: 0,
      playsinline: 1
    }"
  ></youtube-player> -->

  <div *ngIf="false">
    <div
      class="video-navigation-bar debug-red"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <button mat-button color="primary">
        <mat-icon>info</mat-icon>
        <span class="video-id">{{ videoId }}</span>
      </button>
    </div>
  </div>
</div>

<!-- matBadge="+"
matBadgePosition="after"
matBadgeOverlap="true"
matBadgeColor="primary" -->

<!-- [playerVars]="{
      autoplay: 0,
      controls: 'controls',
      modestbranding: 1,
      cc_load_policy: 1,
      color: 'red',
      playsinline: 1,
      showinfo: 0
    }" -->
