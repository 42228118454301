<mat-card
  fxShow="{{ videoEditorInput === null && imageEditorInput === null }}"
  class="container mat-elevation-z5 debug-bluex"
>
  <mat-card-header class="debug-redx" style="height: 56px">
    <img mat-card-avatar src="{{ videoImageUrl }}" />

    <mat-card-title style="font-weight: bolder">{{ title }}</mat-card-title>
    <mat-card-subtitle style="font-weight: bolder">{{
      subtitle
    }}</mat-card-subtitle>
    <div
      class="debug-bluex"
      style="width: 400px"
      fxLayout="row"
      fxLayoutAlign="start start"
      fxLayoutGap="16px"
    >
      <div fxFlex></div>
      <div *ngIf="subtitleVideo">
        <app-video-button
          #subvideo0
          [video]="subtitleVideo"
          [play]="'stack'"
        ></app-video-button>
      </div>
      <div *ngIf="subvideo">
        <app-video-button
          #subvideo1
          [video]="subvideo"
          [play]="'stack'"
        ></app-video-button>
      </div>
    </div>
  </mat-card-header>

  <mat-card-content
    fxShow="{{ videoEditorInput === null && imageEditorInput === null }}"
  >
    <app-button-group
      [style.visibility]="userButtonGroupVisibility"
      #toolbarButtonGroup
      [buttonItems]="userButtonItems"
    ></app-button-group>
  </mat-card-content>
  <mat-card-content
    fxShow="{{ videoEditorInput === null && imageEditorInput === null }}"
  >
    <form [formGroup]="postFormGroup">
      <!-- <img mat-card-avatar src="{{ subvideoImageUrl }}" /> -->
      <mat-form-field class="post-editor-field" floatLabel="always">
        <!-- <mat-label>Add a public comment ...</mat-label> -->
        <textarea
          (blur)="onMessageInputFocus(false)"
          (focus)="onMessageInputFocus(true)"
          [cdkTextareaAutosize]="true"
          [cdkAutosizeMaxRows]="8"
          [cdkAutosizeMinRows]="4"
          formControlName="postInputControl"
          matInput
          #message
          maxlength="5012"
          placeholder="의견을 올려주세요 ..."
          (ngModelChange)="onContentChange($event)"
        ></textarea>
        <!-- <mat-icon matSuffix>add</mat-icon>
        <img matSuffix mat-card-avatar src="{{ subvideoImageUrl }}" /> -->

        <mat-hint align="start"
          ><strong>마크다운 (Mark Down)을 지원합니다.</strong>
        </mat-hint>
        <mat-hint align="end">{{ message.value.length }} / 5012</mat-hint>
      </mat-form-field>
    </form>
    <div class="buttons debug-greenx">
      <button mat-button appDebounceClick (debounceClick)="onCancelClick()">
        취소
      </button>
      <button
        mat-button
        appDebounceClick
        (debounceClick)="onPostClick()"
        *ngIf="content"
      >
        확인
      </button>
    </div>
  </mat-card-content>
</mat-card>

<div [@zoomIn]="zoomIn" *ngIf="videoEditorInput">
  <!-- video-editor -->
  <mat-card-content class="debug-redx">
    <app-video-editor
      [input]="videoEditorInput"
      #videoEditor
    ></app-video-editor>
  </mat-card-content>
</div>
<!-- image-editor -->
<div [@zoomIn]="zoomIn" *ngIf="imageEditorInput">
  <mat-card-content class="debug-redx">
    <app-image-editor [input]="imageEditorInput"> </app-image-editor>
  </mat-card-content>
</div>
<!-- emoji-picker -->
<div [@zoomIn]="zoomIn" *ngIf="emojiPickerEnabled">
  <!-- <emoji-mart title="Pick your emoji…" emoji="point_up"></emoji-mart> -->
  <!-- <emoji-mart set="google"></emoji-mart> -->
  <!-- <emoji-mart title="Go" (emojiClick)="addEmoji($event)"></emoji-mart> -->
  <emoji-mart
    title="이모티콘 선택"
    emoji="point_up"
    set="google"
    [showSingleCategory]="true"
    (emojiClick)="addEmoji($event)"
    [exclude]="['flags']"
    [style]="{ position: 'absolute', bottom: '200px', right: '0px' }"
    [i18n]="{
      search: '검색',
      categories: {
        search: '검색 결과',
        recent: '최신',
        people: '사람',
        nature: '자연',
        foods: '음식',
        activity: '활동',
        places: '장소',
        objects: '물건',
        symbols: '기호'
      }
    }"
  ></emoji-mart>
  <!-- <emoji-mart
      [i18n]="{
        search: 'Recherche',
        categories: { search: 'Résultats de recherche', recent: 'Récents' }
      }"
    ></emoji-mart> -->
</div>
