<mat-card class="container mat-elevation-z5">
  <mat-card-header class="debug-redx" style="height: 56px">
    <img mat-card-avatar src="{{ videoImageUrl }}" />

    <mat-card-title style="font-weight: bolder">동영상</mat-card-title>
    <mat-card-subtitle style="font-weight: bolder"
      >YouTube 동영상 진행바를 이용하여 '시작시간'과 '종료시간'으로 구간
      동영상을 만듭니다.</mat-card-subtitle
    >
  </mat-card-header>

  <mat-card-content class="debug-redx">
    <mat-horizontal-stepper
      linear
      #stepper
      (selectionChange)="selectionChange($event)"
      [disableRipple]="true"
    >
      <mat-step
        [stepControl]="videoUrlFormGroup"
        [editable]="false"
        [completed]="false"
      >
        <form class="debug-redx" [formGroup]="videoUrlFormGroup">
          <ng-template matStepLabel>동영상 주소 입력</ng-template>
          <mat-form-field class="youtube-url-field">
            <mat-label>YouTube 동영상</mat-label>
            <input
              #videoUrlInput
              matInput
              placeholder="https://www.youtube.com/watch?v=abcdefghijk"
              formControlName="videoUrlControl"
              required
              [readonly]="isVideoUrlReadOnly"
              [(ngModel)]="value"
            />
            <button
              mat-button
              *ngIf="value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="value = ''"
            >
              <mat-icon>close</mat-icon>
            </button>

            <!-- <mat-icon matSuffix svgIcon="youtube"></mat-icon> -->
          </mat-form-field>

          <div class="debug-bluex">
            <button mat-button (click)="onCancelClick()">취소</button>
            <button
              mat-button
              (click)="onUseCurrentVideoClick()"
              disabled="{{ videoUrlOkButtonEnabled }}"
            >
              현재 동영상 사용
            </button>
            <button
              mat-button
              matStepperNext
              (click)="onVideoUrlOkClick()"
              disabled="{{ !videoUrlOkButtonEnabled }}"
            >
              다음
            </button>
          </div>
        </form>
      </mat-step>

      <!-- //////////////////////////////////// -->

      <mat-step
        [stepControl]="startSecondsFormGroup"
        [editable]="false"
        [completed]="false"
      >
        <form class="debug-redx" [formGroup]="startSecondsFormGroup">
          <ng-template matStepLabel>동영상 시작 구간</ng-template>

          <mat-form-field class="start-seconds-field" appearance="standard">
            <mat-label>시작시간</mat-label>
            <input
              #startSecondsInput
              matInput
              placeholder="00:00:00"
              formControlName="startSecondsControl"
              [readonly]="true"
            />
            <mat-icon matSuffix>schedule</mat-icon>
          </mat-form-field>

          <div>
            <button mat-button (click)="onCancelClick()">취소</button>
            <!-- <button mat-button matStepperPrevious>이전</button> -->
            <button
              mat-button
              matStepperNext
              (click)="onStartSecondsOkClick()"
              disabled="{{ !startSecondsOkButtonEnabled }}"
            >
              다음
            </button>
          </div>
        </form>
      </mat-step>

      <!-- //////////////////////////////////// -->

      <mat-step
        [stepControl]="endSecondsFormGroup"
        [editable]="false"
        [completed]="false"
      >
        <form class="debug-redx" [formGroup]="endSecondsFormGroup">
          <ng-template matStepLabel>동영상 종료 구간</ng-template>

          <mat-form-field class="end-seconds-field" appearance="standard">
            <mat-label>종료시간</mat-label>
            <input
              #endSecondsInput
              matInput
              placeholder="00:00:00"
              formControlName="endSecondsControl"
              [readonly]="true"
            />
            <mat-icon matSuffix>schedule</mat-icon>
          </mat-form-field>

          <div>
            <button mat-button (click)="onCancelClick()">취소</button>
            <!-- <button mat-button matStepperPrevious>이전</button> -->
            <button
              mat-button
              matStepperNext
              (click)="onEndSecondsOkClick()"
              disabled="{{ !endSecondsOkButtonEnabled }}"
            >
              다음
            </button>
          </div>
        </form>
      </mat-step>

      <!-- //////////////////////////////////// -->
      <mat-step
        [stepControl]="finishFormGroup"
        [editable]="false"
        [completed]="false"
      >
        <form class="debug-redx" [formGroup]="finishFormGroup">
          <ng-template matStepLabel>동영상 완료</ng-template>

          <div class="debug-redx" fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field class="start-seconds-field" appearance="standard">
              <mat-label>시작시간</mat-label>
              <input
                #finalStartSecondsInput
                matInput
                placeholder="00:00:00"
                formControlName="finalStartSecondsControl"
                [readonly]="true"
              />
            </mat-form-field>

            <mat-form-field class="end-seconds-field" appearance="standard">
              <mat-label>종료시간</mat-label>
              <input
                matInput
                placeholder="00:00:00"
                formControlName="finalEndSecondsControl"
                [readonly]="true"
              />
            </mat-form-field>

            <mat-form-field class="end-seconds-field" appearance="standard">
              <mat-label>런팅타임</mat-label>
              <input
                matInput
                placeholder="00:00:00"
                formControlName="finalDurationSecondsControl"
                [readonly]="true"
              />
            </mat-form-field>

            <span class="placeholder">&nbsp;&nbsp;&nbsp;</span>

            <div class="debug-redx" *ngIf="video">
              <app-video-control
                [video]="video"
                [play]="'stack'"
              ></app-video-control>
            </div>
          </div>

          <!-- <button mat-button (click)="onTestPlayClick()">
            <mat-icon fxHide="{{ isTestPlaying }}"
              >play_circle_outline</mat-icon
            >
            <mat-icon fxShow="{{ isTestPlaying }}"
              >pause_circle_outline</mat-icon
            >
            <span class="test-play">동영상 확인</span>
          </button> -->

          <div>
            <button mat-button (click)="onCancelClick()">취소</button>
            <!-- <button mat-button (click)="stepper.reset()">초기화</button> -->
            <button mat-button (click)="onFinishClick()">완료</button>
          </div>
        </form>
      </mat-step>
      <!-- //////////////////////////////////// -->
    </mat-horizontal-stepper>
  </mat-card-content>
</mat-card>

<!-- <div mat-card-avatar class="header-image">
        <img src="https://i.ytimg.com/vi/E_o97EVajJI/default.jpg" />
      </div> -->
<!-- <div mat-card-avatar class="header-image"></div> -->
