import { Action, createReducer, on, props } from '@ngrx/store';
import * as DataActions from '../actions/data.actions';
import { Post } from '../models/posts';

/**
 * @author: john@gomedialy.com
 * @version: 0.14, 09/23/2020p
 * @version: 0.15, 01/13/2021
 */
export const dataFeatureKey = 'data';

// Ex) all, subtitles, mine
export interface DataState {
  // Reserved only for actions
  type: string | null;
  videoId: string;
  category: 'all' | 'subtitle' | 'mine';
  direction: 'previous' | 'next';
  pageSize: number;
  orderBy: 'asc' | 'desc';
  partition?: string;
  posts: Post[];
  isFirst: boolean;
  isLast: boolean;
  start?: string;
  end?: string;
}

export const initialState: DataState = {
  type: null,
  videoId: '',
  category: 'all',
  direction: 'next',
  pageSize: 0,
  orderBy: 'asc',
  partition: undefined,
  posts: [],
  isFirst: false,
  isLast: false,
  start: undefined,
  end: undefined,
};

export const dataReducer = createReducer(
  initialState,
  on(DataActions.clearDataState, (state, action) => {
    return { ...state, ...initialState, type: action.type };
  }),
  // New
  on(DataActions.newPostPageRequest, (state, action) => {
    return { ...state, ...action };
  }),
  on(DataActions.newPostPageRequestProcessing, (state, action) => {
    return { ...state, ...action };
  }),
  on(DataActions.newPostPageResponse, (state, action) => {
    return { ...state, ...action };
  }),
  // Natural
  on(DataActions.naturalPostPageRequest, (state, action) => {
    return { ...state, ...action };
  }),
  on(DataActions.naturalPostPageRequestProcessing, (state, action) => {
    return { ...state, ...action };
  }),
  on(DataActions.naturalPostPageResponse, (state, action) => {
    return { ...state, ...action };
  }),
  // Top
  on(DataActions.topPostPageRequest, (state, action) => {
    return { ...state, ...action };
  }),
  on(DataActions.topPostPageRequestProcessing, (state, action) => {
    return { ...state, ...action };
  }),
  on(DataActions.topPostPageResponse, (state, action) => {
    return { ...state, ...action };
  }),
  // Controversial
  on(DataActions.controversialPostPageRequest, (state, action) => {
    return { ...state, ...action };
  }),
  on(DataActions.controversialPostPageRequestProcessing, (state, action) => {
    return { ...state, ...action };
  }),
  on(DataActions.controversialPostPageResponse, (state, action) => {
    return { ...state, ...action };
  }),
  // Rising
  on(DataActions.risingPostPageRequest, (state, action) => {
    return { ...state, ...action };
  }),
  on(DataActions.risingPostPageRequestProcessing, (state, action) => {
    return { ...state, ...action };
  }),
  on(DataActions.risingPostPageResponse, (state, action) => {
    return { ...state, ...action };
  }),
  // Mine
  on(DataActions.minePostPageRequest, (state, action) => {
    return { ...state, ...action };
  }),
  on(DataActions.minePostPageRequestProcessing, (state, action) => {
    return { ...state, ...action };
  }),
  on(DataActions.minePostPageResponse, (state, action) => {
    return { ...state, ...action };
  }),
  // Etc
  on(DataActions.postPageRequest, (state, action) => {
    return { ...state, ...action };
  }),
  on(DataActions.postPageRequestProcessing, (state, action) => {
    return { ...state, ...action };
  }),
  on(DataActions.postPageResponse, (state, action) => {
    return { ...state, ...action };
  })
);
