import { BaseComponent } from './base.component';

/**
 * @author: john@gomedialy.com
 * @version: 0.1, 10/12/2020
 */
export class BaseHandler<C extends BaseComponent> {
  /* fields */
  parent: C;

  constructor(private parentComponent: C) {
    this.parent = parentComponent;
  }
}
