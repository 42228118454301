import { createAction, props } from '@ngrx/store';
import { ClientData, ContentData } from '../models/data';
import { WordCloudData } from '../services/data.service';

/**
 * @author: john@gomedialy.com
 * @version: 0.12, 01/30/2021
 */

export const wordcloudRequest = createAction('[Home] Wordcloud Request');

/**
 * Without a param: wordcloud, it reloads the current data.
 * Ex) dispatch(wordcloudResponse({}));
 */
export const wordcloudResponse = createAction(
  '[Home] Wordcloud Response',
  props<{
    wordcloud?: WordCloudData;
  }>()
);

export const contentDataRequest = createAction('[Home] Content Data Request');

export const contentDataResponse = createAction(
  '[Home] Content Data Response',
  props<{ content?: ClientData<ContentData[]> }>()
);
