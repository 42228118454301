import { createAction, props } from '@ngrx/store';
import { JSend } from '../models/jsend';

/**
 * @author: john@gomedialy.com
 * @version: 0.11, 10/14/2020
 */
export const editImageRequest = createAction(
  '[Image-Editor] Edit Image Request',
  props<{
    videoId: string;
    postId: string;
  }>()
);

export const editImageResponse = createAction(
  '[Image-Editor] Edit Image Response',
  props<{ jsend: JSend }>()
);

export const clearImageEditor = createAction(
  '[Image-Editor] Clear Image Editor'
);
