import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Store } from '@ngrx/store';
import { Observable, EMPTY, Subject, from, of } from 'rxjs';
import { BaseService, Context } from '../base.service';
import { mainFeatureKey, MainState } from '../reducers/main.reducer';
import firebase from 'firebase/compat/app';
import { NGXLogger } from 'ngx-logger';
import {
  catchError,
  map,
  mergeMap,
  distinctUntilChanged,
  tap,
  filter,
} from 'rxjs/operators';
import { LocalDataService } from './local-data.service';
import { LocalStorageService } from './local-storage.service';

/**
 * @author: john@gomedialy.com
 * @version: 0.14, 11/24/2020
 * @version: 0.16, 01/17/2021
 */
@Injectable({
  providedIn: 'root',
})
export class RealtimeCounterService extends BaseService {
  /* fields */
  private PERMISSION_DENIED_AT = 'permission_denied at';

  constructor(
    mainStore: Store<{
      [mainFeatureKey]: MainState;
    }>,
    protected logger: NGXLogger,
    private db: AngularFireDatabase,
    private localDataService: LocalDataService,
    private localStorageService: LocalStorageService
  ) {
    super(mainStore, logger);
  }

  // // TODO: delete?
  // getPersistentCount(path: string): Observable<number> {
  //   return this.valueChanges<number>(path).pipe(
  //     mergeMap((value) => {
  //       if (value) {
  //         return this.localStorageService.set<number>(path, value);
  //       }
  //       return this.localStorageService.get<number>(path);
  //     }),
  //     map((value) => {
  //       if (value === null) {
  //         return 0;
  //       }
  //       return value;
  //     }),
  //     catchError((error) => {
  //       const message = error.message as string;
  //       /**
  //        * permission_denied occurs in switching users like a user to an anonymous user.
  //        */
  //       // Error: permission_denied at /channels/sjyHKRypROk/views: Client doesn't have permission to access the desired data.
  //       //     at errorForServerCode (index.esm.js:650)
  //       //     at onComplete (index.esm.js:9059)
  //       //     at Object.onComplete (index.esm.js:12557)
  //       //     at PersistentConnection.push.6Uf2.PersistentConnection.onListenRevoked_ (index.esm.js:12167)
  //       //     at PersistentConnection.push.6Uf2.PersistentConnection.onDataPush_ (index.esm.js:11945)
  //       //     at PersistentConnection.push.6Uf2.PersistentConnection.onDataMessage_ (index.esm.js:11931)
  //       //     at Connection.push.6Uf2.Connection.onDataMessage_ (index.esm.js:11240)
  //       //     at Connection.push.6Uf2.Connection.onPrimaryMessageReceived_ (index.esm.js:11234)
  //       //     at WebSocketConnection.onMessage (index.esm.js:11135)
  //       //     at WebSocketConnection.push.6Uf2.WebSocketConnection.appendFrame_ (index.esm.js:10721)
  //       if (message.startsWith(this.PERMISSION_DENIED_AT)) {
  //         // this.logger.debug('[IGNORED]', this.PERMISSION_DENIED_AT + ' ...');
  //       } else {
  //         this.logger.warn(error);
  //       }
  //       return EMPTY;
  //     })
  //   );
  // }

  getCount(path: string): Observable<number> {
    return this.valueChanges<number>(path).pipe(
      filter((v) => {
        /**
         * Either null or 0 is filtered out.
         */
        if (v) {
          return v > 0;
        }
        return false;
      }),
      // tap((v) => console.error(v)),
      // mergeMap((value) => {
      //   return this.localDataService.get<number>(path).pipe(
      //     mergeMap((previousValue) => {
      //       if (previousValue) {
      //         if (previousValue === value) {
      //           // console.error(
      //           //   '###################### content-data the same: '
      //           // );
      //           // return EMPTY;
      //           return of(value);
      //         }
      //       }
      //       this.logger.debug('[COUNT] changed:', path, value);
      //       return this.localDataService.set(path, value).pipe(
      //         mergeMap((v) => {
      //           if (value) {
      //             return of(value);
      //           }
      //           return EMPTY;
      //           // return of(0);
      //         })
      //       );
      //     })
      //   );
      // }),
      // mergeMap((value) => {
      //   if (value) {
      //     return this.localDataService.set<number>(path, value);
      //   }
      //   return this.localDataService.get<number>(path);
      // }),
      mergeMap((value) => {
        if (value) {
          return of(value);
        }
        return EMPTY;
      }),
      distinctUntilChanged((a, b) => {
        this.logger.debug('[COUNT] distinct:', a, b);
        return a === b;
      }),
      catchError((error) => {
        const message = error.message as string;
        /**
         * permission_denied occurs in switching users like a user to an anonymous user.
         */
        // Error: permission_denied at /channels/sjyHKRypROk/views: Client doesn't have permission to access the desired data.
        //     at errorForServerCode (index.esm.js:650)
        //     at onComplete (index.esm.js:9059)
        //     at Object.onComplete (index.esm.js:12557)
        //     at PersistentConnection.push.6Uf2.PersistentConnection.onListenRevoked_ (index.esm.js:12167)
        //     at PersistentConnection.push.6Uf2.PersistentConnection.onDataPush_ (index.esm.js:11945)
        //     at PersistentConnection.push.6Uf2.PersistentConnection.onDataMessage_ (index.esm.js:11931)
        //     at Connection.push.6Uf2.Connection.onDataMessage_ (index.esm.js:11240)
        //     at Connection.push.6Uf2.Connection.onPrimaryMessageReceived_ (index.esm.js:11234)
        //     at WebSocketConnection.onMessage (index.esm.js:11135)
        //     at WebSocketConnection.push.6Uf2.WebSocketConnection.appendFrame_ (index.esm.js:10721)
        if (message.startsWith(this.PERMISSION_DENIED_AT)) {
          // this.logger.debug('[IGNORED]', this.PERMISSION_DENIED_AT + ' ...');
        } else {
          this.logger.warn(error);
        }
        return EMPTY;
      })
    );
  }

  /**
   * Ex) videos/{videoId}/viewCount
   */
  valueChanges<T>(valuePath: string): Observable<T | null> {
    return this.db.object<T>(valuePath).valueChanges();
  }

  /**
   * Ex) videos/{videoId}/viewCount
   */
  increase(valuePath: string, value: number): void {
    const valueRef = firebase.database().ref(valuePath);
    valueRef.transaction((currentValue) => {
      return currentValue + value;
    });
  }

  onInit(context: Context): void {}

  onDestroy(context: Context): void {}

  name(): string {
    return 'realtime-counter';
  }
}
