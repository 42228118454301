import { Post } from '../models/posts';
import { Observable, EMPTY, Subscription, throwError } from 'rxjs';
import { PostSortType, PageRequest, PageResponse } from './post.service';
import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Paths } from '../utils/paths';
import { AuthService } from './auth.service';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs/operators';
import { dataFeatureKey, DataState } from '../reducers/data.reducer';
import {
  controversialPostPageResponse,
  minePostPageResponse,
  naturalPostPageResponse,
  newPostPageResponse,
  risingPostPageResponse,
  topPostPageResponse,
} from '../actions/data.actions';
import firebase from 'firebase/compat/app';
import { PostPreferencesService } from './post-preferences.service';
import { PostControversialQueries } from '../data/post-controversial-queries';
import { PostGroupQueries } from '../data/post-group-queries';
import { PostMineQueries } from '../data/post-mine-queries';
import { PostNaturalQueries } from '../data/post-natural-queries';
import { PostRisingQueries } from '../data/post-rising-queries';
import { PostTopQueries } from '../data/post-top-queries';
import { UserPostQueries } from '../data/user-post-queries';
import { BaseService, Context } from '../base.service';
import { mainFeatureKey, MainState } from '../reducers/main.reducer';
import { NGXLogger } from 'ngx-logger';
import { Objects } from '../utils/objects';
import { clearDataState } from '../actions/data.actions';
import { authStateChanged } from '../actions/auth.actions';
import { authFeatureKey, AuthState } from '../reducers/auth.reducer';

/**
 * @author: john@gomedialy.com
 * @version: 0.26, 10/28/2020
 * @version: 0.27, 12/15/2020
 * @version: 0.30, 01/24/2021
 */
@Injectable({
  providedIn: 'root',
})
export class PostDataService extends BaseService {
  /* fields */
  postsListenerSubscription?: Subscription;
  postPreferencesListenerSubscription?: Subscription;
  videoId: string | null = null;
  data: Post[] = [];
  private lastUpdatedAt = firebase.firestore.Timestamp.now().toMillis();
  private lastPageRequestHash = '';

  constructor(
    mainStore: Store<{
      [mainFeatureKey]: MainState;
    }>,
    private authStore: Store<{
      [authFeatureKey]: AuthState;
    }>,
    protected logger: NGXLogger,
    private dataStore: Store<{
      [dataFeatureKey]: DataState;
    }>,
    private authService: AuthService,
    private postPreferencesService: PostPreferencesService,
    private postNaturalQueries: PostNaturalQueries,
    private postTopQueries: PostTopQueries,
    private postControversialQueries: PostControversialQueries,
    private postRisingQueries: PostRisingQueries,
    private postMineQueries: PostMineQueries,
    private userPostQueries: UserPostQueries,
    private postGroupQueries: PostGroupQueries
  ) {
    super(mainStore, logger);
  }

  findPost(postId: string): Post | undefined {
    return this.data.find((post) => post.postId === postId);
  }

  onInit(context: Context): void {
    // this.videoId = context.videoId;
    // if (this.videoId) {
    //   const user = this.authService.getUser();
    //   if (user) {
    //     this.postPreferencesService.setUserPostPreferencesListener(
    //       this.videoId,
    //       user
    //     );
    //   }
    // }

    this.videoId = context.videoId;
    if (this.videoId) {
      const authStoreSubscription = this.authStore
        .select(authFeatureKey)
        .pipe(
          mergeMap((state) => {
            switch (state.type) {
              case authStateChanged.type.toString():
                const user = this.authService.getUser();
                if (user) {
                  /**
                   * This should be done before load() to get users' likes and dislikes data.
                   */
                  this.postPreferencesService.setUserPostPreferencesListener(
                    context.videoId,
                    // this.videoId,
                    user
                  );
                }
                break;
            }
            return EMPTY;
          })
        )
        .subscribe();
      this.subscriptions.push(authStoreSubscription);
    }
  }

  onDestroy(context: Context): void {
    this.clear();
    this.dataStore.dispatch(clearDataState());
  }

  name(): string {
    return 'post-data';
  }

  private buildPostGroups(posts: Post[]): Post[][] {
    const groups = new Map<string, Post[]>();
    posts.forEach((post) => {
      post = this.postPreferencesService.updatePost(post);
      const path = post.path;
      const rootPath = Paths.root(path);
      const array = groups.get(rootPath);
      switch (post.level) {
        case 0:
          if (array) {
            array.unshift(post);
          } else {
            groups.set(rootPath, [post]);
          }
          break;
        default:
          if (array) {
            array.push(post);
          } else {
            groups.set(rootPath, [post]);
          }
          break;
      }
    });

    /**
     * All the posts are sorted by their path in the group,
     * which is in a natural order.
     * To sort posts by path, the path itself must be sortable as well.
     * That is why postId starts with a timestamp.
     */
    groups.forEach((group) => {
      group.sort((a, b) => a.path.localeCompare(b.path));
    });
    return Array.from(groups.values());
  }

  /**
   * All the groups are sorted by their first item,
   * which is the root.
   */
  private sortPostGroups(
    postGroups: Post[][],
    postSortType: PostSortType
  ): void {
    switch (postSortType) {
      case PostSortType.ALL_TOP:
        postGroups.sort((a, b) => b[0].likes - a[0].likes);
        break;
      case PostSortType.ALL_CONTROVERSIAL:
        postGroups.sort((a, b) => b[0].controversials - a[0].controversials);
        break;
      case PostSortType.ALL_RISING:
        postGroups.sort((a, b) => b[0].likes - a[0].likes);
        break;
      case PostSortType.ALL_NEW:
        postGroups.sort(
          // (a, b) => b[0].createdAt.toMillis() - a[0].createdAt.toMillis()
          (a, b) => b[0].groupedAt.toMillis() - a[0].groupedAt.toMillis()
        );
        break;
      case PostSortType.ALL_NATURAL:
        postGroups.sort(
          // (a, b) => a[0].createdAt.toMillis() - b[0].createdAt.toMillis()
          (a, b) => a[0].groupedAt.toMillis() - b[0].groupedAt.toMillis()
        );
        break;
      case PostSortType.SUBTITLES_TOP:
        postGroups.sort((a, b) => b[0].likes - a[0].likes);
        break;
      case PostSortType.SUBTITLES_CONTROVERSIAL:
        postGroups.sort((a, b) => b[0].controversials - a[0].controversials);
        break;
      case PostSortType.SUBTITLES_RISING:
        postGroups.sort((a, b) => b[0].likes - a[0].likes);
        break;
      case PostSortType.SUBTITLES_NEW:
        postGroups.sort(
          (a, b) => b[0].groupedAt.toMillis() - a[0].groupedAt.toMillis()
        );
        break;
      case PostSortType.SUBTITLES_NATURAL:
        postGroups.sort(
          (a, b) => a[0].groupedAt.toMillis() - b[0].groupedAt.toMillis()
        );
        break;
      // case 'asc':
      //   postNodeArray.sort(
      //     (a, b) =>
      //       a[0].post.createdAt.toMillis() - b[0].post.createdAt.toMillis()
      //   );
      //   break;
      // case 'desc':
      //   postNodeArray.sort(
      //     (a, b) =>
      //       b[0].post.createdAt.toMillis() - a[0].post.createdAt.toMillis()
      //   );
      //   break;
    }
  }

  /**
   * This can be called every 1 second at the worst cases on a single video.
   * TODO: subscritions and unsubscriptions MUST be dynamic!!!
   */
  load(postSortType: PostSortType, pageRequest: PageRequest): void {
    const pageRequestHash = Objects.hashFast(pageRequest);

    // console.error(
    //   '>>>>>>>>>>>>>>>>>>> load(): pageSize: ',
    //   pageRequest.pageSize,
    //   pageRequestHash
    // );

    /**
     * ! This seems to work really well.
     */
    if (this.lastPageRequestHash === pageRequestHash) {
      this.logger.debug('load() duplicate & ignored: ', pageRequestHash);
      if (this.videoId) {
        /**
         * A dummy response for response handling
         */
        this.dataStore.dispatch(
          newPostPageResponse({
            videoId: this.videoId,
            posts: this.data,
            isFirst: true,
            isLast: true,
          })
        );
      }
      return;
    } else {
      this.lastPageRequestHash = pageRequestHash;
    }

    // console.warn(
    //   'Loading: ',
    //   postSortType,
    //   pageRequest.category,
    //   pageRequest.direction,
    //   pageRequest.pageSize,
    //   pageRequest.orderBy
    //   // pageRequest.partition
    // );

    // clear subscriptions
    if (this.postsListenerSubscription) {
      this.postsListenerSubscription.unsubscribe();
    }

    if (this.postPreferencesListenerSubscription) {
      this.postPreferencesListenerSubscription.unsubscribe();
    }

    this.postsListenerSubscription = this.listenToPosts(
      postSortType,
      pageRequest
    )
      .pipe(
        switchMap((pageResponse) => {
          if (pageResponse) {
            const rawData = pageResponse.posts;
            const postGroups = this.buildPostGroups(rawData);
            this.sortPostGroups(postGroups, postSortType);
            const flattenedPosts = ([] as Post[]).concat(...postGroups);
            this.update(flattenedPosts);

            if (this.videoId) {
              switch (postSortType) {
                case PostSortType.ALL_NEW:
                case PostSortType.SUBTITLES_NEW:
                  this.dataStore.dispatch(
                    newPostPageResponse({
                      videoId: this.videoId,
                      posts: this.data,
                      isFirst: pageResponse.isFirst,
                      isLast: pageResponse.isLast,
                      start: pageResponse.start,
                      end: pageResponse.end,
                    })
                  );
                  break;
                case PostSortType.ALL_TOP:
                case PostSortType.SUBTITLES_TOP:
                  this.dataStore.dispatch(
                    topPostPageResponse({
                      videoId: this.videoId,
                      posts: this.data,
                      isFirst: pageResponse.isFirst,
                      isLast: pageResponse.isLast,
                      start: pageResponse.start,
                      end: pageResponse.end,
                    })
                  );
                  break;
                case PostSortType.ALL_CONTROVERSIAL:
                case PostSortType.SUBTITLES_CONTROVERSIAL:
                  this.dataStore.dispatch(
                    controversialPostPageResponse({
                      videoId: this.videoId,
                      posts: this.data,
                      isFirst: pageResponse.isFirst,
                      isLast: pageResponse.isLast,
                      start: pageResponse.start,
                      end: pageResponse.end,
                    })
                  );
                  break;
                case PostSortType.ALL_RISING:
                case PostSortType.SUBTITLES_RISING:
                  this.dataStore.dispatch(
                    risingPostPageResponse({
                      videoId: this.videoId,
                      posts: this.data,
                      isFirst: pageResponse.isFirst,
                      isLast: pageResponse.isLast,
                      start: pageResponse.start,
                      end: pageResponse.end,
                    })
                  );
                  break;
                case PostSortType.ALL_NATURAL:
                case PostSortType.SUBTITLES_NATURAL:
                  this.dataStore.dispatch(
                    naturalPostPageResponse({
                      videoId: this.videoId,
                      posts: this.data,
                      isFirst: pageResponse.isFirst,
                      isLast: pageResponse.isLast,
                      start: pageResponse.start,
                      end: pageResponse.end,
                    })
                  );
                  break;
                case PostSortType.ALL_MINE:
                case PostSortType.SUBTITLES_MINE:
                  this.dataStore.dispatch(
                    minePostPageResponse({
                      videoId: this.videoId,
                      posts: this.data,
                      isFirst: pageResponse.isFirst,
                      isLast: pageResponse.isLast,
                      start: pageResponse.start,
                      end: pageResponse.end,
                    })
                  );
                  break;
              }
            }
          } else {
            // no data
            if (this.videoId) {
              /**
               * A dummy response
               */
              this.dataStore.dispatch(
                newPostPageResponse({
                  videoId: this.videoId,
                  posts: this.data,
                  isFirst: true,
                  isLast: true,
                })
              );
            }

            this.clear();
          }

          return EMPTY;
        })
      )
      .subscribe();
  }

  private listenToPosts(
    postListType: PostSortType,
    pageRequest: PageRequest
  ): Observable<PageResponse | null> {
    switch (postListType) {
      case PostSortType.ALL_TOP:
        return this.postTopQueries.listenToTopPosts(false, pageRequest);
      case PostSortType.ALL_CONTROVERSIAL:
        return this.postControversialQueries.listenToControversialPosts(
          false,
          pageRequest
        );
      case PostSortType.ALL_RISING:
        return this.postRisingQueries.listenToRisingPosts(false, pageRequest);
      case PostSortType.ALL_NEW:
        return this.postNaturalQueries.listenToNaturalPosts(false, pageRequest);
      case PostSortType.ALL_NATURAL:
        return this.postNaturalQueries.listenToNaturalPosts(false, pageRequest);
      case PostSortType.ALL_MINE:
        const allMineUser = this.authService.getUser();
        if (allMineUser) {
          return this.postMineQueries.listenToMinePosts(
            false,
            pageRequest,
            allMineUser
          );
          // TODO: TEST:
          // pageRequest.userId = allMineUser.userId;
          // return this.listenToTop10GroupPostsByUserId(pageRequest);
        }
        return EMPTY;
      case PostSortType.SUBTITLES_TOP:
        return this.postTopQueries.listenToTopPosts(true, pageRequest);
      case PostSortType.SUBTITLES_CONTROVERSIAL:
        return this.postControversialQueries.listenToControversialPosts(
          true,
          pageRequest
        );
      case PostSortType.SUBTITLES_RISING:
        return this.postRisingQueries.listenToRisingPosts(true, pageRequest);
      case PostSortType.SUBTITLES_NEW:
        return this.postNaturalQueries.listenToNaturalPosts(true, pageRequest);
      case PostSortType.SUBTITLES_NATURAL:
        return this.postNaturalQueries.listenToNaturalPosts(true, pageRequest);
      case PostSortType.SUBTITLES_MINE:
        const subtitlesMineUser = this.authService.getUser();
        if (subtitlesMineUser) {
          return this.postMineQueries.listenToMinePosts(
            true,
            pageRequest,
            subtitlesMineUser
          );
        }
        return EMPTY;
    }
    // throw new Error(`Impossible error.`);
    // return this.postNaturalQueries.listenToNaturalPosts(false, pageRequest);
  }

  clear(): void {
    this.data = [];
  }

  reload(): void {
    const data = this.data;
    this.data = [];
    this.data = data;
  }

  /**
   * TODO: This must be super-efficient
   */
  // private lastUpdatedAt: Date = Date.now();
  update(posts: Post[]): void {
    const now = firebase.firestore.Timestamp.now().toMillis();
    const duration = now - this.lastUpdatedAt;
    // console.log('duration: ', duration);
    if (duration > 50) {
      const data = posts;
      this.data = [];
      this.data = data;
      this.lastUpdatedAt = now;
      // console.log('Updated: ', this.data);
    } else {
      // console.log('No update.');
    }
  }

  update2(posts: Post[]): void {
    const data = posts;
    this.data = [];
    this.data = data;
  }

  appendData(posts: Post[]): void {
    const data = this.data;
    const data2 = data.concat(posts);
    this.data = [];
    this.data = data2;
  }
}
