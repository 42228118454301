/**
 * @author: john@gomedialy.com
 * @version: 0.11, 01/12/2021
 */
export const YOUTUBE_IMAGE_PATH = 'assets/images/youtube-48-bw.png';
export const DEFAULT_USER_PHOTO_PATH = 'assets/images/default-user-photo.png';

// Icons
export class Icons {
  private constructor() {}

  public static defaultImageUrl(videoId: string): string {
    return `https://i.ytimg.com/vi/${videoId}/default.jpg`;
  }

  public static localDefaultImageUrl(): string {
    return 'assets/images/youtube-48-bw.png';
  }
}
