import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { NGXLogger } from 'ngx-logger';
import { UserPresence } from '../services/presence.service';

/**
 * @author: john@gomedialy.com
 * @version: 0.11, 01/16/2021
 */
export interface MessageData {
  icon: string | null;
  imageUrl: string | null;
  message: string;
}

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {
  /* fields */
  icon: string | null = null;
  imageUrl: string | null = null;
  message = '';

  constructor(
    private logger: NGXLogger,
    @Inject(MAT_SNACK_BAR_DATA) public messageData: MessageData
  ) {
    this.icon = messageData.icon;
    this.imageUrl = messageData.imageUrl;
    this.message = messageData.message;
  }

  ngOnInit(): void {}
}
