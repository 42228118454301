import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  NgZone,
  Inject,
  HostListener,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { mainFeatureKey, MainState } from '../reducers/main.reducer';
import { EMPTY, Observable, of } from 'rxjs';
import {
  mergeMap,
  filter,
  map,
  delay,
  concatMap,
  tap,
  switchMap,
} from 'rxjs/operators';
import { Item, EmptyItem } from '../models/youtube-data';
import { VideoPlayerComponent } from '../video-player/video-player.component';
import { SubtitleComponent } from '../subtitle/subtitle.component';
import { postFeatureKey, PostState } from '../reducers/post.reducer';
import { PostEditorComponent } from '../post-editor/post-editor.component';
import { BaseComponent } from '../base.component';
import {
  deletePendingPostsRequest,
  postsBoardReady,
} from '../actions/post.actions';
import { videoFeatureKey, VideoState } from '../reducers/video.reducer';
import { PostsBoardComponent } from '../posts-board/posts-board.component';
import { PostDataService } from '../services/post-data.service';
import { dataFeatureKey, DataState } from '../reducers/data.reducer';
import {
  controversialPostPageResponse,
  minePostPageRequest,
  minePostPageResponse,
  naturalPostPageRequest,
  naturalPostPageResponse,
  newPostPageRequest,
  risingPostPageRequest,
  risingPostPageResponse,
  topPostPageRequest,
  topPostPageResponse,
} from '../actions/data.actions';
import {
  scrollChanged,
  scrollDown,
  watchResponse,
} from '../actions/main.actions';
import { PaginatorEventService } from '../paginator/paginator-event.service';
import {
  newPostPageResponse,
  controversialPostPageRequest,
} from '../actions/data.actions';
import { ScrollService } from '../services/scroll.service';
import { WatchPostEditorHandler } from './watch-post-editor.handler';
import { WatchPostBoardHandler } from './watch-post-board.handler';
import { stackFeatureKey, StackState } from '../reducers/stack.reducer';
import { ImagesService } from '../services/images.service';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import {
  playOrPause,
  playVideo,
  seekLeftTo,
  seekRightTo,
} from '../actions/video.actions';
import { searchResponse, watchRefreshed } from '../actions/main.actions';
import { DOCUMENT } from '@angular/common';
import firebase from 'firebase/compat/app';
import { RealtimeCounterService } from '../services/realtime-counter.service';
import { PresenceService, UserPresence } from '../services/presence.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PresenceComponent } from '../presence/presence.component';
import { AuthService } from '../services/auth.service';
import { YouTubeDataService } from '../services/youtube-data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { VideoPlayerService } from '../video-player/video-player.service';
import { scrollToTop } from '../actions/main.actions';
import { DeviceDetectorService } from 'ngx-device-detector';
import { catchError, debounceTime } from 'rxjs/operators';
import { authFeatureKey, AuthState } from '../reducers/auth.reducer';
import { authStateChanged } from '../actions/auth.actions';
import { closeVideos } from '../actions/stack.actions';
import { NGXLogger } from 'ngx-logger';
import { LocalDataService } from '../services/local-data.service';
import {
  clearPostState,
  startSubpostingResponse,
} from '../actions/post.actions';
import { MetaService } from '../services/meta.service';
import {
  VideoBarComponent,
  VideoBarData,
} from '../video-bar/video-bar.component';
import { homeFeatureKey, HomeState } from '../reducers/home.reducer';
import {
  wordcloudResponse,
  contentDataResponse,
} from '../actions/home.actions';
import { WordCloudItem } from '../word-cloud/word-cloud.component';
import { wordcloudRequest } from '../actions/home.actions';
import { postPageRequest } from '../actions/data.actions';
import { environment } from 'src/environments/environment';
import { startSubpostingRequest } from '../actions/post.actions';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn, fadeIn, fadeOut, zoomIn, zoomOut } from 'ng-animate';

/**
 * @author: john@gomedialy.com
 * @version: 0.85, 01/30/2021
 */
@Component({
  selector: 'app-watch',
  templateUrl: './watch.component.html',
  styleUrls: ['./watch.component.scss'],
  animations: [
    trigger('bounceIn', [
      transition(
        'void => *',
        useAnimation(bounceIn, { params: { timing: 1, delay: 0 } })
      ),
    ]),
    trigger('zoomIn', [
      transition(
        'void => *',
        useAnimation(zoomIn, { params: { timing: 0.3, delay: 0 } })
      ),
    ]),
    trigger('zoomOut', [
      transition(
        '* => void',
        useAnimation(zoomOut, { params: { timing: 0.5, delay: 0 } })
      ),
    ]),
    trigger('fadeIn', [
      transition(
        'void => *',
        useAnimation(fadeIn, { params: { timing: 2, delay: 0 } })
      ),
    ]),
    trigger('fadeOut', [
      transition(
        '* => void',
        useAnimation(fadeOut, { params: { timing: 1, delay: 0 } })
      ),
    ]),
  ],
})
export class WatchComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy {
  /* fields */
  // For TabGroup
  tabInitialSelectedIndex = 0;
  subVideoTitle = '참고';

  // For youtube-player
  item!: Item;
  tags: string[] = [];

  /**
   * PostEditor enable or disable
   */
  postEditorInput: PostState | null = null;

  videoId: string;

  @ViewChild('videoPlayer')
  videoPlayerComponent!: VideoPlayerComponent;

  @ViewChild('subtitle')
  subtitleComponent!: SubtitleComponent;

  @ViewChild('postsBoard')
  postsBoardComponent!: PostsBoardComponent;

  @ViewChild('postEditor')
  postEditorComponent!: PostEditorComponent;

  channelTitle: string | undefined = undefined;
  subvideoId: string | null = null;

  playerWidth = 0;
  playerHeight = 0;
  startSeconds = 0;
  endSeconds = 0;

  scrollToTopEnabled = false;
  // tslint:disable-next-line: no-any
  private window: any;
  isDesktop = true;
  isVideoVisible = true;
  isPipVisible = false;
  isVideoBarVisible = false;

  /**
   * From watch-subtitle.handler.ts
   */
  videoState: VideoState | null = null;
  isPostEditorInput = this.postEditorInput !== null;

  /**
   * Accessed from app-video-bar
   */
  videoBarData: VideoBarData = { action: 'rewind', message: '' };
  bounceIn: any;
  zoomIn: any;
  zoomOut: any;
  fadeIn: any;
  fadeOut: any;

  private wordCloudItems: WordCloudItem[] = [];

  constructor(
    private logger: NGXLogger,
    private authStore: Store<{ [authFeatureKey]: AuthState }>,
    private mainStore: Store<{
      [mainFeatureKey]: MainState;
    }>,
    private homeStore: Store<{
      [homeFeatureKey]: HomeState;
    }>,
    private postStore: Store<{
      [postFeatureKey]: PostState;
    }>,
    private videoStore: Store<{
      [videoFeatureKey]: VideoState;
    }>,
    private dataStore: Store<{
      [dataFeatureKey]: DataState;
    }>,
    private stackStore: Store<{
      [stackFeatureKey]: StackState;
    }>,
    private postDataService: PostDataService,
    private paginatorEventService: PaginatorEventService,
    private scrollService: ScrollService,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone,
    private imagesService: ImagesService,
    private mediaObserver: MediaObserver,
    @Inject(DOCUMENT) private document: Document,
    private deviceService: DeviceDetectorService,
    private realtimeCounter: RealtimeCounterService,
    private presence: PresenceService,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private youTubeDataService: YouTubeDataService,
    private spinner: NgxSpinnerService,
    private videoPlayerService: VideoPlayerService,
    private localDataService: LocalDataService,
    private metaService: MetaService
  ) {
    super();

    /**
     * ! videoId is a must before anything else.
     */
    const v = this.activatedRoute.snapshot.queryParamMap.get('v');
    this.videoId = v ? v : '';

    this.window = this.document.defaultView;
    const mediaObserverSubscription = this.mediaObserver
      .asObservable()
      .subscribe((changes: MediaChange[]) => {
        switch (changes[0].mqAlias) {
          case 'xs':
            this.playerWidth = 375;
            this.playerHeight = 240;
            break;
          case 'sm':
            this.playerWidth = 640;
            this.playerHeight = 360;
            break;
          case 'md':
            this.playerWidth = 854;
            this.playerHeight = 480;
            break;
          case 'lg':
          case 'xl':
            this.playerWidth = 1280;
            this.playerHeight = 720;
            break;
          default:
            break;
        }
      });
    this.subscriptions.add(mediaObserverSubscription);
    this.isDesktop = this.deviceService.isDesktop();

    const homeStoreSubscription = this.homeStore
      .select(homeFeatureKey)
      .pipe(
        mergeMap((state) => {
          switch (state.type) {
            case wordcloudResponse.type.toString():
            case contentDataResponse.type.toString():
              if (state.wordcloud) {
                if (this.isDesktop) {
                  this.wordCloudItems = state.wordcloud.desktop;
                } else {
                  this.wordCloudItems = state.wordcloud.mobile;
                }
                this.handleBrowserTitle();
              }
              break;
          }
          return EMPTY;
        })
      )
      .subscribe();
    this.subscriptions.add(homeStoreSubscription);
  }

  private handleBrowserTitle(): void {
    if (this.wordCloudItems && this.item) {
      const query = this.item.query;
      const wordCloudItem = this.wordCloudItems.find(
        (wordCloudItem0) => wordCloudItem0.text === query
      );
      if (wordCloudItem) {
        this.metaService.buildWatch(this.videoId, wordCloudItem);
      } else {
        this.metaService.buildWatch(this.videoId);
      }
    } else if (this.item) {
      // do nothing.
    } else {
      this.metaService.buildWatch(this.videoId);
    }
  }

  onVideoVisible(visible: boolean): void {
    /**
     * visibility only works for desktops.
     */
    if (this.isDesktop) {
      this.videoPlayerService.onVideoPlayerVisible(this, visible);
    }
  }

  getLastScrollPosition(): Observable<number> {
    return this.mainStore.select(mainFeatureKey).pipe(
      filter((state) => {
        if (state.type === scrollChanged.type.toString()) {
          if (state.path === '/watch') {
            return true;
          }
        }
        return false;
      }),
      map((state) => {
        return state.offset;
      })
    );
  }

  ngOnInit(): void {
    /** spinner starts on init */
    this.spinner.show();
  }

  private ngAfterViewInitOnUserReady(): void {
    const postStoreSubscription = this.postStore
      .select(postFeatureKey)
      .pipe(
        mergeMap((state) => {
          switch (state.type) {
            case postsBoardReady.type.toString():
              /**
               * This is the best place for handling roll-backs only after the post-board is ready.
               */
              this.postStore.dispatch(deletePendingPostsRequest());
              // TODO: Test
              this.presence.joinChannel(this.videoId);
              break;
            /**
             * startSubpostingRequest looks better than startSubpostingResponse in transition smoothness.
             */
            case startSubpostingRequest.type.toString():
              // case startSubpostingResponse.type.toString():
              const selection = this.postsBoardComponent
                .selectionOptionSelectedValue;
              switch (selection) {
                case 'subtitle':
                  /**
                   * Transition to all-new.
                   */
                  this.postsBoardComponent.selectAllNew();
                  const videoId = state.videoId;
                  if (videoId) {
                    this.dataStore.dispatch(
                      newPostPageRequest({
                        videoId,
                        category: 'all',
                        direction: 'next',
                        pageSize: environment.pageSize,
                        orderBy: 'desc',
                      })
                    );
                  }
                  break;
                case 'all':
                  // ignored.
                  break;
              }
              break;
          }
          return EMPTY;
        })
      )
      .subscribe();
    this.subscriptions.add(postStoreSubscription);

    /**
     * Only once for initialization
     */
    const mainStoreSubscription = this.mainStore
      .select(mainFeatureKey)
      .pipe(
        mergeMap((state) => {
          switch (state.type) {
            case scrollToTop.type.toString():
              this.onScrollToTop();
              break;
            case scrollDown.type.toString():
              this.onScrollDown();
              break;
            case watchRefreshed.type.toString():
            case searchResponse.type.toString():
              // this.logger.debug('Either watchRefreshed or searchResponse');
              return this.youTubeDataService
                .findItemByVideoId(this.videoId)
                .pipe(
                  mergeMap((item) => {
                    if (item === null) {
                      // No item is found.
                      // this.metaService.buildWatch('', this.videoId);
                      this.handleBrowserTitle();
                      this.item = EmptyItem.create(
                        firebase.firestore.Timestamp.now()
                      );
                      this.item.videoId = this.videoId;
                      this.tags = state.tags;

                      /**
                       * !MUST: This updates video-store with videoId and put the video into the stack
                       * Not to have an ExpressionChangedAfterItHasBeenCheckedError
                       */
                      this.videoStore.dispatch(
                        playVideo({
                          videoId: this.videoId,
                          videoType: 'video',
                          postId: null,
                          startSeconds: 0, // from the beginning
                          endSeconds: 0,
                          duration: 0, // no idea
                        })
                      );

                      // TODO:
                      // export const postPageRequest = createAction(
                      //   '[Data] Post Page Request',
                      //   props<{
                      //     videoId: string;
                      //     category: 'all' | 'subtitle' | 'mine';
                      //     direction: 'previous' | 'next';
                      //     pageSize: number;
                      //     orderBy: 'asc' | 'desc';
                      //     /**
                      //      * startAfter() or endBefore()
                      //      */
                      //     partition?: string;
                      //   }>()
                      // );

                      // TODO: test
                      this.dataStore.dispatch(
                        postPageRequest({
                          videoId: this.videoId,
                          category: 'all',
                          direction: 'next',
                          pageSize: environment.pageSize,
                          orderBy: 'desc',
                        })
                      );
                    } else {
                      this.item = item;
                      this.tags = state.tags;
                      this.channelTitle = item.snippet.channelTitle;
                      /**
                       * We get an item, then we need WordCloud
                       */
                      this.homeStore.dispatch(wordcloudRequest());

                      /**
                       * !MUST: This updates video-store with videoId and put the video into the stack
                       * Not to have an ExpressionChangedAfterItHasBeenCheckedError
                       */
                      this.videoStore.dispatch(
                        playVideo({
                          videoId: this.videoId,
                          videoType: 'video',
                          postId: null,
                          startSeconds: 0, // from the beginning
                          endSeconds: 0,
                          duration: 0, // no idea
                        })
                      );

                      // TODO: Test
                      this.dataStore.dispatch(
                        postPageRequest({
                          videoId: this.videoId,
                          category: 'all',
                          direction: 'next',
                          pageSize: environment.pageSize,
                          orderBy: 'desc',
                        })
                      );
                    }

                    this.spinner.hide();
                    return EMPTY;
                  }),
                  catchError(() => {
                    return EMPTY;
                  })
                );
              break;
            case watchResponse.type.toString():
              // this.logger.debug('watchResponse');
              of('')
                .pipe(
                  delay(0),
                  concatMap(() => {
                    if (state.item) {
                      this.item = state.item;
                      this.tags = state.tags;
                      this.channelTitle = state.item.snippet.channelTitle;
                      this.handleBrowserTitle();
                    }
                    /**
                     * !MUST: This updates video-store with videoId and put the video into the stack
                     * Not to have an ExpressionChangedAfterItHasBeenCheckedError
                     */
                    this.videoStore.dispatch(
                      playVideo({
                        videoId: this.videoId,
                        videoType: 'video',
                        postId: null,
                        startSeconds: 0, // from the beginning
                        endSeconds: 0,
                        duration: 0, // no idea
                      })
                    );

                    this.spinner.hide();
                    return EMPTY;
                  })
                )
                .subscribe(() => {});
              break;
            case scrollChanged.type.toString():
              const offset = state.offset;
              return of(offset);
              break;
          }
          return EMPTY;
        }),
        debounceTime(250),
        mergeMap((offset) => {
          /**
           * Only offset reaches here.
           */
          setTimeout(() => {
            this.scrollToTopEnabled = offset !== 0;
          }, 500);
          return EMPTY;
        })
      )
      .subscribe();
    this.subscriptions.add(mainStoreSubscription);
    // tslint:disable-next-line: no-unused-expression
    new WatchPostEditorHandler(this, this.postStore);
    /**
     * Javascript connector
     */
    // tslint:disable-next-line: no-string-literal
    this.window['watchComponentReference'] = {
      component: this,
      zone: this.ngZone,
      loadAngularFunction: (url: string) => this.onPostImageClick(url),
    };
    const dataStoreSubscription = this.dataStore
      .select(dataFeatureKey)
      .pipe(
        mergeMap((state: DataState) => {
          if (this.postsBoardComponent) {
            switch (state.type) {
              case newPostPageRequest.type.toString():
              case topPostPageRequest.type.toString():
              case controversialPostPageRequest.type.toString():
              case naturalPostPageRequest.type.toString():
              case risingPostPageRequest.type.toString():
              case minePostPageRequest.type.toString():
                this.postsBoardComponent.setLoadingEnabled(true);
                break;
              case newPostPageResponse.type.toString():
              case topPostPageResponse.type.toString():
              case controversialPostPageResponse.type.toString():
              case naturalPostPageResponse.type.toString():
              case risingPostPageResponse.type.toString():
              case minePostPageResponse.type.toString():
                this.postsBoardComponent.setLoadingEnabled(false);
                break;
            }
          }
          this.paginatorEventService.setPreviousEnabled(!state.isFirst);
          this.paginatorEventService.setNextEnabled(!state.isLast);
          return EMPTY;
        })
      )
      .subscribe();
    this.subscriptions.add(dataStoreSubscription);
    const usersSubscription = this.presence
      .valueChanges<UserPresence>(`channels/${this.videoId}/event`)
      .pipe(
        map((userPresence) => {
          const user = this.authService.getUser();
          if (user && userPresence) {
            switch (userPresence.type) {
              case 'joined':
                this.openPresenceSnackBar(userPresence);
                break;
              case 'left':
                // ignored.
                break;
            }
            // if (user.uid !== userPresence.uid) {
            //   this.openPresenceSnackBar(userPresence);
            // }
          }
          return null;
        })
      )
      .subscribe();
    this.subscriptions.add(usersSubscription);
  }

  ngAfterViewInit(): void {
    const authStoreSubscription = this.authStore
      .select(authFeatureKey)
      .pipe(
        mergeMap((state) => {
          switch (state.type) {
            case authStateChanged.type.toString():
              /**
               * TODO: ngAfterViewInitOnUserReady()
               * ngOnInit() MUST wait until a user is ready.
               */
              this.ngAfterViewInitOnUserReady();
              break;
          }
          return EMPTY;
        })
      )
      .subscribe();
    this.subscriptions.add(authStoreSubscription);

    const viewCountSubscription = of('')
      .pipe(
        delay(15000),
        mergeMap(() => {
          this.realtimeCounter.increase(`channels/${this.videoId}/views`, 1);
          return EMPTY;
        })
      )
      .subscribe();
    this.subscriptions.add(viewCountSubscription);

    /**
     * These handlers have access to view-components.
     * Should stay here at ngAfterViewInit()
     */
    // tslint:disable-next-line: no-unused-expression
    new WatchPostBoardHandler(
      this,
      this.scrollService,
      this.postDataService,
      this.postStore,
      this.dataStore
    );

    // this.presence.joinChannel(this.videoId);
  }

  private onPostImageClick(postImageUrl: string): void {
    const postImageURL = new URL(postImageUrl);
    const videoId = postImageURL.searchParams.get('videoId');
    const postId = postImageURL.searchParams.get('postId');
    const imageId = postImageURL.searchParams.get('imageId');
    // console.error('onPostImageClick: ', videoId, postId, imageId);
    if (videoId && postId && imageId) {
      this.imagesService.showPostImages(videoId, postId, imageId);
    }
  }

  ngOnDestroy(): void {
    this.presence.leaveChannel(this.videoId);
    // Clear all the videos history
    this.postStore.dispatch(deletePendingPostsRequest());
    this.stackStore.dispatch(closeVideos());
    this.postStore.dispatch(clearPostState());
    super.ngOnDestroy();
  }

  onScrollToTop(): void {
    // console.log('>>>>>>>>>> onScrollToTop(): ');
    // this.setLastPosition();
    this.scrollService.scrollTo('watch-top');
  }

  onScrollDown(): void {
    // this.setLastPosition();
    // console.log('scrolldown');
    this.localDataService
      .get2<string>('watch', 'scrollId')
      .subscribe((scrollId) => {
        if (scrollId) {
          this.scrollService.scrollTo(scrollId);
        }
      });
  }

  private openPresenceSnackBar(userPresence: UserPresence): void {
    this.snackBar.openFromComponent(PresenceComponent, {
      duration: 2000,
      data: userPresence,
    });
  }

  // Only for logging
  name(): string {
    return 'watch';
  }

  @HostListener('window:keydown', ['$event'])
  // tslint:disable-next-line: typedef
  keyEvent(event: KeyboardEvent) {
    if (event.target instanceof HTMLTextAreaElement) {
    } else {
      switch (event.code) {
        case 'Space':
          this.videoStore.dispatch(playOrPause());

          /**
           * To remove a scroll with Space
           */
          event.preventDefault();
          break;
        case 'ArrowLeft':
          this.videoStore.dispatch(seekLeftTo());
          this.videoBarData = { action: 'rewind', message: '- 2' };
          this.isVideoBarVisible = true;
          setTimeout(() => {
            this.isVideoBarVisible = false;
          }, 500);
          break;
        case 'ArrowRight':
          this.videoStore.dispatch(seekRightTo());
          this.videoBarData = { action: 'forward', message: '+ 10' };
          this.isVideoBarVisible = true;

          setTimeout(() => {
            this.isVideoBarVisible = false;
          }, 500);
          break;
        case 'ArrowUp':
          // console.log(event.code);
          // this.videoStore.dispatch(volumeUp());
          break;
        case 'ArrowDown':
          // console.log(event.code);
          // this.videoStore.dispatch(volumeDown());
          break;
      }
    }
  }
}
