import { Injectable, Output, EventEmitter, Inject } from '@angular/core';
import { Item } from '../models/youtube-data';
import { DOCUMENT } from '@angular/common';
import { NGXLogger } from 'ngx-logger';
import { LocalStorageService } from '../services/local-storage.service';

/**
 * @author: john@gomedialy.com
 * @version: 0.13, 12/14/2020
 */
export interface SearchCardEvent {
  action: 'play' | 'like' | 'share';
  source: Item;
  tags: string[];
}

@Injectable({
  providedIn: 'root',
})
export class SearchCardService {
  /* fields */
  events = new EventEmitter<SearchCardEvent>();
  // private currentWindow: any;
  private window: any;

  constructor(
    private logger: NGXLogger,
    private localStorageService: LocalStorageService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.window = this.document.defaultView;
    // console.error('### WTF: db: ', this.db);
    // from(this.db.object('views/test').update({ count: 1 })).subscribe(
    //   (v) => console.log('result: ', v),
    //   (error) => console.error(error)
    // );
  }

  open(url: string): void {
    // this.logger.info('###################: open: ', url);
    /**
     * if 'url' exists,
     */
    const openAt = new Date().getTime();
    this.localStorageService.set2('browse', 'openAt', openAt).subscribe((v) => {
      this.window.open(url, '_self');
    });

    // this.router.navigate(['/browse'], { queryParams: { url } });
    // if (this.currentWindow) {
    //   this.currentWindow.close();
    // }

    // this.currentWindow = this.window.open(url, 'browse');
    // this.currentWindow.addEventListener(
    //   'scroll',
    //   (event: any) => {
    //     console.log('>>>>>>>>>>>>>>>>>> Wow scroll: ', event);
    //   },
    //   false
    // );
    // this.currentWindow.addEventListener(
    //   'focus',
    //   (event: any) => {
    //     console.log('>>>>>>>>>>>>>>>>>> Wow focus: ', event);
    //   },
    //   false
    // );
    // this.currentWindow.addEventListener(
    //   'blur',
    //   (event: any) => {
    //     console.log('>>>>>>>>>>>>>>>>>> Wow blur: ', event);
    //   },
    //   false
    // );
    // this.currentWindow.addEventListener(
    //   'pagehide',
    //   (event: any) => {
    //     console.log('>>>>>>>>>>>>>>>>>> Wow pagehide: ', event);
    //   },
    //   false
    // );
    // this.currentWindow.addEventListener(
    //   'pageshow',
    //   (event: any) => {
    //     console.log('>>>>>>>>>>>>>>>>>> Wow pageshow: ', event);
    //   },
    //   false
    // );
    // this.currentWindow.addEventListener(
    //   'tabhide',
    //   (event: any) => {
    //     console.log('>>>>>>>>>>>>>>>>>> Wow tabhide: ', event);
    //   },
    //   false
    // );
    // this.currentWindow.addEventListener(
    //   'tabshow',
    //   (event: any) => {
    //     console.log('>>>>>>>>>>>>>>>>>> Wow tabshow: ', event);
    //   },
    //   false
    // );
  }
}
