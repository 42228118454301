// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { NgxLoggerLevel } from 'ngx-logger';

/**
 * @author: john@gomedialy.com
 * @version: 0.1, 03/11/2021
 * @version: 0.11, 11/09/2021
 */

export const environment = {
  production: false,
  functionsBaseUrl: 'https://asia-northeast3-gomedialy-devx.cloudfunctions.net',
  firebase:
    'ewogICJhcGlLZXkiOiAiQUl6YVN5Qkhpa2taaWNqS1JveUpfcURyTG00T21nekRYaXdSdG8wIiwKICAiYXV0aERvbWFpbiI6ICJnb21lZGlhbHktZGV2eC5maXJlYmFzZWFwcC5jb20iLAogICJkYXRhYmFzZVVSTCI6ICJodHRwczovL2dvbWVkaWFseS1kZXZ4LWRlZmF1bHQtcnRkYi5maXJlYmFzZWlvLmNvbSIsCiAgInByb2plY3RJZCI6ICJnb21lZGlhbHktZGV2eCIsCiAgInN0b3JhZ2VCdWNrZXQiOiAiZ29tZWRpYWx5LWRldnguYXBwc3BvdC5jb20iLAogICJtZXNzYWdpbmdTZW5kZXJJZCI6ICI3MzcxMTk3MjAwNjEiLAogICJhcHBJZCI6ICIxOjczNzExOTcyMDA2MTp3ZWI6ZGMzYmYyMjhiYTJiNTBlOTVkOTcxNCIsCiAgIm1lYXN1cmVtZW50SWQiOiAiRy1QVzQ5VFA4Tk5UIgp9',
  kakao:
    'ewogICAgImphdmFzY3JpcHRLZXkiOiAiZDlhY2M0Yjc1NmFlYzYzMWVkYmY1ZWE0NGRkNTM3Y2QiCn0=',
  // subtitleDuration: 30, // 30 seconds
  pendingExpiration: 1800, // 30 minutes
  pageSize: 50, // 10 pages
  previewImagePrefix: '_200x200',
  sourceImagePrefix: '_1200x630',

  gomedialyBaseUrl: 'https://www.defiio.com',
  logging: {
    serverLoggingUrl: '/api/logs',
    level: NgxLoggerLevel.DEBUG,
    serverLogLevel: NgxLoggerLevel.OFF,
    disableConsoleLogging: false,
    enableSourceMaps: true,
  },
};
