import {
  trigger,
  transition,
  style,
  animate,
  keyframes,
} from '@angular/animations';

/**
 * @author: john@gomedialy.com
 * @version: 0.1, 11/18/2020
 * @version: 0.11, 01/16/2021
 */
export const fade = trigger('fade', [
  transition('void => *', [
    style({ opacity: 0, backgroundColor: 'pink' }),
    animate(1000),
  ]),
]);

export const flash = trigger('flash', [
  transition('* => *', [
    // the provided offset values
    animate(
      '250ms',
      keyframes([
        style({
          backgroundColor: '#BDBDBD',
          offset: 0.5,
        }),
        // style({ backgroundColor: '#EF5350', offset: 0.5 }),
        style({ backgroundColor: 'white', offset: 1 }),
        // style({ backgroundColor: 'red', offset: 0 }),
        // style({ backgroundColor: 'blue', offset: 0.2 }),
        // style({ backgroundColor: 'orange', offset: 0.3 }),
        // style({ backgroundColor: 'black', offset: 1 }),
      ])
    ),
  ]),
]);

// export const flash = trigger('flash', [
//   transition('true <=> false', [
//     // the provided offset values
//     animate(
//       '100ms',
//       keyframes([
//         style({
//           backgroundColor: '#BDBDBD',
//           offset: 0.5,
//         }),
//         // style({ backgroundColor: '#EF5350', offset: 0.5 }),
//         style({ backgroundColor: 'white', offset: 1 }),
//         // style({ backgroundColor: 'red', offset: 0 }),
//         // style({ backgroundColor: 'blue', offset: 0.2 }),
//         // style({ backgroundColor: 'orange', offset: 0.3 }),
//         // style({ backgroundColor: 'black', offset: 1 }),
//       ])
//     ),
//   ]),
// ]);
