import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  Input,
  EventEmitter,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PostDataService } from '../services/post-data.service';
import {
  MatButtonToggleGroup,
  MatButtonToggleChange,
} from '@angular/material/button-toggle';
import { Item } from '../models/youtube-data';
import { BaseComponent } from '../base.component';
import { PaginatorComponent } from '../paginator/paginator.component';
import { ScrollService } from '../services/scroll.service';
import {
  PaginatorEvent,
  PaginatorEventService,
} from '../paginator/paginator-event.service';
import { Store } from '@ngrx/store';
import { postFeatureKey, PostState } from '../reducers/post.reducer';
import { map, mergeMap, delay, debounceTime } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';
import {
  PostCountsService,
  PostCountsListener,
} from '../services/post-counts.service';
import {
  startPostingRequest,
  startSubpostingRequest,
} from '../actions/post.actions';
import { Post, PostCounts } from '../models/posts';
import { RealtimeCounterService } from '../services/realtime-counter.service';
import { postsBoardReady } from '../actions/post.actions';
import { environment } from 'src/environments/environment';
import { newPostPageRequest } from '../actions/data.actions';
import { dataFeatureKey, DataState } from '../reducers/data.reducer';

/**
 * @author: john@gomedialy.com
 * @version: 0.39, 09/26/2020
 * @version: 0.46, 01/30/2021
 */
export interface PostBoardEvent {
  action:
    | 'sort_all_top'
    | 'sort_all_controversial'
    | 'sort_all_rising'
    | 'sort_all_new'
    | 'sort_all_natural'
    | 'sort_all_mine'
    | 'sort_subtitle_top'
    | 'sort_subtitle_controversial'
    | 'sort_subtitle_rising'
    | 'sort_subtitle_new'
    | 'sort_subtitle_natural'
    | 'sort_subtitle_mine';
  videoId: string;
  paginatorEvent: PaginatorEvent;
}
@Component({
  selector: 'app-posts-board',
  templateUrl: './posts-board.component.html',
  styleUrls: ['./posts-board.component.scss'],
})
export class PostsBoardComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy, PostCountsListener {
  /* fields */
  commentFormGroup: FormGroup;
  postEditorEnabled = false;
  private initialSortOptionSelectedValue:
    | 'top'
    | 'controversial'
    | 'rising'
    | 'new'
    | 'natural'
    | 'mine' = 'new';
  private initialSelectionOptionSelectedValue: 'all' | 'subtitle' = 'all';

  // From watch
  @Input()
  item!: Item;

  events = new EventEmitter<PostBoardEvent>();

  @ViewChild('sortOptionsGroup')
  sortOptionsGroup?: MatButtonToggleGroup;

  @ViewChild('selectionOptionsGroup')
  selectionOptionsGroup?: MatButtonToggleGroup;

  @ViewChild('topPaginator')
  topPaginator?: PaginatorComponent;

  @ViewChild('bottomPaginator')
  bottomPaginator?: PaginatorComponent;

  private sortOption:
    | 'top'
    | 'controversial'
    | 'rising'
    | 'new'
    | 'natural'
    | 'mine' = this.initialSortOptionSelectedValue; // 'top';
  private selectionOption: 'all' | 'subtitle' = this
    .initialSelectionOptionSelectedValue; // 'all';

  // From html
  dataService: PostDataService;
  // data: Post[] = [];
  private videoId = '';
  isLoading = false;

  // [(model)]
  sortOptionSelectedValue = this.initialSortOptionSelectedValue;

  // [(model)]
  selectionOptionSelectedValue = this.initialSelectionOptionSelectedValue;
  postCount = 0;
  // channelUserCount$: Observable<number> = of(0);
  channelUserCount = 0;

  constructor(
    private formBuilder: FormBuilder,
    private postDataService: PostDataService,
    // private paginatorEventService: PaginatorEventService,
    private scrollService: ScrollService,
    private postStore: Store<{
      [postFeatureKey]: PostState;
    }>,
    private dataStore: Store<{
      [dataFeatureKey]: DataState;
    }>,
    private postCountsService: PostCountsService,
    private realtimeCounter: RealtimeCounterService
  ) {
    super();
    this.commentFormGroup = this.formBuilder.group({
      commentInputControl: ['', Validators.nullValidator],
    });

    /**
     * !Note:
     * !this.data = this.postDataService.data; (x)
     * this.dataService = this.postDataService; (o)
     */
    this.dataService = this.postDataService;

    const postStoreSubscription = this.postStore
      .select(postFeatureKey)
      .pipe(
        mergeMap((state) => {
          switch (state.type) {
            case startPostingRequest.type.toString():
              this.scrollService.scrollTo('watch-center', 500);
              // TODO:
              this.selectAllNew();
              const videoId = state.videoId;
              if (videoId) {
                this.dataStore.dispatch(
                  newPostPageRequest({
                    videoId,
                    category: 'all',
                    direction: 'next',
                    pageSize: environment.pageSize,
                    orderBy: 'desc',
                  })
                );
              }

              break;
            case startSubpostingRequest.type.toString():
              // TODO:
              // this.scrollService.scrollTo('watch-top');
              break;
              // case startSubtitlePostingRequest.type.toString():
              // TODO:
              // this.scrollService.scrollTo('watch-top');
              break;
          }

          return EMPTY;
        })
      )
      .subscribe();
    this.subscriptions.add(postStoreSubscription);
  }

  onPostCountsChange(postCounts: PostCounts): void {
    // throw new Error('Method not implemented.');
    // console.log('>>> ', postCounts);
    this.postCount =
      postCounts.postsCount +
      postCounts.subpostsCount +
      postCounts.titlesCount +
      postCounts.subtitlesCount;
  }

  setLoadingEnabled(loading: boolean): void {
    this.isLoading = loading;
  }

  /**
   * For starting posts and titles.
   */
  selectAllNew(): void {
    if (this.selectionOptionsGroup && this.sortOptionsGroup) {
      this.selectionOptionSelectedValue = 'all';
      this.sortOptionSelectedValue = 'new';
      this.selectionOption = this.selectionOptionSelectedValue;
      this.sortOption = this.sortOptionSelectedValue;
      // TODO: Test
      // this.firePostBoardEvent({ action: 'init' });
    }
  }

  /**
   * TODO: performance bottlenect
   */
  onScrollDown(): void {
    // console.log('@@@@@@@@@@@@@@@@@@@@@ onScrollDown');
    // this.scrollService.onScrollDown();
    // this.firePostBoardEvent({ action: 'next' });

    /**
     * This seems to work better.
     */
    of('')
      .pipe(debounceTime(100))
      .subscribe((v) => {
        // console.log('@@@@@@@@@@@@@@@@@@@@@ onScrollDown');
        this.scrollService.onScrollDown();
        /**
         * This can be called on every 1 second.
         */
        this.firePostBoardEvent({ action: 'next' });
      });
  }

  /**
   * This is called rarely.
   */
  onScrollUp(): void {
    this.scrollService.onScrollUp();
  }

  ngAfterViewInit(): void {
    this.sortOptionSelectedValue = this.initialSortOptionSelectedValue;
    this.selectionOptionSelectedValue = this.initialSelectionOptionSelectedValue;

    /**
     * delay(0) makes sure that this will be executed after ngAfterViewInit().
     * Withouth this, the order is changed.
     *
     * For watch
     * @ViewChild('postsBoard')
     * postsBoardComponent!: PostsBoardComponent;
     */
    of('')
      .pipe(delay(0))
      .subscribe((v) => {
        this.postStore.dispatch(postsBoardReady());
      });
  }

  // [value] = 'selectedValue';
  onSortOptionChange(event: MatButtonToggleChange): void {
    this.sortOption = event.value;
    this.firePostBoardEvent({ action: 'init' });
    // console.error('>>> onSortOptionChange: ', event.value);
  }

  onSelectionOptionChange(event: MatButtonToggleChange): void {
    this.selectionOption = event.value;
    this.firePostBoardEvent({ action: 'init' });
  }

  /**
   * No paginatorEvent means sort & selection change
   */
  private firePostBoardEvent(paginatorEvent: PaginatorEvent): void {
    // console.error(
    //   '>>>>>>>>>>>>>>>>>>> firePostBoardEvent: ',
    //   this.selectionOption,
    //   this.sortOption
    // );
    switch (this.selectionOption) {
      case 'all':
        switch (this.sortOption) {
          case 'top': // sort_top
            this.events.emit({
              action: 'sort_all_top',
              videoId: this.videoId,
              paginatorEvent,
            });
            break;
          case 'controversial': // sort_controversial
            this.events.emit({
              action: 'sort_all_controversial',
              videoId: this.videoId,
              paginatorEvent,
            });
            break;
          case 'rising': // sort_rising
            this.events.emit({
              action: 'sort_all_rising',
              videoId: this.videoId,
              paginatorEvent,
            });
            break;
          case 'new': // sort_new
            this.events.emit({
              action: 'sort_all_new',
              videoId: this.videoId,
              paginatorEvent,
            });
            break;
          case 'natural': // sort_new
            this.events.emit({
              action: 'sort_all_natural',
              videoId: this.videoId,
              paginatorEvent,
            });
            break;
          case 'mine': // sort_new
            this.events.emit({
              action: 'sort_all_mine',
              videoId: this.videoId,
              paginatorEvent,
            });
            break;
        }
        break;
      case 'subtitle':
        switch (this.sortOption) {
          case 'top': // sort_top
            this.events.emit({
              action: 'sort_subtitle_top',
              videoId: this.videoId,
              paginatorEvent,
            });
            break;
          case 'controversial': // sort_controversial
            this.events.emit({
              action: 'sort_subtitle_controversial',
              videoId: this.videoId,
              paginatorEvent,
            });
            break;
          case 'rising': // sort_rising
            this.events.emit({
              action: 'sort_subtitle_rising',
              videoId: this.videoId,
              paginatorEvent,
            });
            break;
          case 'new': // sort_new
            this.events.emit({
              action: 'sort_subtitle_new',
              videoId: this.videoId,
              paginatorEvent,
            });
            break;
          case 'natural': // sort_new
            this.events.emit({
              action: 'sort_subtitle_natural',
              videoId: this.videoId,
              paginatorEvent,
            });
            break;
          case 'mine':
            this.events.emit({
              action: 'sort_subtitle_mine',
              videoId: this.videoId,
              paginatorEvent,
            });
            break;
        }
        break;
        break;
    }
  }

  /**
   * TODO: this can be called many times
   */
  ngOnInit(): void {
    if (this.item.videoId) {
      this.videoId = this.item.videoId;
    }

    this.postCountsService.setPostCountsListener(this.videoId, this);

    const channelUserCountSubscription = this.realtimeCounter
      .valueChanges<number>(`channels/${this.videoId}/users`)
      .pipe(
        map((value) => {
          if (value === null) {
            return 0;
          }
          this.channelUserCount = value;
          return value;
        })
      )
      .subscribe();
    this.subscriptions.add(channelUserCountSubscription);
  }

  // For logging
  name(): string {
    return 'posts-board';
  }
}
