import { createAction, props } from '@ngrx/store';
import { JSend } from '../models/jsend';

/**
 * @author: john@gomedialy.com
 * @version: 0.13, 10/10/2020
 * @version: 0.14, 12/17/2020
 */
export const editSubvideoRequest = createAction(
  '[Video-Editor] Edit Subvideo Request',
  props<{
    /**
     * For a future use (?)
     * Either the video or an external video
     */
    videoId?: string;
    postId: string;
  }>()
);

export const editSubvideoResponse = createAction(
  '[Video-Editor] Edit Subvideo Response',
  props<{ jsend: JSend }>()
);

export const clearVideoEditor = createAction(
  '[Video-Editor] Clear Video Editor'
);

// // TODO: to be removed.
// export const editSubtitleRequest = createAction(
//   '[Video-Editor] Edit Subtitle Video Request',
//   props<{
//     videoId: string;
//     postId: string;
//   }>()
// );

// // TODO: to be removed.
// export const editSubtitleResponse = createAction(
//   '[Video-Editor] Edit Subtitle Video Response',
//   props<{ jsend: JSend }>()
// );
