// import {
//     Exception
// } from "./exceptions";

// import {Optional} from "typescript-optional";
// import {EventBase} from "../models/models";

/**
 * All the data services merge here.
 * @author: john@gomedialy.com
 * @version: 0.11, 09/18/2020
 */
export class Arrays {
  public static isEmpty(array: any[] | undefined): boolean {
    if (array) {
      return !Array.isArray(array) || !array.length;
    }
    return true;
  }

  public static last<T>(array: T[]): T {
    Preconditions.checkState(!Arrays.isEmpty(array), 'Empty array.');
    return array[array.length - 1];
  }

  public static shuffle<T>(array: T[]): T[] {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }
}

export class Exception {
  private constructor(
    public readonly name: string,
    public readonly message: string
  ) {}

  public static IllegalArgumentException(description: string): Exception {
    return new Exception('IllegalArgumentException', description);
  }

  public static IllegalStateException(description: string): Exception {
    return new Exception('IllegalStateException', description);
  }

  public static NullPointerException(description: string): Exception {
    return new Exception('NullPointerException', description);
  }

  public static IndexOutOfBoundsException(description: string): Exception {
    return new Exception('IndexOutOfBoundsException', description);
  }
}

export class Preconditions {
  private constructor() {
    // Static class type
  }

  public static isIf(value: any): boolean {
    // return (value === undefined || value == null || value.length <= 0) ? true : false;
    if (value) {
      return true;
    }
    return false;
  }

  public static checkArgument(
    expression: boolean,
    message: string = '',
    placeholders: Placecholders = ['']
  ): void {
    if (!expression) {
      throw this.createError(
        Exception.IllegalArgumentException,
        message,
        placeholders
      );
    }
  }

  public static checkState(
    expression: boolean,
    message: string = '',
    placeholders: Placecholders = ['']
  ): void {
    if (!expression) {
      throw this.createError(
        Exception.IllegalStateException,
        message,
        placeholders
      );
    }
  }

  public static checkNotNull<T>(
    object: T,
    message: string = '',
    placeholders: Placecholders = ['']
  ): T {
    if (object === undefined || object === null) {
      throw this.createError(
        Exception.NullPointerException,
        message,
        placeholders
      );
    }

    return object;
  }

  public static checkElementIndex(
    index: number,
    limit: number,
    message: string = '',
    placeholders: Placecholders = ['']
  ): number {
    if (index < 0 || index >= limit) {
      throw this.createError(
        Exception.IndexOutOfBoundsException,
        message,
        placeholders
      );
    }
    return index;
  }

  public static checkPositionIndex(
    index: number,
    limit: number,
    message: string = '',
    placeholders: Placecholders = ['']
  ): number {
    if (index < 0 || index > limit) {
      throw this.createError(
        Exception.IndexOutOfBoundsException,
        message,
        placeholders
      );
    }
    return index;
  }

  public static checkPositionIndexes(
    start: number,
    end: number,
    limit: number,
    message: string = '',
    placeholders: Placecholders = ['']
  ): void {
    if (start < 0 || end < start || end > limit) {
      throw this.createError(
        Exception.IndexOutOfBoundsException,
        message,
        placeholders
      );
    }
  }

  // private static createException(error: (message: string) => Exception, message: string, placeholders: Placecholders) : Exception {
  //     const template = new Formatter(placeholders);
  //     return error(template.format(message));
  // }

  private static createError(
    error: (message: string) => Exception,
    message: string,
    placeholders: Placecholders
  ): Error {
    const template = new Formatter(placeholders);
    return new Error(template.format(message));
  }
}

type Placecholders = [any] | { [property: string]: any };
class Formatter {
  private parameters: Map<string, string>;
  private expression: RegExp;

  constructor(values: Placecholders) {
    this.parameters = new Map<string, string>();
    const keys = new Array<string>();
    keys.forEach((key) => {
      // @ts-ignore
      this.parameters.set(`\$\{${key}\}`, String(values[key])); // todo(sigur) resolve TS7017
      keys.push(`\\$\\{${key}\\}`);
    });

    // for (key in values) {
    //   // @ts-ignore
    //   this.parameters.set(`\$\{${key}\}`, String(values[key])); // todo(sigur) resolve TS7017
    //   keys.push(`\\$\\{${key}\\}`);
    // }

    this.expression = new RegExp(keys.join('|'), 'g');
  }

  // todo(sigur) in Guava library all the "not expression parameters" are appended to the end
  public format(template: string): string {
    return template.replace(this.expression, (pattern: string) => {
      return this.parameters.get(pattern) || pattern;
    });
  }
}
