import { createAction, props } from '@ngrx/store';
import { Post, Video } from '../models/posts';

/**
 * @author: john@gomedialy.com
 * @version: 0.22, 10/14/2020
 * @version: 0.27, 01/18/2021
 */

/**
 * Loading PostsBoardComponent takes time and slower than any other components.
 * This is to receive the component ready event.
 */
export const deletePostRequest = createAction(
  '[Post] Delete Post Request',
  props<{
    videoId: string;
    postId: string;
  }>()
);

export const deletePostResponse = createAction(
  '[Post] Delete Post Response',
  props<{ videoId: string; postId: string }>()
);

// TODO: Posts Board Initialized
export const postsBoardReady = createAction('[Post] Posts-Board Ready');

export const createLikePostRequest = createAction(
  '[Post] Create Like-Post Request',
  props<{
    videoId: string;
    postId: string;
  }>()
);

export const createLikePostResponse = createAction(
  '[Post] Create Like-Post Response',
  props<{ videoId: string; postId: string }>()
);

export const createDislikePostRequest = createAction(
  '[Post] Create Dislike-Post Request',
  props<{
    videoId: string;
    postId: string;
  }>()
);

export const createDislikePostResponse = createAction(
  '[Post] Create Dislike-Post Response',
  props<{ videoId: string; postId: string }>()
);

export const clearPostState = createAction('[Post] Clear Post State');

export const deletePendingPostsRequest = createAction(
  '[Post] Delete Pending Posts Request'
);

export const deletePendingPostsResponse = createAction(
  '[Post] Delete Pending Posts Response'
);

export const startPostingRequest = createAction(
  '[Post] Start Posting Request',
  props<{
    videoId: string;
    tags: string[];
  }>()
);

export const startPostingResponse = createAction(
  '[Post] Start Posting Response',
  props<{ videoId: string; postId: string }>()
);

export const startEditPostRequest = createAction(
  '[Post] Start Edit Post Request',
  props<{
    videoId: string;
    postId: string;
    content: string; // MUST
    subvideo0: Video | null; // null means subvideo1 removal
    subvideo1: Video | null; // null means subvideo1 removal
  }>()
);

export const startEditPostResponse = createAction(
  '[Post] Start Edit Post Response',
  props<{ videoId: string; postId: string }>()
);

// TODO: commitPostUpdateRequest
export const commitPostUpdateRequest = createAction(
  '[Post] Commit Post Update Request',
  props<{
    videoId: string;
    postId: string;
    content: string;
    subvideo0: Video | null; // null means subvideo0 removal
    subvideo1: Video | null; // null means subvideo1 removal
  }>()
);

// TODO: commitUpdateRequest
export const commitPostUpdateResponse = createAction(
  '[Post] Commit Post Update Response',
  props<{ videoId: string; postId: string }>()
);

/**
 * Local only
 * Only can be done after startEditPostingResponse
 * Or this just add content to the current post
 */
export const editPendingPostContentRequest = createAction(
  '[Post] Edit Pending Post Content Request',
  props<{
    videoId: string;
    postId: string;
    content: string;
  }>()
);

// Local only
export const editPendingPostContentResponse = createAction(
  '[Post] Edit Pending Post Content Response',
  props<{ videoId: string; postId: string }>()
);

/**
 * Local only
 * Only can be done after startEditPostingResponse
 * Or this just add content to the current post
 */
export const editPostContentRequest = createAction(
  '[Post] Edit Post Content Request',
  props<{
    videoId: string;
    postId: string;
    content: string;
  }>()
);

// TODO: content: stirng (?)
// Local only
export const editPostContentResponse = createAction(
  '[Post] Edit Post Content Response',
  props<{ videoId: string; postId: string }>()
);

export const cancelEditPostContentRequest = createAction(
  '[Post] Cancel Edit Post Content Request',
  props<{
    videoId: string;
    postId: string;
  }>()
);

export const cancelEditPostContentResponse = createAction(
  '[Post] Cancel Edit Post Content Response',
  props<{ videoId: string; postId: string }>()
);

export const cancelPostingRequest = createAction(
  '[Post] Cancel Posting Request',
  props<{
    videoId: string;
    postId: string;
  }>()
);

export const cancelPostingResponse = createAction(
  '[Post] Cancel Posting Response',
  props<{ videoId: string; postId: string }>()
);

// TODO: commitPostRequest
export const commitPostRequest = createAction(
  '[Post] Commit Post Request',
  props<{
    videoId: string;
    postId: string;
    tags: string[];
    content: string;
    subvideo0: Video | null;
    subvideo1: Video | null;
  }>()
);

export const commitPostResponse = createAction(
  '[Post] Commit Post Response',
  props<{ videoId: string; postId: string }>()
);

export const startSubpostingRequest = createAction(
  '[Post] Start Subposting Request',
  props<{
    videoId: string;
    tags: string[];
    parentPost: Post;
  }>()
);

export const startSubpostingResponse = createAction(
  '[Post] Start Subposting Response',
  props<{ videoId: string; postId: string }>()
);

export const cancelSubpostingRequest = createAction(
  '[Post] Cancel Subposting Request',
  props<{
    videoId: string;
    postId: string;
    parentPost: Post;
  }>()
);

export const cancelSubpostingResponse = createAction(
  '[Post] Cancel Subposting Response',
  props<{ videoId: string; postId: string }>()
);

// TODO: commitSubpostRequest
export const commitSubpostRequest = createAction(
  '[Post] Commit Subpost Request',
  props<{
    videoId: string;
    postId: string;
    tags: string[];
    parentPost: Post;
    content: string;
    subvideo0: Video | null;
    subvideo1: Video | null;
  }>()
);

export const commitSubpostResponse = createAction(
  '[Post] Commit Subpost Response',
  props<{ videoId: string; postId: string }>()
);
