import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import {
  cancelPostingResponse,
  commitSubpostResponse,
  cancelSubpostingResponse,
  startEditPostResponse,
  startPostingResponse,
  startSubpostingResponse,
} from '../actions/post.actions';
import { postFeatureKey, PostState } from '../reducers/post.reducer';
import { WatchComponent } from './watch.component';
import {
  commitPostResponse,
  cancelEditPostContentResponse,
} from '../actions/post.actions';
import { commitPostUpdateResponse } from '../actions/post.actions';
import { BaseHandler } from '../base.handler';

/**
 * @author: john@gomedialy.com
 * @version: 0.17, 10/26/2020
 * @version: 0.18, 01/14/2021
 */
export class WatchPostEditorHandler extends BaseHandler<WatchComponent> {
  /* fields */

  constructor(
    watchComponent: WatchComponent,
    private postStore: Store<{
      [postFeatureKey]: PostState;
    }>
  ) {
    super(watchComponent);
    this.init();
  }

  private init(): void {
    const postStoreSubscription = this.postStore
      .select(postFeatureKey)
      .pipe(
        mergeMap((state) => {
          switch (state.type) {
            case commitPostResponse.type.toString():
            case commitSubpostResponse.type.toString():
            case commitPostUpdateResponse.type.toString():
              this.parent.postEditorInput = null;
              break;
            case cancelPostingResponse.type.toString():
            case cancelSubpostingResponse.type.toString():
            case cancelEditPostContentResponse.type.toString():
              this.parent.postEditorInput = null;
              break;
            case startPostingResponse.type.toString():
            case startSubpostingResponse.type.toString():
            case startEditPostResponse.type.toString():
              this.parent.postEditorInput = state;
              break;
          }
          return EMPTY;
        })
      )
      .subscribe();
    this.parent.subscriptions.add(postStoreSubscription);
  }
}
