import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Icons } from '../utils/icons';

/**
 * @author: john@gomedialy.com
 * @version: 0.1, 12/18/2020
 * @version: 0.11, 01/14/2021
 */
@Component({
  selector: 'app-post-delete',
  templateUrl: './post-delete.component.html',
  styleUrls: ['./post-delete.component.scss'],
})
export class PostDeleteComponent implements OnInit {
  /* fields */
  videoImageUrl = '';
  // title = '';
  // videoId = '';
  content = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    // console.log('data: ', data);
    const videoId = data.videoId;
    this.content = data.content;
    this.videoImageUrl = Icons.defaultImageUrl(videoId);
  }

  ngOnInit(): void {}
}
