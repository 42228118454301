import {
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { WordCloudItem } from '../word-cloud/word-cloud.component';
// import { NGXLogger } from 'ngx-logger';
import { QueryEventService } from './query-event.service';
import { RealtimeCounterService } from '../services/realtime-counter.service';
import { Objects } from '../utils/objects';
import { BaseComponent } from '../base.component';
import { MatRipple } from '@angular/material/core';
import { flash } from '../utils/animations';
import { DeviceService } from '../services/device.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn } from 'ng-animate';

/**
 * @author: john@gomedialy.com
 * @version: 0.12, 11/19/2020
 * @version: 0.16, 01/30/2021
 */
@Component({
  selector: 'app-query-button',
  templateUrl: './query-button.component.html',
  styleUrls: ['./query-button.component.css'],
  animations: [
    flash,
    trigger('bounceIn', [
      transition(
        '* <=> *',
        useAnimation(bounceIn, { params: { timing: 1, delay: 0 } })
      ),
    ]),
  ],
})
export class QueryButtonComponent
  extends BaseComponent
  implements OnInit, AfterViewInit {
  /* fields */
  @Input()
  item!: WordCloudItem;

  @ViewChild(MatRipple)
  ripple!: MatRipple;

  isDesktop = false;
  style: any;
  formattedTraffic = '';
  queryCount = 0;
  isOpen = false;
  state: 'true' | 'false' | null | undefined = 'true';
  text = '';
  bounceIn: any;

  constructor(
    // private logger: NGXLogger,
    private deviceService: DeviceService,
    private queryEventService: QueryEventService,
    private realtimeCounter: RealtimeCounterService
  ) {
    super();
    this.isDesktop = this.deviceService.isDesktop();
    // /**
    //  * TODO: font-size adjustment according to width
    //  */
    // if (this.isDesktop) {
    //   this.style = {
    //     'white-space': 'normal',
    //     padding: '0px',
    //     margin: '0px',
    //     'font-weight': '900',
    //     'word-break': 'keep-all', // for korean
    //     // 'font-size': 'min(10vw, 52px)',
    //   };
    // } else {
    //   // white-space: nowrap;
    //   //   overflow: hidden;
    //   //   text-overflow: ellipsis;

    //   // overflow:hidden;
    //   //       text-overflow:ellipsis;
    //   //       white-space:nowrap;

    //   this.style = {
    //     width: 'auto',
    //     'white-space': 'nowrap',
    //     padding: '0px',
    //     margin: '0px',
    //     'font-weight': '900',
    //     overflow: 'hidden',
    //     'text-overflow': 'ellipsis',
    //     // 'word-wrap': 'break-word',
    //     // 'word-break': 'break-all', // for korean
    //     // 'font-size': 'min(10vw, 52px)',
    //   };
    // }
  }

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    // this.realtimeCounter.getCount()
    this.formattedTraffic = this.item.formattedTraffic;
    const text0 = this.item.text;
    // TODO: Test
    this.text = this.item.text;
    const hash = Objects.hashString(text0);
    const queryCountSubscription = this.realtimeCounter
      .getCount(`queries/${hash}`)
      .subscribe((count) => {
        this.queryCount = count;
        // this.triggerRipple();
        this.toggle();
      });

    this.subscriptions.add(queryCountSubscription);
    /**
     * TODO: font-size adjustment according to width
     */
    if (this.isDesktop) {
      this.setDefaultStyle();
      // this.style = {
      //   'white-space': 'normal',
      //   padding: '0px',
      //   margin: '0px',
      //   'font-weight': '900',
      //   'word-break': 'keep-all', // for korean
      //   // 'font-size': 'min(10vw, 52px)',
      // };
    } else {
      const textLength = this.item.text.length;
      switch (this.item.style) {
        case 'mat-display-3':
          // console.error('WTF .... length: ', textLength);
          if (this.text.length >= 9) {
            this.style = {
              'white-space': 'normal',
              padding: '0px',
              margin: '0px',
              'font-weight': '900',
              'word-break': 'keep-all', // for korean
              'font-size': 'min(12vw, 52px)',
            };
          } else {
            this.setDefaultStyle();
          }

          break;
        // case 'mat-display-2':
        //   break;
        // case 'mat-display-1':
        //   break;
        // case 'mat-h2':
        //   break;
        default:
          this.setDefaultStyle();
          break;
      }
    }
  }

  private setDefaultStyle(): void {
    this.style = {
      'white-space': 'normal',
      padding: '0px',
      margin: '0px',
      'font-weight': '900',
      'word-break': 'keep-all', // for korean
    };
  }

  onAnimationEvent(event: AnimationEvent): void {
    // event.phaseName
    // switch (this.state) {
    //   case 'open':
    //     this.state = 'none';
    //     break;
    //   case 'closed':
    //     // this.state = 'none';
    //     this.toggleToNone();
    //     break;
    // }
    // this.logger.info('>>>> closed: ', event, this.state);
  }

  onWordClick(wordCloudItem: WordCloudItem): void {
    const text = wordCloudItem.text;
    const hash = Objects.hashString(text);
    this.queryEventService.onWordClick(wordCloudItem);
    this.realtimeCounter.increase(`queries/${hash}`, 1);
  }

  toggle(): void {
    this.state = this.state === 'false' ? 'true' : 'false';
    // this.bounceIn = this.bounceIn === 'false' ? 'true' : 'false';
  }

  toggleToNone(): void {}

  name(): string {
    return this.item.text;
  }
}
