import {
  Component,
  OnInit,
  Input,
  Inject,
  ViewChildren,
  ViewChild,
  QueryList,
} from '@angular/core';
import { ContentData, EmptyTopSearchData } from '../models/data';
import { DOCUMENT } from '@angular/common';
import firebase from 'firebase/compat/app';
import { NGXLogger } from 'ngx-logger';
import { MatChip, MatChipList } from '@angular/material/chips';
import { AfterViewInit } from '@angular/core';
import { Objects } from '../utils/objects';
import { RealtimeCounterService } from '../services/realtime-counter.service';
import { BaseComponent } from '../base.component';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { mainFeatureKey, MainState } from '../reducers/main.reducer';
import { browseRequest, homeLoaded } from '../actions/main.actions';

/**
 * @author: john@gomedialy.com
 * @version: 0.14, 09/06/2020
 * @version: 0.26, 01/17/2021
 */
export interface Subquery {
  query: string;
  count: number;
}
@Component({
  selector: 'app-content-card-group',
  templateUrl: './content-card-group.component.html',
  styleUrls: ['./content-card-group.component.scss'],
})
export class ContentCardGroupComponent
  extends BaseComponent
  implements OnInit, AfterViewInit {
  /* fields */
  traffic = '0';
  title = '';
  subqueries: Subquery[] = [];

  @Input()
  input: ContentData = EmptyTopSearchData.create(
    firebase.firestore.Timestamp.now()
  );

  @ViewChild(MatChipList)
  matChipList!: MatChipList;

  @ViewChildren(MatChip)
  matChips!: QueryList<MatChip>;

  // tslint:disable-next-line: no-any
  window: any;
  queryCount = 0;

  constructor(
    private logger: NGXLogger,
    private mainStore: Store<{
      [mainFeatureKey]: MainState;
    }>,
    private realtimeCounter: RealtimeCounterService,
    @Inject(DOCUMENT) private document: Document
  ) {
    // For BaseComponent
    super();
    this.window = this.document.defaultView;
  }

  // For a smart scroll
  getScrollId(query: string): string {
    return Objects.hashString(query);
  }

  /**
   * This is called on every card group
   */
  ngOnInit(): void {
    this.title = this.input.query;
    // console.error(
    //   '&&&&&&&&&&&&&&&&&&&&&&&&&&&&& ngOnInit called twice?: ',
    //   this.title
    // );

    this.subqueries = this.input.suggestions.map((query, index) => {
      const subquery: Subquery = { query, count: 0 };
      // const subquery: Subquery = { query };
      // console.error('1. ', query);
      return subquery;
    });

    this.subqueries.forEach((subquery, index) => {
      // const query = `영어 ${subquery.query.trim()}`; // subquery.query.trim();
      // console.log(`>>> subquery: '${query}'`);

      // TODO: Testing
      const hash = Objects.hashString(subquery.query);
      // const hash = Objects.hashString(query);
      const subqueryCountSubscription = this.realtimeCounter
        .getCount(`queries/${hash}`)
        .pipe(
          map((count) => {
            // console.log('sub count: ', count);
            this.subqueries[index].count = count;
            /**
             * main-query count
             */
            if (index === 0) {
              this.queryCount = count;
            }
            // console.error('2. ', subquery.query, count);
            return count;
          })
        )
        .subscribe();
      this.subscriptions.add(subqueryCountSubscription);
    });

    this.traffic = this.input.formattedTraffic;

    // TODO: Test
    if (this.input.isLast) {
      this.mainStore.dispatch(homeLoaded());
    }
  }

  ngAfterViewInit(): void {}

  onSubqueryClick(event: any): void {
    const id = event[0];
    /**
     * hash === scrollId
     */
    const hash = Objects.hashString(id);
    this.realtimeCounter.increase(`queries/${hash}`, 1);
    this.onSubtitleClick(id);
  }

  onSubtitleClick(subtitle: string): void {
    this.mainStore.dispatch(browseRequest({ query: subtitle }));
  }

  name(): string {
    return this.input.suggestions[0];
  }
}
