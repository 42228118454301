import { Component, Input, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { zoomIn } from 'ng-animate';
import { trigger, transition, useAnimation } from '@angular/animations';

/**
 * @author: john@gomedialy.com
 * @version: 0.11, 01/20/2021
 */
export interface VideoBarData {
  action: 'rewind' | 'forward';
  message: string;
}

@Component({
  selector: 'app-video-bar',
  templateUrl: './video-bar.component.html',
  styleUrls: ['./video-bar.component.scss'],
  animations: [
    trigger('zoomIn', [
      transition(
        '* <=> *',
        useAnimation(zoomIn, { params: { timing: 0.25, delay: 0 } })
      ),
    ]),
  ],
})
export class VideoBarComponent implements OnInit {
  /* fields */
  @Input()
  videoBarData!: VideoBarData;
  message = '';
  zoomIn: any;

  constructor(private logger: NGXLogger) {}

  ngOnInit(): void {
    this.message = this.videoBarData.message;
  }
}
