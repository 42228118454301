import { Injectable } from '@angular/core';
// import { YoutubeData, Item } from '../models/youtube-data';
import {
  mergeMap,
  map,
  toArray,
  count,
  last,
  catchError,
} from 'rxjs/operators';
import { from, of, Observable, EMPTY, throwError } from 'rxjs';
import { FirestoreService } from './firestore.service';
import { Post, PostLocal, toPostLocal, toPost, Video } from '../models/posts';
import { AngularFirestore, DocumentData } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { nanoid } from 'nanoid';
import { LocalStorageService } from './local-storage.service';
// import { AuthService } from './auth.service';
import { Firestores } from '../utils/firestores';
import { NGXLogger } from 'ngx-logger';

export interface PageRequest {
  videoId: string;
  category: 'all' | 'subtitle' | 'mine';
  direction: 'previous' | 'next';
  pageSize: number;
  orderBy: 'asc' | 'desc';
  /**
   * Ex) startAfter({id: partition }) or endBefore({id: partition})
   */
  partition?: string;
  // TODO: Testing
  userId?: string;
}

export interface PageResponse {
  videoId: string;
  posts: Post[];
  isFirst: boolean;
  isLast: boolean;
  // hasPrevious: boolean; // hasPrevious, hasNext,
  // hasNext: boolean;
  /**
   * Ex) startAfter({id: start }) or endBefore({id: end})
   */
  start?: string;
  end?: string;
}

/**
 * @author: john@gomedialy.com
 * @version: 0.39, 10/14/2020
 * @version: 0.44, 01/18/2021
 */
export enum PostSortType {
  ALL_TOP,
  ALL_CONTROVERSIAL,
  ALL_RISING,
  ALL_NEW,
  ALL_NATURAL,
  ALL_MINE,
  SUBTITLES_TOP,
  SUBTITLES_CONTROVERSIAL,
  SUBTITLES_RISING,
  SUBTITLES_NEW,
  SUBTITLES_NATURAL,
  SUBTITLES_MINE,
}

@Injectable({
  providedIn: 'root',
})
export class PostService {
  /* fields */

  constructor(
    private logger: NGXLogger,
    private angularFirestore: AngularFirestore,
    private firestoreService: FirestoreService,
    private localStorageService: LocalStorageService
  ) {
    // this.localStorageService
    //   .delete('x')
    //   .pipe(
    //     mergeMap((v) => {
    //       console.error('&&&&&&&&&&&&&&&&&&&&&&&&&& WTF2: ', v);
    //       return of(v);
    //     })
    //   )
    //   .subscribe((value) =>
    //     console.error('&&&&&&&&&&&&&&&&&&&&&&&&&& WTF: ', value)
    //   );
  }

  /**
   * videoId is required to make postId searchable by itself.
   */
  createPostId(videoId: string): string {
    // return `${firebase.firestore.Timestamp.now().toMillis()}-${nanoid()}`;
    // return `${videoId}-${firebase.firestore.Timestamp.now().toMillis()}-${nanoid()}`;
    return `${videoId}.${firebase.firestore.Timestamp.now().toMillis()}.${nanoid(
      11
    )}`;
  }

  postPending(post: Post): Observable<Post> {
    post.status = 'pending';
    return this.post(post).pipe(
      mergeMap((updatedPost) => {
        if (updatedPost.postId) {
          return this.localStorageService
            .set2<PostLocal>(
              'pendings',
              updatedPost.postId,
              toPostLocal(updatedPost)
            )
            .pipe(map((postLocal) => toPost(postLocal)));
        }
        throw new Error(`postId is null: ${post.content}`);
      })
    );
  }

  subpostPending(parentPath: string, post: Post): Observable<Post> {
    post.status = 'pending';
    return this.subpost(parentPath, post).pipe(
      mergeMap((updatedPost) => {
        if (updatedPost.postId) {
          return this.localStorageService
            .set2<PostLocal>(
              'pendings',
              updatedPost.postId,
              toPostLocal(updatedPost)
            )
            .pipe(map((postLocal) => toPost(postLocal)));
        }
        throw new Error(`postId is null: ${post.content}`);
      })
    );
  }

  postCommit(
    postId: string,
    text: string,
    tags: string[],
    subvideo0: Video | null,
    subvideo1: Video | null
  ): Observable<Post> {
    return this.localStorageService.get2<PostLocal>('pendings', postId).pipe(
      mergeMap((postLocal) => {
        if (postLocal) {
          const post = toPost(postLocal);
          post.tags = tags;
          post.status = 'committed';
          post.content = text;

          /**
           * category: 'post' | 'subtitle';
           * postType: 'post' | 'subpost' | 'title' | 'subtitle';
           *
           * For category: subtitles
           *
           * if subvideo0 exists, it is a title categroy.
           * when it a title, postType: post becomes a title and postType: subpost becomes a subtitle.
           *
           * undefined value is not allowed on firestore
           */
          if (subvideo0) {
            switch (post.postType) {
              case 'post':
                post.category = 'subtitle';
                post.postType = 'title';
                break;
              case 'subpost':
                post.category = 'subtitle';
                post.postType = 'subtitle';
                break;
            }
            /**
             * To be sure, set postId again.
             */
            post.subvideo0 = { ...subvideo0, postId: post.postId };
          }
          /**
           * undefined value is not allowed on firestore
           */
          if (subvideo1) {
            /**
             * To be sure, set postId again.
             */
            post.subvideo1 = { ...subvideo1, postId: post.postId };
          }
          post.updatedAt = firebase.firestore.Timestamp.now();
          // post.controversials = controversials(post);

          // console.log('[COMMIT] Post: ', post);

          return this.post(post).pipe(
            mergeMap((updatedPost) => {
              if (updatedPost.postId) {
                return this.localStorageService.delete2(
                  'pendings',
                  updatedPost.postId
                );
              }
              return this.localStorageService.delete2('pendings');
            }),
            map((v) => post)
          );
        }
        throw new Error(`Pending post not exists: ${postId}`);
      })
    );
  }

  /**
   * ! This can change the category and postType
   * ! very raw function! Be careful!
   */
  update(videoId: string, postId: string, data: any): Observable<Post> {
    return this.findPost(videoId, postId).pipe(
      mergeMap((post) => {
        if (post) {
          const doc = `channels/${videoId}/posts/${postId}`;
          // const partial = { ...data, videoId, postId };

          const updatedPost: Post = { ...post, ...data };
          const subvideo0 = updatedPost.subvideo0;

          if (subvideo0) {
            /**
             * From post to subtitle
             */
            switch (post.postType) {
              case 'post':
                updatedPost.category = 'subtitle';
                updatedPost.postType = 'title';
                break;
              case 'subpost':
                updatedPost.category = 'subtitle';
                updatedPost.postType = 'subtitle';
                break;
            }
          } else {
            /**
             * From subtitle to post
             */
            switch (post.postType) {
              case 'title':
                updatedPost.category = 'post';
                updatedPost.postType = 'post';
                break;
              case 'subtitle':
                updatedPost.category = 'post';
                updatedPost.postType = 'subpost';
                break;
            }
          }

          return from(this.angularFirestore.doc(doc).update(updatedPost)).pipe(
            map((v) => updatedPost)
          );
        }
        return throwError(new Error(`Post not exists: ${videoId} ${postId}`));
      })
    );
  }

  postRollback(postId: string): Observable<Post> {
    return this.localStorageService.get2<PostLocal>('pendings', postId).pipe(
      mergeMap((postLocal) => {
        if (postLocal) {
          const post = toPost(postLocal);
          this.postHardDelete(post);
          return of(post);
        }
        throw new Error(`Pending post not exists: ${postId}`);
      })
    );
  }

  /**
   * delete all unfinished posts.
   */
  postRollbacks(): Observable<number> {
    return this.listLocalPendingPosts().pipe(
      mergeMap((post) => {
        this.postHardDelete(post);
        this.logger.debug('postRollbacks(): ', post.postId);
        return of(post);
      }),
      count(),
      mergeMap((counts) => {
        return this.localStorageService.delete2('pendings').pipe(
          map((v) => {
            return counts;
          })
        );
      })
    );
  }

  postSoftDelete(videoId: string, postId: string): Observable<Post> {
    return this.findPost(videoId, postId).pipe(
      mergeMap((post) => {
        if (post) {
          // TODO: set null to content, subvideos ...
          post.status = 'deleted';
          post.updatedAt = firebase.firestore.Timestamp.now();
          // console.log('postSoftDelete: ', post);
          // const postDoc = `channels/${videoId}/posts/${postId}`;
          return from(
            this.angularFirestore
              .doc(`channels/${videoId}/posts/${postId}`)
              .update(post)
          ).pipe(map((v) => post));
        }
        return EMPTY;
      })
    );
  }

  /**
   * ! dangerous
   * This can not be done in the rxjs way.
   */
  postHardDelete(post: Post): void {
    // console.error(
    //   '1 ################### postHardDelete.......... ',
    //   post.postId
    // );
    const postDoc = `channels/${post.videoId}/posts/${post.postId}`;
    this.angularFirestore.doc<Post>(postDoc).delete();
    // console.error(
    //   '2 ################### postHardDeleted.......... ',
    //   post.postId
    // );

    // return this.firestoreService.delete(postDoc).pipe(
    //   map((v) => {
    //     console.error(
    //       '2 ################### postHardDelete.......... ',
    //       post.postId
    //     );
    //     return post;
    //   }),
    //   catchError((error) => {
    //     console.error('#####################', error);
    //     return of(post);
    //   })
    // );
  }

  // postHardDelete2(post: Post): Observable<Post> {
  //   console.error('################### postHardDelete.......... ', post.postId);
  //   const postDoc = `channels/${post.videoId}/posts/${post.postId}`;
  //   return from(this.angularFirestore.doc<Post>(postDoc).delete()).pipe(
  //     map((v) => {
  //       console.error(
  //         '################### postHardDelete.......... ',
  //         post.postId
  //       );
  //       return post;
  //     }),
  //     catchError((error) => {
  //       console.error('#####################', error);
  //       return of(post);
  //     })
  //   );
  // }

  listLocalPendingPosts(): Observable<Post> {
    return this.localStorageService
      .list2<PostLocal>('pendings')
      .pipe(map((postLocal) => toPost(postLocal)));
  }

  post(post: Post): Observable<Post> {
    const doc = `channels/${post.videoId}/posts/${post.postId}`;
    return from(this.angularFirestore.doc(doc).set(post, { merge: true })).pipe(
      map((v) => {
        return post;
      })
    );
  }

  subpost(parentPath: string, post: Post): Observable<Post> {
    if (post.postId) {
      // update
      const doc = `channels/${post.videoId}/posts/${post.postId}`;
      return from(
        this.angularFirestore.doc<Post>(doc).set(post, { merge: true })
      ).pipe(map((v) => post));
    } else {
      // new and to make sure.
      post.postId = this.createPostId(post.videoId);
      post.path = `${parentPath}/posts/${post.postId}`;
      const doc = `channels/${post.videoId}/posts/${post.postId}`;
      return from(
        this.angularFirestore.doc<Post>(doc).set(post, { merge: false })
      ).pipe(map((v) => post));
    }
  }

  subpostByPostId(parentPostId: string, post: Post): Observable<Post> {
    return this.findOnlyPostQueryDocumentSnapshot(parentPostId).pipe(
      mergeMap((postQueryDocumentSnapshot) => {
        if (postQueryDocumentSnapshot) {
          const path = postQueryDocumentSnapshot.ref.path;
          return this.subpost(path, post);
        }
        throw new Error(`Invalid parent-postId: ${parentPostId}`);
      })
    );
  }

  hasSubposts(path: string): Observable<boolean> {
    return this.angularFirestore
      .collection<Post>(`${path}/posts`, (ref) => {
        return ref.limit(1);
      })
      .get({ source: 'default' })
      .pipe(
        map((querySnapshot) => {
          return !querySnapshot.empty;
        })
      );
  }

  findPost(videoId: string, postId: string): Observable<Post | null> {
    const postDoc = `channels/${videoId}/posts/${postId}`;
    return this.angularFirestore
      .doc<Post>(postDoc)
      .get()
      .pipe(
        mergeMap((docSnapshot) =>
          Firestores.documentSnapShotAs<Post>(docSnapshot)
        )
      );
  }

  findOnlyPostQueryDocumentSnapshot(
    postId: string
  ): Observable<firebase.firestore.QueryDocumentSnapshot<DocumentData> | null> {
    return this.angularFirestore
      .collectionGroup<Post>('posts', (ref) =>
        ref.where('postId', '==', postId).limit(1)
      )
      .get()
      .pipe(
        mergeMap((querySnapshot) => {
          return this.firestoreService.findOnlyQueryDocumentSnapshotFromQuerySnapshot(
            querySnapshot
          );
        })
      );
  }
}
