import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  ElementRef,
  HostListener,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Post, Video } from '../models/posts';
import { AuthService } from '../services/auth.service';
import { Observable, of, merge, EMPTY, Subject } from 'rxjs';
import { delay, mergeMap, debounceTime, switchMap, take } from 'rxjs/operators';
import {
  ButtonGroupComponent,
  ButtonGroupEvent,
  ButtonItem,
} from '../button-group/button-group.component';
import { BaseComponent } from '../base.component';
import { Store } from '@ngrx/store';
import {
  videoEditorFeatureKey,
  VideoEditorState,
} from '../reducers/video-editor.reducer';
import {
  editSubvideoRequest,
  clearVideoEditor,
} from '../actions/video-editor.actions';
import { JSend } from '../models/jsend';
import { postFeatureKey, PostState } from '../reducers/post.reducer';
import {
  cancelSubpostingRequest,
  cancelEditPostContentRequest,
  editPendingPostContentRequest,
} from '../actions/post.actions';
import { commitSubpostRequest } from '../actions/post.actions';
import {
  cancelPostingRequest,
  commitPostRequest,
} from '../actions/post.actions';
import { videoFeatureKey, VideoState } from '../reducers/video.reducer';
import { commitPostUpdateRequest } from '../actions/post.actions';
import { PostEditorVideoHandler } from './post-editor-video.handler';
import {
  imageEditorFeatureKey,
  ImageEditorState,
} from '../reducers/image-editor.reducer';
import {
  editPostContentRequest,
  startPostingResponse,
  startSubpostingResponse,
  startEditPostResponse,
} from '../actions/post.actions';
import { PostEditorImageHandler } from './post-editor-image.handler';
import { PostDataService } from '../services/post-data.service';
import {
  clearImageEditor,
  editImageRequest,
} from '../actions/image-editor.actions';
import {
  VideoButtonComponent,
  VideoButtonEvent,
} from '../video-button/video-button.component';
import { trigger, transition, useAnimation } from '@angular/animations';
import {
  bounceIn,
  fadeIn,
  fadeOut,
  rubberBand,
  zoomIn,
  zoomOut,
} from 'ng-animate';
import { Icons } from '../utils/icons';

/**
 * @author: john@gomedialy.com
 * @version: 0.33, 10/21/2020
 * @version: 0.38, 01/30/2021
 */
@Component({
  selector: 'app-post-editor',
  templateUrl: './post-editor.component.html',
  styleUrls: ['./post-editor.component.scss'],
  animations: [
    trigger('rubberBand', [
      transition(
        'void => *',
        useAnimation(rubberBand, { params: { timing: 1, delay: 0 } })
      ),
    ]),
    trigger('bounceIn', [
      transition(
        'void => *',
        useAnimation(bounceIn, { params: { timing: 1, delay: 0 } })
      ),
    ]),
    trigger('zoomIn', [
      transition(
        'void => *',
        useAnimation(zoomIn, { params: { timing: 0.25, delay: 0 } })
      ),
    ]),
    trigger('zoomOut', [
      transition(
        '* => void',
        useAnimation(zoomOut, { params: { timing: 0.25, delay: 0 } })
      ),
    ]),
    trigger('fadeIn', [
      transition(
        'void => *',
        useAnimation(fadeIn, { params: { timing: 0.5, delay: 0 } })
      ),
    ]),
    trigger('fadeOut', [
      transition(
        '* => void',
        useAnimation(fadeOut, { params: { timing: 1, delay: 0 } })
      ),
    ]),
  ],
})
export class PostEditorComponent
  extends BaseComponent
  implements OnInit, AfterViewInit {
  /* fields */
  postFormGroup: FormGroup;

  title: string | null = null;
  subtitle: string | null = null;

  isSubtitle = false;
  subtitleStartTime = 0;
  subtitleEndTime = 0;

  @ViewChild('toolbarButtonGroup')
  toolbarButtonGroupComponent!: ButtonGroupComponent;

  @ViewChild('subvideo0')
  subvideo0ButtonComponent!: VideoButtonComponent;

  @ViewChild('subvideo1')
  subvideo1ButtonComponent!: VideoButtonComponent;

  @ViewChild('message')
  messageInput?: ElementRef;

  /**
   * Input from watch.component
   */
  // tslint:disable-next-line: no-input-rename
  @Input('input')
  inputPostState!: PostState;

  /**
   * Only when editable
   */
  // @Input('post')
  post: Post | null = null;

  // videoImageUrl: string | null = '../assets/images/icons8-film-reel-64.png';
  // subvideoImageUrl: string | null = '../assets/images/icons8-film-reel-64.png';
  videoImageUrl: string | null = null; // '../assets/images/icons8-film-reel-64.png';
  subvideoImageUrl: string | null = null; // '../assets/images/icons8-film-reel-64.png';

  videoId: string | null = null;
  subtitleVideo: Video | null = null; // TODO: subvideo0
  subvideo: Video | null = null; // TODO: subvideo1

  userButtonItems: ButtonItem[] = [
    {
      action: 'edit-subvideo',
      // svgIcon: 'youtube',
      matIcon: 'video_library',
      tooltip: '동영상',
    },
    {
      action: 'add-emoji',
      matIcon: 'mood',
      tooltip: '이모티콘',
    },
    {
      action: 'add-image',
      matIcon: 'image',
      tooltip: '이미지',
    },
  ];
  userButtonGroupVisibility: 'visible' | 'hidden' = 'visible';

  /**
   * VideoEditor enable or disable
   */
  videoEditorInput: VideoEditorState | null = null;
  imageEditorInput: ImageEditorState | null = null;

  /**
   * Input value
   */
  private contentSubject = new Subject<string>();
  content = '';
  emojiPickerEnabled = false;
  rubberBand: any;
  bounceIn: any;
  zoomIn: any;
  zoomOut: any;
  fadeIn: any;
  fadeOut: any;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private postStore: Store<{
      [postFeatureKey]: PostState;
    }>,
    private videoStore: Store<{
      [videoFeatureKey]: VideoState;
    }>,
    private videoEditorStore: Store<{
      [videoEditorFeatureKey]: VideoEditorState;
    }>,
    private imageEditorStore: Store<{
      [imageEditorFeatureKey]: ImageEditorState;
    }>,
    private postDataSource: PostDataService
  ) {
    super();

    // const user = this.authService.getUser();
    // if (user) {
    //   this.photoUrl = user.photoUrl;
    // }
    this.postFormGroup = this.formBuilder.group({
      postInputControl: ['', Validators.nullValidator],
    });

    new PostEditorVideoHandler(this, this.videoStore, this.videoEditorStore);
    new PostEditorImageHandler(this, this.imageEditorStore);
  }

  @HostListener('window:keydown', ['$event'])
  // tslint:disable-next-line: typedef
  keyEvent(event: KeyboardEvent) {
    // console.log(event.key, event.code);
    switch (event.code) {
      case 'Escape':
        this.onCancelClick();
        break;
      // case 'Space':
      //   // console.log(event.code);
      //   // this.videoStore.dispatch(playOrPause());
      //   event.preventDefault();
      //   break;
      // case 'ArrowLeft':
      //   // console.log(event.code);
      //   // this.videoStore.dispatch(seekLeftTo());
      //   event.preventDefault();
      //   break;
      // case 'ArrowRight':
      //   // console.log(event.code);
      //   // this.videoStore.dispatch(seekRightTo());
      //   event.preventDefault();
      //   break;
      // case 'ArrowUp':
      //   // console.log(event.code);
      //   // this.videoStore.dispatch(volumeUp());
      //   break;
      // case 'ArrowDown':
      //   // console.log(event.code);
      //   // this.videoStore.dispatch(volumeDown());
      //   break;
    }
  }

  ngOnInit(): void {
    const contentSubscription = this.contentSubject
      .pipe(
        debounceTime(250),
        switchMap((content) => {
          this.content = content;
          // console.log('> ', this.input);

          switch (this.inputPostState.type) {
            case startPostingResponse.type.toString():
            case startSubpostingResponse.type.toString():
              if (this.videoId) {
                this.postStore.dispatch(
                  editPendingPostContentRequest({
                    videoId: this.videoId,
                    postId: this.inputPostState.postId
                      ? this.inputPostState.postId
                      : '',
                    content,
                  })
                );
              }
              break;
            case startEditPostResponse.type.toString():
              if (this.videoId) {
                this.postStore.dispatch(
                  editPostContentRequest({
                    videoId: this.videoId,
                    postId: this.inputPostState.postId
                      ? this.inputPostState.postId
                      : '',
                    content,
                  })
                );
              }
              break;
          }
          return EMPTY;
        })
      )
      .subscribe();
    this.subscriptions.add(contentSubscription);

    // postStateInit
    this.postStateInit(this.inputPostState);
  }

  private postStateInit(input: PostState): void {
    this.videoId = input.videoId ? input.videoId : '';
    // this.videoImageUrl = `https://i.ytimg.com/vi/${this.videoId}/default.jpg`;
    this.videoImageUrl = Icons.defaultImageUrl(this.videoId);
    if (input.content) {
      this.content = input.content;
    }

    switch (input.type) {
      case startPostingResponse.type.toString():
      case 'post':
        this.title = '댓글 게시';
        this.subtitle = '동영상, 이모티콘, 이미지를 사용할 수 있습니다.';
        this.isSubtitle = false;
        break;
      case startSubpostingResponse.type.toString():
      case 'subpost':
        this.title = '댓글 추가';
        this.subtitle = '동영상, 이모티콘, 이미지를 사용할 수 있습니다.';
        this.isSubtitle = false;
        break;
      case startEditPostResponse.type.toString():
        this.title = '댓글 수정';
        this.subtitle = '동영상, 이모티콘, 이미지를 사용할 수 있습니다.';
        this.subtitleStartTime = 0;
        this.subtitleEndTime = 0;

        // TODO: Test
        if (input.postId) {
          const value = this.postDataSource.findPost(input.postId);
          // console.log('Wow Found it: ', value);
          if (value) {
            this.post = value;
            if (this.post.subvideo0) {
              this.subtitleVideo = this.post.subvideo0;
            }
            if (this.post.subvideo1) {
              this.subvideo = this.post.subvideo1;
            }
          }
        }

        if (input.content) {
          // this.content = input.content;
          // this.postFormGroup.patchValue({
          //   postInputControl: input.content,
          //   options: { emitEvent: true },
          // });
          // tslint:disable-next-line: no-non-null-assertion
          this.postFormGroup.get('postInputControl')!.setValue(input.content);
        }
        break;
    }

    this.focusToMessageInput();
  }

  // From MatInput
  onContentChange(event: string): void {
    this.contentSubject.next(event);
  }

  ngAfterViewInit(): void {
    // console.log(
    //   '@@@@@@@@@@@@@@@@@@@@@@@@@ found buttons: ',
    //   this.subvideo0ButtonComponent,
    //   this.subvideo1ButtonComponent
    // );

    const toolbarButtonGroupEventSubscription = this.toolbarButtonGroupComponent.events.subscribe(
      (event: ButtonGroupEvent) => {
        this.onButtonGroupEvent(event);
      }
    );
    this.subscriptions.add(toolbarButtonGroupEventSubscription);

    if (this.subvideo0ButtonComponent) {
      this.subvideo0ButtonComponent.events
        .pipe(take(1))
        .subscribe((event: VideoButtonEvent) => {
          // console.log('########## event: ', event);
          this.subtitleVideo = null;
        });
    }
    if (this.subvideo1ButtonComponent) {
      this.subvideo1ButtonComponent.events
        .pipe(take(1))
        .subscribe((event: VideoButtonEvent) => {
          // console.log('########## event: ', event);
          this.subvideo = null;
        });
    }
  }

  private onButtonGroupEvent(event: ButtonGroupEvent): void {
    // console.log('onButtonGroupEvent: ', event);
    switch (event.action) {
      case 'edit-subvideo':
        /**
         * TODO: this should handle both subtitle-video and subvideo
         */
        if (this.inputPostState.videoId && this.inputPostState.postId) {
          // hide emojiPicker
          this.emojiPickerEnabled = false;

          this.videoEditorStore.dispatch(
            editSubvideoRequest({
              videoId: this.inputPostState.videoId,
              postId: this.inputPostState.postId,
            })
          );
        }
        break;
      case 'add-emoji':
        if (this.inputPostState.postId) {
          this.emojiPickerEnabled = !this.emojiPickerEnabled;
        }
        break;
      case 'add-image':
        if (this.inputPostState.postId) {
          // hide emojiPicker
          this.emojiPickerEnabled = false;
          this.imageEditorStore.dispatch(
            editImageRequest({
              videoId: this.inputPostState.videoId
                ? this.inputPostState.videoId
                : '',
              postId: this.inputPostState.postId,
            })
          );
        }
        break;
    }
  }

  onMessageInputFocus(focused: boolean): void {
    // console.log('>>>>>>>>>> messageInputFocus: ', focused);
    if (focused) {
      this.emojiPickerEnabled = false;
    }
  }

  focusToMessageInput(): void {
    // Focus to TextArea
    // console.log('focusToMessageInput');
    of('')
      .pipe(delay(100))
      .subscribe((v) => {
        if (this.messageInput) {
          this.messageInput.nativeElement.focus();
        }
      });
  }

  onSubtitleTimestampsClick(): void {
    // if (this.inputPostState.postId) {
    //   if (this.videoId) {
    //     this.videoEditorStore.dispatch(
    //       editSubtitleRequest({
    //         videoId: this.videoId,
    //         postId: this.inputPostState.postId,
    //       })
    //     );
    //   }
    // }
  }

  onPostClick(): void {
    if (this.content && this.content.length > 0) {
      if (this.inputPostState) {
        // console.error(
        //   '>>>>>>>>>>>>>>>>>>>>>> onPostClick: ',
        //   this.videoId,
        //   this.inputPostState.type,
        //   this.subvideo
        // );
        switch (this.inputPostState.type) {
          case startPostingResponse.type.toString():
            if (this.videoId && this.inputPostState.postId) {
              this.postStore.dispatch(
                commitPostRequest({
                  videoId: this.videoId,
                  postId: this.inputPostState.postId,
                  tags: this.inputPostState.tags,
                  content: this.content,
                  subvideo0: this.subtitleVideo ? this.subtitleVideo : null,
                  subvideo1: this.subvideo ? this.subvideo : null,
                })
              );
            }
            break;
          case startSubpostingResponse.type.toString():
            if (
              this.videoId &&
              this.inputPostState.postId &&
              this.inputPostState.parentPost
            ) {
              this.postStore.dispatch(
                commitSubpostRequest({
                  videoId: this.videoId,
                  postId: this.inputPostState.postId,
                  parentPost: this.inputPostState.parentPost,
                  tags: this.inputPostState.tags,
                  content: this.content,
                  subvideo0: this.subtitleVideo ? this.subtitleVideo : null,
                  subvideo1: this.subvideo ? this.subvideo : null,
                })
              );
            }
            break;
          case startEditPostResponse.type.toString():
            // console.log(
            //   '################## editPostResponse WTF!!!: ',
            //   this.subtitleVideo,
            //   this.subvideo
            // );

            if (this.videoId && this.inputPostState.postId) {
              this.postStore.dispatch(
                commitPostUpdateRequest({
                  videoId: this.videoId,
                  postId: this.inputPostState.postId,
                  // postId: this.inputPostState.postId
                  //   ? this.inputPostState.postId
                  //   : '',
                  content: this.content,
                  subvideo0: this.subtitleVideo,
                  subvideo1: this.subvideo,
                })
              );
            }

            break;
        }
      }
      this.postFormGroup.reset();
      // this.videoEditorInput = null;
      this.videoEditorInput = null;
      // this.imageEditorInput = null;
      this.imageEditorInput = null;
      // this.closeVideoEditor();
      this.clearVideoEditor();
      this.clearImageEditor();

      // this.subtitleVideo = undefined;
      // this.subvideo = undefined;
    } else {
      this.onCancelClick();
    }
  }

  onCancelClick(): void {
    if (this.inputPostState) {
      switch (this.inputPostState.type) {
        case startPostingResponse.type.toString():
          if (this.videoId && this.inputPostState.postId) {
            this.postStore.dispatch(
              cancelPostingRequest({
                videoId: this.videoId,
                postId: this.inputPostState.postId,
              })
            );
          }
          break;
        case startSubpostingResponse.type.toString():
          if (
            this.videoId &&
            this.inputPostState.postId &&
            this.inputPostState.parentPost
          ) {
            this.postStore.dispatch(
              cancelSubpostingRequest({
                videoId: this.videoId,
                postId: this.inputPostState.postId,
                parentPost: this.inputPostState.parentPost,
              })
            );
          }
          break;
        case startEditPostResponse.type.toString():
          // case 'subtitle':
          if (this.videoId) {
            this.postStore.dispatch(
              cancelEditPostContentRequest({
                videoId: this.videoId,
                postId: this.inputPostState.postId
                  ? this.inputPostState.postId
                  : '',
              })
            );
          }
          break;
      }
    }
    this.postFormGroup.reset();
    // this.subtitleVideo = undefined;
    // this.subvideo = undefined;

    // this.closeVideoEditor();
    this.videoEditorInput = null;
    // this.videoEditorEnabled = false;
    this.imageEditorInput = null;
    // this.imageEditorEnabled = false;
    this.clearVideoEditor();
    this.clearImageEditor();
  }

  addEmoji(event: any): void {
    const emoji = event.emoji.colons;
    // console.log('addEmoji: ', this.content, emoji);
    // this.content

    this.postFormGroup.get('postInputControl')!.setValue(this.content + emoji);
    // this.contentSubject.next(this.content + emoji);
  }

  private clearVideoEditor(): void {
    this.videoEditorStore.dispatch(clearVideoEditor());
  }

  private clearImageEditor(): void {
    this.imageEditorStore.dispatch(clearImageEditor());
  }
}
