<div class="container">
  <app-video-player
    #pipPlayer
    [id]="'pip-player'"
    [videoId]="videoId"
    [width]="playerWidth"
    [height]="playerHeight"
    [playerVars]="pipPlayerVars"
  ></app-video-player>
</div>
<!-- [@fadeIn]="fadeIn"  -->
