import { Action, createReducer, on, props } from '@ngrx/store';
import * as PostActions from '../actions/post.actions';
import { JSend } from '../models/jsend';
import { Post, Video } from '../models/posts';

/**
 * @author: john@gomedialy.com
 * @version: 0.21, 10/14/2020
 * @version: 0.26, 01/18/2021
 */
export const postFeatureKey = 'post';

export interface PostState {
  type: string | null;
  videoId: string | null;
  postId: string | null; // For likes and dislikes
  parentPost: Post | null;
  startSeconds: number | null;
  duration: number | null;
  content: string | null;
  subvideo: Video | null;
  tags: string[];
}

export const initialState: PostState = {
  type: null,
  videoId: null,
  postId: null,
  parentPost: null,
  startSeconds: null,
  duration: null,
  content: null,
  subvideo: null,
  tags: [],
};

export const postReducer = createReducer(
  initialState,

  on(PostActions.deletePostRequest, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),
  on(PostActions.deletePostResponse, (state, action) => {
    return { ...state, ...action };
  }),

  on(PostActions.postsBoardReady, (state, action) => {
    return { ...state, ...action };
  }),

  // Likes
  on(PostActions.createLikePostRequest, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),
  on(PostActions.createLikePostResponse, (state, action) => {
    return { ...state, ...action };
  }),

  // Dislikes
  on(PostActions.createDislikePostRequest, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),
  on(PostActions.createDislikePostResponse, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),

  /**
   * When clear, at least leave the ngrx type.
   */
  on(PostActions.clearPostState, (state, action) => {
    return { ...initialState, type: action.type };
  }),

  on(PostActions.deletePendingPostsRequest, (state, action) => {
    return { ...state, ...action };
  }),
  on(PostActions.deletePendingPostsResponse, (state, action) => {
    return { ...state, ...action };
  }),

  // Posts
  on(PostActions.startPostingRequest, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),
  // on(PostActions.startPostingResponse, (state, action) => {
  //   return { ...state, ...action, jsend: action.jsend, ...action.jsend.data };
  // }),
  on(PostActions.startPostingResponse, (state, action) => {
    return { ...state, ...action };
  }),

  // Edit
  on(PostActions.startEditPostRequest, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),
  on(PostActions.startEditPostResponse, (state, action) => {
    return { ...state, ...action };
  }),

  on(PostActions.commitPostUpdateRequest, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),
  on(PostActions.commitPostUpdateResponse, (state, action) => {
    return { ...state, ...action };
  }),

  on(PostActions.editPendingPostContentRequest, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),
  on(PostActions.editPendingPostContentResponse, (state, action) => {
    return { ...state, ...action };
  }),

  on(PostActions.editPostContentRequest, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),
  on(PostActions.editPostContentResponse, (state, action) => {
    return { ...state, ...action };
  }),

  on(PostActions.cancelEditPostContentRequest, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),
  on(PostActions.cancelEditPostContentResponse, (state, action) => {
    return { ...state, ...action };
  }),

  on(PostActions.cancelPostingRequest, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),
  // on(PostActions.cancelPostingResponse, (state, action) => {
  //   return { ...state, ...action, jsend: action.jsend };
  // }),
  on(PostActions.cancelPostingResponse, (state, action) => {
    return { ...state, ...action };
  }),
  on(PostActions.commitPostRequest, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),
  // on(PostActions.commitPostResponse, (state, action) => {
  //   return { ...state, ...action, jsend: action.jsend, ...action.jsend.data };
  // }),
  on(PostActions.commitPostResponse, (state, action) => {
    return { ...state, ...action };
  }),
  // Subposts
  on(PostActions.startSubpostingRequest, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),
  // on(PostActions.startSubpostingResponse, (state, action) => {
  //   return { ...state, ...action, jsend: action.jsend, ...action.jsend.data };
  // }),
  on(PostActions.startSubpostingResponse, (state, action) => {
    return { ...state, ...action };
  }),
  on(PostActions.cancelSubpostingRequest, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),
  // on(PostActions.cancelSubpostingResponse, (state, action) => {
  //   return { ...state, ...action, jsend: action.jsend };
  // }),
  on(PostActions.cancelSubpostingResponse, (state, action) => {
    return { ...state, ...action };
  }),
  on(PostActions.commitSubpostRequest, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),
  // on(PostActions.commitSubpostResponse, (state, action) => {
  //   return { ...state, ...action, jsend: action.jsend, ...action.jsend.data };
  // })
  on(PostActions.commitSubpostResponse, (state, action) => {
    return { ...state, ...action };
  })
);
