import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
  HostListener,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { BaseComponent } from '../base.component';
import { videoFeatureKey, VideoState } from '../reducers/video.reducer';
import { delay, switchMap, mergeMap } from 'rxjs/operators';
import { pauseVideo, playVideo, videoPaused } from '../actions/video.actions';
import { EMPTY, of } from 'rxjs';
import { videoPlaying, videoEnded } from '../actions/video.actions';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import {
  videoEditorFeatureKey,
  VideoEditorState,
} from '../reducers/video-editor.reducer';
import {
  editSubvideoResponse,
  // editSubtitleResponse,
} from '../actions/video-editor.actions';
import { JSend } from '../models/jsend';
import { Video } from '../models/posts';
import {
  editSubvideoRequest,
  // editSubtitleRequest,
} from '../actions/video-editor.actions';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeIn } from 'ng-animate';
import { Icons } from '../utils/icons';

/**
 * Ex) https://www.youtube.com/watch?v=fZS-7tcJ_X4
 * @author: john@gomedialy.com
 * @version: 0.23, 10/24/2020
 * @version: 0.30, 01/30/2021
 */
@Component({
  selector: 'app-video-editor',
  templateUrl: './video-editor.component.html',
  styleUrls: ['./video-editor.component.scss'],
  // animations: [trigger('fadeIn', [transition('* => *', useAnimation(fadeIn))])],
})
export class VideoEditorComponent
  extends BaseComponent
  implements OnInit, AfterViewInit {
  /* fields */
  // fadeIn: any;
  /**
   * Input from watch.component
   * inputVideoEditorState can not be null.
   */
  @Input('input')
  inputVideoEditorState!: VideoEditorState;

  // can not be null
  private videoId!: string;
  // can not be null
  private postId!: string;

  /**
   * Input for video-control
   */
  video: Video | null = null;

  private subvideo: Video = {
    videoType: 'subvideo',
    subvideoId: '',
    videoId: '',
    postId: null,
    startSeconds: 0,
    endSeconds: 0,
    duration: 0,
  };

  videoUrlFormGroup!: FormGroup;
  startSecondsFormGroup!: FormGroup;
  endSecondsFormGroup!: FormGroup;
  finishFormGroup!: FormGroup;

  // Only for focus
  @ViewChild('videoUrlInput')
  videoUrlInput?: ElementRef;

  // Only for focus
  @ViewChild('startSecondsInput')
  startSecondsInput?: ElementRef;

  // Only for focus
  @ViewChild('endSecondsInput')
  endSecondsInput?: ElementRef;

  // Only for focus
  @ViewChild('finalStartSecondsInput')
  finalStartSecondsInput?: ElementRef;

  isEditable = false;
  selectionIndex = 0;
  videoUrlOkButtonEnabled = false;
  startSecondsOkButtonEnabled = true;
  endSecondsOkButtonEnabled = false;

  startMiliseconds = 0;
  endMiliseconds = 0;
  durationMiliseconds = 0;
  finalStartMiliseconds = 0;
  finalEndMiliseconds = 0;
  finalDurationMiliseconds = 0;
  isTestPlaying = false;
  isVideoUrlReadOnly = false;
  videoImageUrl: string | null = 'assets/images/youtube-48-bw.png';
  value = '';
  isSubtitle = false;

  isSubtitle2 = false;

  constructor(
    private videoStore: Store<{
      [videoFeatureKey]: VideoState;
    }>,
    private videoEditorStore: Store<{
      [videoEditorFeatureKey]: VideoEditorState;
    }>,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  @HostListener('window:keydown', ['$event'])
  // tslint:disable-next-line: typedef
  keyEvent(event: KeyboardEvent) {
    // console.log('video-editor', event.key, event.code);
    switch (event.code) {
      case 'Escape':
        this.onCancelClick();
        break;
      case 'Space':
        event.preventDefault();
        break;
      case 'ArrowLeft':
        event.preventDefault();
        break;
      case 'ArrowRight':
        event.preventDefault();
        break;
      case 'ArrowUp':
        break;
      case 'ArrowDown':
        break;
    }
  }

  // ngOnInit(): void {}

  ngOnInit(): void {
    switch (this.inputVideoEditorState.type) {
      case editSubvideoRequest.type.toString():
        // this.state = state;

        // ! For now, this is not a subtitle
        // this.isSubtitle = false;
        // Step 0
        this.subvideo.videoType = 'subvideo';
        if (
          this.inputVideoEditorState.videoId &&
          this.inputVideoEditorState.postId
        ) {
          this.videoId = this.inputVideoEditorState.videoId;
          this.postId = this.inputVideoEditorState.postId;
        }

        this.videoUrlFormGroup = this.formBuilder.group({
          videoUrlControl: [
            // {
            //   value: `https://www.youtube.com/watch?v=${this.videoId}`,
            //   disabled: false,
            // },
            { value: '', disabled: false },
            [Validators.required, this.urlValidation],
          ],
        });
        break;
    }

    // Step 1
    this.startSecondsFormGroup = this.formBuilder.group({
      startSecondsControl: [
        { value: '00:00:00', disabled: false },
        // [Validators.required],
      ],
    });
    // Step 2
    this.endSecondsFormGroup = this.formBuilder.group({
      endSecondsControl: [
        { value: '00:00:00', disabled: false },
        // [Validators.required],
      ],
    });
    // Step 3
    this.finishFormGroup = this.formBuilder.group({
      finalStartSecondsControl: [
        { value: '00:00:00', disabled: false },
        // [Validators.required],
      ],
      finalEndSecondsControl: [
        { value: '00:00:00', disabled: false },
        // [Validators.required],
      ],
      finalDurationSecondsControl: [
        { value: '00:00:00', disabled: false },
        // [Validators.required],
      ],
    });
  }

  /**
   * All the data are directly linked to ui changes.
   * Therefore, things should be done in ngAfterViewInit(), not ngOnInit();
   */
  ngAfterViewInit(): void {
    // this.ngOnInit2();
    const videoStoreSubscription = this.videoStore
      .select(videoFeatureKey)
      .pipe(
        switchMap((state) => {
          switch (state.type) {
            case videoPlaying.type.toString():
              if (this.selectionIndex === 3) {
                this.isTestPlaying = true;
              }
              this.updateTimes(state);
              break;
            case videoPaused.type.toString():
              if (this.selectionIndex === 3) {
                this.isTestPlaying = false;
              }
              this.updateTimes(state);
              break;
            default:
              if (this.selectionIndex === 3) {
                this.isTestPlaying = false;
              }
              break;
          }

          return EMPTY;
        })
      )
      .subscribe();
    this.subscriptions.add(videoStoreSubscription);

    /**
     * Only for subvideos
     */
    of('')
      .pipe(delay(100))
      .subscribe((v) => {
        if (this.videoUrlInput) {
          // TODO: Test

          this.videoUrlInput.nativeElement.focus();
        }
      });
  }

  selectionChange(event: StepperSelectionEvent): void {
    this.selectionIndex = event.selectedIndex;

    /**
     * For subtitles, the first step is removed so that the index is changed by one.
     * Just increase the index by one to make up for the difference.
     */
    if (this.subvideo.videoType === 'subtitle') {
      // if (this.input.action === 'subtitle') {
      this.selectionIndex++;
    }
    switch (this.selectionIndex) {
      // switch (event.selectedIndex) {
      case 0:
        break;
      case 1:
        /**
         * To remove focus from the stepper header so that any key events;
         * likt arrow-left or arroow-right is not delived to the stepper header.
         */
        of('')
          .pipe(delay(100))
          .subscribe((v) => {
            if (this.startSecondsInput) {
              this.startSecondsInput.nativeElement.focus();
            }
          });
        break;
      case 2:
        this.subvideo.startSeconds = +(this.startMiliseconds / 1000).toFixed(0);
        this.finalStartMiliseconds = this.startMiliseconds;
        /**
         * To remove focus from the stepper header so that any key events;
         * likt arrow-left or arroow-right is not delived to the stepper header.
         */
        of('')
          .pipe(delay(100))
          .subscribe((v) => {
            if (this.endSecondsInput) {
              this.endSecondsInput.nativeElement.focus();
            }
          });
        break;
      case 3:
        // pause the video
        this.videoStore.dispatch(
          pauseVideo({
            videoId: this.subvideo.videoId,
          })
        );

        this.subvideo.endSeconds = this.endMiliseconds / 1000;

        const duration: number = +(
          this.endMiliseconds / 1000 -
          this.subvideo.startSeconds
        ).toFixed(0);

        this.subvideo.duration = duration;
        this.subvideo.subvideoId = `${this.subvideo.videoId}-${this.subvideo.startSeconds}-${this.subvideo.duration}`;

        // this.finalStartMiliseconds = this.subvideo.startSeconds * 1000;
        this.finalDurationMiliseconds = this.subvideo.duration * 1000;
        this.finalEndMiliseconds =
          this.durationMiliseconds + this.finalStartMiliseconds;

        this.setFinishFormValues();

        this.video = this.subvideo;
        // this.video.postId = this.input.postId;
        if (this.postId) this.video.postId = this.postId;
        // console.error('>>>>>>>>>>> after: ', this.video);

        /**
         * To remove focus from the stepper header so that any key events;
         * likt arrow-left or arroow-right is not delived to the stepper header.
         */
        of('')
          .pipe(delay(100))
          .subscribe((v) => {
            if (this.finalStartSecondsInput) {
              this.finalStartSecondsInput.nativeElement.focus();
            }
          });

        break;
    }
  }

  private setFinishFormValues(): void {
    const startTime = new Date(this.finalStartMiliseconds)
      .toISOString()
      .substr(11, 8);

    this.finishFormGroup.controls['finalStartSecondsControl'].setValue(
      startTime
    );

    const endTime = new Date(this.finalEndMiliseconds)
      .toISOString()
      .substr(11, 8);

    this.finishFormGroup.controls['finalEndSecondsControl'].setValue(endTime);

    const durationTime = new Date(this.finalDurationMiliseconds)
      .toISOString()
      .substr(11, 8);

    this.finishFormGroup.controls['finalDurationSecondsControl'].setValue(
      durationTime
    );
  }

  /**
   * ! This means the video is a subtitle
   */
  onUseCurrentVideoClick(): void {
    // console.info('############## onUseCurrentVideoClick: ', this.videoId);
    const url = `https://www.youtube.com/watch?v=${this.videoId}`;
    this.videoUrlFormGroup.controls['videoUrlControl'].setValue(url);
    this.isSubtitle2 = true;
  }

  onVideoUrlOkClick(): void {
    const url: string = this.videoUrlFormGroup.controls['videoUrlControl']
      .value;
    // https://www.youtube.com/watch?v=rf4FlJvkkrA

    const videoId = this.getYouTubeVideoId(url);
    if (videoId) {
      this.subvideo.videoId = videoId;

      /**
       * TODO: without delay of 1 second, it causes angular ui errors.
       */
      setTimeout(() => {
        this.videoStore.dispatch(
          playVideo({
            // videoType: this.input.action,
            videoType: this.subvideo.videoType,
            videoId,
            postId: null,
            startSeconds: 0,
            endSeconds: 0,
            duration: 0,
          })
        );
      }, 1000);

      // this.videoImageUrl = `https://i.ytimg.com/vi/${videoId}/default.jpg`;
      this.videoImageUrl = Icons.defaultImageUrl(videoId);
    }
  }

  onStartSecondsOkClick(): void {}

  onEndSecondsOkClick(): void {}

  onCancelClick(): void {
    // this.events.emit({ action: 'edit_cancel' });

    if (this.inputVideoEditorState) {
      switch (this.inputVideoEditorState.type) {
        // case editSubtitleRequest.type.toString():
        //   this.videoEditorStore.dispatch(
        //     editSubtitleResponse({
        //       // jsend: JSend.fail({ ...this.input, failId: 'cancel' }),
        //       jsend: JSend.fail({ failId: 'cancel' }),
        //     })
        //   );
        //   break;
        case editSubvideoRequest.type.toString():
          this.videoEditorStore.dispatch(
            editSubvideoResponse({
              // jsend: JSend.fail({ ...this.input, failId: 'cancel' }),
              jsend: JSend.fail({ failId: 'cancel' }),
            })
          );
          break;
      }
    }
  }

  /**
   * video-editor has no way of knowing the video-type.
   * Here we just use subvideo. The right type will be determined in post-editor-video.handler
   * TODO: subtitles vs. subvideos
   */
  onFinishClick(): void {
    if (this.inputVideoEditorState) {
      switch (this.inputVideoEditorState.type) {
        // case editSubtitleRequest.type.toString():
        //   this.videoEditorStore.dispatch(
        //     editSubtitleResponse({
        //       jsend: JSend.success(this.subvideo),
        //     })
        //   );
        //   break;

        case editSubvideoRequest.type.toString():
          /**
           * Since it is a subtitle.
           * Change videoType from subvideo to subtitle.
           */
          if (this.isSubtitle2) {
            this.subvideo.videoType = 'subtitle';
          }

          this.videoEditorStore.dispatch(
            editSubvideoResponse({
              // !the videoType of the subvideo will be determined in post-editor-video.handler
              jsend: JSend.success(this.subvideo),
            })
          );
          break;
      }
    }
  }

  private setStartSecondsFormValue(): void {
    const startTime = new Date(this.startMiliseconds)
      .toISOString()
      .substr(11, 8);

    this.startSecondsFormGroup.controls['startSecondsControl'].setValue(
      startTime
    );
  }

  private setEndSecondsFormValue(): void {
    const endTime = new Date(this.endMiliseconds).toISOString().substr(11, 8);
    this.endSecondsFormGroup.controls['endSecondsControl'].setValue(endTime);
  }

  private updateTimes(state: VideoState): void {
    // console.log('state: ', state);
    this.startMiliseconds = state.timestamp * 1000;
    this.endMiliseconds = state.timestamp * 1000;

    this.setStartSecondsFormValue();
    this.setEndSecondsFormValue();

    if (this.selectionIndex === 2) {
      this.endSecondsOkButtonEnabled =
        this.endMiliseconds > this.finalStartMiliseconds;
      const durationMilisecondsValue =
        this.endMiliseconds - this.finalStartMiliseconds;
      if (durationMilisecondsValue > 0) {
        this.durationMiliseconds = durationMilisecondsValue;
      } else {
        this.durationMiliseconds = 0;
      }
    }
  }

  urlValidation = (control: AbstractControl) => {
    const url = control.value;
    if (this.getYouTubeVideoId(url)) {
      // null means ok
      this.videoUrlOkButtonEnabled = true;
      return null;
    }
    // not ok
    this.videoUrlOkButtonEnabled = false;
    return { error: 'WTF' };
  };

  // testValidation = (control: AbstractControl) => {
  //   const value = control.value;

  //   console.log('testValidation: ', value);
  //   // if (this.getYouTubeVideoId(url)) {
  //   //   // null means ok
  //   //   this.videoUrlOkButtonEnabled = true;
  //   //   return null;
  //   // }
  //   // // not ok
  //   // this.videoUrlOkButtonEnabled = false;
  //   // return { error: 'WTF' };
  //   return null;
  // };

  /**
   * Ex) https://www.youtube.com/watch?v=rf4FlJvkkrA
   * @param url
   */
  private getYouTubeVideoId(url: string): string | null {
    try {
      const videoId = new URL(url.trim()).searchParams.get('v');
      if (videoId && videoId.length > 8) {
        // this.videoImageUrl = `https://i.ytimg.com/vi/${videoId}/default.jpg`;
        this.videoImageUrl = Icons.defaultImageUrl(videoId);
        return videoId;
      }
    } catch (error) {
      // ignore
    }
    // this.videoImageUrl = '../assets/images/icons8-film-reel-64.png';
    return null;
  }

  name(): string {
    return 'video-editor';
  }
}
