import { Injectable, Output, EventEmitter } from '@angular/core';
import { Post, Video } from '../models/posts';
import { videoFeatureKey, VideoState } from '../reducers/video.reducer';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { concatMap, filter, mergeMap, switchMap } from 'rxjs/operators';
import {
  videoPlaying,
  videoPaused,
  videoEnded,
  directPlayVideo,
  pauseVideo,
  playVideo,
} from '../actions/video.actions';
import { BaseService, Context } from '../base.service';
import { mainFeatureKey, MainState } from '../reducers/main.reducer';
import { VideoControlComponent } from './video-control.component';
import { VideoPlayerService } from '../video-player/video-player.service';
import { NGXLogger } from 'ngx-logger';
import { LocalStorageService } from '../services/local-storage.service';
import { LocalDataService } from '../services/local-data.service';

/**
 * TODO: BaseService is not working.
 * ! BaseService is not working. Why?
 *
 * While a video is playing, if another video starts,
 * the former does not emit any events like pause or stop at all.
 * Only the new video emits events like cued, playing and paused.
 *
 * @author: john@gomedialy.com
 * @version: 0.13, 10/08/2020
 * @version: 0.18, 01/16/2021
 */
@Injectable({
  providedIn: 'root',
})
export class VideoControlService extends BaseService {
  /**
   * id: postId/videoType
   * [id: VideoControlComponent]
   */
  private videoControlMap = new Map<string, VideoControlComponent>();
  private selectedPostId: string | null = null;
  private selectedVideoType: string | null = null;
  /**
   * Access from VideoControlComponent
   */
  isPlaying = false;

  constructor(
    public mainStore: Store<{
      [mainFeatureKey]: MainState;
    }>,
    protected logger: NGXLogger,
    private videoStore: Store<{
      [videoFeatureKey]: VideoState;
    }>,
    private videoPlayerService: VideoPlayerService,
    private localDataService: LocalDataService
  ) {
    // super(mainStore);
    super(mainStore, logger);

    let lastType: string | null = null;
    const videoStoreSubscription = this.videoStore
      .select(videoFeatureKey)
      .pipe(
        /**
         * Only when the state.type changes.
         * Ex) playing -> Paused -> Ended
         */
        filter((state: VideoState) => {
          // if (state.type === videoPlaying.type.toString()) {
          //   return true;
          // }
          if (state.type !== lastType) {
            lastType = state.type;
            return true;
          }
          return false;
        }),
        concatMap((state) => {
          switch (state.type) {
            case videoPlaying.type.toString():
              this.isPlaying = true;
              this.onVideoPlaying(state);
              break;
            case videoPaused.type.toString():
              this.isPlaying = false;
              this.onVideoPaused(state);
              break;
            case videoEnded.type.toString():
              this.isPlaying = false;
              this.onVideoEnded(state);
              break;
          }
          return EMPTY;
        })
      )
      .subscribe();
    // this.subscriptions.push(videoStoreSubscription);
  }

  // TODO: not working
  onInit(context: Context): void {}

  // TODO: not working
  onDestroy(context: Context): void {
    this.clear();
  }

  onPlayClick(video: Video, playType: 'stack' | 'direct'): void {
    /**
     * TODO: Performance improvement
     */
    this.videoControlMap.forEach((videoControl) => {
      videoControl.isPlaying = false;
      // console.log('onPlayClick: ', videoControl.video.postId);
    });

    if (this.checkVideoPlaying(video)) {
      // console.log('@@@@@@@@@@@@@@@@@@@@@ pause: ', video.postId, video.videoId);
      this.videoStore.dispatch(pauseVideo({ videoId: video.videoId }));
      if (video.postId) {
        this.clearSelectedStatesIfMatch(video.postId, video.videoType);
      }
    } else {
      // console.error(
      //   '@@@@@@@@@@@@@@@@@@@@@@ playing new: ',
      //   playType,
      //   video.postId,
      //   video.videoId
      // );
      switch (playType) {
        case 'stack':
          this.videoStore.dispatch(playVideo(video));
          break;
        case 'direct':
          this.videoStore.dispatch(directPlayVideo(video));
          break;
      }
      if (video.postId) {
        this.setSelectedStates(video.postId, video.videoType);

        /**
         * This goes with the downward button on toolbar.
         * This works like a bookmark.
         */
        this.localDataService
          .set2('watch', 'scrollId', video.postId)
          .subscribe();
      }
    }
  }

  private onVideoPlaying(state: VideoState): void {
    /**
     * To listen to the toolbar events
     */
    this.videoControlMap.forEach((videoControl) => {
      videoControl.isPlaying = false;
    });

    if (state.postId) {
      const videoControlComponent = this.videoControlMap.get(
        this.getId(state.postId, state.videoType)
      );
      if (videoControlComponent) {
        videoControlComponent.isPlaying = true;
      }
      this.setSelectedStates(state.postId, state.videoType);
    }
  }

  private onVideoPaused(state: VideoState): void {
    if (state.postId) {
      const videoControlComponent = this.videoControlMap.get(
        this.getId(state.postId, state.videoType)
      );
      if (videoControlComponent) {
        videoControlComponent.isPlaying = false;
      }
      this.clearSelectedStatesIfMatch(state.postId, state.videoType);
    }
  }

  private onVideoEnded(state: VideoState): void {
    if (state.postId) {
      const videoControlComponent = this.videoControlMap.get(
        this.getId(state.postId, state.videoType)
      );
      if (videoControlComponent) {
        videoControlComponent.isPlaying = false;
      }
      this.clearSelectedStatesIfMatch(state.postId, state.videoType);
    }
  }

  private clearSelectedStatesIfMatch(postId: string, videoType: string): void {
    if (this.selectedPostId && this.selectedVideoType) {
      if (
        postId === this.selectedPostId &&
        videoType === this.selectedVideoType
      ) {
        this.selectedPostId = null;
        this.selectedVideoType = null;
      }
    }
  }

  private setSelectedStates(postId: string, videoType: string): void {
    this.selectedPostId = postId;
    this.selectedVideoType = videoType;
  }

  init(videoControlComponent: VideoControlComponent): void {
    const video = videoControlComponent.video;
    if (video) {
      const postId = video.postId;
      if (postId) {
        const id = this.getId(postId, video.videoType);
        // console.log('*** init: ', id);
        this.videoControlMap.set(id, videoControlComponent);
      }
    }
  }

  private getId(postId: string, videoType: string): string {
    return `${postId}/${videoType}`;
  }

  checkVideoPlaying(video: Video): boolean {
    /**
     * This keeps the state in memory after refresh
     */
    if (this.selectedPostId && this.selectedVideoType) {
      return (
        video.postId === this.selectedPostId &&
        video.videoType === this.selectedVideoType
      );
    }
    return false;
  }

  private clear(): void {
    this.videoControlMap.clear();
    this.selectedPostId = null;
    this.selectedVideoType = null;
  }

  name(): string {
    return 'video-control';
  }
}
