import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import {
  videoEditorFeatureKey,
  VideoEditorState,
} from '../reducers/video-editor.reducer';
import {
  editSubvideoRequest,
  editSubvideoResponse,
} from '../actions/video-editor.actions';
import { videoFeatureKey, VideoState } from '../reducers/video.reducer';
import { PostEditorComponent } from './post-editor.component';
import { BaseHandler } from '../base.handler';
import { Video } from '../models/posts';
import { Icons } from '../utils/icons';

/**
 * @author: john@gomedialy.com
 * @version: 0.13, 10/24/2020
 * @version: 0.15, 01/09/2020
 */
export class PostEditorVideoHandler extends BaseHandler<PostEditorComponent> {
  /* fields */

  constructor(
    private postEditorComponent: PostEditorComponent,
    private videoStore: Store<{
      [videoFeatureKey]: VideoState;
    }>,
    private videoEditorStore: Store<{
      [videoEditorFeatureKey]: VideoEditorState;
    }>
  ) {
    super(postEditorComponent);
    this.init();
  }

  private isSubtitle(videoId: string): boolean {
    return this.parent.videoId === videoId;
  }

  private init(): void {
    const videoEditorSubscription = this.videoEditorStore
      .select(videoEditorFeatureKey)
      .pipe(
        mergeMap((state) => {
          switch (state.type) {
            case editSubvideoRequest.type.toString():
              this.parent.videoEditorInput = state;
              break;
            case editSubvideoResponse.type.toString():
              // console.log('################ editSubvideoResponse: ', state);

              if (state.jsend) {
                const subvideoJsend = state.jsend;
                switch (subvideoJsend.status) {
                  case 'success':
                    const video = subvideoJsend.data as Video;
                    if (this.isSubtitle(video.videoId)) {
                      // subtitle
                      // console.log('@@@@@@@@@@@@@@ it is a subtitle: ', video);
                      this.parent.subtitleVideo = video;
                      if (this.parent.subtitleVideo) {
                        this.parent.subtitleStartTime =
                          this.parent.subtitleVideo.startSeconds * 1000;
                        this.parent.subtitleEndTime =
                          this.parent.subtitleStartTime +
                          this.parent.subtitleVideo.duration * 1000;
                      }
                    } else {
                      // subvideo
                      // console.log('@@@@@@@@@@@@@@ it is a subvideo: ', video);
                      this.parent.subvideo = video;
                      if (this.parent.subvideo) {
                        this.parent.subvideoImageUrl = Icons.defaultImageUrl(
                          this.parent.subvideo.videoId
                        );
                        // this.parent.subvideoImageUrl = `https://i.ytimg.com/vi/${this.parent.subvideo.videoId}/default.jpg`;
                      }
                    }

                    break;
                  case 'fail':
                  case 'error':
                    /**
                     * TODO: videoId from fail or error
                     */
                    // const videoEditorInput = subvideoJsend.data as VideoEditorInput;
                    // if (videoEditorInput.videoId) {
                    //   this.videoStore.dispatch(
                    //     pauseVideoRequest({
                    //       videoId: videoEditorInput.videoId,
                    //     })
                    //   );
                    // }
                    break;
                }
              }
              this.parent.videoEditorInput = null;
              break;
          }
          return EMPTY;
        })
      )
      .subscribe();
    this.parent.subscriptions.add(videoEditorSubscription);
  }
}
