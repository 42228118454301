import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { EMPTY, of, from, Observable, throwError } from 'rxjs';
import { map, switchMap, mergeMap } from 'rxjs/operators';
import { JSend } from '../models/jsend';
import { AuthService } from '../services/auth.service';
import firebase from 'firebase/compat/app';
import {
  commitPostRequest,
  commitPostResponse,
  startPostingRequest,
  startPostingResponse,
  cancelPostingRequest,
  cancelPostingResponse,
  commitSubpostRequest,
  commitSubpostResponse,
  startSubpostingRequest,
  cancelSubpostingRequest,
  startSubpostingResponse,
  cancelSubpostingResponse,
  createLikePostRequest,
  createLikePostResponse,
  createDislikePostRequest,
  createDislikePostResponse,
  editPostContentRequest,
  editPostContentResponse,
  startEditPostRequest,
  startEditPostResponse,
  cancelEditPostContentRequest,
  cancelEditPostContentResponse,
  editPendingPostContentRequest,
  editPendingPostContentResponse,
  commitPostUpdateResponse,
  commitPostUpdateRequest,
  deletePostRequest,
  deletePostResponse,
  deletePendingPostsRequest,
  deletePendingPostsResponse,
} from '../actions/post.actions';
import { Post, TEXT_MARKDOWN } from '../models/posts';
import { Paths } from '../utils/paths';
import { PostService } from '../services/post.service';
import { PostPreferencesService } from '../services/post-preferences.service';
import { PostCountsService } from '../services/post-counts.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';

/**
 * @author: john@gomedialy.com
 * @version: 0.32, 10/28/2020
 * @version: 0.37, 01/18/2021
 */
@Injectable()
export class PostEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private postService: PostService,
    private postPreferenceService: PostPreferencesService,
    private postCountsService: PostCountsService,
    private afs: AngularFirestore
  ) {}

  deletePendingPostsRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deletePendingPostsRequest),
      switchMap((state) => {
        return this.postService.postRollbacks().pipe(
          map((counts) => {
            return deletePendingPostsResponse();
          })
        );
      })
    );
  });

  deletePostRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deletePostRequest),
      switchMap((state) => {
        const videoId = state.videoId;
        const postId = state.postId;
        return this.postService.postSoftDelete(videoId, postId);
      }),
      mergeMap((post) => {
        return of(
          deletePostResponse({ videoId: post.videoId, postId: post.postId })
        );
      })
    );
  });

  createLikePostRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createLikePostRequest),
      switchMap((state) => {
        const videoId = state.videoId;
        const postId = state.postId;
        const doc = `channels/${videoId}/posts/${postId}`;

        return this.postPreferenceService
          .preferPost(
            videoId,
            postId,
            true, // like
            doc
          )
          .pipe(
            mergeMap((v) => {
              return of(createLikePostResponse({ videoId, postId }));
            })
          );
      })
      // mergeMap((post) => {
      //   return of(createLikePostResponse({ videoId: post.videoId, postId: post.postId }));
      // })
    );
  });

  createDislikePostRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createDislikePostRequest),
      switchMap((state) => {
        const videoId = state.videoId;
        const postId = state.postId;
        const doc = `channels/${videoId}/posts/${postId}`;
        return this.postPreferenceService
          .preferPost(
            videoId,
            postId,
            false, // dislike
            doc
          )
          .pipe(
            mergeMap((v) => {
              return of(createDislikePostResponse({ videoId, postId }));
            })
          );
      })
      // mergeMap((post) => {
      //   return of(createDislikePostResponse({ jsend: JSend.success() }));
      // })
    );
  });

  startPostingRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(startPostingRequest),
      switchMap((state) => {
        return this.startPendingPost(state.videoId, state.tags);
      }),
      mergeMap((pendingPost) => {
        return of(
          startPostingResponse({
            postId: pendingPost.postId,
            videoId: pendingPost.videoId,
          })
        );
        // return of(
        //   startPostingResponse({
        //     jsend: JSend.success({
        //       postId: pendingPost.postId,
        //       videoId: pendingPost.videoId,
        //       content: pendingPost.content,
        //       status: pendingPost.status,
        //     }),
        //   })
        // );
      })
    );
  });

  /**
   * TODO: Edit
   */
  startEditPostRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(startEditPostRequest),
      switchMap((state) => {
        return this.postService.update(state.videoId, state.postId, {
          status: 'editing',
          editingAt: firebase.firestore.Timestamp.now(),
        });
      }),
      mergeMap((partial) => {
        // console.log('effects: ', post);
        return of(
          startEditPostResponse({
            videoId: partial.videoId,
            postId: partial.postId,
            // jsend: JSend.success(partial),
          })
        );
      })
    );
  });

  commitPostUpdateRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commitPostUpdateRequest),
      switchMap((state) => {
        return this.postService.update(state.videoId, state.postId, {
          status: 'committed',
          content: state.content,
          subvideo0: state.subvideo0,
          subvideo1: state.subvideo1,
          updatedAt: firebase.firestore.Timestamp.now(),
        });
      }),
      mergeMap((post) => {
        // console.log('effects: updatePostRequest: ', partial);
        return of(
          commitPostUpdateResponse({
            // jsend: JSend.success(post),
            videoId: post.videoId,
            postId: post.postId,
          })
        );
      })
    );
  });

  cancelEditPostRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cancelEditPostContentRequest),
      switchMap((state) => {
        return this.postService.update(state.videoId, state.postId, {
          status: 'committed',
          editingAt: firebase.firestore.Timestamp.now(),
        });
      }),
      mergeMap((partial) => {
        // console.log('effects: ', post);
        return of(
          cancelEditPostContentResponse({
            videoId: partial.videoId,
            postId: partial.postId,
            // jsend: JSend.success(partial),
          })
        );
      })
    );
  });

  editPendingPostRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(editPendingPostContentRequest),
      // switchMap((state) => {
      //   return this.startPendingPost(state.videoId, state.tags);
      // }),
      mergeMap((post) => {
        // console.log('effects: ', post);
        return of(
          editPendingPostContentResponse({
            videoId: post.videoId,
            postId: post.postId,

            // jsend: JSend.success({
            //   postId: post.postId,
            //   videoId: post.videoId,
            //   content: post.content,
            // }),
          })
        );
      })
    );
  });

  editPostRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(editPostContentRequest),
      // switchMap((state) => {
      //   return this.startPendingPost(state.videoId, state.tags);
      // }),
      mergeMap((post) => {
        // console.log('effects: ', post);
        return of(
          editPostContentResponse({
            videoId: post.videoId,
            postId: post.postId,
            // jsend: JSend.success({
            //   content: post.content,
            // }),
          })
        );
        // return of(
        //   editPostContentResponse({
        //     jsend: JSend.success({
        //       postId: post.postId,
        //       videoId: post.videoId,
        //       content: post.content,
        //     }),
        //   })
        // );
      })
    );
  });

  cancelPostingRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cancelPostingRequest),
      switchMap((state) => {
        return this.cancelPendingPost().pipe(
          mergeMap((counts) => {
            return of(
              cancelPostingResponse({
                videoId: state.videoId,
                postId: state.postId,
              })
            );
          })
        );
      })
      // mergeMap((v) => {
      //   return of(cancelPostingResponse({ jsend: JSend.success() }));
      // })
    );
  });

  commitPostRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commitPostRequest),
      switchMap((state) => {
        // console.log('>>>>>>>>>>>>>>>>> WTF: ', state);
        if (state.subvideo1) {
          return this.postService.postCommit(
            state.postId,
            state.content,
            state.tags,
            state.subvideo0,
            state.subvideo1
          );
        }
        return this.postService.postCommit(
          state.postId,
          state.content,
          state.tags,
          state.subvideo0,
          state.subvideo1
        );
      }),
      mergeMap((post) => {
        // console.log('createPostResponse: ', post.content);
        return this.postCountsService
          .updatePostCounts(post.videoId, 'posts', 1)
          .pipe(
            mergeMap((v) => {
              return of(
                commitPostResponse({
                  videoId: post.videoId,
                  postId: post.postId,
                })
              );

              // return of(
              //   commitPostResponse({
              //     jsend: JSend.success({ status: post.status }),
              //   })
              // );
            })
          );
      })
    );
  });

  /**
   * Subpost
   */
  startSubpostingRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(startSubpostingRequest),
      switchMap((state) => {
        // console.log('WTF: ', state);
        return this.startPendingSubpost(
          state.videoId,
          state.parentPost,
          state.tags
        );
      }),
      mergeMap((post) => {
        return of(
          startSubpostingResponse({
            videoId: post.videoId,
            postId: post.postId,
          })
        );

        // return of(
        //   startSubpostingResponse({
        //     jsend: JSend.success({
        //       postId: post.postId,
        //       videoId: post.videoId,
        //     }),
        //   })
        // );
      })
    );
  });

  cancelSubpostingRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cancelSubpostingRequest),
      switchMap((state) => {
        // console.error('WTF: ', state);
        return this.cancelPendingSubpost().pipe(
          mergeMap((counts) => {
            return of(
              cancelSubpostingResponse({
                videoId: state.videoId,
                postId: state.postId,
              })
            );
          })
        );
      })
      // mergeMap((v) => {
      //   // return of(cancelSubpostingResponse({ jsend: JSend.success() }));
      //   return of(cancelSubpostingResponse({ jsend: JSend.success() }));
      // })
    );
  });

  commitSubpostRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(commitSubpostRequest),
      switchMap((state) => {
        return this.postService.postCommit(
          state.postId,
          state.content,
          state.tags,
          state.subvideo0,
          state.subvideo1
        );
      }),
      mergeMap((post) => {
        // console.log('createPostResponse: ', post.content);
        return this.postCountsService
          .updatePostCounts(post.videoId, 'subposts', 1)
          .pipe(
            mergeMap((v) => {
              return of(
                commitSubpostResponse({
                  videoId: post.videoId,
                  postId: post.postId,
                })
              );

              // return of(
              //   commitSubpostResponse({
              //     jsend: JSend.success({
              //       postId: post.postId,
              //       videoId: post.videoId,
              //       content: post.content,
              //       status: post.status,
              //     }),
              //   })
              // );
            })
          );
      })
      // switchMap((post) => {
      //   console.log('createSubPostResponse: ', post.content);
      //   return of(createSubpostResponse({ jsend: JSend.success() }));
      // })
    );
  });

  private startPendingPost(videoId: string, tags: string[]): Observable<Post> {
    const user = this.authService.getUser();
    if (user) {
      // TODO: start pending posts
      const postId = this.postService.createPostId(videoId);
      const timestamp = firebase.firestore.Timestamp.now();
      const post: Post = {
        category: 'post',
        tags,
        postType: 'post',
        groupId: postId,
        postId,
        path: `channels/${videoId}/posts/${postId}`,
        userId: user.userId,
        displayName: user.displayName ? user.displayName : 'Unknown',
        status: 'pending',
        contentType: TEXT_MARKDOWN,
        content: '',
        videoId,
        photoUrl: user.photoUrl
          ? user.photoUrl
          : '../assets/images/default-user-photo.png',
        level: 0, // root
        likes: 0,
        dislikes: 0,
        controversials: 0,
        email: user.email ? user.email : 'Unknown',
        groupedAt: timestamp,
        createdAt: timestamp,
        updatedAt: timestamp,
      };
      return this.postService.postPending(post);
    }
    return throwError(new Error('User not loggined-in'));
  }

  private cancelPendingPost(): Observable<number> {
    return this.postService.postRollbacks();
  }

  private startPendingSubpost(
    videoId: string,
    parentPost: Post,
    tags: string[]
  ): Observable<Post> {
    const timestamp = firebase.firestore.Timestamp.now();

    const user = this.authService.getUser();
    if (user) {
      // TODO: start pending posts
      const postId = this.postService.createPostId(videoId);
      const parentPath = parentPost.path;
      const level = Paths.level(parentPath) + 1;

      // console.error(
      //   '%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% subpost level: ',
      //   parentPath,
      //   level
      // );

      const post: Post = {
        category: 'post',
        tags,
        postType: 'subpost',
        groupId: parentPost.postId,
        // postId: undefined,
        postId,
        // path: '',
        path: `${parentPath}/posts/${postId}`,
        userId: user.userId,
        displayName: user.displayName ? user.displayName : 'Unknown',
        // pending: true,
        status: 'pending',
        contentType: TEXT_MARKDOWN,
        content: '',
        videoId,
        photoUrl: user.photoUrl
          ? user.photoUrl
          : '../assets/images/default-user-photo.png',
        level, // difference between root and child
        likes: 0,
        dislikes: 0,
        controversials: 0,
        email: user.email ? user.email : 'Unknown',
        groupedAt: parentPost.groupedAt,
        createdAt: timestamp,
        updatedAt: timestamp,
      };
      return this.postService.subpostPending(parentPath, post).pipe(
        switchMap((updatedPost) => {
          return of(updatedPost);
        })
      );
    }
    return throwError(new Error('User not loggined-in'));
  }

  private cancelPendingSubpost(): Observable<number> {
    return this.postService.postRollbacks();
  }
}
