import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { Video } from '../models/posts';
import { Preconditions } from '../utils/guava';

/**
 * @author: john@gomedialy.com
 * @version: 0.1, 12/18/2020
 */
export interface VideoButtonEvent {
  action: 'subvideo';
  source: Video;
}
@Component({
  selector: 'app-video-button',
  templateUrl: './video-button.component.html',
  styleUrls: ['./video-button.component.scss'],
})
export class VideoButtonComponent implements OnInit {
  /* fields */
  @Input('video')
  video!: Video;

  @Output()
  events = new EventEmitter<VideoButtonEvent>();

  /**
   * Whether to use either playVideoRequest() or directPlayVideoRequest()
   */
  @Input('play')
  playType: 'stack' | 'direct' = 'stack';

  isPlaying = false;

  // videoImageUrl: string | null = '../assets/images/icons8-film-reel-64.png';
  // subvideoImageUrl: string | null = '../assets/images/icons8-film-reel-64.png';
  videoImageUrl: string | null = null; // '../assets/images/icons8-film-reel-64.png';
  subvideoImageUrl: string | null = null; // '../assets/images/icons8-film-reel-64.png';
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';
  value = 10;
  tooltip = '';
  isPlayIconVisible = false;

  iconsClass: string = 'icons';

  centered = false;
  disabled = false;
  unbounded = false;

  radius: number = 0.5;
  color2: string = 'red';

  constructor() {}

  ngOnInit(): void {
    if (this.video) {
      const postId = this.video.postId;
      Preconditions.checkNotNull(postId, 'postId is null.');
      /**
       * For video-editor, it does not produce any postId.
       * In this case, the video has no postId.
       */
      // if (postId === undefined) {
      //   console.log(
      //     'postId is null and it is replaced by videoId: ',
      //     this.video.videoId
      //   );
      //   this.video = { ...this.video, postId: this.video.videoId };
      // }
      // this.isPlaying = this.videoControlService.checkVideoPlaying(this.video);
      // https://www.youtube.com/watch?v=F_S2DVErabk
      // const videoId = 'BF9UdoJLdwE';
      this.videoImageUrl = `https://i.ytimg.com/vi/${this.video.videoId}/default.jpg`;
      switch (this.video.videoType) {
        case 'subtitle':
          this.tooltip = '구간동영상 삭제';
          this.iconsClass = 'icons icon-gray';
          break;
        case 'subvideo':
          this.tooltip = '외부동영상 삭제';
          this.iconsClass = 'icons icon-white';
          break;
      }
    }
    // this.videoControlService.init(this);
  }

  onClick(): void {
    // this.isPlaying = !this.isPlaying;
    if (this.video) {
      // this.videoControlService.onPlayClick(this.video, this.playType);
      console.log('onClick...: video-button', this.video);
      this.events.emit({ action: 'subvideo', source: this.video });
      // switch (this.video.videoType) {
      //   case 'subtitle':
      //     this.tooltip = '구간동영상';
      //     this.iconsClass = 'icons icon-gray';
      //     this.events.emit({ action: 'subvideo0', source: this.video });
      //     break;
      //   case 'subvideo':
      //     this.tooltip = '외부동영상';
      //     this.iconsClass = 'icons icon-white';
      //     this.events.emit({ action: 'subvideo1', source: this.video });
      //     break;
      // }
    }
  }
}
