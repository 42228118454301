<div
  class="container debug-redx"
  fxLayout="row layout-wrap"
  fxLayoutAlign="center center"
  fxLayoutGap="16px"
>
  <div class="title mat-display-1">한(恨)의 영어</div>
  <div>&nbsp;</div>
  <ng-container *ngFor="let item of inputs">
    <app-query-button [item]="item"></app-query-button>
  </ng-container>
</div>
