import { createReducer, on } from '@ngrx/store';
import * as StackActions from '../actions/stack.actions';
import { Video } from '../models/posts';

/**
 * @author: john@gomedialy.com
 * @version: 0.14, 10/08/2020
 * @version: 0.15, 01/07/2021
 */
export const stackFeatureKey = 'stack';

export interface StackState {
  type: string | null;
  video: Video | null;
  index: number;
  stack: Video[];
}

export const initialState: StackState = {
  type: null,
  video: null,
  index: -1,
  stack: [],
};

export const stackReducer = createReducer(
  initialState,

  on(StackActions.pushVideo, (state, action) => {
    // console.error('##### pushVideo: ', state, action);
    // if (state.stack.length === 0) {
    //   return {
    //     ...state,
    //     type: action.type,
    //     stack: [{ ...action.video }],
    //   };
    // }

    const newStack = [...state.stack];
    newStack.push({ ...action.video });
    // if (state.video) {
    //   // newStack.push(state.video);
    //   newStack.push({ ...action.video });
    // }
    return {
      // ...state,
      type: action.type,
      video: null,
      index: newStack.length - 1,
      stack: newStack,
    };
  }),

  on(StackActions.popVideo, (state, action) => {
    const newStack = [...state.stack];
    newStack.pop();
    return {
      // ...state,
      type: action.type,
      video: null,
      index: newStack.length === 0 ? -1 : newStack.length - 1,
      stack: newStack,
    };

    // if (state.video) {
    //   newStack.pop();
    // }
    // return {
    //   ...state,
    //   type: action.type,
    //   stack: newStack,
    // };
  }),

  /**
   * Leave [0]
   */
  on(StackActions.clearVideos, (state, action) => {
    const newStack = [state.stack[0]];
    return {
      type: action.type,
      video: null,
      index: 0,
      stack: newStack,
    };

    // return {
    //   // ...state,
    //   type: action.type,
    //   stack: newStack,
    // };
  }),

  on(StackActions.closeVideos, (state, action) => {
    // return {
    //   ...state,
    //   type: action.type,
    //   stack: [],
    // };

    return {
      type: action.type,
      video: null,
      index: -1,
      stack: [],
    };
  })

  // // Push video
  // on(StackActions.pushVideoRequest, (state, action) => {
  //   return { ...state, ...action, jsend: null };
  //   // return { ...state, video: action.video, jsend: null };
  // }),
  // on(StackActions.pushVideoResponse, (state, action) => {
  //   const newLinks = [...state.stack];
  //   if (state.video) {
  //     newLinks.push(state.video);
  //   }
  //   return {
  //     ...state,
  //     type: action.type,
  //     stack: newLinks,
  //     jsend: action.jsend,
  //   };

  //   // TODO: original
  //   // return { ...state, ...action, jsend: action.jsend };
  // }),

  // // Pop video
  // on(StackActions.popVideoRequest, (state, action) => {
  //   return { ...state, ...action, jsend: null };
  //   // return { ...state, video: action.video, jsend: null };
  // }),
  // on(StackActions.popVideoResponse, (state, action) => {
  //   const newLinks = [...state.stack];

  //   if (state.video) {
  //     newLinks.pop();
  //   }
  //   // console.error('>>>> popVideoResponse: ', newLinks);
  //   return {
  //     ...state,
  //     type: action.type,
  //     stack: newLinks,
  //     jsend: action.jsend,
  //   };

  //   // TODO: original
  //   // return { ...state, ...action, jsend: action.jsend };
  // }),

  // // Clear videos
  // on(StackActions.clearVideosRequest, (state, action) => {
  //   return { ...state, ...action, jsend: null };
  //   // return { ...state, video: action.video, jsend: null };
  // }),
  // on(StackActions.clearVideosResponse, (state, action) => {
  //   const newLinks = [state.stack[0]];
  //   return {
  //     ...state,
  //     type: action.type,
  //     stack: newLinks,
  //     jsend: action.jsend,
  //   };

  //   // TODO: original
  //   // return { ...state, ...action, jsend: action.jsend };
  // }),

  // // Close videos
  // on(StackActions.closeVideosRequest, (state, action) => {
  //   return { ...state, ...action, jsend: null };
  //   // return { ...state, video: action.video, jsend: null };
  // }),
  // on(StackActions.closeVideosResponse, (state, action) => {
  //   // const newLinks = [];
  //   return {
  //     ...state,
  //     type: action.type,
  //     stack: [],
  //     jsend: action.jsend,
  //   };

  //   // TODO: original
  //   // return { ...state, ...action, jsend: action.jsend };
  // })
);
