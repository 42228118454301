import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

/**
 * @author: john@gomedialy.com
 * @version: 0.1, 11/25/2020
 */
@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor(private deviceDetectorService: DeviceDetectorService) {}

  isDesktop(): boolean {
    return this.deviceDetectorService.isDesktop();
  }
}
