<div id="home-top"></div>
<div class="container debug-redx" fxLayout="column" fxLayoutGap="0px">
  <app-word-cloud [inputs]="wordCloudItems" #wordCloud></app-word-cloud>
  <ng-container *ngFor="let item of contentDataList">
    <app-content-card-group
      id="{{ createHtmlElementId(item.query) }}"
      [input]="item"
    ></app-content-card-group>
  </ng-container>
</div>
<div
  *ngIf="scrollToTopEnabled"
  ngClass.lt-lg="fab-up-mobile"
  ngClass.lg="fab-up-mobile"
  ngClass.gt-lg="fab-up-desktop"
  [@zoomIn]="zoomIn"
  [@zoomOut]="zoomOut"
>
  <button mat-fab style="background-color: #616161" (click)="onScrollToTop()">
    <mat-icon style="font-weight: bolder">arrow_upward</mat-icon>
  </button>
</div>

<ngx-spinner
  type="ball-beat"
  size="medium"
  bdColor="rgba(0, 0, 0, 0)"
  color="#F44336"
></ngx-spinner>
