<div id="watch-top"></div>
<div class="video-player">
  <app-video-player
    class="video-player"
    #videoPlayer
    [videoId]="videoId"
    [startSeconds]="startSeconds"
    [endSeconds]="endSeconds"
    [item]="item"
    [width]="playerWidth"
    [height]="playerHeight"
  ></app-video-player>
</div>
<div class="container debug-redx">
  <!-- <ng-container *ngIf="item">
    <app-video-player
      #videoPlayer
      [videoId]="videoId"
      [startSeconds]="startSeconds"
      [endSeconds]="endSeconds"
      [item]="item"
      [width]="playerWidth"
      [height]="playerHeight"
    ></app-video-player>
  </ng-container> -->
  <div id="watch-center" (ngxVisibility)="onVideoVisible($event)"></div>

  <!-- <ng-container *ngIf="subtitleInput && isDesktop">
    <app-subtitle #subtitle [input]="subtitleInput"></app-subtitle>
  </ng-container> -->
  <ng-container *ngIf="item && item.query && postEditorInput === null">
    <app-video-info [item]="item"></app-video-info>
  </ng-container>
  <div [@bounceIn]="bounceIn" *ngIf="postEditorInput">
    <app-post-preview></app-post-preview>
  </div>
  <div *ngIf="item">
    <app-posts-board #postsBoard [item]="item"></app-posts-board>
  </div>
  <div
    *ngIf="scrollToTopEnabled"
    ngClass.lt-lg="fab-up-mobile"
    ngClass.lg="fab-up-mobile"
    ngClass.gt-lg="fab-up-desktop"
    [@zoomIn]="zoomIn"
    [@zoomOut]="zoomOut"
  >
    <button mat-fab style="background-color: #616161" (click)="onScrollToTop()">
      <mat-icon style="font-weight: bolder">arrow_upward</mat-icon>
    </button>
  </div>
</div>
<ngx-spinner
  type="ball-beat"
  size="medium"
  bdColor="rgba(0, 0, 0, 0)"
  color="#9E9E9E"
></ngx-spinner>
<ng-container *ngIf="postEditorInput">
  <div [@fadeIn]="fadeIn" [@fadeOut]="fadeOut" class="dimmer"></div>
</ng-container>

<ng-container *ngIf="isDesktop && isPipVisible">
  <app-pip-player
    [@zoomIn]="zoomIn"
    class="pip-player mat-elevation-z24"
    [videoState]="videoState"
  ></app-pip-player>
</ng-container>

<ng-container *ngIf="isDesktop && isVideoBarVisible">
  <app-video-bar
    class="video-bar"
    [videoBarData]="videoBarData"
  ></app-video-bar>
</ng-container>
<ng-container *ngIf="postEditorInput">
  <div [@zoomIn]="zoomIn" [@zoomOut]="zoomOut" class="post-editor">
    <app-post-editor [input]="postEditorInput" #postEditor></app-post-editor>
  </div>
</ng-container>
