import { DOCUMENT } from '@angular/common';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, ErrorHandler, Inject } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, EMPTY } from 'rxjs';

/**
 * When users log out,
 */
const MISSING_OR_INSUFFICIENT_PERMISSION =
  'Missing or insufficient permissions.';
/**
 * @author: john@gomedialy.com
 * @version: 0.13, 02/01/2021
 */
@Injectable()
export class GlobalErrorHandler implements ErrorHandler, HttpInterceptor {
  /* fields */
  private window: any;

  constructor(
    private logger: NGXLogger,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.window = this.document.defaultView;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return EMPTY;
  }

  handleError(error: Error): void {
    console.error('global:', error);
    const message = error.message;
    if (message && message.startsWith(MISSING_OR_INSUFFICIENT_PERMISSION)) {
      this.logger.debug('[IGNORED] ', MISSING_OR_INSUFFICIENT_PERMISSION);

      // setTimeout(() => {
      //   this.window.location.reload();
      // }, 1000);
    } else {
      this.logger.error(error);
    }
  }
}
