import firebase from 'firebase/compat/app';
import { TopSearch } from './trends';
import { Item } from './youtube-data';

/**
 * @author: john@gomedialy.com
 * @version: 0.11, 09/06/2020
 * @version: 0.16, 01/14/2021
 */
export interface DataCache<T> {
  // data: T[];
  data: T;
  createdAt: Date;
}

export interface ClientData<T> {
  // data: T[];
  data: T;
  createdAt: firebase.firestore.Timestamp;
}

export interface ContentData extends TopSearch {
  items: Item[];
  suggestions: string[];

  /**
   * TODO: Test
   * To check if all the data is loaded by checking the last.
   */
  isLast?: boolean;
}

export class EmptyTopSearchData {
  public static create(timestamp: firebase.firestore.Timestamp): ContentData {
    const topSearchData: ContentData = {
      /* fields */
      date: timestamp,
      timestamp,
      query: '',
      formattedTraffic: '',
      relatedQueries: [],
      items: [],
      suggestions: [],
    };

    return topSearchData;
  }
}
