import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BaseComponent } from '../base.component';

/**
 * @author: john@gomedialy.com
 * @version: 0.1, 11/18/2021
 */
@Component({
  selector: 'app-home2',
  templateUrl: './home2.component.html',
  styleUrls: ['./home2.component.scss'],
})
export class Home2Component
  extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  constructor(private logger: NGXLogger) {
    super();
    // this.goToEvent();
  }

  ngAfterViewInit(): void {
    // throw new Error('Method not implemented.');
  }

  ngOnInit(): void {}

  onClick() {
    // nothing
  }

  openAppMarket() {
    window.location.href =
      'http://play.google.com/store/apps/details?id=com.gomedialy.haanglish';
  }

  openAppStore() {
    window.location.href =
      'https://apps.apple.com/us/app/%ED%95%9C%EA%B8%80%EB%A6%AC%EC%89%AC/id1567846939';
  }
}
