import { createAction, props } from '@ngrx/store';
import { Post } from '../models/posts';

/**
 * @author: john@gomedialy.com
 * @version: 0.14, 09/23/2020
 * @version: 0.15, 01/13/2021
 */

/**
 * Better to be quick
 */
export const clearDataState = createAction('[Data] Clear Data State');

/**
 * Natural
 */
export const postPageRequest = createAction(
  '[Data] Post Page Request',
  props<{
    videoId: string;
    category: 'all' | 'subtitle' | 'mine';
    direction: 'previous' | 'next';
    pageSize: number;
    orderBy: 'asc' | 'desc';
    /**
     * startAfter() or endBefore()
     */
    partition?: string;
  }>()
);

export const postPageRequestProcessing = createAction(
  '[Data] Post Page Request Processing'
);

export const postPageResponse = createAction(
  '[Data] Post Page Response',
  props<{
    videoId: string;
    posts: Post[];
    isFirst: boolean;
    isLast: boolean;
    start?: string; // docId
    end?: string;
  }>()
);

/**
 * New
 */
export const newPostPageRequest = createAction(
  '[Data] New Post Page Request',
  props<{
    videoId: string;
    category: 'all' | 'subtitle' | 'mine';
    direction: 'previous' | 'next';
    pageSize: number;
    orderBy: 'asc' | 'desc';
    /**
     * startAfter() or endBefore()
     */
    partition?: string;
  }>()
);

export const newPostPageRequestProcessing = createAction(
  '[Data] New Post Page Request Processing'
);

export const newPostPageResponse = createAction(
  '[Data] New Post Page Response',
  props<{
    videoId: string;
    posts: Post[];
    isFirst: boolean;
    isLast: boolean;
    start?: string; // docId
    end?: string;
  }>()
);

/**
 * Natural
 */
export const naturalPostPageRequest = createAction(
  '[Data] Natural Post Page Request',
  props<{
    videoId: string;
    category: 'all' | 'subtitle' | 'mine';
    direction: 'previous' | 'next';
    pageSize: number;
    orderBy: 'asc' | 'desc';
    /**
     * startAfter() or endBefore()
     */
    partition?: string;
  }>()
);

export const naturalPostPageRequestProcessing = createAction(
  '[Data] Natural Post Page Request Processing'
);

export const naturalPostPageResponse = createAction(
  '[Data] Natural Post Page Response',
  props<{
    videoId: string;
    posts: Post[];
    isFirst: boolean;
    isLast: boolean;
    start?: string; // docId
    end?: string;
  }>()
);

/**
 * Top
 */
export const topPostPageRequest = createAction(
  '[Data] Top Post Page Request',
  props<{
    videoId: string;
    category: 'all' | 'subtitle' | 'mine';
    direction: 'previous' | 'next';
    pageSize: number;
    orderBy: 'asc' | 'desc';
    /**
     * startAfter() or endBefore()
     */
    partition?: string;
  }>()
);

export const topPostPageRequestProcessing = createAction(
  '[Data] Top Post Page Request Processing'
);

export const topPostPageResponse = createAction(
  '[Data] Top Post Page Response',
  props<{
    videoId: string;
    posts: Post[];
    isFirst: boolean;
    isLast: boolean;
    start?: string; // docId
    end?: string;
  }>()
);

/**
 * Controversial
 */
export const controversialPostPageRequest = createAction(
  '[Data] Controversial Post Page Request',
  props<{
    videoId: string;
    category: 'all' | 'subtitle' | 'mine';
    direction: 'previous' | 'next';
    pageSize: number;
    orderBy: 'asc' | 'desc';
    /**
     * startAfter() or endBefore()
     */
    partition?: string;
  }>()
);

export const controversialPostPageRequestProcessing = createAction(
  '[Data] Controversial Post Page Request Processing'
);

export const controversialPostPageResponse = createAction(
  '[Data] Controversial Post Page Response',
  props<{
    videoId: string;
    posts: Post[];
    isFirst: boolean;
    isLast: boolean;
    start?: string; // docId
    end?: string;
  }>()
);

/**
 * Rising
 */
export const risingPostPageRequest = createAction(
  '[Data] Rising Post Page Request',
  props<{
    videoId: string;
    category: 'all' | 'subtitle' | 'mine';
    direction: 'previous' | 'next';
    pageSize: number;
    orderBy: 'asc' | 'desc';
    /**
     * startAfter() or endBefore()
     */
    partition?: string;
  }>()
);

export const risingPostPageRequestProcessing = createAction(
  '[Data] Rising Post Page Request Processing'
);

export const risingPostPageResponse = createAction(
  '[Data] Rising Post Page Response',
  props<{
    videoId: string;
    posts: Post[];
    isFirst: boolean;
    isLast: boolean;
    start?: string; // docId
    end?: string;
  }>()
);

/**
 * Mine
 */
export const minePostPageRequest = createAction(
  '[Data] Mine Post Page Request',
  props<{
    videoId: string;
    category: 'all' | 'subtitle' | 'mine';
    direction: 'previous' | 'next';
    pageSize: number;
    orderBy: 'asc' | 'desc';
    /**
     * startAfter() or endBefore()
     */
    partition?: string;
  }>()
);

export const minePostPageRequestProcessing = createAction(
  '[Data] Mine Post Page Request Processing'
);

export const minePostPageResponse = createAction(
  '[Data] Mine Post Page Response',
  props<{
    videoId: string;
    posts: Post[];
    isFirst: boolean;
    isLast: boolean;
    start?: string; // docId
    end?: string;
  }>()
);
