<div class="container debug-redx" fxLayout="column" fxLayoutGap="8px">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
    <div>
      <img class="image-rounded" src="{{ photoUrl }}" />
    </div>
    <div class="mat-title">
      {{ title }}
    </div>
  </div>

  <div
    class="word-wrap debug-redx"
    markdown
    ngPreserveWhitespaces
    emoji
    [data]="draftContent"
    style="color: #9e9e9e"
  ></div>
</div>
