<div
  [@fadeIn]="fadeIn"
  class="container debug-redx"
  fxLayout="column"
  fxLayoutGap="8px"
>
  <div fxLayout="row" fxLayoutAlign="start center">
    <div>
      <img
        width="32"
        height="32"
        style="vertical-align: middle"
        src="assets/images/youtube-48-bw.png"
      />
    </div>
    <div fxLayout="column">
      <div
        style="
          margin: 0px;
          font-size: small;
          font-weight: bolder;
          line-height: 1.5;
        "
      >
        {{ title }}
      </div>
      <div
        style="
          color: gray;
          font-size: small;
          font-weight: bolder;
          line-height: 1;
        "
      >
        {{ subtitle | dateAgo }}
      </div>
    </div>
  </div>

  <div>
    <p class="mat-body-2" style="font-weight: bolder">{{ description0 }}</p>
    <p class="mat-body-2" style="font-weight: bolder">{{ description1 }}</p>
  </div>
</div>
