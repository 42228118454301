<div
  class="container gray-font debug-redx"
  fxLayout="row"
  fxLayoutAlign="end center"
  fxLayoutGap="16px"
>
  <!-- <span>1 - 10 of 100</span> -->

  <div>
    <button
      #previous
      mat-icon-button
      disabled="false"
      (click)="onPreviousClick()"
    >
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button #next mat-icon-button disabled="false" (click)="onNextClick()">
      <mat-icon>navigate_next</mat-icon>
    </button>
  </div>
</div>
