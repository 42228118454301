import { Component, OnInit, Input, Inject } from '@angular/core';
import { Strings } from '../utils/strings';
import { DOCUMENT } from '@angular/common';
import { RealtimeCounterService } from '../services/realtime-counter.service';
import { NGXLogger } from 'ngx-logger';
import { SearchCardService } from './search-card.service';
import { SearchItem } from '../services/google-search.service';
import { Objects } from '../utils/objects';
import { LocalStorageService } from '../services/local-storage.service';
import { BaseComponent } from '../base.component';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeIn } from 'ng-animate';
import { mergeMap, concatMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

/**
 * @author: john@gomedialy.com
 * @version: 0.19, 02/01/2021
 */
@Component({
  selector: 'app-search-card',
  templateUrl: './search-card.component.html',
  styleUrls: ['./search-card.component.scss'],
  animations: [
    trigger('fadeIn', [transition('* <=> *', useAnimation(fadeIn))]),
  ],
})
export class SearchCardComponent extends BaseComponent implements OnInit {
  /* fields */
  fadeIn: any;

  @Input()
  item!: SearchItem;

  title = '';
  description = '';
  private isDesktop = false;

  /**
   * Double as a scrollId
   */
  linkId = '';
  viewCount = 0;
  imageUrl = 'https://via.placeholder.com/400x200.png?text=Loading';

  constructor(
    private logger: NGXLogger,
    private searchCardService: SearchCardService,
    private realtimeCounter: RealtimeCounterService,
    private localStorageService: LocalStorageService,
    private deviceService: DeviceDetectorService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
    this.isDesktop = this.deviceService.isDesktop();
  }

  ngOnInit(): void {
    if (this.item) {
      const link = this.item.link;
      this.linkId = Objects.hashString(link);

      const viewCountSubscription = this.realtimeCounter
        .getCount(`links/${this.linkId}`)
        .subscribe((counts) => {
          if (counts === 1) {
            // ignored.
          } else {
            /**
             * count starts from 1 and 1 is ignored because 1 occurs on each increase.
             */
            this.viewCount = counts;
          }
        });
      this.subscriptions.add(viewCountSubscription);

      this.title = Strings.decodeHtml(this.document, this.item.title);
      this.description = Strings.decodeHtml(
        this.document,
        this.item.description
      );

      if (this.item.screenshot) {
        this.imageUrl = this.item.screenshot;
      } else if (this.item.image) {
        this.imageUrl = this.item.image;
      } else {
        this.imageUrl = '../assets/images/loading.jpeg';
      }

      this.localStorageService
        .get2<string>('browse', 'linkId')
        .pipe(
          mergeMap((linkId) => {
            if (linkId === this.linkId) {
              this.realtimeCounter.increase(`links/${linkId}`, 1);
            }
            return of(linkId);
          }),
          concatMap((linkId) => {
            return this.localStorageService.delete2<string>('browse', 'linkId');
          })
        )
        .subscribe();
    }
  }

  onCardImageClick(): void {
    const url = this.item.link;

    /**
     * linkId === scrollId
     */
    const linkId = Objects.hashString(url);
    this.localStorageService.set2('browse', 'linkId', linkId).subscribe((v) => {
      this.searchCardService.open(url);
      if (!this.isDesktop) {
        setTimeout(() => {
          this.realtimeCounter.increase(`links/${linkId}`, 1);
        }, 1000);
      }
    });
  }

  name(): string {
    return 'search-card';
  }
}
