import firebase from 'firebase/compat/app';
import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Objects } from '../utils/objects';
import { distinctUntilChanged, mergeMap, tap } from 'rxjs/operators';

/**
 * @author: john@gomedialy.com
 * @version: 0.14, 12/13/2020
 */
export interface SearchData {
  query: string;
  items: SearchItem[];
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
}

export interface SearchItem {
  domain: string;
  title: string;
  link: string;
  description: string;
  image: string | null;
  screenshot: string | null;
  favicon: string;
  subtitle: string;
  createdAt: firebase.firestore.Timestamp | null;
  updatedAt: firebase.firestore.Timestamp | null;
  index: number; // for ordering within an array
}

@Injectable({
  providedIn: 'root',
})
export class GoogleSearchService {
  constructor(private afs: AngularFirestore) {}

  listenToSearchesData(query: string): Observable<SearchItem[]> {
    return this.afs
      .collection<SearchItem>('google_searches_data', (ref) => {
        return ref.where('query', '==', query).orderBy('index', 'asc');
      })
      .valueChanges()
      .pipe(
        // duplicates are not updated.
        distinctUntilChanged((x, y) => {
          return Objects.hashFast(x) === Objects.hashFast(y);
        })
      );
  }
}
