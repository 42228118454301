import * as hash from 'object-hash';

/**
 * @author: john@gomedialy.com
 * @version: 0.1, 09/18/2020
 * @version: 0.13, 01/08/2021
 */
export class Objects {
  private constructor() {}

  public static hashString(value: string): string {
    return hash.sha1(value);
  }

  public static hashObject(value: any): string {
    // return hash.sha1(JSON.stringify(value));
    return hash.sha1(value);
  }

  /**
   * Only to remove duplicates
   */
  public static hashFast(value: any): string {
    return hash.MD5(value);
  }

  public static fromMap<T>(map: Map<string, string>): T {
    return Array.from(map.entries()).reduce(
      (main, [key, value]) => ({ ...main, [key]: value }),
      {}
    ) as T;
  }

  public static convert<T>(value: any): T {
    return Objects.fromJson(Objects.toJson(value));
  }

  public static toJson(value: any): string {
    return JSON.stringify(value);
  }

  public static toPrettyJson(value: any): string {
    return JSON.stringify(value, Object.keys(value).sort(), 2);
  }

  /**
   * stringify with tabs inserted at each level
   */
  public static toPrettyJson2(value: any): string {
    return JSON.stringify(value, Object.keys(value).sort(), '\t');
  }

  public static fromJson<T>(json: string): T {
    return JSON.parse(json);
  }

  public static fromBase64Json<T>(base64Json: string): T {
    const json = atob(base64Json);
    return JSON.parse(json);
  }
}
