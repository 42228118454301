import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { Post } from '../models/posts';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PostService } from '../services/post.service';
import { DateTimeService } from '../services/date-time.service';
import { JSend } from '../models/jsend';
import { BaseComponent } from '../base.component';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeIn } from 'ng-animate';

/**
 * @author: john@gomedialy.com
 * @version: 0.14, 10/28/2020
 * @version: 0.15, 12/17/2020
 * @version: 0.16, 01/04/2021
 */
@Component({
  selector: 'app-subtitle',
  templateUrl: './subtitle.component.html',
  styleUrls: ['./subtitle.component.scss'],
  animations: [trigger('fadeIn', [transition('* => *', useAnimation(fadeIn))])],
})
export class SubtitleComponent
  extends BaseComponent
  implements OnInit, AfterViewInit {
  /* fields */
  fadeIn: any;
  defaultImage = 'https://via.placeholder.com/400x200.png?text=Image';

  // tslint:disable-next-line: no-input-rename
  @Input('input')
  post!: Post;
  // post$: Observable<Post> = EMPTY;

  @Output()
  events = new EventEmitter<JSend>();

  photoUrl = '';
  displayName = '';
  // createdAt: firebase.firestore.Timestamp = firebase.firestore.Timestamp.now();
  createdAt: Date = new Date();
  content = '';

  postEditorEnabled = false;
  isPending = false;
  isCommenting = false;
  likes = 0;
  dislikes = 0;
  commentFormGroup: FormGroup;
  likeIconColor = 'gray';
  dislikeIconColor = 'gray';

  constructor(private formBuilder: FormBuilder) {
    super();
    this.commentFormGroup = this.formBuilder.group({
      commentInputControl: ['', Validators.nullValidator],
    });
  }

  onReady(): void {
    // console.log('@@@@@@@@@@@@@@@ ngx-markdown ready...');
  }

  ngAfterViewInit(): void {}

  /**
   * TODO: this is called each time the data change.
   */
  ngOnInit(): void {
    if (this.post) {
      this.photoUrl = this.post.photoUrl;
      this.displayName = this.post.displayName;
      this.createdAt = this.post.createdAt.toDate();
      this.content = this.post.content;
      this.likes = this.post.likes;
      this.dislikes = this.post.dislikes;
    }
  }

  onLikeClick(): void {
    // post-board handles this.
    // this.postEventService.onLikeClick(this.node);
  }

  onDislikeClick(): void {
    // post-board handles this.
    // this.postEventService.onDislikeClick(this.node);
  }

  onCommentClick(): void {
    // post-board handles this.
    // this.postEventService.onCommentClick(this.node);
  }

  name(): string {
    return 'subtitle';
  }
}
