import { createReducer, on } from '@ngrx/store';
import * as MainActions from '../actions/main.actions';
import { JSend } from '../models/jsend';
import { Item } from '../models/youtube-data';

/**
 * @author: john@gomedialy.com
 * @version: 0.13, 09/15/2020
 * @version: 0.17, 10/31/2020
 * @version: 0.21, 12/20/2020
 * @version: 0.22, 01/07/2021
 */
export const mainFeatureKey = 'main';

// path, videoId
export interface MainState {
  type: string | null;
  path: string | null;
  videoId: string | null;
  offset: number;
  item: Item | null;
  tags: string[];
  query: string | null;
  jsend: JSend | null;
}

export const initialState: MainState = {
  type: null,
  path: null,
  videoId: null,
  offset: 0,
  item: null,
  tags: [],
  query: null,
  jsend: null,
};

export const mainReducer = createReducer(
  initialState,
  on(MainActions.hasScrolledToTop, (state, action) => {
    return { ...state, ...action };
  }),
  on(MainActions.scrollToTop, (state, action) => {
    return { ...state, ...action };
  }),
  on(MainActions.scrollDown, (state, action) => {
    return { ...state, ...action };
  }),

  on(MainActions.homeRefreshed, (state, action) => {
    return { ...state, ...action };
  }),
  on(MainActions.homeLoaded, (state, action) => {
    // return { ...state, ...action };
    return { ...initialState, type: action.type };
  }),

  on(MainActions.watchRefreshed, (state, action) => {
    return { ...state, ...action };
  }),

  on(MainActions.browseRefreshed, (state, action) => {
    return { ...state, ...action };
  }),

  on(MainActions.scrollChanged, (state, action) => {
    return { ...state, ...action };
  }),
  on(MainActions.routeChanged, (state, action) => {
    return { ...state, ...action };
  }),

  // TODO: Test
  // export const initialState: MainState = {
  //   type: null,
  //   path: null,
  //   videoId: null,
  //   offset: 0,
  //   item: null,
  //   tags: [],
  //   query: null,
  //   jsend: null,
  // };

  // on(MainActions.home, (state, action) => {
  //   return { ...state, ...action };
  //   return {
  //     type: action.type,
  //     path: null,
  //     videoId: null,
  //     offset: -1,
  //     item: null,
  //     tags: [],
  //     query: null,
  //     jsend: null,
  //   };
  // }),
  // on(MainActions.watch, (state, action) => {
  //   return { ...state, ...action };
  // }),

  on(MainActions.homeRequest, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),
  on(MainActions.homeResponse, (state, action) => {
    /**
     * Empty all the data back home.
     */
    return { ...initialState, type: action.type };
  }),
  on(MainActions.watchRequest, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),
  on(MainActions.watchResponse, (state, action) => {
    return { ...state, ...action };
  }),
  on(MainActions.searchRequest, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),
  on(MainActions.searchResponse, (state, action) => {
    return { ...state, ...action, jsend: action.jsend };
  }),
  on(MainActions.browseRequest, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),
  on(MainActions.browseResponse, (state, action) => {
    return { ...state, ...action, jsend: action.jsend };
  }),
  on(MainActions.browseReloadedRequest, (state, action) => {
    return { ...state, ...action, jsend: null };
  }),
  on(MainActions.browseReloadedResponse, (state, action) => {
    return { ...state, ...action, jsend: action.jsend };
  })
  // on(MainActions.loadVideoRequest, (state, action) => {
  //   return { ...state, ...action, jsend: null };
  // }),
  // on(MainActions.loadVideoResponse, (state, action) => {
  //   if (action.jsend.data) {
  //     return { ...state, ...action, jsend: action.jsend, ...action.jsend.data };
  //   }
  //   return { ...state, ...action, jsend: action.jsend };
  // })
);
